import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import TimePicker from 'react-time-picker';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer,
} from '../Modal.style';
import {
  CloseModal, Flexer, IconInput, ErrorDates,
  ErrorMsg, Title, Subtitle, ModalScheduleContainer, DateWrapper, TimeWrapper,
  SingleTimeWrapper, Session, ButtonShareContainer,
} from './style';
import MenuButton from '../../../atoms/MenuButton';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';
import { Button, CustomDatePicker } from '../../../atoms';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import { TOJ_SESSION_PLAN_SET } from '../../../../app/redux/actions/toj';
import { isArabic } from '../../../../utils/locale';

const modalId = 'ModalSchedule';

const ModalSchedule = ({
  onClose, closeIcon, vocabulary, data: info, setSessionPlan,
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(info);
  }, [info]);

  const [currentSlot, setCurrentSlot] = useState();
  const [prevSlot, setPrevSlot] = useState(null);
  const [nextSlot, setNextSlot] = useState(null);
  const [minDateLimit, setMinDateLimit] = useState(new Date());
  const [maxDateLimit, setMaxDateLimit] = useState(null);
  const [slotIsValid, setSlotIsValid] = useState(false);
  const [slotError, setSlotError] = useState();
  const [valuesChanged, setValuesChanged] = useState(false);

  const [selectedDate, setSelectedDate] = useState();
  const [selectedStarttime, setSelectedStarttime] = useState();
  const [selectedEndTime, setSelectedEndtime] = useState();

  const resetState = () => {
    setCurrentSlot(null);
    setPrevSlot(null);
    setNextSlot(null);
    setMinDateLimit(new Date());
    setMaxDateLimit(null);
    setSlotError('');
    setSlotIsValid(false);
    setValuesChanged(false);
    setSelectedDate(null);
    setSelectedStarttime(null);
    setSelectedEndtime(null);
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      if (data?.slot) setCurrentSlot(data?.slot);
      if (data?.slots && data?.slots[data?.index - 1]) setPrevSlot(data?.slots[data?.index - 1]);
      if (data?.slots && data?.slots[data?.index + 1]) setNextSlot(data?.slots[data?.index + 1]);
      setMaxDateLimit(new Date(Number(data?.deadline)));
    } else {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (currentSlot) {
      setSelectedDate(new Date(currentSlot.startDateTimestamp));
      setSelectedStarttime(new Date(currentSlot.startDateTimestamp));
      setSelectedEndtime(new Date(currentSlot.endDateTimestamp));
    }
    if (prevSlot) setMinDateLimit(moment(new Date(prevSlot.endDateTimestamp)).isBefore(new Date()) ? new Date() : new Date(prevSlot.endDateTimestamp));
    if (nextSlot) setMaxDateLimit(new Date(nextSlot.startDateTimestamp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot, prevSlot, nextSlot]);

  useEffect(() => {
    if (selectedDate && selectedStarttime && selectedEndTime) {
      const formattedSelectedDate = new Date(new Date().getFullYear(selectedDate), new Date(selectedDate).getMonth(), new Date(selectedDate).getDate());
      const startDateTimestamp = typeof selectedStarttime === 'string'
        ? moment(new Date(formattedSelectedDate)).add(selectedStarttime.split(':')[0], 'hours').add(selectedStarttime.split(':')[1], 'minutes').unix() * 1000
        : moment(new Date(formattedSelectedDate)).add(new Date(selectedStarttime).getHours(), 'hours').add(new Date(selectedStarttime).getMinutes(), 'minutes').unix() * 1000;
      const endDateTimestamp = typeof selectedEndTime === 'string'
        ? moment(new Date(formattedSelectedDate)).add(selectedEndTime.split(':')[0], 'hours').add(selectedEndTime.split(':')[1], 'minutes').unix() * 1000
        : moment(new Date(formattedSelectedDate)).add(new Date(selectedEndTime).getHours(), 'hours').add(new Date(selectedEndTime).getMinutes(), 'minutes').unix() * 1000;

      const nowTimestamp = moment(new Date()).unix() * 1000;
      const startTime = moment(startDateTimestamp);
      const endTime = moment(endDateTimestamp);
      const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
      const minimumDurationForEachSession = data?.totalAvailableSlotTime / (data?.slotSize - data?.slots.length);
      if (valuesChanged && nowTimestamp > startDateTimestamp) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotPastDate, 'invalidTimeSlotPastDate'));
      } else if (startDateTimestamp >= endDateTimestamp) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlot, 'invalidTimeSlot'));
      } else if (durationInMinutes < 30) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotMininum, 'invalidTimeSlotMininum'));
      } else if (prevSlot
        && (moment(new Date(startDateTimestamp)).isBetween(new Date(prevSlot.startDateTimestamp), new Date(prevSlot.endDateTimestamp))
          || moment(new Date(endDateTimestamp)).isBetween(new Date(prevSlot.startDateTimestamp), new Date(prevSlot.endDateTimestamp))
          || startDateTimestamp === prevSlot.startDateTimestamp || endDateTimestamp === prevSlot.startDateTimestamp
          || startDateTimestamp === prevSlot.endDateTimestamp || endDateTimestamp === prevSlot.endDateTimestamp)) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotOverlapping, 'invalidTimeSlotOverlapping'));
      } else if (nextSlot
        && (moment(new Date(startDateTimestamp)).isBetween(new Date(nextSlot.startDateTimestamp), new Date(nextSlot.endDateTimestamp))
          || moment(new Date(endDateTimestamp)).isBetween(new Date(nextSlot.startDateTimestamp), new Date(nextSlot.endDateTimestamp))
          || startDateTimestamp === nextSlot.startDateTimestamp || endDateTimestamp === nextSlot.startDateTimestamp
          || startDateTimestamp === nextSlot.endDateTimestamp || endDateTimestamp === nextSlot.endDateTimestamp)) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotOverlapping, 'invalidTimeSlotOverlapping'));
      } else if (data?.slots.length !== data.slotSize && durationInMinutes > data?.totalAvailableSlotTime) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotExceeds, 'invalidTimeSlotExceeds'));
      } else if (data?.slots.length !== data.slotSize && durationInMinutes > minimumDurationForEachSession) {
        setSlotIsValid(false);
        setSlotError(getValueVocabulary(vocabulary.invalidTimeSlotSession, 'invalidTimeSlotSession'));
      } else {
        setSlotError('');
        setSlotIsValid(true);
      }
    } else {
      setSlotError('');
      setSlotIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedStarttime, selectedEndTime]);

  const handleOnChangeStarttime = (time) => {
    if (time && typeof time !== 'object') {
      setSelectedStarttime(time);
      if (selectedDate && data?.isLastSession && data?.slotSize !== data?.slots.length) {
        const formattedSelectedDate = new Date(new Date().getFullYear(selectedDate), new Date(selectedDate).getMonth(), new Date(selectedDate).getDate());
        const startDateTimestamp = moment(new Date(formattedSelectedDate)).add(time.split(':')[0], 'hours').add(time.split(':')[1], 'minutes').unix() * 1000;
        setSelectedEndtime(moment(new Date(startDateTimestamp)).add(data?.totalAvailableSlotTime, 'minutes').format('HH:mm'));
      } else if (selectedDate && data?.slotSize === data?.slots.length) {
        const formattedSelectedDate = new Date(new Date().getFullYear(selectedDate), new Date(selectedDate).getMonth(), new Date(selectedDate).getDate());
        const startDateTimestamp = moment(new Date(formattedSelectedDate)).add(time.split(':')[0], 'hours').add(time.split(':')[1], 'minutes').unix() * 1000;
        const startTime = moment(data?.slot?.startDateTimestamp);
        const endTime = moment(data?.slot?.endDateTimestamp);
        const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
        setSelectedEndtime(moment(new Date(startDateTimestamp)).add(durationInMinutes, 'minutes').format('HH:mm'));
      }
    } else {
      setSlotIsValid(false);
    }
    setValuesChanged(true);
  };

  const handleOnChangeEndtime = (time) => {
    if (time && typeof time !== 'object') {
      setSelectedEndtime(time);
    } else {
      setSlotIsValid(false);
    }
    setValuesChanged(true);
  };

  const handleOnChangeSelectedDate = (date) => {
    setSelectedDate(date);
    setValuesChanged(true);
  };

  const handleOnClose = () => {
    resetState();
    onClose();
  };

  const handleOnSubmit = () => {
    const { tojId: idItem } = data;
    const formattedSelectedDate = new Date(new Date().getFullYear(selectedDate), new Date(selectedDate).getMonth(), new Date(selectedDate).getDate());
    const startDateTimestamp = typeof selectedStarttime === 'string'
      ? moment(new Date(formattedSelectedDate)).add(selectedStarttime.split(':')[0], 'hours').add(selectedStarttime.split(':')[1], 'minutes').unix() * 1000
      : moment(new Date(formattedSelectedDate)).add(new Date(selectedStarttime).getHours(), 'hours').add(new Date(selectedStarttime).getMinutes(), 'minutes').unix() * 1000;
    const endDateTimestamp = typeof selectedEndTime === 'string'
      ? moment(new Date(formattedSelectedDate)).add(selectedEndTime.split(':')[0], 'hours').add(selectedEndTime.split(':')[1], 'minutes').unix() * 1000
      : moment(new Date(formattedSelectedDate)).add(new Date(selectedEndTime).getHours(), 'hours').add(new Date(selectedEndTime).getMinutes(), 'minutes').unix() * 1000;
    let payload = {};
    if (currentSlot) {
      payload = {
        idItem,
        idSlot: currentSlot.idSlot,
        slots: [{
          startDateTimestamp,
          endDateTimestamp,
        }],
      };
    } else {
      payload = {
        idItem,
        slots: [{
          startDateTimestamp,
          endDateTimestamp,
        }],
      };
    }
    setSessionPlan(payload);
    handleOnClose();
  };

  return (
    <Modal id={modalId} onModalClose={handleOnClose}>
      <Container>
        {closeIcon && (
          <CloseModal>
            <MenuButton isOpen onClick={handleOnClose} />
          </CloseModal>
        )}
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          widthUnit="px"
          width="680"
          heightUnit="%"
          height="90"
          className="modalSchedule"
        >
          <ModalScheduleContainer arabic={isArabic()}>
            <CloseModal onClick={onClose} className="icon-close" />
            <Title><Arial type="modalScheduleTitle" vocabulary="modalScheduleTitle">{getValueVocabulary(vocabulary.modalScheduleTitle, 'modalScheduleTitle')}</Arial></Title>
            <Subtitle><Arial type="modalScheduleLabels" vocabulary="modalSetScheduleDescription">{getValueVocabulary(vocabulary.modalSetScheduleDescription, 'modalSetScheduleDescription')}</Arial></Subtitle>
            <Session>
              <Arial style={{ color: '#a3a6b2', marginBottom: '20px' }} type="slotTitle">
                {isArabic() ? (
                  `${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')} °${data?.index + 1}`
                ) : (
                  `${data?.index + 1}° ${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')}`
                )}
              </Arial>
              <Flexer>
                <DateWrapper>
                  <Arial type="modalScheduleLabels">{getValueVocabulary(vocabulary.modalScheduleInputDate, 'modalScheduleInputDate')}</Arial>
                  <CustomDatePicker
                    minDate={minDateLimit}
                    maxDate={maxDateLimit}
                    onChange={(date) => handleOnChangeSelectedDate(date)}
                    value={selectedDate || (data?.slot?.startDateTimestamp ? new Date(data?.slot?.startDateTimestamp) : null)}
                  />
                </DateWrapper>
                <TimeWrapper>
                  <SingleTimeWrapper>
                    <Arial type="modalScheduleLabels">{getValueVocabulary(vocabulary.modalScheduleInputStartTime, 'modalScheduleInputStartTime')}</Arial>
                    <TimePicker
                      onChange={handleOnChangeStarttime}
                      amPmAriaLabel="AM/PM"
                      clearIcon={null}
                      hourPlaceholder="HH"
                      minutePlaceholder="MM"
                      format="hh:mm a"
                      clockIcon={<IconInput className="icon-clock" />}
                      value={selectedStarttime || (data?.slot?.startDateTimestamp ? new Date(data?.slot?.startDateTimestamp) : null)}
                    />
                  </SingleTimeWrapper>
                  <SingleTimeWrapper>
                    <Arial type="modalScheduleLabels">{getValueVocabulary(vocabulary.modalScheduleInputEndTime, 'modalScheduleInputEndTime')}</Arial>
                    <TimePicker
                      onChange={handleOnChangeEndtime}
                      minTime={selectedStarttime}
                      amPmAriaLabel="AM/PM"
                      clearIcon={null}
                      hourPlaceholder="HH"
                      minutePlaceholder="MM"
                      format="hh:mm a"
                      clockIcon={<IconInput className="icon-clock" />}
                      disabled={data?.isLastSession || data?.slotSize === data?.slots?.length}
                      value={selectedEndTime || (data?.slot?.endDateTimestamp ? new Date(data?.slot?.endDateTimestamp) : null)}
                    />
                  </SingleTimeWrapper>
                </TimeWrapper>
              </Flexer>
            </Session>
            {slotError && <ErrorDates><ErrorMsg><Arial type="slotError">{slotError}</Arial></ErrorMsg></ErrorDates>}
            <ButtonShareContainer>
              <Button
                btnText={getValueVocabulary(vocabulary.modalScheduleButtonSave, 'modalScheduleButtonSave')}
                disabled={!slotIsValid || !valuesChanged}
                onClick={handleOnSubmit}
                type="tojDetail"
              />
            </ButtonShareContainer>
          </ModalScheduleContainer>
        </ModalGlobalContainer>
      </Container>
    </Modal>
  );
};

ModalSchedule.propTypes = {
  closeIcon: PropTypes.bool,
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  data: PropTypes.object,
  // HOC (connect, dispatch)
  setSessionPlan: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalSchedule.id = modalId;

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { data } = state.modal;
    return {
      vocabulary,
      data,
    };
  },
  dispatch => ({
    setSessionPlan: (payload) => dispatch({ type: TOJ_SESSION_PLAN_SET._REQUEST, payload }),
    onClose: () => dispatch({ type: MODAL_CLOSE }),
  })
)(ModalSchedule);
