import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getSurveyClassroomContentMock, setInsertSurveyMock } from './mock';

export default {
  getSurveyContent: ({ id }) => {
    if (shouldUseMock()) {
      return getSurveyClassroomContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/survey/${id}/retrieve?object_type=virtual_room`, 'GET');
  },
  setInsertSurvey: body => {
    const { remote_classroom_id, ...otherBody } = body;
    if (shouldUseMock()) {
      return setInsertSurveyMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}integration/survey/${remote_classroom_id}/insert?object_type=remote_classroom`,
      'POST',
      otherBody
    );
  }
};
