import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import {
  LEARNING_EVENTS_PLANNABLE_LIST_GET,
  LEARNING_EVENTS_PLANNED_LIST_GET
} from '../actions/learningevents';
import learningevents from '../../api/learningevents';
import { calcPagination } from '../../../utils/pagination';

function* getPlannedList({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(learningevents.getPlannedList, filters);
    yield put({ type: LEARNING_EVENTS_PLANNED_LIST_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: LEARNING_EVENTS_PLANNED_LIST_GET._ERROR, error });
    console.log('sagas > learningevents > getPlannedList', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPlannedListWatch() {
  yield takeLatest(LEARNING_EVENTS_PLANNED_LIST_GET._REQUEST, getPlannedList);
}

function* getPlannableList({
  loader = true, subset = false
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { pager: { current_page, total_pages } = {} } = yield select(state => state.learningevents);
    const { next_page, isLastPage } = calcPagination(subset ? { current_page, total_pages } : {});
    if (!isLastPage) {
      const completeFilters = {
        items_per_page: 24, page: next_page
      };
      const { data: { data: response, pager } = {} } = yield call(learningevents.getPlannableList, completeFilters);
      yield put({
        type: LEARNING_EVENTS_PLANNABLE_LIST_GET._SUCCESS, response, pager, subset
      });
    }
  } catch (error) {
    yield put({ type: LEARNING_EVENTS_PLANNABLE_LIST_GET._ERROR, error });
    console.log('sagas > learningevents > getPlannableList', error);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* getPlannableListWatch() {
  yield takeLatest(LEARNING_EVENTS_PLANNABLE_LIST_GET._REQUEST, getPlannableList);
}

export default [
  getPlannedListWatch(),
  getPlannableListWatch()
];
