import styled, { css } from 'styled-components';

export const Title = styled.div`
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.tablet} {
    font-size: 25px;
    text-align: center;
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 60px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Category = styled.div`
  cursor: pointer;
  font-size: 14px;
  line-height: 1.14;
  transition: ${props => props.theme.constants.transitionBase};
    
  &:hover {
    opacity: 0.6;
  }
  
  ${props => props.isActive && css`
    text-shadow: -0.06ex 0 white, 0.06ex 0 white;
  `};
`;

export const CategoriesContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 25%;
  padding-right: 20px;

  @media ${props => props.theme.device.tablet} {
    max-width: 75%;
    margin-bottom: 30px;
  }

  @media ${props => props.theme.device.mobileL} {
    max-width: 90%;
  }
  
  ${Category}:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const AnswerBody = styled.div`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.veryLightPink};
  font-size: 14px;
  line-height: 1.29;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
`;

export const Contact = styled.div`
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: 10px;
  text-transform: uppercase;
  transition: ${props => props.theme.constants.transitionBase};
  span {
    margin-right: 6px;
  }
`;

export const AnswerContainer = styled.div`
  overflow: hidden;
  width: 100%;
  ${AnswerBody} {
    max-height: ${props => (props.isOpen ? '100%' : 0)};
    padding:  ${props => (props.isOpen ? '10px 50px 56px 20px' : '0 50px 0 20px')};
    ${Contact} {
      display: ${props => (props.isOpen ? 'inline-flex' : 'none')};
      opacity: ${props => (props.isOpen ? 1 : 0)};
    }
  }
`;

export const AnswersContainer = styled.div`
  border-left: 1px solid ${props => props.theme.colors.gray};
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  max-width: 75%;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  
  ${AnswerContainer}:not(:last-child) {
    margin-bottom: 10px;
  }

  @media ${props => props.theme.device.tablet} {
    border-left: none;
    padding-left: 0px;
  }

  @media ${props => props.theme.device.mobileL} {
    max-width: 90%;
  }
`;

export const AnswerText = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.8)};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  padding: 10px 50px 10px 20px;
  transition: ${props => props.theme.constants.transitionBase};
  
  &:hover {
    opacity: 0.6;
  }
  
  span {
    position: absolute;
    font-size: 10px;
    right: 20px;
    top: 20px;
  }
`;


export const FaqContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  padding: 0px 0px 96px;

  ${({ arabic }) => arabic && css`
    ${FaqWrapper} {
      flex-direction: row-reverse;
      @media ${props => props.theme.device.tablet} {
        flex-direction: column;
      }
    }
    ${AnswersContainer} {
      direction: rtl;
      border-left: 0;
      padding-left: 0;
      border-right: 1px solid #5c5c5c;
      padding-right: 20px;
    }
    ${Title} {
      text-align: center !important;
      direction: rtl;
    }
    ${AnswerText} {
      padding: 10px 20px 10px 20px !important;
      direction: rtl;
      span {
        right: auto;
        left: 20px;
      }
    }
  `}
`;
