import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import tableHeader from '../../../utils/table-header';


import {
  Container,
  Card,
  CardContent,
  CardHead,
  CardItem,
  TableCardContainer,
  Description,
} from './style';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';

const TableCard = ({
  type, data, onClick, vocabulary,
}) => {
  const getDescription = (desc_type, values) => {
    const typeLabels = {
      pill_collection: 'Raccolta di pillole',
      news: 'News',
      pill_item: 'Video Pillola',
      course: 'Corso',
      toj: 'Toj',
      remote_classroom: 'Aula Remota',
    };
    let value = values[desc_type];
    if (desc_type === 'date') {
      value = moment(new Date(value * 1000)).format('DD/MM/YYYY h:mm A');
    }
    if (desc_type === 'type') {
      value = typeLabels[value];
    }
    let description = '';
    description = value || '-';
    return description;
  };

  const getRow = row => {
    const table = tableHeader.filter(header => header.loc === type)[0];
    return table.headers.map((header, index) => (
      <CardContent
        key={`content-${index}`}
        className={`${table.loc} ${header.field}`}
      >
        <CardHead key={`head-${index}`}><Arial type="tableTitle" configuration={{ lineHeight: 1.29, fontWeight: 700 }}>{header.label}</Arial></CardHead>
        <CardItem key={`item-${index}`}>
          <Arial type="tableDescription">
            <Description>{getDescription(header.field, row)}</Description>
          </Arial>
        </CardItem>
      </CardContent>
    ));
  };

  const getEmptyRow = () => {
    const table = tableHeader.filter(header => header.loc === type)[0];
    return table.headers.map((header, index) => (
      <CardContent
        key={`content-${index}`}
        className={`${table.loc} ${header.field}`}
      >
        {index === 0
        && <CardHead key={`head-${index}`}><Arial type="tableDescription" configuration={{ lineHeight: 1.29 }}>{getValueVocabulary(vocabulary.noResults, 'noResults')}</Arial></CardHead>}
      </CardContent>
    ));
  };

  return (
    <Container arabic={isArabic()}>
      <TableCardContainer>
        {data && data.length ? (
          data.map((row, index) => (
            <Card key={`card-${index}`} onClick={() => onClick(row)}>{getRow(row)}</Card>
          ))
        ) : (
          <Card>{getEmptyRow()}</Card>
        )}
      </TableCardContainer>
    </Container>
  );
};

TableCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default connect(
  (state) => {
    const { vocabulary } = state.app;
    return { vocabulary };
  }
)(TableCard);
