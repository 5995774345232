import styled, { css } from 'styled-components';

export const AccordionContainer = styled.div`
  padding: 20px 0px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 200px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #5c5c5c;
`;

export const AccordionContent = styled.div`
  height: 35px;
  cursor: pointer;
  padding: 0px 20px 20px;
`;

export const Icon = styled.span`
  font-size: 20px;
  ${props => props.visibleBody
    && css`
      transform: rotate(-180deg);
    `}
`;

export const Body = styled.div`
  width: 100%;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in, opacity 0.2s ease-in;

  ${props => props.isOpen
    && css`
      min-height: auto;
      height: 100%;
      opacity: 1;
      padding: ${props.noPadding ? '0px' : '0px 20px 0px'};
      border-top: 1px solid ${props.theme.colors.brownGrey};
      overflow: visible;
    `}
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  width: 100%;
  height: 30px;
`;
