import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CommentContainer, CommentsContainer, CommentsWrapper,
  CommentWrapper, TextArea, Timestamp, UserAvatar, Text, CommentBody,
  CommentInfo, CommentsHeader, MaxLength
} from './style';
import Arial from '../../typography/arial';
import formatTimestamp from '../../../utils/formatTimestamp';
import { connect } from 'react-redux';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const SurveyComments = ({
  list = [], onChange, value: val, vocabulary
}) => {
  const [newComment, setNewComment] = useState(val);


  const handleOnchangeNewComment = ({ target: { value } }) => {
    setNewComment(value);
    if (onChange) onChange(value);
  };

  return (
    <CommentsContainer>
      <CommentsHeader>
        <CommentsWrapper>
          <TextArea maxLength={250} onChange={handleOnchangeNewComment} value={newComment} placeholder={getValueVocabulary(vocabulary.writeACommentPlaceholder, 'writeACommentPlaceholder')} />

          <MaxLength>
            <Arial type="newsDate" vocabulary="maxCharacters">{getValueVocabulary(vocabulary.maxCharacters, 'maxCharacters')}</Arial>
          </MaxLength>
        </CommentsWrapper>
      </CommentsHeader>
      <CommentContainer>
        {list.map((item = {}, index) => (
          <CommentWrapper key={`comments${index}`}>
            <UserAvatar avatar={item?.user_picture} />
            <CommentBody>
              <CommentInfo>
                <Arial type="newsTitle">{`${item?.field_name} ${item?.field_surname}`}</Arial>
                <Timestamp>
                  <Arial type="newsDate">{formatTimestamp(item?.created)}</Arial>
                </Timestamp>
              </CommentInfo>
              <Text>
                <Arial type="guidebookCategory"><span dangerouslySetInnerHTML={{ __html: item?.comment_body }} /></Arial>
              </Text>
            </CommentBody>

          </CommentWrapper>
        ))}

      </CommentContainer>

    </CommentsContainer>
  );
};

SurveyComments.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  // HOC (connect, state)
  vocabulary: PropTypes.object
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  }
)(SurveyComments);
