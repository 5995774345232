import styled, { css } from 'styled-components';


export const ProductCourseElementDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  > div:last-child {
    color: #a3a6b2;
  }
`;

export const ProductCourseDetails = styled.div`
  display: flex;
  align-items: center;
  
  > div:first-child {
    width: fit-content;
    margin-right: 5px;
  }
`;

export const ProductCourseTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
  font-size: 14px;
  white-space: normal;
  text-overflow: ellipsis;
  max-width: 100%;

  >div.tooltip {
    display: inline;
    position: relative;

    .tooltip:hover:after{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      background: #444;
      border-radius: 8px;
      color: #fff;
      content: attr(title);
      margin: -82px auto 0;
      font-size: 16px;
      padding: 13px;
      width: 220px;
    }
    .tooltip:hover:before{
      border: solid;
      border-color: #444 transparent;
      border-width: 12px 6px 0 6px;
      content: "";
      left: 45%;
      bottom: 30px;
      position: absolute;
    }
  }
`;

export const ProductCourseContainer = styled.div`
  align-items: center;
  background-color: ${props => (props.active ? props.theme.colors.sharkLight : props.theme.colors.transparent)};
  border-radius: 10px;
  cursor: ${props => (props.blocked ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 98%;
  padding: 10px 10px 10px 5px;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  opacity: ${props => (props.blocked ? 0.4 : 1)};

  &:after {
    content: '';
    border-bottom: 1px solid ${props => props.theme.colors.gray};
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${props => !props.blocked
  && css`
      &:hover {
        background-color: ${props.active
    ? props.theme.colors.black
    : props.theme.mixin.rgba(props.theme.colors.black, 0.8)};
      }
    `};

  ${({ arabic }) => arabic && css`
    flex-direction: row-reverse;

    ${ProductCourseTitle} {
      direction: rtl;
    }

    ${ProductCourseElementDetails} {
      margin-left: 0;
      margin-right: 10px;
      align-items: flex-end;
    }
    ${ProductCourseDetails} {
      flex-direction: row-reverse;
      > div:first-child {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  `};
`;

export const ProductCourseElementCounter = styled.div`
  margin-right: 5px;
  font-size: 12px;
  width: 14px;
  text-align: center;
  color: ${props => props.theme.colors.brownGrey};
`;

export const ProductCourseCover = styled.div`
  background-image: url(${props => props.cover});
  background-size: cover;
  border-radius: 4px;
  min-height: 60px;
  min-width: 60px;
`;

export const ProductCoursesListCount = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: fit-content;
    width: auto;
  }

  > div:first-child {
    margin-right: 10px;
  }
`;

export const IconCompleted = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.chartreuse};
  overflow:hidden;
  border-radius:50%;
`;
