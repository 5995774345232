import React from 'react';
import PropTypes from 'prop-types';
import {
  IconCompleted,
  ProductCourseContainer,
  ProductCourseCover,
  ProductCourseElementDetails,
  ProductCourseTitle,
  ProductCourseDetails,
} from './style';
import Arial from '../../typography/arial';
import { convertDuration } from '../../../utils/common';

const LearningOjbectCard = ({
  item: { cover, title, duration, blocked, status },
  onClick,
  isActive = false,
  label,
  arabic,
}) => (
  <ProductCourseContainer arabic={arabic} onClick={onClick} active={isActive} blocked={blocked}>
    <ProductCourseCover cover={cover} />
    <ProductCourseElementDetails>
      <ProductCourseTitle title={title} type="collectionCourseTitle">{title}</ProductCourseTitle>
      <ProductCourseDetails>
        <Arial configuration={{ size: 14 }}>{convertDuration(duration, 'mm:ss')}&nbsp; {label}</Arial>
        {(status === 'completed' || status === 'passed') && <IconCompleted className="icon-shape" />}
      </ProductCourseDetails>
    </ProductCourseElementDetails>
  </ProductCourseContainer>
);

LearningOjbectCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  arabic: PropTypes.bool,
};

export default LearningOjbectCard;
