import produce from 'immer';
import {
  FAVOURITE_LIST_GET
} from '../actions/favourite';
import { AUTH_LOGOUT } from '../actions';

const initialState = {
  data: [],
  pager: {}
};

const favouriteReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case AUTH_LOGOUT._REQUEST:{
      draft = initialState;
      break;
    }

    case FAVOURITE_LIST_GET._SUCCESS: {
      const { data, pager, subset } = action;
      draft.data = subset ? [...state.data, ...data] : data;
      draft.pager = pager;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default favouriteReducer;
