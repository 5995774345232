import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px 20px 14px 20px;
  
  @media ${props => props.theme.device.mobileL} {
    height: initial;
  }
`;

export const Title = styled.div`
  text-align: center;
  margin: 19px auto 0 auto;
  color: ${props => props.theme.colors.white};
`;

export const Subtitle = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin: auto auto 20px;
  width: 320px;
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 400px;
`;

export const CloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 99;
  font-size: 16px;
  top: 20px;
  right: 20px;
  color: ${props => props.theme.colors.white};
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
  width: 380px;
  margin-right: auto;
  margin-left: auto;

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  margin: 4px auto 0 auto;
  width: 200px;
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    
    > button {
      margin-bottom: 0;
      border-radius: 0;
    }
  }
`;

