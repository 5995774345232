import styled from 'styled-components';

const CourseStatusContainer = styled.div`
  padding: 3px 10px;
  color: ${props => props.color};
  text-transform: uppercase;
  text-align: center;
  background: ${props => props.background};
`;

export default CourseStatusContainer;
