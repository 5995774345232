import produce from 'immer';
import PropTypes from 'prop-types';

import { AUTH_LOGOUT, USER_INFO_GET } from '../actions';
// import storage from '../../../utils/storage';

export const UserDataInterface = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string,
  // fiscal_code: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  mail: PropTypes.string,
  picture: PropTypes.string,
  group: PropTypes.string,
  last_accessed_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  last_login_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  created_time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bad_dealer: PropTypes.oneOf([0, 1, '0', '1'])
});

const initialState = {
  data: {},
  isDealer: true
};

const userReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case USER_INFO_GET._SUCCESS: {
      const { response = {} } = action;
      // storage.write('default_language', response.lang);
      draft.data = response;
      break;
    }

    case AUTH_LOGOUT._SUCCESS: {
      draft.data = initialState.data;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default userReducer;
