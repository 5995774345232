import styled, { css } from 'styled-components/macro';

export const Top = styled.div`
  z-index: 1;
`;
export const Bottom = styled.div`
  z-index: 1;
`;

export const ScheduleLabel = styled.div`
  width: 135px;
  height: 19px;
  border-radius: 14px;
  border: solid 1px #fff;
  background-color: rgba(61, 63, 72, 0.3);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  ${({ alt }) => alt && css`
    height: 37px;
  `}
`;

export const Deadline = styled.div`
  font-size: 10px;
  line-height: 2;
  margin-left: 10px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 20px;
`;
export const Info = styled.div`
  font-size: 10px;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 178px;
  color: ${props => props.theme.colors.white};
  z-index: 0;
  position: absolute;
  top: 31px;
  right: -42px;
  transform: rotate(45deg);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  ${({ alt }) => alt && css`
    height: 42px;
    right: -46px;
    top: 23px;
  `}
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    background-color: #9e9e9e;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    background-color: #9e9e9e;
    transform: rotate(45deg);
  }
  
  & > div {
    z-index: 1;
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 8px;

    ${({ alt }) => alt && css`
      padding: 8px 31px;
    `}
    
    &:after {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(17.8% 0%,82% 0%,100% 100%,0% 100%);
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: -1;
      background-color: white;

      ${({ alt }) => alt && css`
        clip-path: polygon(23% 0%,76% 0%,100% 100%,0% 100%);
      `}
    }
  }
`;

export const ContactHRContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ContactHRIcon = styled.div`
  font-size: 20px;
  margin-right: 5px;
`;
export const ContactHRLabel = styled.div``;

export const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: 275px;
  height: 270px;
  border-radius: 4px;
  padding: 20px;  
  transition: ${props => props.theme.constants.transitionBase};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8));
    transition: ${props => props.theme.constants.transitionBase};
  }
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    &:before {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
    }
  }
  
  ${({ isExpired, theme }) => isExpired && css`
    cursor: not-allowed;
    &:before {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1));
    }
    &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      &:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1));
      }
    }
    ${Banner} {
      color: ${theme.colors.tamarillo};
    }
    ${Title} {
      opacity: 0.8;
    }
    ${Info} {
      opacity: 0.8;
    }
  `}

  ${({ arabic }) => arabic && css`
    ${Banner} {
      right: auto;
      left: -42px;
      transform: rotate(315deg);
    }

    ${ScheduleLabel} {
      margin-left: auto;
    }

    ${ContactHRContainer} {
      flex-direction: row-reverse;
    }
    ${ContactHRIcon} {
      margin-right: 0;
      margin-left: 5px;
    }
  `}
`;
