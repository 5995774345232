import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../ui/gridSystem';
import {
  CategoriesContainer, FaqContainer, FaqWrapper,
  Title, AnswersContainer, AnswerContainer,
  AnswerText, AnswerBody, Category,
} from './style';
import { FAQ_GET } from '../../redux/actions';
import BackButton from '../../../ui/atoms/BackButton';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Arial from '../../../ui/typography/arial';
import { NoResult } from '../../../ui/atoms';
import HtmlRaw from '../../../ui/atoms/HtmlRaw';
import { isArabic } from '../../../utils/locale';

const renderAnswer = (question, answer, callback, isOpen, category, index) => (
  <AnswerContainer key={`${category}-${index}`} isOpen={isOpen}>
    <AnswerText onClick={callback}> <HtmlRaw html={question} /> <span className={isOpen ? 'icon-grow' : 'icon-decrease'} /></AnswerText>
    <AnswerBody>
      <HtmlRaw html={answer} />
      {/*<Contact onClick={() => console.log('contact', question)}><span className="icon-pencil" />contattaci</Contact>*/}
    </AnswerBody>
  </AnswerContainer>
);

const Faq = ({
  categories, getFaq, faqs: items, vocabulary,
}) => {
  const [activeCategory, setActiveCategory] = useState(categories?.length ? categories[0] : {});
  const [activeAnswers, setActiveAnswers] = useState([]);
  const [activeAnswer, setActiveAnswer] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    getFaq();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveCategory(categories?.length ? categories[0] : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    setActiveAnswers((items || []).find((item) => item.category === activeCategory?.id)?.data || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    setActiveAnswer(activeAnswers?.length ? 0 : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnswers]);

  const selectCategory = (category) => {
    setActiveCategory(category);
  };

  return (
    <FaqContainer arabic={isArabic()}>
      <Container className="p-0">
        <BackButton />
        <Title><Arial type="EditorialAboutTitle" vocabulary="faqTitle">{getValueVocabulary(vocabulary.faqTitle, 'faqTitle')}</Arial></Title>
        <FaqWrapper>
          { items?.length ? (
            <>
              <CategoriesContainer>
                {(categories || []).map(category => (
                  <Category
                    key={category?.id}
                    isActive={category?.id === activeCategory?.id}
                    onClick={() => selectCategory(category)}
                    title={category?.name}
                  >{category?.name}
                  </Category>
                ))}
              </CategoriesContainer>
              <AnswersContainer>
                {activeAnswers.length
                  ? activeAnswers.map((data, index) => renderAnswer(data.title, data.description, () => setActiveAnswer(index), activeAnswer === index, data.category, index))
                  : (<NoResult />)
                }
                {}
              </AnswersContainer>
            </>
          ) : (<NoResult />)
          }
        </FaqWrapper>
      </Container>
    </FaqContainer>
  );
};

Faq.propTypes = {

  // HOC (connect, state)
  categories: PropTypes.arrayOf(PropTypes.object),
  faqs: PropTypes.arrayOf(PropTypes.object),
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getFaq: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { faq: { categories, faqs } = {}, vocabulary } = state.app;
    return {
      categories, faqs, vocabulary,
    };
  },
  dispatch => ({
    getFaq: () => dispatch({ type: FAQ_GET._REQUEST }),
  })
)(Faq);
