import styled, { css } from 'styled-components';
import { Container } from '../../../ui/gridSystem';

export const FavouriteRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 25px);

  @media ${props => props.theme.device.tablet} {
    justify-content: center; 
  }
`;

export const FavouriteCol = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;
`;

export const ViewMore = styled.div`
  margin: 25px auto;
  text-align: center;
  width: 100%;
  
  div[data-vocabulary="viewMore"] {
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }
`;

export const FavouritesContainer = styled(Container).attrs(() => ({
  className: 'p-0'
}))`
  div[data-vocabulary="favouritesTitle"] {
    padding: 40px 0 45px 0;
  }

  ${({ arabic }) => arabic && css`
  
    ${FavouriteRow} {
      justify-content: flex-end;

      @media ${props => props.theme.device.tabletL} {
        justify-content: center;
      }
    }
  `}
`;
