import styled, { css } from 'styled-components';

const InputSearch = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  //border-radius: 4px;
  padding-bottom: 6px;
  padding-right: 30px;
  margin-right: 16px;
  outline: none;
  color: #fff;
  font-size: 13px;
  width: 100%;
  padding: 6px 36px 6px 6px;
  transition: ${props => props.theme.constants.transitionBase};

  &::placeholder {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    opacity: 1;
  }

  &:focus,
  &:active {
    //background-color: ${props => props.theme.colors.white};
    //color: ${props => props.theme.colors.black};
    color: #fff;
    //padding: 6px 36px 6px 6px;
    //border-radius: 6px;
    &::placeholder {
      //color: ${props => props.theme.colors.black};
    }
    & ~ span {
      //color: ${props => props.theme.colors.themeGreen};
    }
  }

  /* @media ${props => props.theme.device.mobileL} {
    margin-right: 0;
    &::placeholder {
      color: ${props => props.theme.colors.black};
      font-size: 13px;
      font-weight: 300;
    }
    color: ${props => props.theme.colors.black};
    padding: 6px 36px 6px 6px;
    &::placeholder {
      color: ${props => props.theme.colors.black};
    }
    & ~ span {
      color: ${props => props.theme.colors.themeGreen};
    }
  } */
`;

const IconSearch = styled.span`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  color: #fff;
  transition: ${props => props.theme.constants.transitionBase};

  &:before {
    color: #fff;
  }
`;

const Container = styled.div.attrs(props => ({
  className: `${props.mobile ? 'mobile' : ''}`
}))`
  position: relative;
  width: 100%;

  .link-route-search {
    color: #fff;
  }

  ${({ arabic }) => arabic && css`
    margin: 0;
    margin-left: 16px;
    ${InputSearch} {
      text-align: right;
      padding: 6px 6px 6px 36px;

      @media ${props => props.theme.device.mobileL} {
      }
    }

    ${IconSearch} {
      right: auto;
      left: 5px;
      transform: scale(-1, 1) translate(-50%,-50%);
    }
  `}

 /*  @media ${props => props.theme.device.tablet} {
    &.mobile {
      padding-left: 10px;

      form {
        display: flex;
        justify-content: flex-end;
      }

      ${IconSearch} {
        font-size: 23px;
      }
      ${InputSearch} {
        width: 0;
        padding: 0;
        font-size: 23px;
        transition: 0.5s all;
      }

      ${props => props.isOpen
        && css`
          ${InputSearch} {
            width: 100%;
            transition: 0.5s all;
            padding-right: 35px;

            &::placeholder {
              font-size: 16px;
            }
          }
        `}
    }
  } */
`;

export { Container, InputSearch, IconSearch };
