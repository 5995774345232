export const newFormField = ({
  field = '', value = '', valid, required = false, type, touched = false, survey, mandatory, firstQuestion
}) => ({
  field, value, valid: valid || !required, required, type, touched, survey, mandatory, firstQuestion
});

export const newForm = (fields = [{}]) => {
  let form = {};
  fields.forEach(({
    field = '', valid = false, value, required, type, touched, survey, mandatory, firstQuestion
  }) => {
    form = {
      ...form,
      [field]: {
        field, value, valid, required, touched, type, survey, mandatory, firstQuestion
      }
    };
  });
  return form;
};

export const isValidForm = (form = {}) => {
  let isValid = true;
  Object.keys(form).forEach(key => {
    const { required = false, value, valid = false } = form[key];
    isValid = isValid && (required ? Boolean(value) && typeof valid !== 'string' && valid : valid && typeof valid !== 'string');
  });
  return isValid;
};


export const getValues = (form = {}) => {
  const values = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(form).map(key => { values[key] = form[key]?.value; });
  return values;
};
