import styled from 'styled-components';

const AuthContainer = styled.div`
  background-color: ${props => props.theme.colors.primaryColor};
  color: ${props => props.theme.colors.white};
  height: 100vh;
  width: 100vw;
`;

const Background = styled.div`
  height: 100%;
  position: relative;
  width: 45vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 20vh;
    width: 100%;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

const FormContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 55%;
  width: 100%;
  max-width: 35vw;

  @media ${props => props.theme.device.mobileL} {
    position: relative;
    left: unset;
    top: unset;
    max-width: unset;
  }
`;

const FormTitle = styled.div`
  position: relative;
  > div[data-vocabulary] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: fit-content;
  margin: 10px auto 36px auto;
`;

const Image = styled.img`
  margin-bottom: 60px;
`;

const InputWrapper = styled.div`
  margin: 20px auto 0 auto;
  width: 400px;
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  margin: 25px auto 0 auto;
  width: 400px;
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 0;
  }
`;

export {
  AuthContainer, Background, Image,
  FormContainer, InputWrapper, FormTitle,
  ButtonContainer, LogoContainer
};
