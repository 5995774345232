import styled from 'styled-components';

export const DownloadLinkContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;

  div:hover {
    text-decoration: underline;
  }
`;

export const Link = styled.a`
  display: flex;
  color: ${props => props.theme.colors.primaryColor};

  &:hover {
    color: ${props => props.theme.colors.primaryColor};
  }
`;

export const Icon = styled.span`
  transform: rotate(90deg) translate(-1px);
`;
