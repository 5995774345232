const colors = theme => {
  let color = '';
  switch (theme) {
    case 'themeGreen':
      color = '#258900';
      break;
    case 'themeBlack':
      color = '#2c2d32';
      break;
    case 'themeBlue':
      color = '#256ca5';
      break;
    case 'themeYellow':
      color = '#ffae02';
      break;
    default:
      color = '#ffffff';
  }

  return {
    /* Main */
    primaryColor: color,
    primary: '#0C304E',
    primaryDark: '#3D597A',
    primaryMedium: '#6B86A9',
    primaryLight: '#C4CFDD',
    primaryLighter: '#F4F7FC',
    primaryThemeBlue: '#00b2f3',
    primaryYellow: '#ffae02',
    primaryGreen: '#258900',
    secondary: '#7CB342',
    secondaryDark: '#659533',
    secondaryMedium: '#9CCC65',
    secondaryLight: '#F5FAF0',
    primaryBlack: '#000',
    black: '#222222',
    white: '#ffffff',
    body_bg: '#f2f5fa',
    brownGrey: '#989898',
    darkGrassGreen: '#268900',
    islamicGreen: '#03ab03',
    transparent: 'transparent',
    veryLightPink: '#e3e3e3',
    black30: 'rgba(0, 0, 0, 0.3)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black40: 'rgba(0, 0, 0, 0.4)',
    black50: 'rgba(0, 0, 0, 0.5)',
    black05: 'rgba(34, 34, 34, 0.5)',
    black07: 'rgba(63, 63, 63, 0.7)',
    shark: '#191a1f',
    sharkLight: '#1F2025',
    primaryBlue: '#0190e5',
    lightBlue: 'rgba(0, 178, 243, 0.6)',
    midGray: '#575a64',
    midGrayDark: '#242629',

    /* Secondary */
    armadillo: '#4A453B',
    orange: '#ffcb6c',
    yellow: '#FFC107',
    amber: '#FFBE0A',
    orangeDark: '#F5A623',
    orangeDarker: '#E55D33',
    red: '#d0635c',
    redError: '#e30613',
    darkRed: '#660606',
    salem: '#077a42',
    japaneseLaurel: '#008800',
    greenLight: '#79bf69',
    azureDark: '#2196F3',
    azureLight: '#90CAF9',
    purple: '#9b006f',

    /* Grey */
    mineShaft: '#3E3E3E',
    mineShaft2: '#232323',
    greyDark: '#222323',
    doveGray: '#323232',
    doveGray2: '#6f6f6f',
    silver: '#C8C8C8',
    greyMedium: '#a3a6b2',
    greyLight: '#e8e8e8',
    greyLighter: '#959595',
    lightGrey: '#D8D8D8',
    gray: '#5c5c5c',
    gray2: '#7F7F7F',
    lighterGrey: '#f8f8f8',
    darkerGrey: '#3c3c3c',
    silverGrey: '#cccccc',
    santasGray: '#9ea0a5',
    hazeGray: '#e4e6e5',
    wildSand: '#F4F4F4',
    athensGray: '#E4E7EB',
    alto: '#DFDFDF',
    gallery: '#EEEEEE',
    greyishBrown: '#464646',

    /* Form */
    disabled: '#a0a8b0',

    /* Shadow */
    borderShadow: '0 0 10px 0 rgba(0,0,0,0.01), 0 5px 20px 0 rgba(0,0,0,0.08)',

    /* Gradient lines */
    gradientBlue: 'linear-gradient(to right, #00baf7 0%, #0081dd 99%)',
    gradientGreen: 'linear-gradient(to right, #5ed600 0%, #00b100 99%)',
    grandientYellow: 'linear-gradient(to right, #ffac00 0%, #ff9700 99%)',

    /* timeLineColor */
    yellowTimeline: 'linear-gradient(to bottom, #00baf7 13%, #0081dd 90%)',
    blueTimeline: 'linear-gradient(to bottom, #ffac00 12%, #ff9700 85%)',

    /* CircleNameContainer */
    lightPurple: '#c8cee7',
    lima: '#69AE1F',
    buttercup: '#EEA308',
    tamarillo: '#A11818',
    yellowOrange: '#FFC243',
    chartreuse: '#73DE00',

    learningEvents: 'rgba(1, 144, 229, 0.3)',
    mandatoryTraining: 'rgba(115, 222, 0, 0.3)',
    optionalCourses: 'rgba(255, 194, 67, 0.3)'
  };
};

export default colors;
