import styled from 'styled-components';

export const Image = styled.div`
  background: url(${props => props.source});
  background-color: ${props => props.theme.colors.darkGreen};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  width: 100%;
  max-width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: 36px;
`;

export const CircleName = styled.div`
  background-color: #beddb9;
  border-radius: 50%;
  height: ${props => props.size}px;
  text-align: center;
  min-width: ${props => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    margin-right: 0;
  }

  .initials {
    line-height: 1;
    color: #258900;
  }
`;
