import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { parseQueryParams } from '../../utils/queryParams';
import { favoriteNodeActionMock, getCoursesMock } from './mock';

export default {
  getMandatoryTrainingList: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getOptionalCoursesList: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  setFavourite: (payload) => {
    if (shouldUseMock()) {
      return favoriteNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  }
};
