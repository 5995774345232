import {
  takeLatest, put, call
} from 'redux-saga/effects';

import {
  SEARCH_MANDATORY_TRAINING_LIST_GET,
  SEARCH_OPTIONAL_COURSES_LIST_GET,
  SEARCH_SET_FAVOURITE
} from '../actions/search';
import search from '../../api/search';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';
import home from '../../api/home';
import { getDefaultLanguage } from '../../../utils/locale';

function* searchMandatoryTraningGet({ title }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data } = {} } = yield call(search.getMandatoryTrainingList, { lang: defaultLanguage, title, type: 'selection' });
    yield put({ type: SEARCH_MANDATORY_TRAINING_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > search > searchMandatoryTraningGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* searchMandatoryTraningGetWatch() {
  yield takeLatest(SEARCH_MANDATORY_TRAINING_LIST_GET._REQUEST, searchMandatoryTraningGet);
}

function* searchOptionalCoursesGet({ title }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data } = {} } = yield call(search.getOptionalCoursesList, { lang: defaultLanguage, title, type: 'collection' });
    yield put({ type: SEARCH_OPTIONAL_COURSES_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > search > searchOptionalCoursesGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* searchOptionalCoursesGetWatch() {
  yield takeLatest(SEARCH_OPTIONAL_COURSES_LIST_GET._REQUEST, searchOptionalCoursesGet);
}

function* searchFavouriteSet({ nid, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        node_type,
        action_type: 'favourite'
      };
      yield call(home.setFavourite, payload);
      yield put({ type: SEARCH_SET_FAVOURITE._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > search > searchFavouriteSet', err);
    }
  }
}

function* searchFavouriteSetWatch() {
  yield takeLatest(SEARCH_SET_FAVOURITE._REQUEST, searchFavouriteSet);
}


export default [
  searchMandatoryTraningGetWatch(),
  searchOptionalCoursesGetWatch(),
  searchFavouriteSetWatch()
];
