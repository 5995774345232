import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NoResultContainer } from './style';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const NoResult = ({ text, vocabulary }) => (
  <NoResultContainer>
    <Arial type="editorialImageTitle">{text || getValueVocabulary(vocabulary.noResults, 'noResults')}</Arial>
  </NoResultContainer>
);

NoResult.propTypes = {
  text: PropTypes.string,
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  }
)(NoResult);
