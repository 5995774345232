import {
  takeLatest, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import { getDefaultLanguage } from '../../../utils/locale';

import notification from '../../api/notification';
import { NOTIFICATION_GET, NOTIFICATION_READ } from '../actions/notifications';

function* getNotification({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: response = {} } = yield call(notification.getNotification, { ...filters, lang: defaultLanguage });
    yield put({ type: NOTIFICATION_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: NOTIFICATION_GET._ERROR, error });
    console.log('sagas > notification > getNotification', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* NotificationGetWatch() {
  yield takeLatest(NOTIFICATION_GET._REQUEST, getNotification);
}

function* notificationRead({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const params = {
      id
    };
    yield call(notification.notificationRead, params);
    yield put({ type: NOTIFICATION_READ._SUCCESS });
    yield put({ type: NOTIFICATION_GET._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > notification > notificationRead', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* notificationReadWatch() {
  yield takeLatest(NOTIFICATION_READ._REQUEST, notificationRead);
}

export default [
  NotificationGetWatch(),
  notificationReadWatch()
];
