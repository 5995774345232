import React from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';

import { connect } from 'react-redux';
import {
  ScrollerContainer, Text, Icon, Wrapper,
} from './style';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';

const Scroller = ({ target, vocabulary }) => (
  <ScrollerContainer>
    <Wrapper arabic={isArabic()} onClick={() => scrollToElement(`#${target}`, { offset: -150 }, 10)}>
      <Text>
        <Arial type="scrollUp" vocabulary="action_come_back">
          {getValueVocabulary(vocabulary.action_come_back || 'action_come_back')}
        </Arial>
      </Text>
      <Icon className="icon-torna-su" />
    </Wrapper>
  </ScrollerContainer>
);

Scroller.propTypes = {
  target: PropTypes.string,
  vocabulary: PropTypes.object,
};

Scroller.defaultProps = {
  target: 'anchor',
};

export default connect(
  state => {
    const { vocabulary } = state.app;

    return {
      vocabulary,
    };
  }
)(Scroller);
