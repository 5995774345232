import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Banner, Bottom, CardDetails,
  Container, Detail, Favourite,
  Icon, Label, Title, Top,
} from './style';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { convertDuration, durationConvert, getExactLabel } from '../../../utils/common';
import dateFormat from '../../../utils/date-format';
// eslint-disable-next-line import/no-cycle
import { filterLanguages, isArabic } from '../../../utils/locale';

const CardCourses = ({
  item, onClickCard, onClickFavourite, vocabulary,
}) => {
  const [data, setData] = useState(item);
  const [isFavourite, setIsFavourite] = useState(item.favorite);

  useEffect(() => {
    setData(item);
    setIsFavourite(item?.favorite);
  }, [item]);

  const handleOnClickCard = () => {
    if (onClickCard) onClickCard();
  };

  const handleOnclickFavourite = (e) => {
    if (onClickFavourite) onClickFavourite();
    setIsFavourite(!isFavourite);
    e.stopPropagation();
  };

  const getValueBanner = () => {
    switch (data.status) {
      case 'empty': return 'statusEmpty';
      case 'started': return 'statusStarted';
      case 'expiring': return 'statusExpiring';
      case 'expired': return 'statusExpired';
      case 'completed': return 'statusCompleted';
      default: return null;
    }
  };

  return (
    <Container arabic={isArabic()} onClick={handleOnClickCard} cover={data?.cover}>
      <Banner alt={getValueVocabulary(vocabulary[getValueBanner()], getValueBanner())?.length >= 13 ? true : undefined} data={data}><Arial type="bannerCardLearningEvents" vocabulary={getValueBanner()}>{getValueVocabulary(vocabulary[getValueBanner()], getValueBanner())}</Arial></Banner>
      {data?.type === 'collection' && (
        <Favourite>
          <Icon size={20} onClick={handleOnclickFavourite} className={isFavourite ? 'icon-preferiti' : 'icon-heart-empty'} />
        </Favourite>
      )}
      <Top>
        <Label type={data.type}>{data?.category?.name}</Label>
        {data?.type === 'selection' && data?.expire_date ? (
          <Arial type="expDateCourse" vocabulary="expDateCourse">
            { isArabic() ? (
              `${dateFormat(data?.expire_date, '', getValueVocabulary(vocabulary.dateFormat, ''))} :${getValueVocabulary(vocabulary.expDateCourse, 'expDateCourse')}`
            ) : (
              `${getValueVocabulary(vocabulary.expDateCourse, 'expDateCourse')}: ${dateFormat(data?.expire_date, '', getValueVocabulary(vocabulary.dateFormat, ''))}`
            )}
          </Arial>
        ) : null}
      </Top>
      <Bottom>
        <Title>{data?.title}</Title>
        <CardDetails>
          <Detail>
            <Icon className="icon-icon-video" size={10} />
            <Arial type="collectionCardFooter">
              { isArabic() ? (
                `${getExactLabel(vocabulary, data?.total_lo_items, 'totalLearningObject', 'totalLearningObjects')} ${data?.total_lo_items}`
              ) : (
                `${data?.total_lo_items} ${getExactLabel(vocabulary, data?.total_lo_items, 'totalLearningObject', 'totalLearningObjects')}`
              )}
            </Arial>
          </Detail>
          <Detail>
            <Icon className="icon-clock" size={10} />
            <Arial type="collectionCardFooter">
              { isArabic() ? (
                `${getValueVocabulary(vocabulary.totalCourseDuration, 'totalCourseDuration')} ${durationConvert(data?.duration)}`
              ) : (
                `${durationConvert(data?.duration)} ${getValueVocabulary(vocabulary.totalCourseDuration, 'totalCourseDuration')}`
              )}
            </Arial>
          </Detail>
        </CardDetails>
      </Bottom>
    </Container>
  );
};

CardCourses.propTypes = {
  item: PropTypes.object.isRequired,
  onClickCard: PropTypes.func,
  onClickFavourite: PropTypes.func,
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    vocabulary: state.app.vocabulary,
  })
)(CardCourses);
