import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer
} from '../Modal.style';
import {
  ModalBodyContainer, Title,
  Subtitle, ButtonContainer
} from './style';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import { Button } from '../../../atoms';

const modalId = 'ModalConfirmResetPassword';

const ModalConfirmResetPassword = ({ onClose, vocabulary }) => (
  <Modal id={modalId}>
    <Container>
      <ModalGlobalContainer
        onMouseDown={stopEvent}
        onClick={stopEvent}
        width="500"
        height="238"
      >
        <ModalBodyContainer>
          <Title><Arial type="confirmationForgotPasswordTitle" vocabulary="confirmationForgotPasswordTitle">{getValueVocabulary(vocabulary.confirmationForgotPasswordTitle, 'confirmationForgotPasswordTitle')}</Arial></Title>
          <Subtitle><Arial type="confirmationForgotPasswordSubTitle" vocabulary="confirmationForgotPasswordSubTitle">{getValueVocabulary(vocabulary.confirmationForgotPasswordSubTitle, 'confirmationForgotPasswordSubTitle')}</Arial></Subtitle>
          <ButtonContainer>
            <Button
              height={40}
              size={Button.SIZE.FULL}
              btnText={getValueVocabulary(vocabulary.btnBackToLogin, 'btnBackToLogin')}
              onClick={onClose}
            />
          </ButtonContainer>
        </ModalBodyContainer>
      </ModalGlobalContainer>
    </Container>
  </Modal>
);

ModalConfirmResetPassword.propTypes = {
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
};

ModalConfirmResetPassword.id = modalId;

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ModalConfirmResetPassword);
