import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import {
  setInsertSurveyMock,
  getSurveyTojMock,
  setInsertSurveySessionMock
} from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getSurveyContent: ({ filters: { id, toj_id: tojId } }) => {
    if (shouldUseMock()) {
      return getSurveyTojMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}integration/survey/${id}/retrieve${parseQueryParams(
        { tojId, object_type: 'toj' }
      )}
    `,
      'GET'
    );
  },
  getSurveySessionContent: ({ filters: { id, toj_id: tojId, idSlot } }) => {
    if (shouldUseMock()) {
      return getSurveyTojMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}integration/survey/${id}/retrieve${parseQueryParams(
        { tojId, idSlot, object_type: 'toj_slot' }
      )}
    `,
      'GET'
    );
  },
  setInsertSurvey: body => {
    const { toj_id, ...otherBody } = body;
    if (shouldUseMock()) {
      return setInsertSurveyMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}integration/survey/${toj_id}/insert?object_type=toj`,
      'POST',
      otherBody
    );
  },
  setInsertSurveySession: body => {
    console.log('body', body);
    if (shouldUseMock()) {
      return setInsertSurveySessionMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}integration/toj/sessionanswers`,
      'POST',
      body
    );
  }
};
