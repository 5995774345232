import styled, { css } from 'styled-components';
import { Container } from '../../../ui/gridSystem';

export const Wrapper = styled.div`
  padding-bottom: 100px;
  padding-top: 80px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 40px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }
`;

export const Filter = styled.div`
  min-width: 250px;

  & > div:first-child {
    margin-bottom: 10px;
  }
`;

export const SelectsContainer = styled.div`
  width: 50%;
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    margin-bottom: 20px;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    flex-direction: column;

    > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }  
`;

export const FilterPillsContainer = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3.5px 5px 3.5px 10px;
  }

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: fit-content;
    align-items: flex-start;
  }  
`;

export const TabsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  // Filter Tabs on two row and center alignment
  > div {
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media ${props => props.theme.device.tablet} {
      max-width: 100%;
    }
 
    > div {
      margin-bottom: 10px;
    }
  }
 
  @media ${props => props.theme.device.tablet} {
    text-align: center;    
  }
  `;

export const CoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 25px);

  @media ${props => props.theme.device.tablet} {
    padding: 0 20px;
    justify-content: center;    
  }
`;

export const CourseItem = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;
`;

export const ViewMore = styled.div`
  margin: 20px auto;
  width: 150px;
  text-align: center;
  cursor: pointer;
`;

export const OptionalCoursesContainer = styled(Container).attrs(() => ({
  className: 'p-0'
}))`
  ${({ arabic }) => arabic && css`
    ${FiltersContainer},
    ${CoursesContainer},
    ${FilterPillsContainer} {
      flex-direction: row-reverse;
    }

    ${SelectsContainer} {
      flex-direction: row-reverse;
      
      > div:first-child {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    @media ${props => props.theme.device.tabletL} {
      ${FiltersContainer} {
        flex-direction: column;
      }
    }

    @media ${props => props.theme.device.mobileL} {
      ${FiltersContainer} {
        flex-direction: column;
      }

      ${SelectsContainer} {
        flex-direction: column;

        > div:first-child {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  `}
`;
