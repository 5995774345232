import styled, { css } from 'styled-components';
import Button from './index';

const ButtonContainer = styled.button`
  width: 100%;
  max-width: ${props => (props.size === Button.SIZE.DEFAULT ? '248px' : 'unset')};
  height: ${props => (props.height ? `${props.height}px` : '50px')};
  border-radius: 4px;
  background-color: ${props => (props.type === Button.TYPE.PRIMARY
    ? props.theme.colors.white
    : props.type === Button.TYPE.THIRD
      ? 'rgba(255, 255, 255, 0.3)'
      : props.theme.colors.black)};
  color: ${props => (props.type === Button.TYPE.PRIMARY
    ? props.theme.colors.primaryBlue
    : props.type === Button.TYPE.THIRD
      ? props.theme.colors.white
      : props.theme.colors.greyMedium)};
  margin: 20px 0;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  border: 1px solid
    ${props => (props.type === Button.TYPE.PRIMARY
    ? props.theme.colors.transparent
    : props.theme.colors.greyMedium)};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: ${props => (props.disabled ? 0.6 : 0.8)};
  }

  transition: ${props => props.theme.constants.transitionBase};
  text-transform: uppercase;
  
  ${props => props.type === 'mandatorytraining'
  && css`
      background-color: ${props.theme.colors.chartreuse};
      border-color: ${props.theme.colors.chartreuse};
      color: ${props.theme.colors.white};
    `}
    
    ${props => props.type === 'optionalcourses'
  && css`
      background-color: ${props.theme.colors.yellowOrange};
      border-color: ${props.theme.colors.yellowOrange};
      color: ${props.theme.colors.white};
    `}

  ${props => props.type === 'remote_classroom'
    && css`
      background-color: rgba(31, 32, 37, 0.5);
      border-color: ${props.theme.colors.yellowOrange};
      color: ${props.theme.colors.yellowOrange};
    `}

  ${props => props.type === 'toj'
    && css`
      background-color: rgba(31, 32, 37, 0.5);
      border-color: #00b2f3;
      color: #00b2f3;
    `}

  ${props => props.type === 'eventsCourses'
    && css`
      background-color: rgba(31, 32, 37, 0.5);
      border-color: #00b2f3;
      color: #00b2f3;
      margin: 20px 0 10px;
    `}

  ${props => props.type === 'tojDetail'
    && css`
      background-color: ${!props.disabled ? '#ffffff' : 'rgba(255, 255, 255, 0.2)'};
      border-color: ${!props.disabled ? '#ffffff' : '#00b2f3'};
      color: #00b2f3;
    `}
`;

const Date = styled.div`
  margin-top: 3px;
`;

export { ButtonContainer, Date };
