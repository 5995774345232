import styled, { css } from 'styled-components';

export const ScrollerContainer = styled.div`
  ox-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #414247;
  color: ${props => props.theme.colors.white};
  height: 80px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fontset.arial};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.22;
  text-transform: uppercase;
  
  > div {
    div, span {
      cursor: pointer;
      transition: ${props => props.theme.constants.transitionBase};
    }
    &:hover {
      div, span {
        color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.8)};
      }
    }
  }
`;

export const Text = styled.label`
  margin-bottom: 0;
  > div {
    display: inline;
  }
`;


export const Icon = styled.span`
  margin-left: 5px;
  font-size: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ arabic }) => arabic && css`
    flex-direction: row-reverse;

    ${Icon} {
      margin-left: 0;
      margin-right: 5px;
      transform: scaleX(-1);
    }
  `};
`;
