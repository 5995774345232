import {
  takeLatest, put, call, all
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  HOME_FAVOURITE_LIST_GET, HOME_LEARNING_EVENTS_LIST_GET,
  HOME_MANDATORY_TRAINING_LIST_GET,
  HOME_OPTIONAL_COURSES_LIST_GET,
  HOME_SET_FAVOURITE, HOME_TOJ_PLANNED_LIST_GET
} from '../actions/home';
import home from '../../api/home';
import { getDefaultLanguage } from '../../../utils/locale';

function* homeLearningEventsGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data } = {} } = yield call(home.getPlannableList, { items_per_page: 10, page: 1 });
    yield put({ type: HOME_LEARNING_EVENTS_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeLearningEventsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homeLearningEventsGetWatch() {
  yield takeLatest(HOME_LEARNING_EVENTS_LIST_GET._REQUEST, homeLearningEventsGet);
}

function* homeLearingGetTOJPlannedList({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(home.getPlannedList, filters);
    yield put({ type: HOME_TOJ_PLANNED_LIST_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: HOME_TOJ_PLANNED_LIST_GET._ERROR, error });
    console.log('sagas > learningevents > getPlannedList', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* homeLearingGetTOJPlannedListWatch() {
  yield takeLatest(HOME_TOJ_PLANNED_LIST_GET._REQUEST, homeLearingGetTOJPlannedList);
}

function* homeMandatoryTraningGet({ loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data } = {} } = yield call(home.getMandatoryTrainingList, { lang: defaultLanguage, items_per_page: 10, page: 1, type: 'selection' });
    yield put({ type: HOME_MANDATORY_TRAINING_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeMandatoryTraningGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* homeMandatoryTraningGetWatch() {
  yield takeLatest(HOME_MANDATORY_TRAINING_LIST_GET._REQUEST, homeMandatoryTraningGet);
}

function* homeOptionalCoursesGet({ loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data } = {} } = yield call(home.getOptionalCoursesList, { lang: defaultLanguage, items_per_page: 10, page: 1, type: 'collection' });
    yield put({ type: HOME_OPTIONAL_COURSES_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeOptionalCoursesGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* homeOptionalCoursesGetWatch() {
  yield takeLatest(HOME_OPTIONAL_COURSES_LIST_GET._REQUEST, homeOptionalCoursesGet);
}

function* homeFavouriteListGet({ loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { data: { data } = {} } = yield call(home.getFavoriteList, { items_per_page: 10, page: 1 });
    yield put({ type: HOME_FAVOURITE_LIST_GET._SUCCESS, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > home > homeFavouriteListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* homeFavouriteListGetWatch() {
  yield takeLatest(HOME_FAVOURITE_LIST_GET._REQUEST, homeFavouriteListGet);
}


function* homeFavouriteSet({ nid, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        node_type,
        action_type: 'favourite'
      };
      yield call(home.setFavourite, payload);
      yield put({ type: HOME_SET_FAVOURITE._SUCCESS });
      yield all([
        yield homeFavouriteListGet({ loader: false }),
        yield homeMandatoryTraningGet({ loader: false }),
        yield homeOptionalCoursesGet({ loader: false })
      ]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > home > homeFavouriteSet', err);
    }
  }
}

function* homeFavouriteSetWatch() {
  yield takeLatest(HOME_SET_FAVOURITE._REQUEST, homeFavouriteSet);
}

export default [
  homeLearningEventsGetWatch(),
  homeLearingGetTOJPlannedListWatch(),
  homeMandatoryTraningGetWatch(),
  homeOptionalCoursesGetWatch(),
  homeFavouriteListGetWatch(),
  homeFavouriteSetWatch()
];
