import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleHeader = styled.div`
  & > div:first-child {
    @media ${props => props.theme.device.tablet} {
      padding: 20px 0;
    }
  }

  & > div:last-child {
    margin-top: 7px;
    text-decoration: underline;
    cursor: pointer;

    @media ${props => props.theme.device.mobileL} {
      margin-bottom: 20px;
    }
  }
`;

export const Filters = styled.div`
  min-width: 250px;

  & > div:first-child {
    margin-bottom: 10px;
  }
`;

export const Type = styled.div`
  margin-left: 20px;

  ${({ notRead }) => !notRead && css`
    margin-left: 30px;
  `}

  @media ${props => props.theme.device.tablet} {
    ${({ notRead }) => !notRead && css`
      margin-left: 20px;
    `}
  }

  ${({ arabic }) => arabic && css`
    margin-left: 0;
    margin-right: 20px;

    ${({ notRead }) => !notRead && css`
      margin-left: 0;
      margin-right: 30px;
    `}

    @media ${props => props.theme.device.tablet} {
      ${({ notRead }) => !notRead && css`
        margin-left: 0;
        margin-right: 20px;
      `}
    }
  `}
`;

export const ReadAll = styled.div``;

export const Description = styled.div`
  padding: 5px 0;
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.white};

  ${({ notRead }) => !notRead && css`
    color: ${({ theme }) => theme.colors.greyMedium};
  `}
`;

export const Date = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.white};

  ${({ notRead }) => !notRead && css`
    color: ${({ theme }) => theme.colors.greyMedium};
  `}
`;

export const InfoType = styled.div`
  color: ${({ theme }) => theme.colors.primaryThemeBlue};
  display: flex;
  align-items: center;
`;

export const InfoDate = styled.div`
  color: ${({ theme }) => theme.colors.primaryThemeBlue};
  display: flex;
  align-items: center;
`;

export const InfoTitle = styled.div`
  color: ${({ theme }) => theme.colors.greyMedium};
  display: flex;
  align-items: center;
  font-size: 10px;
`;

export const NotificationWrapper = styled.div`
  padding-bottom: 100px;
  padding-top: 80px;

  ${({ arabic }) => arabic && css`

    ${HeaderContainer} {
      flex-direction: row-reverse;
    }

    ${InfoType} {
      flex-direction: row-reverse;
    }
  `}
`;
