import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DatePickerContainer, IconCalendar } from './style';
import { isArabic } from '../../../utils/locale';

const CustomDatePicker = ({
  onChange, placeholder, value,
  name, valid, readonly,
  disabled, minDate, maxDate, widthInput = '100%',
  showTimeSelect = false,
}) => {
  const [val, setVal] = useState(value);

  useEffect(() => { setVal(value); }, [value]);

  const updateValue = (newValue) => {
    setVal(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <DatePickerContainer
      arabic={isArabic()}
      valid={readonly || valid}
      value={value}
      readonly={readonly}
      disabled={disabled}
      widthInput={widthInput}
    >
      <DatePicker
        dateFormat="dd/MM/yyyy"
        onChange={updateValue}
        placeholderText={readonly ? '-' : placeholder}
        selected={val}
        name={name}
        readOnly={readonly}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
      />
      <IconCalendar disabled={disabled} className="icon-calendar" />
    </DatePickerContainer>
  );
};

CustomDatePicker.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  placeholder: 'DD/MM/YYYY',
  valid: true,
};

export default CustomDatePicker;
