import moment from 'moment';
import { SHOULD_ANGLIFY } from './locale';

const dateFormat = (date, type, format, toBeMultiplied = true) => {
  const finalFormat = format ??
    type === 'short' ? 'DD/MM/YYYY' :
    type === 'news' ? 'DD MMMM YYYY' :
    'DD/MM/YYYY - h:mm A';
  
  const finalDate = toBeMultiplied ? date * 1000 : date;

  return SHOULD_ANGLIFY.includes(moment.locale().toUpperCase()) ?
    moment(finalDate).locale('EN').format(finalFormat) :
    moment(finalDate).format(finalFormat);
};

export default dateFormat;
