import styled from 'styled-components';

export const ContentCardContainer = styled.div`
  position: relative;
  height: 180px;
  width: 48%;
  padding: 30px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  
  cursor: pointer;
  
  background: url(${({ background }) => background}) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transform: scale(1.03);
  }
  
  transition: ${props => props.theme.constants.transitionBase};
  
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const Title = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
  opacity: 0.6;
`;

export const Subtitle = styled.div`
  position: relative;
  z-index: 1;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  border-radius: 4px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2) 100%, rgba(0, 0, 0, 0.8) 0%);
  z-index: 0;
`;
