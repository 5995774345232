import React from 'react';
import PropTypes from 'prop-types';
import { StarsContainer, StarItem } from './style';

const Stars = ({ rate, stars }) => {
  const starsElement = [];
  for (let i = 0; i < stars; i += 1) {
    starsElement.push(
      <StarItem
        key={i}
        className={` ${
          i < rate ? 'icon-star-full selected' : 'icon-star-full'
        }`}
      />
    );
  }
  return <StarsContainer>{starsElement}</StarsContainer>;
};

Stars.propTypes = {
  rate: PropTypes.number,
  stars: PropTypes.number
};

Stars.defaultProps = {
  rate: 0,
  stars: 5
};

export default Stars;
