import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import queryString from 'query-string';
import { Container } from '../../../ui/gridSystem';
import { AUTH_RESET, AUTH_RESET_WITH_TOKEN } from '../../redux/actions';
import {
  AuthContainer, Background, FormContainer,
  FormTitle, InputWrapper, ButtonContainer,
  LogoContainer
} from './styles';
import { Button, CustomInput, Logo } from '../../../ui/atoms';
import heroImage from '../../../ui/assets/img/home_page.jpg';
import {
  getValues,
  isValidForm,
  newForm,
  newFormField
} from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import Arial from '../../../ui/typography/arial';
import {
  ColoredLinesBar, ColoredLinesContainer, Subtitle, Title
} from '../Auth/styles';
import LogoAuth from '../../../ui/assets/img/logo-testo-bianco.png';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const initialAuthForm = newForm([
  newFormField({
    field: 'old_password',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'new_password',
    required: true,
    type: RegexpType.RESET_PASSWORD,
    valid: true
  }),
  newFormField({
    field: 'confermapwd',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true
  })
]);

const AuthReset = ({
  resetPassword, resetPasswordWithToken, vocabulary, location
}) => {
  const { token } = queryString.parse(location.search);
  const [authForm, setAuthForm] = useState(initialAuthForm);

  useEffect(() => {
    if (token) {
      delete initialAuthForm.old_password;
      setAuthForm(initialAuthForm);
    } else {
      setAuthForm(initialAuthForm);
    }
  }, [token]);

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid:
          (typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true)
          && (field === 'confermapwd'
            ? authForm.new_password.value === newValue
            : true)
      }
    });
  };

  return (
    <AuthContainer>
      <Background img={heroImage} />
      <ColoredLinesContainer>
        <ColoredLinesBar />
        <ColoredLinesBar />
        <ColoredLinesBar />
      </ColoredLinesContainer>
      <Container>
        <Arial>
          <Title>
            <Arial type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.authTitle, 'authTitle')}
            </Arial>
          </Title>
          <Subtitle>
            <Arial type="descriptionHome" vocabulary="authDescription">
              {getValueVocabulary(vocabulary.authDescription, 'authDescription')}
            </Arial>
          </Subtitle>
        </Arial>
        <FormContainer>
          <LogoContainer>
            <Logo size={Logo.SIZE.XL} src={LogoAuth} />
          </LogoContainer>
          <FormTitle>
            <Arial type="resetPassword" vocabulary="resetPassword">{getValueVocabulary(vocabulary.resetPassword, 'resetPassword')}</Arial>
          </FormTitle>
          {!token && (
          <InputWrapper>
            <CustomInput
              placeholder={getValueVocabulary(vocabulary.oldPasswordPlaceholder, 'oldPasswordPlaceholder')}
              onChange={newValue => handleOnChange('old_password', newValue)}
              type="password"
              value={authForm?.old_password?.value}
              valid={authForm?.old_password?.valid}
            />
          </InputWrapper>
          )}
          <InputWrapper>
            <CustomInput
              placeholder={getValueVocabulary(vocabulary.newPasswordPlaceholder, 'newPasswordPlaceholder')}
              onChange={newValue => handleOnChange('new_password', newValue)}
              type="password"
              value={authForm?.new_password?.value}
              valid={authForm?.new_password?.valid}
              error={getValueVocabulary(vocabulary.pwdError, 'pwdError')}
            />
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              placeholder={getValueVocabulary(vocabulary.confirmPasswordPlaceholder, 'confirmPasswordPlaceholder')}
              onChange={newValue => handleOnChange('confermapwd', newValue)}
              type="password"
              value={authForm?.confermapwd?.value}
              valid={authForm?.confermapwd?.valid}
              error={getValueVocabulary(vocabulary.confirmPwdError, 'confirmPwdError')}
            />
          </InputWrapper>
          <ButtonContainer>
            <Button
              height={40}
              size={Button.SIZE.FULL}
              btnText={getValueVocabulary(vocabulary.btnLogin, 'btnLogin')}
              disabled={!isValidForm(authForm)}
              onClick={() => (token ? resetPasswordWithToken({ ...getValues(authForm), token }) : resetPassword(getValues(authForm)))}
            />
          </ButtonContainer>
        </FormContainer>
      </Container>
    </AuthContainer>
  );
};

AuthReset.propTypes = {
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  resetPassword: PropTypes.func.isRequired,
  resetPasswordWithToken: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { location } = state.router;
    return { vocabulary, location };
  },
  dispatch => ({
    resetPassword: params => dispatch({ type: AUTH_RESET._REQUEST, params }),
    resetPasswordWithToken: params => dispatch({ type: AUTH_RESET_WITH_TOKEN._REQUEST, params })
  })
)(AuthReset);
