import styled from 'styled-components';

const StarsContainer = styled.div.attrs(() => ({
  className: 'stars'
}))`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: ${props => (props.hasLabel ? 'space-between' : 'unset')};

  & > span:hover {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${props => props.theme.colors.secondary.red};
  }
`;

const StarItem = styled.span`
  font-size: 20px;
  margin-top: -5px;
  margin-right: 10px;
  cursor: pointer;

  &.icon-star-full {
    color: ${props => props.theme.colors.secondary.red};
  }
`;

export { StarsContainer, StarItem };
