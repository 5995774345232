import styled, { css } from 'styled-components';

export const SurveyLikeDislikeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.span`
  color: ${props => props.theme.colors.brownGrey}
  ${({ selected, theme }) => selected && css`
    color: ${theme.colors.azureDark}
  `}
`;

export const Label = styled.span`
  margin-left: 10px; 
`;


export const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
