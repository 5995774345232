import {
  takeLatest, put, call,
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import {
  REMOTE_CLASSROOM_CONTENT_GET,
  REMOTE_CLASSROOM_COMMENTS_GET,
  REMOTE_CLASSROOM_COMMENTS_SET,
  REMOTE_CLASSROOM_LIKE_DISLIKE,
} from '../actions/remoteclassroom';
import remoteclassroom from '../../api/remoteclassroom';
import routes from '../../../routes';

function* getRemoteClassroomContent({ id, loader = true }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { data: { data: response = {} } = {} } = yield call(remoteclassroom.getRemoteClassroomContent, { id });
    yield put({ type: REMOTE_CLASSROOM_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: REMOTE_CLASSROOM_CONTENT_GET._ERROR, error });
    yield put(push(routes.home.path));
    // eslint-disable-next-line no-console
    console.log('sagas > remoteclassroom > getRemoteClassroomContent', error);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* getRemoteClassroomContentWatch() {
  yield takeLatest(REMOTE_CLASSROOM_CONTENT_GET._REQUEST, getRemoteClassroomContent);
}

function* remoteClassroomCommentsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { data: comments } = yield call(remoteclassroom.getRemoteClassroomComments, { id });
      yield put({ type: REMOTE_CLASSROOM_COMMENTS_GET._SUCCESS, comments });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > remoteclassroom > remoteClassroomCommentsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* remoteClassroomCommentsGetWatch() {
  yield takeLatest(REMOTE_CLASSROOM_COMMENTS_GET._REQUEST, remoteClassroomCommentsGet);
}

function* remoteClassroomCommentsSet({ id, subject, comment_body }) {
  try {
    yield put({ type: LOADER_OPEN });
    const payload = {
      entity_id: [{ target_id: id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'comment' }],
      subject: [{ value: subject }],
      comment_body: [{ value: comment_body }],
    };
    yield call(remoteclassroom.setRemoteClassroomComments, payload);
    yield put({ type: REMOTE_CLASSROOM_COMMENTS_SET._SUCCESS });
    yield put({ type: REMOTE_CLASSROOM_COMMENTS_GET._REQUEST, id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > remoteclassroom > remoteClassroomCommentsSet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* remoteClassroomCommentsSetWatch() {
  yield takeLatest(REMOTE_CLASSROOM_COMMENTS_SET._REQUEST, remoteClassroomCommentsSet);
}


function* setLikeDislikeFeedback({ payload }) {
  try {
    const { feedback_value, id } = payload;
    yield call(remoteclassroom.setLikeDislikeFeedback, { feedback_value, id });
    yield put({ type: REMOTE_CLASSROOM_LIKE_DISLIKE._SUCCESS });
    yield put({ type: REMOTE_CLASSROOM_CONTENT_GET._REQUEST, id, loader: false });

    //yield put(push(`${routes.toj.path}/${tojId}`));
  } catch (err) {
    yield put({ type: REMOTE_CLASSROOM_LIKE_DISLIKE._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > remoteclassroom > setLikeDislikeFeedback', err);
  }
}

function* setLikeDislikeFeedbackWatch() {
  yield takeLatest(REMOTE_CLASSROOM_LIKE_DISLIKE._REQUEST, setLikeDislikeFeedback);
}

export default [
  getRemoteClassroomContentWatch(),
  remoteClassroomCommentsGetWatch(),
  remoteClassroomCommentsSetWatch(),
  setLikeDislikeFeedbackWatch(),
];
