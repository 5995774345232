import styled from 'styled-components';

const StarsContainer = styled.div.attrs(() => ({
  className: 'stars'
}))`
  width: 100%;
`;

const StarItem = styled.span`
  margin-right: 4px;
  color: ${props => props.theme.colors.greyLight};

  &.selected {
    color: ${props => props.theme.colors.amber};
  }
`;

export { StarsContainer, StarItem };
