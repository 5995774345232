import styled from 'styled-components';

const QuestionBoxContainer = styled.div`
  width: 380px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.01);
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 20px 20px 10px 20px;
  margin-bottom: 20px;
  position:relative;

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }

  

`;

export const QuestionContainer = styled.div`
  position:relative;
  border-bottom: 1px solid #5c5c5c;


  &:after {
    background: linear-gradient(to bottom, #beddb9 0%, #268900 75%);
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 16px;
    width: 2px;
  }
`;

const Title = styled.div`
  position: relative;
  padding-bottom: 14px;
  line-height: 20px;

  &:before {
    content: '';
    position: absolute;
    width: 112%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -20px;
    bottom: 0;
  }
`;

const Text = styled.div`
  margin-top: 26px;
  color: ${props => props.theme.colors.brownGrey};
  padding-bottom: 14px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
  width: 180px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left:19px;
`;

const Icon = styled.span`
  font-size: 18px;
  color: #79bf69;
  background: white;
  overflow:hidden;
  border-radius:50%;
  margin-right: 10px;
  border: 0.5px solid ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.white};

`;

const VoidIcon = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.white};
  margin-right: 10px;

`;

const QuestionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:23px;

`;

export {
  QuestionBoxContainer, Title, Text, ButtonContainer, Icon, VoidIcon, IconContainer, QuestionWrap
};
