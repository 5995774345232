import moment from 'moment';

const formatTimestamp = (date) => {
  if (date) {
    const diffSeconds = moment(new Date()).diff(new Date(date), 'seconds');
    const hum = moment.duration(-diffSeconds, 'seconds').humanize(true);
    return hum;
  }
  return '';
};

export default formatTimestamp;
