import React from 'react';
import PropTypes from 'prop-types';
import { Image, CircleName } from './style';
import Arial from '../../typography/arial';

const getInitials = (name) => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
};

const CircleImage = ({ src, size, name = '' }) => (src ? <Image source={src} size={size} />
  : (
    <CircleName size={size}>
      <span className="initials">
        <Arial types="button">{getInitials(name)}</Arial>
      </span>
    </CircleName>
  ));

CircleImage.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string
};

CircleImage.defaultProps = {
  src: '',
  size: 36
};

export default CircleImage;
