import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, push } from 'redux-first-history';

import { BackButtonContainer, Span } from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Arial from '../../typography/arial';
import { isArabic } from '../../../utils/locale';

const BackButton = ({
  back, pushUrl, url, vocabulary, currentPath, backPath,
}) => {
  const linkUrl = url || (backPath && backPath.pathname !== currentPath ? `${backPath.pathname}${backPath.search}` : '/');

  return (
    <BackButtonContainer>
      <div onClick={() => { linkUrl ? pushUrl(linkUrl) : back(); }}>
        <Span>
          <Arial type="backButton" vocabulary="action_back">
            {!isArabic() ? (
              `< ${getValueVocabulary(vocabulary.action_back, 'action_back')}`
            ) : (
              `${getValueVocabulary(vocabulary.action_back, 'action_back')} >`
            )
            }
          </Arial>
        </Span>
      </div>
    </BackButtonContainer>
  );
};

BackButton.propTypes = {
  vocabulary: PropTypes.object,
  currentPath: PropTypes.string,
  backPath: PropTypes.object,
  url: PropTypes.string,
  // HOC (connect, dispatch)
  back: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { vocabulary, currentPath, backPath } = state.app;
    return {
      vocabulary,
      currentPath,
      backPath,
    };
  },
  dispatch => ({
    back: () => dispatch(goBack()),
    pushUrl: (url) => dispatch(push(url)),
  })
)(BackButton);
