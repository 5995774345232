import styled, { css } from 'styled-components/macro';
import { Container } from '../../../ui/gridSystem';

export const Banner = styled.div`
  width: 100vw;
  height: 300px;
  position: relative;

  @media ${props => props.theme.device.mobileL}{
    height: 500px;
  }
`;

export const BannerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  height: 100%;
  
  @media ${props => props.theme.device.tabletL}{
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .back-button {
    ${({ hasSlot }) => (hasSlot ? 'padding: 0 0 20px 0' : 'padding: 0 0 40px 0')};
  }
  
  div[data-vocabulary="newsDetailTitle"] {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 250px;

  @media ${props => props.theme.device.laptop} {
    padding: 80px 30px 60px 25px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 32px 22px 0 23px;

    .mobile-no-padding-right {
      padding-right: 0;
    }

    .no-gutters {
      margin-top: 23px;
    }
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 20px 20px;
    height: 100vh;
    margin-bottom: -293px;
  }
`;

export const BannerInfo = styled.div`
   display: flex;
   flex-direction:row;
   align-items: flex-end;
   justify-content: space-between;
   margin-top: auto;
   
  > div  {
    width: fit-content;
    margin-bottom: 10px;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 311px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  z-index: -1;

  @media ${props => props.theme.device.mobileL} {
    height: 100%;
    top: 0;
  }
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;

  @media ${props => props.theme.device.tabletL} {
    left: 0;
    padding: 0 5px;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;

export const Time = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const FooterWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;

  & > button {
    height: 40px;
    width: 180px;
  }

  span {
    font-size: 14px;
    line-height: 0.71;
    font-weight: normal;
  }

  @media ${props => props.theme.device.tabletL} {
    position: absolute;
    right: 0;
    width: 100%;
    padding: 0 20px;
  }
`;

export const OverviewContainer = styled.div``;

export const Text = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;

  ${props => props.nopad
    && css`
      padding-bottom: 0;
    `};

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const SectionContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  margin-bottom: 50px;
  
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 20px;
  }
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 180px;
    height: 40px;
  }

  ${props => props.link
    && css`
      margin-bottom: 20px;
    `};
`;

export const Link = styled.a`
  border-radius: 4px;
  background-color: #ffffff;
  color: ${props => (props.isClassroom ? props.theme.colors.primaryThemeBlue : '#258900')};
  opacity: 1;
  border: 1px solid transparent;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: unset;
  line-height: 1;
  text-align: unset;

  &:hover {
    color: ${props => (props.isClassroom ? props.theme.colors.primaryThemeBlue : '#258900')};
    opacity: 0.8;
  }

  &:focus,
  &:active {
    color: ${props => (props.isClassroom ? props.theme.colors.primaryThemeBlue : '#258900')};;
    outline: none;
  }

  @media ${props => props.theme.device.tabletL} {
    margin: 20px 0 0 0;
  }

  @media ${props => props.theme.device.mobileL} {
    margin: 0;
  }
  
  ${({ disabled }) => disabled
  && css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
`;

export const RightContainerItems = styled.div`
  overflow: hidden;
`;

export const IconCompleted = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.primaryThemeBlue};
  background: white;
  overflow:hidden;
  border-radius:50%;
  background-color: ${props => props.theme.colors.white};
  border: 0.5px solid ${props => props.theme.colors.white};
`;

export const TabsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    text-align: center;    
  }
`;

export const SlotTitle = styled.div`
  div[data-vocabulary='slotTitle'] {
    margin-bottom: 5px;
    color: ${props => props.theme.colors.greyMedium}
  }
  
  div[data-vocabulary='slotDate'] {
    margin-right: 35px;
    text-transform: capitalize;

    @media ${props => props.theme.device.mobileL} {
      margin-right: 10px;
    }
  }
  div[data-vocabulary='scheduledTime'] {
    color: ${props => props.theme.colors.greyMedium}
  }
  div[data-vocabulary='slotTitleSchedule'] {
    & > div {
      display: inline;
    }
    @media ${props => props.theme.device.mobileL} {
      margin-bottom: 10px;
    }
  }
`;

export const IconWrapper = styled.div`
  line-height: 100%;
  vertical-align: middle;
  display: inline-flex;
  margin-left: 30px;
  
  @media ${props => props.theme.device.mobileL} {
    margin-left: 15px;
  }
`;

export const SessionDuration = styled.div`
  margin-bottom: 10px;
  & > div {
    margin-bottom: 5px;
  }
`;

export const Wrapper = styled.div`
  ${({ arabic }) => arabic && css`
    ${BannerContainer} {
      div[data-vocabulary="newsDetailTitle"] {
        flex-direction: row-reverse;
      }
    }
    ${BannerInfo} {
      flex-direction: row-reverse;
    }
    ${IconWrapper} {
      margin-right: 30px;
      margin-left: 0;
    }
  `}
`;
