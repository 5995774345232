import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getAgendaPlannedListMock, getAgendaPlannableListMock } from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getPlannedList: (filters) => {
    if (shouldUseMock()) {
      return getAgendaPlannedListMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/agenda/planned_list${parseQueryParams(filters)}`, 'GET');
  },
  getPlannableList: (filters) => {
    if (shouldUseMock()) {
      return getAgendaPlannableListMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/plannablelist${parseQueryParams(filters)}`, 'GET');
  }
};
