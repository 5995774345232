import styled, { css } from 'styled-components';

export const CloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 99;
  font-size: 16px;
  top: 20px;
  right: 20px;
  color: ${props => props.theme.colors.white};
`;

export const Title = styled.div`
  width: 680px;
  align-self: center;
  text-align: center;
  margin-bottom: 30px;
  padding: 7px 0 25px;
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobileL} {
    width: 100vw;
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.white};
  margin-bottom: 5px;
`;

export const IconClose = styled.span`
  top: 20px !important;
  right: 20px !important;
  z-index: 100;
`;

export const Session = styled.div`
  margin: 25px 0;
`;

export const Flexer = styled.div`
  display: flex;
  padding: 0.3rem 0;
  justify-content: space-between;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const ErrorDates = styled.div`
  display: flex;
  justify-content: center;
`;
export const ErrorMsg = styled.div`
  color: ${props => props.theme.colors.orangeDarker};
`;

export const DateWrapper = styled.div`
  width: 45%;

  > div:first-child {
    margin-bottom: 10px;
  }

  > div {
    width: 160px;
  }

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 15px;
  }
`;

export const SingleTimeWrapper = styled.div`
  > div:first-child {
    margin-bottom: 10px;
  }
  
  .react-time-picker__wrapper {
    border: none;
    position: relative;
  }
  
  .react-time-picker__inputGroup {
    width: 120px;
    height: 38px;
    padding: 0 10px;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.colors.greyMedium};
    background-color: ${props => props.theme.colors.black30};
    color: ${props => props.theme.colors.white};
    
    input, select {
      font-size: 14px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }
  }
  
  .react-time-picker--disabled {
    background-color: transparent;
    cursor: not-allowed;
    opacity: 0.8;
    
    input, button, select {
      cursor: not-allowed;
    }
  }
  
  .react-time-picker__inputGroup__leadingZero {
    font-size: 14px;
    margin-left: 2px;
  }
  
  .react-time-picker__inputGroup__input--hasLeadingZero{
    padding-left: 7px;
  }
  
  .react-time-picker__clock{
    display: none;
  }
  
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.3);
  }
  
  .react-time-picker__inputGroup__input react-time-picker__inputGroup__minute {
    min-width: 18px;
  }
  
  .react-time-picker__inputGroup__input{
    color: ${props => props.theme.colors.white};
    outline: none;
  }

  .react-time-picker__inputGroup__amPm {
    > option {
      background: #2a2a2a;
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const TimeWrapper = styled.div`
  width: 55%;
  display: flex;

  > div:not(:last-child) {
    margin-right: 50px;

    @media ${props => props.theme.device.mobileL} {
      margin-right: 10px;
    }
  }
`;

export const WrapperTimePicker = styled.div``;

export const IconInput = styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%,-50%);
  font-size: 20px;
  color: ${props => props.theme.colors.primaryThemeBlue};
`;

export const IconWrapper = styled.div`
  padding: 9px;
  color: ${props => props.theme.colors.white};
  max-height: 100%;
  margin-right: -10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    background-color: ${props => props.theme.colors.white50};
    left: 0;
    top: 10%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 40px;
    padding-left: 9px;
    padding-top: 7px;
  }
`;

export const ButtonShareContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35px;

  @media ${props => props.theme.device.mobileL} {
    justify-content: center;
    margin-top: 10px;
  }

  & > button {
    width: fit-content;
    height: 40px;
    margin: 0;
    text-transform: uppercase;
  }
`;

export const ModalScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-time-picker__button {
    padding: 0;
  }

  ${({ arabic }) => arabic && css`
    align-items: flex-end;
    ${Subtitle} {
      text-align: right;
    }
    ${Session} {
      text-align: right;
    }
    ${Flexer} {
      flex-direction: column;
    }
    ${IconInput} {
      right: auto;
      left: 15px;
    }
    ${TimeWrapper} {
      flex-direction: row-reverse;
      justify-content: flex-end;
      > div:not(:last-child) {
        margin-right: 0;
        margin-left: 50px;

        @media ${props => props.theme.device.mobileL} {
          margin-left: 10px;
        }
      }
    }
    ${DateWrapper} {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: 10px;
      @media ${props => props.theme.device.mobileL} {
        margin-right: 25px;
      }

      > div:first-child {
        margin-left: auto;
      }
      > div {
        margin-left: auto;
      }
    }
  `}
`;
