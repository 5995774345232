import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import {
  CustomSelect, FilterPill, NoResult, Tabs,
} from '../../../ui/atoms';
import {
  Wrapper, FiltersContainer, Filter, SelectsContainer,
  FilterPillsContainer, TabsContainer, CoursesContainer, ViewMore,
  CourseItem, OptionalCoursesContainer,
} from './style';
import Arial from '../../../ui/typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { defaultOptionalStatusList, defaultTabsCourses } from '../../../utils/common';
import {
  OPTIONAL_COURSES_CATEGORIES_GET,
  OPTIONAL_COURSES_LIST_GET,
  OPTIONAL_COURSES_SET_FAVOURITE,
} from '../../redux/actions/optionalcourses';
import routes from '../../../routes';
import CardCourses from '../../../ui/atoms/CardCourses';
import { isArabic } from '../../../utils/locale';

const OptionalCourses = ({
  list, pager, categories, vocabulary, getOptionalCoursesList,
  setFavourite, getCategories, pushUrl,
}) => {
  const [courses, setCourses] = useState([]);
  const [defaultTabs, setDefaultTabs] = useState(defaultTabsCourses(vocabulary, 0, 0));
  const [selectedTab, setSelectedTab] = useState();
  const [selectedProgressFilter, setSelectedProgressFilter] = useState();
  const [selectedAreasFilter, setSelectedAreasFilter] = useState([]);
  const [optionProgress, setOptionProgress] = useState([]);
  const [optionAreas, setOptionAreas] = useState([]);

  useEffect(() => {
    if (selectedTab && selectedTab.value !== 'all') {
      setCourses([...list].filter(item => item.area === selectedTab.value));
    } else {
      setCourses([...list]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, selectedTab]);

  useEffect(() => {
    if (categories.length <= 0) getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    const formattedOptionProgress = [...defaultOptionalStatusList].map(({ label, value }) => ({ label: getValueVocabulary(vocabulary[label], label), value }));
    const formattedAreas = [...categories].map(({ name: label, id: value }) => ({ label, value }));
    setOptionProgress(formattedOptionProgress);
    setOptionAreas(formattedAreas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, vocabulary]);

  useEffect(() => {
    const headQuarterListTotal = [...list].filter(item => item.area === 'head_quarter').length;
    const regionalListTotal = [...list].filter(item => item.area === 'regional').length;
    const tabs = defaultTabsCourses(vocabulary, headQuarterListTotal, regionalListTotal);
    setSelectedTab(tabs[0]);
    setDefaultTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, vocabulary]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const area = [...selectedAreasFilter.map(filter => filter.value)].toString().replace(/,/g, ';') || undefined;
    const progress = selectedProgressFilter?.value;
    getOptionalCoursesList({ area, progress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgressFilter, selectedAreasFilter]);

  const removeFilter = (item) => {
    const newFilters = (selectedAreasFilter || []).filter(filter => filter.value !== item);
    setSelectedAreasFilter(newFilters);
  };

  const handleOnClickViewMore = () => {
    const area = [...selectedAreasFilter.map(filter => filter.value)].toString().replace(/,/g, ';') || undefined;
    const progress = selectedProgressFilter?.value;
    getOptionalCoursesList({
      area, progress, subset: true, loader: false,
    });
  };

  const redirectOptionalCoursesDetails = (id) => {
    pushUrl(`${routes.optionalcourses.path}/${id}`);
  };

  return (
    <OptionalCoursesContainer arabic={isArabic()}>
      <Wrapper>
        <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="optionalCourses">{getValueVocabulary(vocabulary.optionalCourses, 'optionalCourses')}</Arial>
        <TabsContainer>
          <Tabs tabType={Tabs.TYPE.OPTIONALCOURSES} tabs={[...defaultTabs]} onChange={value => setSelectedTab(value)} selected={selectedTab} />
        </TabsContainer>
        <FiltersContainer>
          <SelectsContainer>
            <Filter>
              <Arial type="newsTitle">{getValueVocabulary(vocabulary.filtersProgress, 'filtersProgress')}</Arial>
              <CustomSelect
                isClearable
                selected={selectedProgressFilter}
                options={optionProgress}
                onChange={newValue => setSelectedProgressFilter(newValue)}
              />
            </Filter>
            <Filter>
              <Arial type="newsTitle">{getValueVocabulary(vocabulary.optionalCoursesFiltersTitle, 'optionalCoursesFiltersTitle')}</Arial>
              <CustomSelect
                isClearable
                isSearchable
                options={optionAreas}
                selected={selectedAreasFilter}
                onChange={newValue => setSelectedAreasFilter(newValue || [])}
                isMulti
              />
            </Filter>
          </SelectsContainer>
          <FilterPillsContainer>
            {(selectedAreasFilter || []).map(filter => <FilterPill key={`filter-${filter.value}`} onRemove={removeFilter} value={filter.value} label={filter.label} />)}
          </FilterPillsContainer>
        </FiltersContainer>
        {(!selectedTab || selectedTab.value === 'all') && (
          <CoursesContainer>
            {
              [...list].map((item) => (
                <CourseItem key={`course-${item.id}`}>
                  <CardCourses item={item} onClickCard={() => redirectOptionalCoursesDetails(item.id)} onClickFavourite={() => setFavourite({ nid: item.id })} />
                </CourseItem>
              ))
            }
          </CoursesContainer>
        )}
        {selectedTab && selectedTab.value === 'head_quarter' && (
          <CoursesContainer>
            {
              [...list].filter((item) => item.area === selectedTab.value).map((item) => (
                <CourseItem key={`course-${item.id}`}>
                  <CardCourses item={item} onClickCard={() => redirectOptionalCoursesDetails(item.id)} onClickFavourite={() => setFavourite({ nid: item.id })} />
                </CourseItem>
              ))
            }
          </CoursesContainer>
        )}
        {selectedTab && selectedTab.value === 'regional' && (
          <CoursesContainer>
            {
              [...list].filter((item) => item.area === selectedTab.value).map((item) => (
                <CourseItem key={`course-${item.id}`}>
                  <CardCourses item={item} onClickCard={() => redirectOptionalCoursesDetails(item.id)} onClickFavourite={() => setFavourite({ nid: item.id })} />
                </CourseItem>
              ))
            }
          </CoursesContainer>
        )}
        {courses.length <= 0 && <NoResult />}
        {selectedTab?.value === 'all' && pager?.current_page < pager?.total_pages && (
          <ViewMore onClick={handleOnClickViewMore}>
            <Arial type="viewMore">{getValueVocabulary(vocabulary.viewMore, 'viewMore')}</Arial>
          </ViewMore>
        )}
      </Wrapper>
    </OptionalCoursesContainer>
  );
};

OptionalCourses.propTypes = {
  // HOC (connect, state)
  list: PropTypes.array.isRequired,
  pager: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  vocabulary: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getOptionalCoursesList: PropTypes.func.isRequired,
  setFavourite: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { data: list = [], pager = {}, categories } = state.optionalcourses;
    const { vocabulary } = state.app;
    return {
      list, pager, categories, vocabulary,
    };
  },
  dispatch => ({
    getOptionalCoursesList: ({
      subset, loader, area, progress,
    }) => dispatch({
      type: OPTIONAL_COURSES_LIST_GET._REQUEST, subset, loader, area, progress,
    }),
    setFavourite: ({ nid }) => dispatch({ type: OPTIONAL_COURSES_SET_FAVOURITE._REQUEST, nid }),
    getCategories: () => dispatch({ type: OPTIONAL_COURSES_CATEGORIES_GET._REQUEST }),
    pushUrl: url => dispatch(push(url)),
  })
)(OptionalCourses);
