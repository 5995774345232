import produce from 'immer';
import { REMOTE_CLASSROOM_SURVEY_CONTENT_GET } from '../actions/remoteclassroomsurvey';

const initialState = {
  selected_survey: {}
};

const RemoteClassroomSurveyReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case REMOTE_CLASSROOM_SURVEY_CONTENT_GET._SUCCESS: {
      const { response } = action;
      // eslint-disable-next-line prefer-destructuring
      draft.selected_survey = {
        ...state.selected_survey,
        ...response
      };
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default RemoteClassroomSurveyReducer;
