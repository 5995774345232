import styled, { css } from 'styled-components';

export const CommentsWrapper = styled.div`

  & > button {
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${props => props.theme.colors.greyDark};
  border: 1px solid ${props => props.theme.colors.brownGrey};
  border-radius: 12px;
  color: ${props => props.theme.colors.white};
  margin: 14px 0 0px;
  padding: 15px 21px;
  width: 100%;
  outline: none;
  
  ::placeholder {
    color: #a3a6b2;
    font-size: 14px;
    font-style: italic;
    line-height: 1.29;;
  }
`;

export const CommentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  
  margin-bottom: 40px;
`;

export const CommentContainer = styled.div`
  position: relative;
  padding: 20px 0;
  overflow-y: hidden;
  height: 100%;
  max-height: 220px;
  width: 100%;
  
  ${props => props.viewMore && css`
    overflow-y: scroll;
    max-height: 300px;
  `}
`;

export const CommentInfo = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  > div {
    width: auto;
  }
`;

export const CommentBody = styled.div`
  margin-left: 20px;
  position: relative;
`;

export const UserAvatar = styled.div`
  background-image: url(${props => props.avatar});
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

export const Text = styled.div`
  margin-top: 5px;
`;

export const Timestamp = styled.span`
  color: ${props => props.theme.colors.brownGrey};
  margin-left: 10px;
`;

export const CommentsHeader = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  margin-bottom: 20px;
`;

export const ViewMore = styled.div`
  margin: 20px auto;
  width: 150px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

export const CommentsContainer = styled.div`
  padding: 20px 0;

  ${props => !props.viewMore && css`
    &:after {
      content: '';
      width: 100%;
      height: 215px;
      background-image: linear-gradient(to bottom,rgba(44,45,50,0),#2c2d32 99%);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `}

  ${({ arabic }) => arabic && css`
    ${TextArea} {
      text-align: right;
    }
    ${CommentWrapper} {
      flex-direction: row-reverse;
    }
    ${CommentContainer} {
      text-align: right;
    }
    ${CommentInfo} {
      flex-direction: row-reverse;
    }
    ${Timestamp} {
      margin-left: 0;
      margin-right: 10px;
    }
    ${CommentBody} {
      margin-left: 0;
      margin-right: 20px;
    }
  `}
`;
