import {
  call, delay, put, select, takeLatest
} from 'redux-saga/effects';

import {
  APP_CONFIGURATION_GET, APP_STORE_RELOAD,
  // CHANGE_LANGUAGE,
  FAQ_GET, LOADER_CLOSE, LOADER_OPEN, USER_INFO_GET, VOCABULARY_GET
} from '../actions';
import app from '../../api/app';
import { getDefaultLanguage } from '../../../utils/locale';
//import { NOTIFICATION_GET } from '../actions/notifications';

function* appConfigGet() {
  yield delay(250);
  try {
    yield put({ type: LOADER_OPEN });
    const actualConfig = yield select(state => state.app.appConfig);

    if (!(actualConfig && actualConfig.homeHeroImage)) {
      const { data: response } = yield call(app.getConfig);
      yield put({ type: APP_CONFIGURATION_GET._SUCCESS, response });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > appConfigGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* appConfigGetWatch() {
  yield takeLatest(APP_CONFIGURATION_GET._REQUEST, appConfigGet);
}

function* appReload() {
  try {
    yield put({ type: LOADER_OPEN });
    const { authState: { isLogged } = {} } = yield select(state => state.app);
    if (isLogged) {
      yield put({ type: USER_INFO_GET._REQUEST });
    } else {
      yield put({ type: VOCABULARY_GET._REQUEST });
    }
    //yield put({ type: NOTIFICATION_GET._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > appReload', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* appReloadWatch() {
  yield takeLatest(APP_STORE_RELOAD, appReload);
}

function* faqGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data: { categories, faqs } = {} } = {} } = yield call(app.getFaq, { lang: defaultLanguage });
    yield put({ type: FAQ_GET._SUCCESS, categories, faqs });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > faqGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* faqGetWatch() {
  yield takeLatest(FAQ_GET._REQUEST, faqGet);
}

function* vocabularyGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data: vocabulary = {} } = {} } = yield call(app.getVocabulary, { lang: defaultLanguage });

    yield put({ type: VOCABULARY_GET._SUCCESS, vocabulary });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > app > faqGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
    yield put({ type: LOADER_CLOSE });
  }
}

function* vocabularyGetWatch() {
  yield takeLatest(VOCABULARY_GET._REQUEST, vocabularyGet);
}

// function* changeLanguage({ langcode }) {
//   try {
//     yield put({ type: LOADER_OPEN });
//     yield call(app.changeLanguage, { langcode: langcode.toLowerCase() });
//     yield put({ type: CHANGE_LANGUAGE._SUCCESS });
//     window.location.reload();
//   } catch (err) {
//     // eslint-disable-next-line no-console
//     console.log('sagas > app > changeLanguage', err);
//   } finally {
//     yield put({ type: LOADER_CLOSE });
//   }
// }

// function* changeLanguageWatch() {
//   yield takeLatest(CHANGE_LANGUAGE._REQUEST, changeLanguage);
// }

export default [
  appConfigGetWatch(),
  appReloadWatch(),
  faqGetWatch(),
  vocabularyGetWatch(),
  // changeLanguageWatch()
];
