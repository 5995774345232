import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { isArabic } from '../../../utils/locale';
import {
  BoxFinalTestContainer, Icon, BoxFinalTestContent, HeaderWrap, ButtonContainer,
} from './style';
import Arial from '../../typography/arial';
import { Link } from '../../../app/pages/RemoteClassroom/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

const BoxFinalTest = ({
  title, disabled, isToj, link, isClassroom, canPartecipate, vocabulary,
}) => {
  const convertUrlParticipate = url => (url.indexOf('http') < 0 ? `https:\\\\${url}` : url);

  return (
    <BoxFinalTestContainer isToj={isToj} arabic={isArabic()} disabled={disabled}>
      <BoxFinalTestContent onClick={() => {}}>
        <HeaderWrap isToj={isToj}>
          <Arial type="slotTitleAR">{title}</Arial>
          {isToj ? <Icon className="icon-arrow-right" />
            : (
              <ButtonContainer>
                <Link
                  href={link ? convertUrlParticipate(link) : ''}
                  target="_blank"
                  isClassroom={isClassroom}
                  disabled={!canPartecipate}
                >
                  {getValueVocabulary(vocabulary.joinButtonLabel, 'joinButtonLabel')}
                </Link>
              </ButtonContainer>
            )}
        </HeaderWrap>
      </BoxFinalTestContent>
    </BoxFinalTestContainer>
  );
};
BoxFinalTest.propTypes = {
  title: PropTypes.string,
  isToj: PropTypes.bool,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  isClassroom: PropTypes.bool,
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  }
)(BoxFinalTest);
