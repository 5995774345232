import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import {
  Input, InputContainer, Error, InputWrapper, Label, Icon,
} from './style';
import Roboto from '../../typography/roboto';
import { RegexpType, validator } from '../../../utils/validator';
import Arial from '../../typography/arial';
import { isArabic } from '../../../utils/locale';

const CustomInput = ({
  onBlur, onChange, placeholder, value,
  name, type, valid, readonly, autoFocus,
  disabled, min, max, error, label, icon,
}) => {
  const [val, setVal] = useState(value);
  const [focus, setFocus] = useState(false);

  const toggleFocus = () => setFocus(s => !s);

  useEffect(() => { setVal(value); }, [value]);

  const updateValue = ({ currentTarget: { value: newValue } = {} }) => {
    if (newValue.length <= 0 || !validator(RegexpType.SPACEONLY, newValue)) {
      setVal(newValue);
      if (onChange) onChange(newValue.trim());
    }
  };

  const handleOnBlur = () => {
    toggleFocus();
    if (onBlur) onBlur(val);
  };

  const getDefaultError = () => 'Errore: il campo non è valido';

  return (
    <InputWrapper arabic={isArabic()}>
      <InputContainer hasIcon={icon} valid={readonly || valid} value={val} readonly={readonly} disabled={disabled} error={error} focus={focus}>
        {(type === 'date' && label) && (
          <Label>
            <Arial type="description">{label}</Arial>
          </Label>
        )}
        <Input
          autoFocus={autoFocus}
          type={type}
          onBlur={handleOnBlur}
          onFocus={toggleFocus}
          onChange={updateValue}
          placeholder={readonly ? '-' : placeholder}
          value={val}
          name={name}
          readonly={readonly}
          disabled={disabled}
          min={min}
          max={max}
          label={label}
        />
        {icon && (
          <Icon className={icon} />
        )}
      </InputContainer>
      {!valid && error ? (
        <Error>
          <Roboto type="error">
            {typeof error === 'string' ? error : getDefaultError()}
          </Roboto>
        </Error>
      ) : null}
    </InputWrapper>
  );
};

CustomInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

CustomInput.defaultProps = {
  autoFocus: false,
  error: 'The value entered is invalid',
  placeholder: '',
  readonly: false,
  type: 'text',
  valid: true,
};

export default CustomInput;
