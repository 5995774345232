import styled, { css } from 'styled-components';

export const Top = styled.div`
  z-index: 1;
`;
export const Bottom = styled.div`
  z-index: 1;
`;

export const Hour = styled.div`
  font-size: 10px;
  margin-left: 10px;
`;

export const SessionDuration = styled.div`
  font-size: 10px;
  margin-left: 10px;
`;

export const SessionDate = styled.div`
  font-size: 10px;
  margin-left: 10px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 10px;
`;
export const Info = styled.div`
  font-size: 10px;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 178px;
  color: ${props => props.theme.colors.white};
  z-index: 0;
  position: absolute;
  top: 31px;
  right: -42px;
  transform: rotate(45deg);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;

  ${({ alt }) => alt && css`
    height: 42px;
    right: -46px;
    top: 23px;
  `}
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    background-color: #077ca7;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    background-color: #077ca7;
    transform: rotate(45deg);
  }
  
  & > div {
    z-index: 1;
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 8px;

    ${({ alt }) => alt && css`
      padding: 8px 31px;
    `}
    
    &:after {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(17.8% 0%,82% 0%,100% 100%,0% 100%);
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: -1;
      background-color: #00b2f3;

      ${({ alt }) => alt && css`
        clip-path: polygon(23% 0%,76% 0%,100% 100%,0% 100%);
      `}
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;

  & > button {
    width: 166px;
    height: 48px;
  }
`;

export const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: ${({ width }) => (width || 255)}px;
  height: ${({ height }) => (height || 295)}px;
  border-radius: 4px;
  padding: 20px;  
  transition: ${props => props.theme.constants.transitionBase};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  @media ${props => props.theme.device.mobileL} {
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8));
    transition: ${props => props.theme.constants.transitionBase};
  }
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    &:before {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
    }
  }

  ${({ arabic }) => arabic && css`
    ${ButtonContainer} {
      flex-direction: row-reverse;
    }

    ${Banner} {
      right: auto;
      left: -42px;
      transform: rotate(315deg);
    }
  `}
`;
