import styled, { css } from 'styled-components';
import { Container } from '../../../ui/gridSystem';

export const TojBanner = styled.div`
  width: 100vw;
  height: 300px;
  position: relative;

  @media ${props => props.theme.device.mobileL}{
    height: 500px;
  }
`;

export const TojBannerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  height: 100%;
  
  @media ${props => props.theme.device.tabletL}{
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .back-button {
    ${({ hasSlot }) => (hasSlot ? 'padding: 0 0 20px 0' : 'padding: 0 0 40px 0')};
  }
  
  div[data-vocabulary="tojDetailsTile"] {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const TojBannerInfo = styled.div`
   display: flex;
   flex-direction:row;
   align-items: flex-end;
   justify-content: space-between;
   margin-top: auto;
   
  > div  {
    width: fit-content;
    margin-bottom: 10px;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SessionsStatus = styled.div`
  padding: 0 15px;
  height: 30px; 
  font-size: 14px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  background: rgba(0, 178, 243, 0.55);
  border-radius: 14px;
  display: flex;
  align-items: center;
`;

export const SessionDuration = styled.div`
  margin-bottom: 10px;
  & > div {
    margin-bottom: 5px;
  }
`;

export const Body = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.midGray};
  transition: max-height 0.2s ease-in, opacity 0.2s ease-in;
  padding: 15px 0 8px 0;
  
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    height: 78px;
    padding-top: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
`;


export const InfoSession = styled.div``;


export const HeaderWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: center;
    height: 100px;
  }
`;


export const InfoParticipationContainer = styled.div`
  display: flex;
  align-items: flex-end;
  span.icon-check {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.colors.primaryThemeBlue};
  }
`;

export const InfoParticipation = styled.div`
  font-size: 14px;
  font-weight: ${props => (props.selected ? 'normal' : 'bold')};;
  color: ${props => (props.isLabel
    ? `${props.theme.colors.primaryThemeBlue}`
    : `${props.theme.colors.white}`)};
    
    
    div[data-vocabulary='infoParticipationLabel'] {
      margin-right: 20px;
    }
    div[data-vocabulary='infoParticipationValue'] {
      margin-right: ${props => (props.isTutor && !props.isCompleted ? 20 : 95)}px;
    }
`;

export const QuestionJoin = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
`;

export const SelectParticipateWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  width: 40%;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const Background = styled.div`
  height: 70%;
  min-height: 300px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});
  z-index: -1;

  @media ${props => props.theme.device.mobileL} {
    height: 100%;
    top: 0;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

export const ButtonScheduleContainer = styled.div`
  display: flex;

  & > button {
    width: 180px;
    height: 40px;
    margin: 0;
  }
`;

export const SlotContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const SlotsContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;

  > div:not(:first-child) {
    margin-top: 20px;
  }
`;

export const StudentSurvey = styled.div`
  margin-left: auto;
  & > button {
    padding: 0 10px;
    min-width: 180px;
    margin: 0;
  }
  > div {
    padding: 0 20px;
  }

  @media ${props => props.theme.device.mobileL} {
    margin-left: 0;
  }
`;

export const Title = styled.div`
  margin: 20px 0;
`;

export const OverviewContainer = styled.div``;

export const Text = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const IconWrapper = styled.div`
  line-height: 100%;
  vertical-align: middle;
  display: inline-flex;
  margin-left: 30px;
  
  @media ${props => props.theme.device.mobileL} {
    margin-left: 15px;
  }
`;

export const InfoContainer = styled.div`
  width: 380px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.1);
  background-color: #1f2025;
  color: #ffffff;
  padding: 15px 20px 30px 20px;
  margin-bottom: 20px;

  @media ${props => props.theme.device.mobileL} {
    width: auto;
    margin-left: 0px;
  }
`;

export const InfoTitle = styled.div`
  position: relative;
  padding-bottom: 14px;

  &:before {
    content: '';
    position: absolute;
    width: 112%;
    height: 1.5px;
    background-color: rgba(63, 63, 63, 0.7);
    left: -20px;
    bottom: 0;
  }
`;

export const InfoDescription = styled.div`
  margin-top: 26px;
  color: ${props => props.theme.colors.white};
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.white};
  margin-bottom: 15px;
`;

export const Link = styled.a`
  border-radius: 4px;
  background-color: #ffffff;
  color: ${props => props.theme.colors.primaryBlue};
  opacity: 1;
  border: 1px solid transparent;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  min-width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: unset;
  line-height: 1;
  text-align: unset;

  &:hover {
    color: #258900;
    opacity: 0.8;
  }

  &:focus,
  &:active {
    color: #258900;
    outline: none;
  }

  @media ${props => props.theme.device.mobileL} {
    margin: 0;
  }

  ${({ disabled }) => disabled
  && css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

export const ButtonContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ParticipateBtnMessage = styled.div`
  margin-left: 15px;
  padding-right: 20px;

  @media ${props => props.theme.device.mobileL} {
    margin-left: 0px;
    margin-top: 5px;
  }
`;

export const SlotTitle = styled.div`
  div[data-vocabulary='slotTitle'] {
    margin-bottom: 5px;
    color: ${props => props.theme.colors.greyMedium}
  }
  
  div[data-vocabulary='slotDate'] {
    margin-right: 35px;
    text-transform: capitalize;
  }
  div[data-vocabulary='scheduledTime'] {
    color: ${props => props.theme.colors.greyMedium}
  }
  div[data-vocabulary='slotTitleSchedule'] {
    & > div {
      display: inline;
    }
  }

  ${({ arabic }) => arabic && css`
      div[data-vocabulary='slotTitleSchedule'] {
        display: flex;
        flex-direction: row-reverse;
      }
      div[data-vocabulary='scheduledTime'] {
        width: fit-content;
        text-align: left;
      }
      div[data-vocabulary='slotDate'] {
        margin-right: 0;
        margin-left: 35px;
        width: fit-content;
        text-align: left;
      }
  `}
`;

export const SelectAccordionOption = styled.div`
  cursor: ${props => (props.canModify ? 'pointer' : 'not-allowed')};
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid white;
  }
`;

export const Icon = styled.span`
  font-size: 20px;
  color: ${props => (props.canModify
    ? props.theme.colors.islamicGreen
    : props.theme.colors.brownGrey)};
`;

export const MaterialWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
`;

export const RightContainerItems = styled.div`
    overflow: hidden;
`;

export const TabsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    text-align: center;    
  }
`;

export const IconCompleted = styled.span`
  color: ${props => props.theme.colors.primaryThemeBlue};
  background: white;
  overflow:hidden;
  border-radius:50%;
  background-color: ${props => props.theme.colors.white};
  border: 0.5px solid ${props => props.theme.colors.white};
`;

export const SlotSingleActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  @media ${props => props.theme.device.mobileL} {
      flex-direction: column;
      > div:first-child {
        align-items: center;
        margin-bottom: 20px;
      }  
  }
`;

export const SlotSingle = styled.div`
  width: 100%;
  padding: 15px 20px;
  background: ${props => props.theme.colors.sharkLight};
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
  
  ${({ isScheduled }) => !isScheduled && css`
    padding-top: 9px;
    ${HeaderWrap} {
      align-items: center;
    }
    ${Body} {
      cursor: not-allowed;
      opacity: 0.2;
    }
    ${SelectParticipateWrapper} {
      pointer-events: none;
    }
  `}
  
  ${({ isPlannable }) => !isPlannable && css`
    padding-top: 9px;
  `}
`;

export const TojContentContainer = styled(Container)`
  padding-bottom: 110px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  
  @media ${props => props.theme.device.tabletL}{
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
`;


export const TojWrapper = styled.div`
  ${({ arabic }) => arabic && css`
    ${TojBannerContainer} {
      div[data-vocabulary="tojDetailsTile"] {
        flex-direction: row-reverse;
      }
    }

    ${IconWrapper} {
      margin-right: 30px;
      margin-left: 0;
    }

    ${TojBannerInfo} {
      flex-direction: row-reverse;

      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
      }

    ${HeaderWrap} {
      flex-direction: row-reverse;

      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    ${InfoSession} {
      width: 60%;
    }

    ${Body} {
      flex-direction: row-reverse;

      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
      }
    }

    ${SlotSingleActionButtons} {
      flex-direction: row-reverse;

      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
      }
    }

    ${ButtonContainer} {
      flex-direction: row-reverse;

      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    ${SlotTitle} {
      div[data-vocabulary='slotTitle'] {
        text-align: left !important;
      }
      div[data-vocabulary='slotTitleSchedule'] {
        display: flex;
        flex-direction: row-reverse;

        div[data-vocabulary='slotDate'] {
          margin-right: 0;
          margin-left: 35px;
        }
      }
    }
  `}
`;

export const CourseNotAvailable = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;

  > div {
    line-height: 1.17;

    @media ${props => props.theme.device.mobileL} {
      font-size: 22px;
    }
  }
`;
