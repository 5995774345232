import { apiCall, baseUrl, extendUrl } from './config';
import { parseQueryParams } from '../../utils/queryParams';
import { shouldUseMock } from '../../utils/common';
import {
  favoriteNodeActionMock,
  getCategoriesMock,
  getCourseDetailMock,
  getCoursesMock,
  setCommentsMock
} from './mock';

export default {
  getOptionalCoursesList: (filters) => {
    if (shouldUseMock()) {
      return getCoursesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course${parseQueryParams(filters)}`, 'GET');
  },
  getOptionalCoursesDetails: ({ id, ...filters }) => {
    if (shouldUseMock()) {
      return getCourseDetailMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/course/${id}/details${parseQueryParams(filters)}`, 'GET');
  },
  setNodeAction: (payload) => {
    if (shouldUseMock()) {
      return favoriteNodeActionMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/node_action`, 'POST', payload);
  },
  setComment: (body) => {
    if (shouldUseMock()) {
      return setCommentsMock();
    }
    return apiCall('/comment', 'POST', body);
  },
  getCategories: (filters) => {
    if (shouldUseMock()) {
      return getCategoriesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}vocabulary${parseQueryParams(filters)}`, 'GET');
  }
};
