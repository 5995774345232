import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import { calcPagination } from '../../../utils/pagination';
import { getDefaultLanguage } from '../../../utils/locale';
import favourite from '../../api/favourite';
import {
  FAVOURITE_LIST_GET, SET_FAVOURITE
} from '../actions/favourite';

function* favouriteListGet({ loader = true, subset = false }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { pager: { current_page, total_pages } = {} } = yield select(state => state.favourite);
    const { next_page, isLastPage } = calcPagination({ current_page, total_pages });
    if (!isLastPage) {
      const { defaultLanguage } = getDefaultLanguage();
      const { data: { data, pager } = {} } = yield call(favourite.getFavouriteList, { items_per_page: 24, page: next_page, lang: defaultLanguage });
      yield put({
        type: FAVOURITE_LIST_GET._SUCCESS, data, pager, subset
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > favourite > favouriteListGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* favouriteListGetWatch() {
  yield takeLatest(FAVOURITE_LIST_GET._REQUEST, favouriteListGet);
}


function* favouriteSet({ nid, node_type }) {
  if (nid) {
    try {
      const payload = {
        nid,
        node_type,
        action_type: 'favourite'
      };
      yield call(favourite.setFavourite, payload);
      yield put({ type: SET_FAVOURITE._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > favourite > favouriteSet', err);
    }
  }
}

function* favouriteSetWatch() {
  yield takeLatest(SET_FAVOURITE._REQUEST, favouriteSet);
}
export default [
  favouriteListGetWatch(),
  favouriteSetWatch()
];
