const durationFormatHour = time => {
  if (time) {
    const hour = Math.trunc(time / 60);
    const min = (time % 60).toFixed(0);
    return `${hour > 0 ? `${hour}h` : ''} ${min > 0 ? `${min}min` : ''}`;
  }

  return '';
};

export default durationFormatHour;
