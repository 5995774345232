import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import {
  getTojContentMock, getAgendaPlannedListMock, setTojSessionModeMock, setSessionPlanMock
} from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getTojContent: ({ id, tutee_id, role }) => {
    if (shouldUseMock()) {
      return getTojContentMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/toj/${id}/details${parseQueryParams({ tutee_id, role })}`, 'GET');
  },
  getTojList: (filters) => {
    if (shouldUseMock()) {
      return getAgendaPlannedListMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/agenda/planned_list${parseQueryParams(filters)}`, 'GET');
  },
  setTojSessionMode: (body) => {
    if (shouldUseMock()) {
      return setTojSessionModeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/toj/sessionmode`, 'POST', body);
  },
  setLikeDislikeFeedback: ({ id, feedback_value }) => {
    if (shouldUseMock()) {
      return setTojSessionModeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/feedback/${id}/insert`, 'POST', { feedback_value });
  },
  setSessionPlan: (body) => {
    if (shouldUseMock()) {
      return setSessionPlanMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/sessionplan`, 'POST', body);
  }
};
