import styled, { css } from 'styled-components';

const Title = styled.div`
  position: relative;
  padding-bottom: 14px;
  line-height: 20px;

  &:before {
    content: '';
    position: absolute;
    width: 112%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -20px;
    bottom: 0;
  }
`;

const Text = styled.div`
  margin-top: 26px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Icon = styled.span`
  font-size: 48px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.isActive
    ? props.theme.colors.azureDark
    : props.theme.colors.brownGrey)};
  display: flex;
  align-items: center;

  & > div {
    padding-left: 10px;
  }
`;

const LikeDislikeBoxContainer = styled.div`
  width: 380px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.1);
  background-color: #1f2025;
  color: ${props => props.theme.colors.white};
  padding: 15px 20px 30px 20px;
  margin-bottom: 20px;

  @media ${props => props.theme.device.tabletL} {
    width: 300px;
    height: auto;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }

  ${({ arabic }) => arabic && css`
    ${Text} {
      flex-direction: row-reverse;
      span, div {
        transform: scale(-1, 1);
      }
      div {
        padding-left: 0;
        padding-right: 10px;
      }
    }
  `}
`;

export {
  LikeDislikeBoxContainer, Title, Text, Icon
};
