import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Label, Input, CustomCheckbox } from './style';
import Arial from '../../typography/arial';

const CheckboxSurvey = ({
  label, name, onChange, checked, value: eValue, disabled
}) => {
  const [value, setValue] = useState(eValue);
  const handleOnChange = ({ currentTarget: { value: newValue } = {} }) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  return (
    <Label disabled={disabled}>
      <Input checked={checked} disabled={disabled} type="checkbox" name={`checkbox-${name}`} value={value} onChange={handleOnChange} />
      <Arial>{label || checked}</Arial>
      <CustomCheckbox />
    </Label>
  );
};


CheckboxSurvey.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default CheckboxSurvey;
