import styled from 'styled-components';

const Container = styled.div`
  .custom-tooltip {
    width: 242px;
    min-height: 122px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px ${props => props.theme.colors.lighterGrey};
    background-color: ${props => props.theme.colors.white};
    padding: 15px;
    opacity: 1;

    & > div {
      text-align: center;
      color: ${props => props.theme.colors.gray};
      white-space: pre-wrap;
    }
  }
`;

export { Container };
