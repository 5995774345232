import { createAsyncActionType } from './index';

export const OPTIONAL_COURSES_LIST_GET = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_LIST_GET');
export const OPTIONAL_COURSES_DETAILS_GET = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_DETAILS_GET');
export const OPTIONAL_COURSES_SET_FAVOURITE = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_SET_FAVOURITE');
export const OPTIONAL_COURSES_LO_SET_LIKEDISLIKE = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_LO_SET_LIKEDISLIKE');
export const OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS');
export const OPTIONAL_COURSES_LO_SET_VIEWS = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_LO_SET_VIEWS');
export const OPTIONAL_COURSES_LO_SET_COMMENT = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_LO_SET_COMMENT');
export const OPTIONAL_COURSES_CATEGORIES_GET = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_CATEGORIES_GET');
export const OPTIONAL_COURSES_LO_UNSET = createAsyncActionType('optionalcourses', 'OPTIONAL_COURSES_LO_UNSET');
