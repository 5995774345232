import styled from 'styled-components';

export const ImageItem = styled.img`
  width: 100%;
  height: auto;
`;

export const VideoPlay = styled.div`
  z-index: 10;
  cursor: pointer;
  margin-top: 40px;

  > span {
    font-size: 60px;
  }
`;

export const VideoPlayContainer = styled.div`
  width: 100%;
  display: ${props => (props.visible ? 'block' : 'none')};
  video {
    object-fit: cover;
    width: 100%;
    height: auto;
    outline: none;
  }
`;

export const VideoContainer = styled.div`
  margin-top: 100px;
  
  ul {
    list-style: initial;
  }
`;

export const ImageContainer = styled.div`
  display: ${props => (!props.visible ? 'block' : 'none')};
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black50};
`;

export const VideoTitle = styled.div`
  margin-bottom: 10px;
`;

export const VideoSubTitle = styled.div`
  padding: 0 40px
`;
