import styled, { css } from 'styled-components';

export const Input = styled.input`
  background-color: ${props => props.theme.colors.transparent};
  border: none;
  width: ${props => (props.type === 'date' && props.label ? 'auto' : '100%')};
  height: 100%;

  :focus {
    outline: none;
  }
  
  font-family: ${({ theme }) => theme.fontset.arial};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-overflow: ellipsis;
  color: ${props => (props.type === 'date' ? props.theme.colors.greyMedium : props.theme.colors.white)}; 
  position: ${props => (props.type === 'date' && props.label ? 'absolute' : 'none')};
  top: ${props => (props.type === 'date' && props.label ? '0' : 'none')};
  right: ${props => (props.type === 'date' && props.label ? '10px' : 'none')};
  max-width: ${props => (props.type === 'date' && props.label ? '160px' : 'none')};

  ::placeholder {
    font-weight: 500;
    font-style: italic;
    color: ${props => props.theme.colors.greyMedium};
    opacity: 50%;
  }
  
  ::-webkit-input-placeholder { /* Edge */
    font-weight: 500;
    color: ${props => props.theme.colors.greyMedium};
    opacity: 50%;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 500;
    color: ${props => props.theme.colors.greyMedium};
    opacity: 50%;
  }
  
  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}
  
  ${({ readonly }) => readonly && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const InputContainer = styled.div`
  position: relative;
  min-width: 65px;
  height: 38px;
  border: solid 1px ${({ valid, value, theme }) => (valid || !value ? theme.colors.greyMedium : theme.colors.orangeDarker)};
  background-color: ${props => props.theme.colors.black30};
  padding: 0 10px;
  border-radius: 4px;

  ${props => props.hasIcon && css`
    padding-right: 30px;
  `};
  
  @media ${props => props.theme.device.mobileL} {
    padding: 0 0 0 5px;
    min-width: 80px;
    ${props => props.hasIcon && css`
      padding-right: 30px;
    `};
  }

    
  ${({ disabled, readonly }) => disabled && !readonly && css`
    cursor: not-allowed;
    opacity: 0.6;
  `};
  
  ${({ readonly, theme }) => readonly && css`
    pointer-events: none;
    border: none;
    background-color: ${theme.colors.custom.transparent};;
    padding: 10px 0;
  `};
`;


export const SpanIcon = styled.span`
  position: absolute;
  width: 50px;
  height: 40px;
  right: 0;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  background-color: ${props => (props.hasValue ? props.theme.colors.orangeDarker : 'transparent')};
  color: ${props => (props.hasValue ? props.theme.colors.white : props.theme.colors.black)};
  cursor: pointer;
  font-size: 30px;
`;

export const ValidInput = styled.span`
  position: absolute;
  font-size: 35px;
  font-weight: 700;
  top: 50%;
  right: 5px;
  color: ${({ theme }) => theme.colors.green};
  transform: translate(0, -50%);
`;


export const Error = styled.span`
  color: ${props => props.theme.colors.orangeDarker};
  display: block;
  margin-top: 5px;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.greyMedium};
  position: absolute;
  top: 13px;

& > div {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;


  @media ${props => props.theme.device.tablet} {
    max-width: 300px;;
  }

  @media ${props => props.theme.device.mobileM} {
    max-width: 130px;
  }

  @media ${props => props.theme.device.mobileS} {
    max-width: 100px;
  }
}
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: ${props => props.theme.colors.primaryThemeBlue}
`;

export const InputWrapper = styled.div`
  width: 100%;

  ${({ arabic }) => arabic && css`
    ${Input} {
      text-align: right;
    }
    ${InputContainer} {

      @media ${props => props.theme.device.mobileL} {
        padding: 0 5px 0 0;
        min-width: 80px;
      }
    }
  `}
`;
