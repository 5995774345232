import styled, { css } from 'styled-components';

export const IconCalendar = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: ${({ disabled }) => (disabled ? '#00618d' : '#00b2f3')};
`;

export const DatePickerContainer = styled.div`
  position: relative;
  width: ${props => props.widthInput};
  height: 40px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.colors.greyMedium};
  background-color: ${props => props.theme.colors.black30};
  color:${props => props.theme.colors.white};
  padding: 10px 5px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
    
  ${({ disabled, readonly }) => disabled && !readonly && css`
    cursor: not-allowed;
    background-color: rgba(63, 63, 63, 0.7);
    color: #a3a6b2;
    border: solid 1px #a3a6b2;
  `};
  
  ${({ readonly }) => readonly && css`
    pointer-events: none;
    border: none;
    background-color: 'transparent';
    padding: 10px 0;
  `};
  
  input {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #fff;
    width: inherit;
    
    ${({ disabled, readonly, theme }) => disabled && !readonly && css`
    cursor: not-allowed;
    color: #a3a6b2;
  `};

    
    &:focus {
      outline: none;
    }
    
    ::placeholder {
      font-weight: normal;
      font-style: italic;
      color: gray;
    }
    
    ::-webkit-input-placeholder { /* Edge */
      font-weight: normal;
      font-style: italic;
      color: gray;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-weight: normal;
      font-style: italic;
      color: gray;
    }
  
  }
  
  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      z-index: 2;
    }
  }

  
  .react-datepicker {
    font-family: ${({ theme }) => theme.fontset.arial};
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 0;
    transition: all 250ms ease-in-out;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 0;
    }
    &:focus {
      outline: none;
    }
  }
  .react-datepicker__day {
      border-radius: 0;
      transition: all 250ms ease-in-out;
      &:hover {
        border-radius: 0;
      }
      &:focus {
        outline: none;
      }
  }

  div.react-datepicker-popper {
    z-index: 3;
  }

  ${({ arabic }) => arabic && css`
    input {
      text-align: right;
    }
    ${IconCalendar} {
      right: auto;
      left: 10px;
    }
  `}
`;
