import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';

import {
  SubNavBarContainer, ContentWrapper, SubMenuElementContainer, Wrapper,
} from './style';

import { Container } from '../../gridSystem';
import routes from '../../../routes';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Arial from '../../typography/arial';
import { isArabic } from '../../../utils/locale';

const subMenuElements = [
  {
    title: 'learningEvents',
    url: routes.learningevents.path,
  },
  {
    title: 'mandatoryTraining',
    url: routes.mandatorytraining.path,
  },
  {
    title: 'optionalCourses',
    url: routes.optionalcourses.path,
  },
];

const subMenuElement = ({ title, url }, redirect, isActive = true, vocabulary) => (
  <SubMenuElementContainer onClick={() => redirect(url)} active={isActive} key={title} vocabulary={vocabulary} color={title}>
    <Arial type="SubMenuElement" vocabulary={title}>{getValueVocabulary(vocabulary[title], title)}</Arial>
  </SubMenuElementContainer>
);

const SubNavBar = ({
  currentPath, pushUrl, vocabulary, navigationMobile,
}) => {
  const [activePath, setActivePath] = useState(currentPath);
  useEffect(() => { setActivePath(currentPath); }, [currentPath]);

  const changeLocation = (url) => {
    pushUrl(url);
  };

  return (
    <SubNavBarContainer arabic={isArabic()} navigationMobile={navigationMobile}>
      <Container className="p-0">
        <ContentWrapper className="container-fluid">
          <Wrapper>
            {subMenuElements.map(elem => subMenuElement(elem, changeLocation, activePath.includes(elem?.url), vocabulary))}
          </Wrapper>
        </ContentWrapper>
      </Container>
    </SubNavBarContainer>
  );
};

SubNavBar.propTypes = {

  // HOC (connect, state)
  currentPath: PropTypes.string.isRequired,
  navigationMobile: PropTypes.bool.isRequired,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    currentPath: state.app.currentPath,
    vocabulary: state.app.vocabulary,
    navigationMobile: state.app.navigationMobile,
  }),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
  })
)(SubNavBar);
