import React from 'react';

import { connect } from 'react-redux';
import {
  FootContainer, FootContent, FootSection, IntesaLogo,
  Link, LinkSection,
} from './style';
import { Container } from '../../gridSystem';
import Arial from '../../typography/arial';
import { Logo } from '../../atoms';
import LogoAuth from '../../assets/img/logo-testo-bianco.png';
import { isArabic } from '../../../utils/locale';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import compose from '../../../utils/compose';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';


const links = [
  { label: 'CORPORATE WEBSITE', url: 'https://group.intesasanpaolo.com/en/' },
];

const getLink = ({ label, url }) => (
  <Link href={url} key={label} target="_blank"><Arial style={{ fontSize: '14px', lineHeight: 1.71 }}>{label}</Arial></Link>
);

const Footer = ({ vocabulary }) => (
  <FootContainer arabic={isArabic()}>
    <Container className="content-wrapper">
      <FootContent>
        <FootSection>
          <Logo size={Logo.SIZE.LARGE} src={LogoAuth} />
        </FootSection>
        <FootSection>
          <Arial style={{
            width: '100%', borderBottom: '1px solid #575a64', paddingBottom: '5px', fontSize: '14px', fontWeight: 'normal',
          }}
          >
            {getValueVocabulary(vocabulary.usefulLinksLabel, 'usefulLinksLabel')}
          </Arial>
          <LinkSection>
            {links.map(link => getLink(link))}
          </LinkSection>
        </FootSection>
      </FootContent>
      <IntesaLogo className="icon-isp-logo-2" />
    </Container>
  </FootContainer>
);

Footer.propTypes = {};

const composed = compose(
  connect(
    state => ({
      vocabulary: state.app.vocabulary,
    })
  ),
  withMediaQueries
)(Footer);
export default composed;
