import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Footer, NavBar, Scroller } from '../index';
import {
  PageContainer,
  ColoredLinesBar,
  ColoredLinesContainer,
} from './styles';
import { LinearLoader } from '../Loader';
import SubNavBar from '../SubNavBar';
import { isArabic } from '../../../utils/locale';

const GlobalLayout = ({ children, isLoading }) => (
  <>
    <NavBar />
    <SubNavBar />
    <ColoredLinesContainer>
      <ColoredLinesBar />
      <ColoredLinesBar />
      <ColoredLinesBar />
    </ColoredLinesContainer>
    {isLoading && <LinearLoader />}
    <PageContainer arabic={isArabic()} id="anchor">{children}</PageContainer>
    <Scroller />
    <Footer />
  </>
);

GlobalLayout.propTypes = {
  children: PropTypes.node,

  // HOC (connect, state)
  isLoading: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isLoading: state.app.loader.isSpinning,
}))(GlobalLayout);
