import produce from 'immer';
import {
  HOME_FAVOURITE_LIST_GET, HOME_LEARNING_EVENTS_LIST_GET,
  HOME_MANDATORY_TRAINING_LIST_GET,
  HOME_OPTIONAL_COURSES_LIST_GET, HOME_TOJ_PLANNED_LIST_GET
} from '../actions/home';

const initialState = {
  mandatory_training: [],
  optional_courses: [],
  favourites: [],
  learning_events: [],
  plannedTOJ: []
};

const homeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case HOME_LEARNING_EVENTS_LIST_GET._SUCCESS: {
      const { data } = action;
      const orderedPlannableList = data.sort((a, b) => new Date(a.values.deadline) - new Date(b.values.deadline));
      draft.learning_events = orderedPlannableList;
      break;
    }
    case HOME_TOJ_PLANNED_LIST_GET._SUCCESS: {
      const { response = [] } = action;
      const formattedToj = [...response].reduce((acc, value) => {
        const item = {
          ...value.values,
          agenda_type: value.type,
          slots: value.values.slots.sort((a, b) => new Date(a.start) - new Date(b.start))
        };
        return [...acc, item];
      }, []);

      const reOrderedPlannedTOJ = formattedToj.sort((a, b) => new Date(b.slots[0].startDateTimestamp) - new Date(a.slots[0].startDateTimestamp));
      draft.plannedTOJ = reOrderedPlannedTOJ;
      break;
    }
    case HOME_MANDATORY_TRAINING_LIST_GET._SUCCESS: {
      const { data: { courses = [] } } = action;
      draft.mandatory_training = courses;
      break;
    }
    case HOME_OPTIONAL_COURSES_LIST_GET._SUCCESS: {
      const { data: { courses = [] } } = action;
      draft.optional_courses = courses;
      break;
    }
    case HOME_FAVOURITE_LIST_GET._SUCCESS: {
      const { data } = action;
      draft.favourites = data;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default homeReducer;
