import styled from 'styled-components';

export const Image = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 270px;
    height: 100%;
    position: relative;
`;


export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
`;

export const IconVideo = styled.div`
    font-size: 60px;
    cursor: pointer;
`;

export const VideoWithModalContainer = styled.div`
    font-size: 60px;
    cursor: pointer;
    height: 100%;
`;
