import {
  takeLatest, takeEvery, put, call
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from '../actions';

import {
  REMOTE_CLASSROOM_SET_INSERT_SURVEY,
  REMOTE_CLASSROOM_SURVEY_CONTENT_GET
} from '../actions/remoteclassroomsurvey';
import { ErrorModal } from '../../../ui/components/Modals';
import remoteclassroomsurvey from '../../api/remoteclassroomsurvey';
import ModalSurveyConfirmation from '../../../ui/components/Modals/ModalSurveyConfirmation';

function* getRemoteClassroomSurveyContent({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data: response } = {}
    } = yield call(remoteclassroomsurvey.getSurveyContent, { id });
    yield put({ type: REMOTE_CLASSROOM_SURVEY_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: REMOTE_CLASSROOM_SURVEY_CONTENT_GET._ERROR, error });
    console.log('sagas > survey > getRemoteClassroomSurveyContent', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getRemoteClassroomSurveyContentWatch() {
  yield takeEvery(
    REMOTE_CLASSROOM_SURVEY_CONTENT_GET._REQUEST,
    getRemoteClassroomSurveyContent
  );
}

function* setInsertRemoteClassroomSurvey({
  remoteClassroomId,
  answers,
  id,
  description
}) {
  try {
    const body = {
      remote_classroom_id: remoteClassroomId,
      id,
      description,
      answers: Object.keys(answers).map(key => {
        const { survey } = answers[key];

        let answer = '';
        switch (survey?.questionType) {
          case 'question_multiple':
            answer = answers[key]?.value?.toString().replace(/,/g, ';');
            break;
          default:
            answer = answers[key]?.value;
            break;
        }

        return {
          question_id: survey?.idQuestion,
          question: survey?.question,
          comment: '',
          has_comment: survey?.hasComment,
          order: survey?.order,
          answer
        };
      })
    };

    console.log('setInsertRemoteClassroomSurvey ', body);
    yield call(remoteclassroomsurvey.setInsertSurvey, body);
    yield put({ type: REMOTE_CLASSROOM_SET_INSERT_SURVEY._SUCCESS });
    yield put({ type: MODAL_OPEN, id: ModalSurveyConfirmation.id });
    //yield put(push(`${routes.remoteclassroom.path}/${remoteClassroomId}`));
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ErrorModal.id,
      data: {
        title: 'Errore',
        message: err?.response?.data.action_result.message
      }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > survey > setInsertRemoteClassroomSurvey', err);
  }
}

function* setInsertRemoteClassroomSurveyWatch() {
  yield takeLatest(
    REMOTE_CLASSROOM_SET_INSERT_SURVEY._REQUEST,
    setInsertRemoteClassroomSurvey
  );
}

export default [
  getRemoteClassroomSurveyContentWatch(),
  setInsertRemoteClassroomSurveyWatch()
];
