import styled, { css } from 'styled-components';

export const CommentsContainer = styled.div`
  min-width:780px;
  margin-top: -10px;
  margin-bottom: -19.5px;

  @media ${props => props.theme.device.laptop} {
    min-width:auto;
  }
`;

export const MaxLength = styled.div`
color: ${props => props.theme.colors.brownGrey};
position:absolute;
  bottom: 10px;
  right: 10px;
`;

export const CommentsWrapper = styled.div`
  position:relative;
`;

export const TextArea = styled.textarea`
  background-color: ${props => props.theme.colors.greyDark};
  border: 1px solid ${props => props.theme.colors.brownGrey};
  border-radius: 4px;
  color: ${props => props.theme.colors.white};
  margin: 14px 0 0px;
  padding: 15px 21px;
  width: 100%;
  font-style: italic;
  height:150px;
`;

export const CommentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const CommentContainer = styled.div`
  overflow-y: hidden;
  height: 100%;
  max-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  ${props => props.viewMore && css`
    overflow-y: scroll
  `}
`;

export const CommentInfo = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  > div {
    width: auto;
  }
`;

export const CommentBody = styled.div`
  margin-left: 20px;
  position: relative;
`;

export const UserAvatar = styled.div`
  background-image: url(${props => props.avatar});
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

export const Text = styled.div`
  margin-top: 5px;
`;

export const Timestamp = styled.span`
  color: ${props => props.theme.colors.brownGrey};
  margin-left: 10px;
`;

export const CommentsHeader = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  margin-bottom: 20px;
`;
