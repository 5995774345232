import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Roboto from '../../typography/roboto';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
`;

const Button = styled.button`
  background: transparent;
  border: transparent;
  min-width: 100px;
  min-height: 25px;
  margin: 0 15px;
`;

const IconArrow = styled.span`
  font-size: 22px;
`;

const PaginatorButtons = ({
  currentPage, totalPage, onChangePage, disabled
}) => (
  <Container>
    <Button onClick={() => onChangePage(currentPage - 1)} disabled={currentPage === 0 || disabled}><Roboto type="infoTitle" configuration={{ lineHeight: 1.29, size: 16 }}><IconArrow className="icon-left" /></Roboto></Button>
    <Button onClick={() => onChangePage(currentPage + 1)} disabled={currentPage + 1 === totalPage || disabled}><Roboto type="infoTitle" configuration={{ lineHeight: 1.29, size: 16 }}><IconArrow className="icon-arrow-right" /></Roboto></Button>
  </Container>
);

PaginatorButtons.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default PaginatorButtons;
