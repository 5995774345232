import styled, { css } from 'styled-components';
import { ToastContainer as Toaster } from 'react-toastify';

export const ToastWrapper = styled.div`
  .Toastify__toast-container {
    top: 10px;
    @media ${props => props.theme.device.mobile} {
      top: 10px;
    }
  }
`;

export const ToastContainer = styled(Toaster)`
  .Toastify__toast--default, .Toastify__toast--success, .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.sharkLight};
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.8);
    color: ${({ theme }) => theme.colors.white};
    
    @media ${props => props.theme.device.mobile} {
      min-height: 40px;
    }
  };
  
  ${({ isArabic }) => isArabic && css`
    .Toastify__toast-body {
      flex-direction: row-reverse;
    }
  `}
`;

export const ToastCustomContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  
  > div {
    margin-left: 10px;
  }
`;
