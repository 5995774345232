import styled, { css } from 'styled-components';
import Row from '../../../ui/gridSystem/Row';
import { Col } from '../../../ui/gridSystem';

export const ResultContainer = styled(Row)`
  margin-top: 40px;
  
  .info-container {
    margin-bottom: 7px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 18px;
  padding-top: 50px;
  text-align: center;
`;

export const SearchWord = styled.span`
  font-weight: 900;
`;

export const Icon = styled.span`
  font-size: 18px;
`;

export const TabsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  // Filter Tabs on two row and center alignment
  > div {
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 
    > div {
      margin-bottom: 10px;
    }
  }
 
  @media ${props => props.theme.device.tablet} {
    text-align: center;    
  }
`;

export const TotalCount = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brownGrey};
`;

export const TotalCountLabel = styled.span`
  font-style: italic;
`;

export const TotalCountNumber = styled.span`
  font-style: bold;
`;

export const ItemsContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }
`;

export const CardContainer = styled(Col)`
  padding: 20px 10px 0;
  @media ${props => props.theme.device.tablet} {
    width: 33.3%;
  }

  @media ${props => props.theme.device.mobileL} {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const InfoContainer = styled.div.attrs(() => ({
  className: 'info-container'
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.transparent};

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    padding: 0px;
  }

  @media ${props => props.theme.device.mobileL} {
    //padding-right: 20px;
    margin-bottom: 15px;
  }

  .state {
    width: auto;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: ${props => props.theme.colors.darkerGrey};
    text-transform: uppercase;
    padding: 2px 5px;

    @media ${props => props.theme.device.tablet} {
      display: none;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 15px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: ${props => props.theme.colors.transparent};

  ${({ arabic }) => arabic && css`
    ${HeaderContent} {
      justify-content: flex-end;
    }
    ${Title} {
      margin-right: 0;
      margin-left: 15px;
    }
    ${ItemsContainer} {
      flex-direction: row-reverse;
    }
  `}
`;
