import {
  takeLatest, takeEvery, put, call,
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from '../actions';

import { ErrorModal } from '../../../ui/components/Modals';
import {
  TOJ_SURVEY_CONTENT_GET,
  TOJ_SET_INSERT_SURVEY,
  TOJ_SURVEY_SESSION_CONTENT_GET,
  TOJ_SET_INSERT_SURVEY_SESSION,
} from '../actions/tojsurvey';
import tojsurvey from '../../api/tojsurvey';
import ModalSurveyConfirmation from '../../../ui/components/Modals/ModalSurveyConfirmation';

function* getTojSurveyContent({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data: response } = {},
    } = yield call(tojsurvey.getSurveyContent, { filters });
    yield put({ type: TOJ_SURVEY_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_SURVEY_CONTENT_GET._ERROR, error });
    console.log('sagas > survey > getTojSurveyContent', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTojSurveyContentWatch() {
  yield takeEvery(TOJ_SURVEY_CONTENT_GET._REQUEST, getTojSurveyContent);
}

function* getTojSurveySessionContent({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      data: { data: response } = {},
    } = yield call(tojsurvey.getSurveySessionContent, { filters });
    yield put({ type: TOJ_SURVEY_SESSION_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_SURVEY_SESSION_CONTENT_GET._ERROR, error });
    console.log('sagas > survey > getTojSurveyContent', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTojSurveySessionContentWatch() {
  yield takeEvery(
    TOJ_SURVEY_SESSION_CONTENT_GET._REQUEST,
    getTojSurveySessionContent
  );
}

function* setInsertTojSurvey({
  tojId, answers, id, description,
}) {
  try {
    const body = {
      toj_id: tojId,
      id,
      description,
      answers: Object.keys(answers).map(key => {
        const { survey } = answers[key];

        let answer = '';
        switch (survey?.questionType) {
          case 'question_multiple':
            answer = answers[key]?.value?.toString().replace(/,/g, ';');
            break;
          default:
            answer = answers[key]?.value;
            break;
        }

        return {
          question_id: survey?.idQuestion,
          question: survey?.question,
          comment: '',
          has_comment: survey?.hasComment,
          order: survey?.order,
          answer,
        };
      }),
    };

    yield call(tojsurvey.setInsertSurvey, body);
    yield put({ type: TOJ_SET_INSERT_SURVEY._SUCCESS });
    yield put({ type: MODAL_OPEN, id: ModalSurveyConfirmation.id });
    //yield put(push(`${routes.toj.path}/${tojId}`));
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ErrorModal.id,
      data: {
        title: 'Errore',
        message: err?.response?.data.action_result.message,
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > survey > setInsertTojSurvey', err);
  }
}

function* setInsertTojSurveyWatch() {
  yield takeLatest(TOJ_SET_INSERT_SURVEY._REQUEST, setInsertTojSurvey);
}

function* setInsertTojSurveySession({ answers: selected_answers, idSlot }) {
  try {
    const body = {
      idSlot,
      answers: Object.keys(selected_answers).map(key => {
        const { survey } = selected_answers[key];

        switch (survey?.questionType) {
          case 'question_multiple':
          { const answer = selected_answers[key]?.value?.toString().replace(/,/g, ';');
            return {
              idQuestion: survey?.idQuestion,
              question: survey?.question,
              comment: '',
              hasComment: survey?.hasComment,
              order: survey?.order,
              answer,
            };
          }
          default:
          { const answer = selected_answers[key]?.value;
            return {
              idQuestion: survey?.idQuestion,
              question: survey?.question,
              comment: '',
              hasComment: survey?.hasComment,
              order: survey?.order,
              answer,
            };
          }
        }
      }),
    };
    yield call(tojsurvey.setInsertSurveySession, body);
    yield put({ type: TOJ_SET_INSERT_SURVEY_SESSION._SUCCESS });
    yield put({ type: MODAL_OPEN, id: ModalSurveyConfirmation.id });
    //yield put(push(`${routes.toj.path}/${tojId}`));
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ErrorModal.id,
      data: {
        title: 'Errore',
        message: err?.response?.data.action_result.message,
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > survey > setInsertTojSurveySession', err);
  }
}

function* setInsertTojSurveySessionWatch() {
  yield takeLatest(
    TOJ_SET_INSERT_SURVEY_SESSION._REQUEST,
    setInsertTojSurveySession
  );
}

export default [
  getTojSurveyContentWatch(),
  setInsertTojSurveyWatch(),
  getTojSurveySessionContentWatch(),
  setInsertTojSurveySessionWatch(),
];
