import {
  takeLatest, put, call, select
} from 'redux-saga/effects';

import { push } from 'redux-first-history';
import queryString from 'query-string';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';

import {
  TOJ_CONTENT_GET, TOJ_LIKE_DISLIKE, TOJ_LIST_GET, TOJ_SESSION_MODE, TOJ_SESSION_PLAN_SET
} from '../actions/toj';
import toj from '../../api/toj';
import routes from '../../../routes';
import Toast from '../../../ui/components/Toast';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

function* getTojContent({ filters, loader = false }) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    if (filters.role === 'tutee') delete filters.tutee_id;
    const { data: { data: response } = {} } = yield call(toj.getTojContent, filters);
    yield put({ type: TOJ_CONTENT_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_CONTENT_GET._ERROR, error });
    yield put(push(routes.home.path));
    console.log('sagas > toj > getTojContent', error);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* getTojContentWatch() {
  yield takeLatest(TOJ_CONTENT_GET._REQUEST, getTojContent);
}

function* getTojList({ filters }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(toj.getTojList, filters);
    yield put({ type: TOJ_LIST_GET._SUCCESS, response });
  } catch (error) {
    yield put({ type: TOJ_LIST_GET._ERROR, error });
    console.log('sagas > toj > getTojList', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTojListWatch() {
  yield takeLatest(TOJ_LIST_GET._REQUEST, getTojList);
}

function* setTojSessionMode({ idSlot, mode, tojId }) {
  try {
    const body = { idSlot, mode };
    yield call(toj.setTojSessionMode, body);
    yield put({ type: TOJ_SESSION_MODE._SUCCESS });
    const { location } = yield select(state => state.router);
    const { tutee_id, role } = queryString.parse(location.search);
    yield getTojContent({ filters: { id: tojId, tutee_id, role }, loader: false });
  } catch (err) {
    yield put({ type: TOJ_SESSION_MODE._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > survey > setTojSessionMode', err);
  }
}

function* setTojSessionModeWatch() {
  yield takeLatest(TOJ_SESSION_MODE._REQUEST, setTojSessionMode);
}

function* setLikeDislikeFeedback({ payload }) {
  try {
    const { feedback_value, id } = payload;
    yield call(toj.setLikeDislikeFeedback, { feedback_value, id });
    yield put({ type: TOJ_LIKE_DISLIKE._SUCCESS });
    const { location } = yield select(state => state.router);
    const { tutee_id, role } = queryString.parse(location.search);
    yield getTojContent({ filters: { id, tutee_id, role }, loader: false });
  } catch (err) {
    yield put({ type: TOJ_LIKE_DISLIKE._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > toj > setLikeDislikeFeedback', err);
  }
}

function* setLikeDislikeFeedbackWatch() {
  yield takeLatest(TOJ_LIKE_DISLIKE._REQUEST, setLikeDislikeFeedback);
}

function* setSessionPlan({ payload }) {
  try {
    const { data: { data } = {} } = yield call(toj.setSessionPlan, payload);
    yield put({ type: TOJ_SESSION_PLAN_SET._SUCCESS });
    const { location } = yield select(state => state.router);
    const { tutee_id, role } = queryString.parse(location.search);
    const { vocabulary } = yield select(state => state.app);
    if (Array.isArray(data)) {
      const message = getValueVocabulary(vocabulary.sessionUpdateMessageError, 'sessionUpdateMessageError');
      Toast.show(Toast.TYPE.ERROR, message);
    } else {
      yield getTojContent({ filters: { id: payload.idItem, tutee_id, role }, loader: false, showToast: true });
      const message = getValueVocabulary(vocabulary.sessionUpdateMessageSuccess, 'sessionUpdateMessageSuccess');
      Toast.show(Toast.TYPE.SUCCESS, message);
    }
  } catch (err) {
    yield put({ type: TOJ_SESSION_PLAN_SET._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > toj > setSessionPlan', err);
    const { vocabulary } = yield select(state => state.app);
    const message = getValueVocabulary(vocabulary.sessionUpdateMessageError, 'sessionUpdateMessageError');
    Toast.show(Toast.TYPE.ERROR, message);
  }
}

function* setSessionPlanWatch() {
  yield takeLatest(TOJ_SESSION_PLAN_SET._REQUEST, setSessionPlan);
}


export default [
  getTojContentWatch(),
  getTojListWatch(),
  setTojSessionModeWatch(),
  setLikeDislikeFeedbackWatch(),
  setSessionPlanWatch()
];
