import React from 'react';
import PropTypes from 'prop-types';

import { LogoContainer, LogoImg } from './style';
// import LogoBase from '../../assets/img/logo.svg';

const Logo = ({
  size, onClick, src
}) => (
  <LogoContainer size={size} onClick={onClick}>
    <LogoImg src={src} />
  </LogoContainer>
);

Logo.SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XL: 'xl'
};

Logo.propTypes = {
  size: PropTypes.oneOf(Object.values(Logo.SIZE)),
  onClick: PropTypes.func
};

Logo.defaultProps = {
  size: Logo.SIZE.MEDIUM
};

export default Logo;
