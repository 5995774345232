import React, {
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'redux-first-history';
import { FAVOURITE_LIST_GET, SET_FAVOURITE } from '../../redux/actions/favourite';
import {
  FavouriteCol, FavouriteRow, FavouritesContainer, ViewMore,
} from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import Arial from '../../../ui/typography/arial';
import routes from '../../../routes';
import CardCourses from '../../../ui/atoms/CardCourses';
import { NoResult } from '../../../ui/atoms';
import { isArabic } from '../../../utils/locale';


const Favourites = ({
  list, pager, vocabulary,
  getFavouriteList, setFavourite, pushUrl,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getFavouriteList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFavouriteCard = ({ id, type }) => {
    if (type === 'selection') pushUrl(`${routes.mandatorytraining.path}/${id}`);
    if (type === 'collection') pushUrl(`${routes.optionalcourses.path}/${id}`);
  };

  const onClickSetFavourite = ({ id, type }) => {
    if (type === 'selection') setFavourite({ nid: id, node_type: type });
    if (type === 'collection') setFavourite({ nid: id, node_type: type });
  };

  const onClickViewMore = () => {
    getFavouriteList({ subset: true, loader: false });
  };

  return (
    <FavouritesContainer arabic={isArabic()}>
      <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="favouritesTitle">{getValueVocabulary(vocabulary.favouritesTitle, 'favouritesTitle')}</Arial>
      <FavouriteRow>
        {
          [...list].map(item => (
            <FavouriteCol key={`favourite-${item.code}`}>
              <CardCourses item={item} onClickCard={() => onClickFavouriteCard(item)} onClickFavourite={() => onClickSetFavourite(item)} />
            </FavouriteCol>
          ))
        }
      </FavouriteRow>
      {list.length <= 0 && <NoResult />}
      {pager?.current_page < pager?.total_pages && (
        <ViewMore>
          <Arial type="viewMore" vocabulary="viewMore" onClick={onClickViewMore}>{getValueVocabulary(vocabulary.viewMore, 'viewMore')}</Arial>
        </ViewMore>
      )}
    </FavouritesContainer>
  );
};

Favourites.propTypes = {
  // HOC (connect, state)
  list: PropTypes.array.isRequired,
  pager: PropTypes.object.isRequired,
  vocabulary: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getFavouriteList: PropTypes.func.isRequired,
  setFavourite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { data: list = [], pager = {} } = state.favourite;
    const { vocabulary } = state.app;
    return {
      list, pager, vocabulary,
    };
  },
  dispatch => ({
    getFavouriteList: ({ subset, loader }) => dispatch({ type: FAVOURITE_LIST_GET._REQUEST, subset, loader }),
    setFavourite: ({ nid, node_type }) => dispatch({ type: SET_FAVOURITE._REQUEST, nid, node_type }),
    pushUrl: url => dispatch(push(url)),
  })
)(Favourites);
