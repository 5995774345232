import styled, { css } from 'styled-components';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobile} {
    font-size: 12px;
  }
  
  div[class*="-placeholder"] {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    color: ${props => props.theme.colors.brownGrey};
  }
  
  div[class*="-menu"] {
    font-weight: normal;
    z-index: 3;
    div[class*="-MenuList"] div[class*="-option"] {
      span {
        top: 50%;
        left: 50%
        transform: translate(-50%, -50%);
      }
    } 
    
  }

  div[class*="-indicatorContainer"] {
    position: relative;
    color: ${props => props.theme.colors.white};
    
    &:first-child {
      padding-right: 0;
    }

    &:hover {
      color: ${props => props.theme.colors.white};
    }
    
    &:after {
      content: '';
      display: block;
      height: 70%;
      position: absolute;
      width: 1px;
      background: ${props => props.theme.colors.brownGrey};
      top: 15%;
    }
  }
  
  div[class*="ValueContainer"] {
    overflow: auto;
    height: inherit;
    
    div[class*="Input"] {
      color: ${props => props.theme.colors.white};
    }
  }
  
  div[class*="singleValue"] {
    color: ${props => props.theme.colors.brownGrey};
  }

  div[class*="control"] {
    > div:first-child {
      overflow: auto;
      height: inherit;
    }
      
  }
  
  ${props => props.readonly && css`
    pointer-events: none;
    div[class*="control"] {
      border: none;
      background-color: transparent;
    }
    
    div[class*="ValueContainer"] {
      padding: 0;
    }
    
    div[class*="IndicatorsContainer"] {
      display: none;
    }
    
  `}
  
  ${({ isSearchType }) => isSearchType && css`
    div[class*="control"] {
      cursor: auto;
    }
    div[class*="-indicatorContainer"] {
      cursor: pointer;
    }
    span ~ div[class*="-indicatorContainer"] {
      display: none;
    }
  `}

  ${({ arabic }) => arabic && css`

    div[class*="-option"] {
      justify-content: flex-end !important;
    }

    div[class*="-ValueContainer"] {
      div[class*="-placeholder"] {
        right: 10px !important;
      }
    }

    div[class*="singleValue"] {
      right: 10px !important;
    }
  `}
`;

export { SelectContainer };
