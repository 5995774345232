import produce from 'immer';
import {
  MANDATORY_TRAINING_CATEGORIES_GET,
  MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS,
  MANDATORY_TRAINING_DETAILS_GET, MANDATORY_TRAINING_LO_UNSET,
  MANDATORY_TRAINING_LIST_GET, MANDATORY_TRAINING_LO_SET_COMMENT,
  MANDATORY_TRAINING_LO_SET_LIKEDISLIKE, MANDATORY_TRAINING_LO_SET_VIEWS
} from '../actions/mandatorytraining';

const initialState = {
  data: [],
  pager: {},
  categories: [],
  selectedMandatoryTraining: {},
  contentNotAvailable: false
};

const mandatoryTrainingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case MANDATORY_TRAINING_LIST_GET._SUCCESS: {
      const { data, pager, subset } = action;
      const { courses } = data;
      draft.data = subset ? [...state.data, ...courses] : courses;
      draft.pager = pager;
      break;
    }

    case MANDATORY_TRAINING_CATEGORIES_GET._SUCCESS: {
      const { categories } = action;
      draft.categories = categories;
      break;
    }

    case MANDATORY_TRAINING_DETAILS_GET._SUCCESS: {
      const { data } = action;
      const { lo_list = [] } = data;
      draft.selectedMandatoryTraining = {
        ...data,
        lo_list: [...lo_list].reduce((acc, val, index, array) => {
          if (!array[index - 1]) {
            return [...acc, {
              ...val, blocked: false, index, last_item: index === array.length - 1
            }];
          }
          if (array[index - 1] && (acc[index - 1].status === 'completed' || acc[index - 1].status === 'passed')) {
            return [...acc, {
              ...val, blocked: false, index, last_item: index === array.length - 1
            }];
          }
          return [...acc, {
            ...val, blocked: true, index, last_item: index === array.length - 1
          }];
        }, [])
      };
      break;
    }

    case MANDATORY_TRAINING_DETAILS_GET._REQUEST: {
      draft.contentNotAvailable = false;
      break;
    }

    case MANDATORY_TRAINING_DETAILS_GET._ERROR: {
      draft.contentNotAvailable = true;
      break;
    }

    case MANDATORY_TRAINING_LO_SET_LIKEDISLIKE._SUCCESS: {
      const { nid, action_type } = action;
      const { selectedMandatoryTraining } = state;
      const { lo_list } = selectedMandatoryTraining;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(nid)) {
          const { like, dislike, user_actions: { like: isLike, dislike: isDislike } } = lo_item;
          return [
            ...acc,
            {
              ...lo_item,
              like: action_type === 'like' ? like + 1 : (like !== 0 && (isLike || isDislike)) ? like - 1 : 0,
              dislike: action_type === 'dislike' ? dislike + 1 : (dislike !== 0 && (isLike || isDislike)) ? dislike - 1 : 0,
              user_actions: {
                like: action_type === 'like',
                dislike: action_type === 'dislike'
              }
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedMandatoryTraining = {
        ...selectedMandatoryTraining,
        lo_list: remappedLOs
      };
      break;
    }
    case MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS._SUCCESS: {
      draft.selectedMandatoryTraining = {
        ...state.selectedMandatoryTraining,
        user_actions: {
          views: true
        }
      };
      break;
    }
    case MANDATORY_TRAINING_LO_SET_VIEWS._SUCCESS: {
      const { lo_id } = action;
      const { selectedMandatoryTraining } = state;
      const { lo_list } = selectedMandatoryTraining;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(lo_id)) {
          return [
            ...acc,
            {
              ...lo_item,
              user_actions: {
                ...lo_item.user_actions,
                views: true
              }
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedMandatoryTraining = {
        ...selectedMandatoryTraining,
        lo_list: remappedLOs
      };
      break;
    }
    case MANDATORY_TRAINING_LO_SET_COMMENT._SUCCESS: {
      const { payload, lo_id } = action;
      const { selectedMandatoryTraining } = state;
      const { lo_list } = selectedMandatoryTraining;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(lo_id)) {
          const { comments } = lo_item;
          return [
            ...acc,
            {
              ...lo_item,
              comments: [payload, ...comments]
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedMandatoryTraining = {
        ...selectedMandatoryTraining,
        lo_list: remappedLOs
      };
      break;
    }case MANDATORY_TRAINING_LO_UNSET._SUCCESS: {
      draft.selectedMandatoryTraining = initialState.selectedMandatoryTraining;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default mandatoryTrainingReducer;
