const tableHeader = [
  {
    loc: 'notifications',
    headers: [
      { label: 'Tipologia', field: 'type' },
      { label: 'Data', field: 'date' },
      { label: 'Descrizione', field: 'title' }
    ]
  }
];

export default tableHeader;
