import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  position: relative;
`;

const SetModalSize = (size, unit, value) => {
  const unitValue = unit || 'px';
  const defaultValue = value === 'width' ? '644' : '459';
  const sizeValue = size || defaultValue;
  return sizeValue + unitValue;
};

export const ModalGlobalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.black};
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  user-select: none;
  padding: ${props => (props.padding ? `${props.padding}` : '26px')};
  width: ${props => SetModalSize(props.width, props.widthUnit, 'width')};
  min-height: ${props => SetModalSize(props.height, props.heightUnit, 'height')};
  margin: 0 auto;

  &.modalSchedule {
    padding: 25px 40px 60px;
  }
  
  > span {
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: ${props => props.theme.constants.transitionBase};
    
    &:hover {
      opacity: 0.6;
    }
  }

    @media ${props => props.theme.device.mobileL} {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0;
    overflow: auto;
    
    display: flex;
    flex-direction: column;

    &.modalSchedule {
      justify-content: flex-start;
    }
  }
`;
