import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';

import moment from 'moment';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  OPTIONAL_COURSES_CATEGORIES_GET,
  OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS,
  OPTIONAL_COURSES_DETAILS_GET,
  OPTIONAL_COURSES_LIST_GET,
  OPTIONAL_COURSES_LO_SET_COMMENT,
  OPTIONAL_COURSES_LO_SET_LIKEDISLIKE,
  OPTIONAL_COURSES_LO_SET_VIEWS,
  OPTIONAL_COURSES_SET_FAVOURITE,
} from '../actions/optionalcourses';
import optionalcourses from '../../api/optionalcourses';
import { calcPagination } from '../../../utils/pagination';
import { getDefaultLanguage } from '../../../utils/locale';
import { truncateWithDots } from '../../../utils/common';
import { DRUPAL_MAX_TITLE_LENGTH } from '../../../const';

function* optionalCoursesGet({
  loader = true, subset = false, area, progress,
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { pager: { current_page, total_pages } = {} } = yield select(state => state.optionalcourses);
    const { next_page, isLastPage } = calcPagination(subset ? { current_page, total_pages } : {});
    if (!isLastPage) {
      const { defaultLanguage } = getDefaultLanguage();
      const completeFilters = {
        items_per_page: 24, page: next_page, area, progress, lang: defaultLanguage, type: 'collection',
      };
      const { data: { data, pager } = {} } = yield call(optionalcourses.getOptionalCoursesList, completeFilters);
      yield put({
        type: OPTIONAL_COURSES_LIST_GET._SUCCESS, data, pager, subset,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > optionalcourses > optionalCoursesGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* optionalCoursesGetWatch() {
  yield takeLatest(OPTIONAL_COURSES_LIST_GET._REQUEST, optionalCoursesGet);
}

function* optionalCoursesDetailsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { defaultLanguage } = getDefaultLanguage();
      const { data: { data = {} } = {} } = yield call(optionalcourses.getOptionalCoursesDetails, { id, lang: defaultLanguage });
      yield put({ type: OPTIONAL_COURSES_DETAILS_GET._SUCCESS, data });
    } catch (err) {
      yield put({ type: OPTIONAL_COURSES_DETAILS_GET._ERROR });
      // eslint-disable-next-line no-console
      console.log('sagas > optionalcourses > optionalCoursesDetailsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* optionalCoursesDetailsGetWatch() {
  yield takeLatest(OPTIONAL_COURSES_DETAILS_GET._REQUEST, optionalCoursesDetailsGet);
}


function* favouriteSet({ nid }) {
  if (nid) {
    try {
      const payload = { nid, node_type: 'collection', action_type: 'favourite' };
      yield call(optionalcourses.setNodeAction, payload);
      yield put({ type: OPTIONAL_COURSES_SET_FAVOURITE._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > optionalcourses > favouriteSet', err);
    }
  }
}

function* favouriteSetWatch() {
  yield takeLatest(OPTIONAL_COURSES_SET_FAVOURITE._REQUEST, favouriteSet);
}

function* loSetLikeDislike({ nid, action_type }) {
  if (nid && action_type) {
    try {
      const payload = { nid, action_type, node_type: 'learning_object' };
      yield call(optionalcourses.setNodeAction, payload);
      yield put({ type: OPTIONAL_COURSES_LO_SET_LIKEDISLIKE._SUCCESS, nid, action_type });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > optionalcourses > loSetLikeDislike', err);
    }
  }
}

function* loSetLikeDislikeWatch() {
  yield takeLatest(OPTIONAL_COURSES_LO_SET_LIKEDISLIKE._REQUEST, loSetLikeDislike);
}

function* optionalCoursesDetailsSetViews({ nid }) {
  if (nid) {
    try {
      const payload = { nid, action_type: 'views', node_type: 'collection' };
      yield call(optionalcourses.setNodeAction, payload);
      yield put({ type: OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS._SUCCESS, nid, action_type: 'views' });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > optionalcourses > mantdatoryTraniningDetailsSetViews', err);
    }
  }
}

function* optionalCoursesDetailsSetViewsWatch() {
  yield takeLatest(OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS._REQUEST, optionalCoursesDetailsSetViews);
}

function* loSetViews({ nid }) {
  if (nid) {
    try {
      const payload = { nid, action_type: 'views', node_type: 'learning_object' };
      yield call(optionalcourses.setNodeAction, payload);
      yield put({ type: OPTIONAL_COURSES_LO_SET_VIEWS._SUCCESS, lo_id: nid });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > optionalcourses > loSetViews', err);
    }
  }
}

function* loSetViewsWatch() {
  yield takeLatest(OPTIONAL_COURSES_LO_SET_VIEWS._REQUEST, loSetViews);
}

function* loSetComment({ body }) {
  try {
    const payload = {
      entity_id: [{ target_id: body.id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'comment' }],
      subject: [{ value: truncateWithDots(body.subject, DRUPAL_MAX_TITLE_LENGTH) }],
      comment_body: [{ value: body.comment_body }],
    };
    yield call(optionalcourses.setComment, payload);
    const { data: { first_name, last_name } = {} } = yield select(state => state.user);
    yield put({
      type: OPTIONAL_COURSES_LO_SET_COMMENT._SUCCESS,
      lo_id: body.id,
      payload: {
        first_name, last_name, comment_body: body.comment_body, created: moment().unix(), changed: moment().unix(),
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > optionalcourses > loSetLikeDislike', err);
  }
}

function* loSetCommentWatch() {
  yield takeLatest(OPTIONAL_COURSES_LO_SET_COMMENT._REQUEST, loSetComment);
}

function* categoriesGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data: categories = {} } = {} } = yield call(optionalcourses.getCategories, { lang: defaultLanguage, name: 'collection' });

    yield put({ type: OPTIONAL_COURSES_CATEGORIES_GET._SUCCESS, categories });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > optionalcourses > categoriesGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
    yield put({ type: LOADER_CLOSE });
  }
}

function* categoriesGetWatch() {
  yield takeLatest(OPTIONAL_COURSES_CATEGORIES_GET._REQUEST, categoriesGet);
}

export default [
  optionalCoursesGetWatch(),
  optionalCoursesDetailsGetWatch(),
  favouriteSetWatch(),
  loSetLikeDislikeWatch(),
  optionalCoursesDetailsSetViewsWatch(),
  loSetViewsWatch(),
  loSetCommentWatch(),
  categoriesGetWatch(),
];
