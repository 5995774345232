import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  SurveyLikeDislikeContainer, Action, Label, Icon
} from './style';

const RadioButton = ({
  onChange
}) => {
  const [value, setValue] = useState('');

  const handleLikeDislike = (newValue) => {
    newValue !== value ? setValue(newValue) : setValue('');
  };

  useEffect(() => {
    if (onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <SurveyLikeDislikeContainer>
      <Action onClick={() => handleLikeDislike('true')}>
        <Icon className="icon-like" selected={value === 'true'} />
        <Label>Pollice sù</Label>
      </Action>
      <Action onClick={() => handleLikeDislike('false')}>
        <Icon className="icon-nonutile" selected={value === 'false'} />
        <Label>Pollice giù</Label>
      </Action>
    </SurveyLikeDislikeContainer>
  );
};


RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default RadioButton;
