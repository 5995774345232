import React from 'react';
import PropTypes from 'prop-types';
import { Zoom, toast } from 'react-toastify';
import { ToastContainer, ToastCustomContainer, ToastWrapper } from './style';
import Icon from '../../atoms/Icon';
import Arial from '../../typography/arial';
import theme from '../../theme';
import { isArabic } from '../../../utils/locale';

const Toast = () => (
  <ToastWrapper>
    <ToastContainer
      autoClose={10000000}
      closeButton={false}
      transition={Zoom}
      isArabic={isArabic()}
    />
  </ToastWrapper>
);

const ToastSuccess = ({ children }) => (
  <ToastCustomContainer>
    <Icon type="icon-verde" size={30} />
    <Arial type="toast" style={{ marginLeft: '10px' }}>
      {children}
    </Arial>
  </ToastCustomContainer>
);
ToastSuccess.propTypes = {
  children: PropTypes.node,
};

const ToastError = ({ children }) => (
  <ToastCustomContainer>
    <Icon type="icon-allert" size={30} color={theme().colors.primary.red} />
    <Arial type="toast" style={{ marginLeft: '10px' }}>
      {children}
    </Arial>
  </ToastCustomContainer>
);
ToastError.propTypes = {
  children: PropTypes.node,
};

Toast.TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
};

Toast.show = (type = Toast.TYPE.DEFAULT, content = 'you miss to pass content') => {
  switch (type) {
    case Toast.TYPE.SUCCESS:
      toast.success(<ToastSuccess>{content}</ToastSuccess>);
      break;
    case Toast.TYPE.ERROR:
      toast.error(<ToastError>{content}</ToastError>);
      break;
    default:
      toast[type](content);
      break;
  }
};

Toast.propTypes = {};

export default Toast;
