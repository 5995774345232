import styled from 'styled-components';

export const GuidebookCardContainer = styled.div`
  position: relative;
  height: 140px;
  width: 550px;
  padding: 20px;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gray};
  transition: ${props => props.theme.constants.transitionBase};
  margin: 10px 20px;

  &:hover {
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
  }

  @media ${props => props.theme.device.mobileL} {
    width: 340px;
    height: auto;
    margin: auto;
  }
`;

export const Guidebook = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Date = styled.div`
  color: ${props => props.theme.colors.brownGrey};
`;

export const Title = styled.div`
  margin: 10px 0px;

  > div {
    white-space: break-spaces;
  }
`;

export const Description = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconAttachment = styled.div`
  margin-left: ${props => (props.position === 'right' ? 'auto' : '')};
  height: 40px;
  width: 40px;
`;

export const Icon = styled.span`
    font-size: 40px;

`;

export const GuidebookCardContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  width: 480px;
`;
