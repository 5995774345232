import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer,
} from '../Modal.style';
import { CloseModal, ModalPartecipateConferenceContainer, ButtonsContainer } from './style';
import MenuButton from '../../../atoms/MenuButton';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';
import { Button } from '../../../atoms';
import { isArabic } from '../../../../utils/locale';

const modalId = 'ModalPartecipateConference';

const ModalPartecipateConference = ({
  onClose, closeIcon, vocabulary, data, btnKey,
}) => {
  const handleOnConfirm = () => {
    data.handleOnSubmit();
    onClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        {closeIcon && (
          <CloseModal>
            <MenuButton isOpen onClick={() => onClose()} />
          </CloseModal>
        )}
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          widthUnit="%"
          width="50"
          heightUnit="%"
          height="90"
        >
          <ModalPartecipateConferenceContainer arabic={isArabic()}>
            <Arial configuration={{ textTransform: 'none', size: 16, lineHeight: 1.5 }}>Do you want to join {data.mode} ?</Arial>
            <ButtonsContainer>
              <Button btnText="Confirm" onClick={handleOnConfirm} height={40} />
              <Button btnText="Cancel" onClick={onClose} height={40} />
            </ButtonsContainer>
          </ModalPartecipateConferenceContainer>
        </ModalGlobalContainer>
      </Container>
    </Modal>
  );
};

ModalPartecipateConference.propTypes = {
  data: PropTypes.object,
  closeIcon: PropTypes.bool,
  btnKey: PropTypes.string,
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
};

ModalPartecipateConference.id = modalId;

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { data } = state.modal;
    return {
      vocabulary,
      data,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE }),
  })
)(ModalPartecipateConference);
