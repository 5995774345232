import styled, { css } from 'styled-components';


const commonCss = css`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontset.gotham};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.17;
`;

export const CellPill = styled.div`
  background-color: ${({ theme }) => theme.colors.brightgreen};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 0;
  height: 20px;
  width: 27px;
  text-align: center;
  font-size: 12px;
`;

export const TableContainer = styled.div`
  position: relative;
  .rdt_Table {
    background-color: ${({ theme }) => theme.colors.black};
    ${commonCss};
  }

  .rdt_TableHeadRow {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }
  
  .rdt_TableCol, .rdt_TableCol_Sortable {
    background-color: ${({ theme }) => theme.colors.black};
    ${commonCss};
    color: ${({ theme }) => theme.colors.white};

    & span {
      opacity: 1;
    }
    
  }

  .rdt_TableCol {
    text-transform: uppercase;
  }
  
  .rdt_TableRow {
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.black};
    min-height: fit-content;

    ${commonCss};
    
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.doveGray};
    }
    
    transition: all 125ms ease-in-out;
  }
  .rdt_TableBody {
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;     max-width: 60%;
      border-radius: 10px;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  }

  ${({ arabic }) => arabic && css`
    .rdt_TableHeadRow,
    .rdt_TableCol {
      flex-direction: row-reverse;
    }

    .rdt_TableRow {
      display: flex;
      flex-direction: row-reverse;
    }

    .rdt_TableCell {
      direction: rtl;
    }
  `}
`;
