import styled from 'styled-components';

export const BannerWrap = styled.div`
padding: 50px 0;

    &:nth-child(odd) {
      background-color: ${props => props.theme.colors.transparent};
    }
    &:nth-child(2),
    &:nth-child(6) {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.5)};
    }
    &:nth-child(4),
    &:nth-child(8) {
      background-color: ${props => props.theme.colors.gray};
    }

@media ${props => props.theme.device.mobileL} {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media ${props => props.theme.device.tablet} {
  padding: 50px 10px;
}
`;
