import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import moment from 'moment';

import {
  SEARCH_WORD_SET,
} from '../../../app/redux/actions/search';
import {
  APP_MENU_NOTIFICATION_TOGGLE,
  APP_MENU_TOGGLE,
  AUTH_LOGOUT,
  APP_NAVIGATION_TOGGLE, USER_INFO_GET,
  // CHANGE_LANGUAGE
} from '../../../app/redux/actions';

import {
  NavBarContainer, LoginWrapper, IconWrapper, ContentWrapper,
  SearchContainer, Notifications, NotificationHeader,
  NotificationLabel, NotificationContent, NotificationDate, NotificationDescription,
  SeeAll, Status, IconMenu, NotificationMenu, Icon,
  InfoDescription, CircleName, NameContainer, MobileIconContainer,
  NoResultContainer, NotificationBadge, IconContainer, IconSpan,
  FaqContainer, IconsContainer, LanguageContainer, Filter, SearchWrapper, LogoWrapper, ActionWrapper, FirstNav, SecondNav,
} from './style';

import {
  CustomSelect,
  Logo, MenuButton, NoResult, SearchBar,
} from '../../atoms';
import Menu from '../Menu';
import routes from '../../../routes';
import storage from '../../../utils/storage';
import compose from '../../../utils/compose';
import { optionCountry, getCountry, isArabic } from '../../../utils/locale';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';
import Arial from '../../typography/arial';
import {
  NOTIFICATION_GET,
  NOTIFICATION_READ,
} from '../../../app/redux/actions/notifications';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import LogoBase from '../../assets/img/logo-testo-bianco.png';

import { parseQueryParams } from '../../../utils/queryParams';

const menuElements = [
  {
    title: 'Logout',
    icon: 'icon-logout',
    url: routes.logout.path,
  },
];

const getInitials = (name) => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
};


const NavBar = ({
  menuMobileOpen,
  notificationMenuMobileOpen,
  setSearchWord,
  startLogout,
  pushUrl,
  menuToggle,
  notificationMenuToggle,
  navigationMobileToogle,
  navigationMobile,
  getNotification,
  mediaIsPhone,
  mediaIsTablet,
  mediaIsLaptop,
  notifications,
  readNotification,
  vocabulary,
  userName,
  location,
  getUserInfo,
  // user_lang,
  // changeLanguage
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);

  useEffect(() => {
    getNotification();
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const countrySel = optionCountry?.find(country => country.value.toLowerCase() === user_lang?.toLowerCase());
    const countrySel = getCountry();
    setSelectedCountry(countrySel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // user_lang, vocabulary
  ]);

  const [search, setSearch] = useState('');
  const handleOnChangeSearch = value => setSearch(value);

  const handleOnSubmitSearch = e => {
    e.preventDefault();
    if (search.length > 0) {
      setSearch('');
      setSearchWord(search);
      pushUrl(routes.search.path);
    }
  };

  const changeLocation = url => {
    menuToggle();
    notificationMenuToggle();
    pushUrl(url);
  };

  const logout = () => {
    startLogout();
  };

  const toggleMenu = (e, isOpen) => {
    setMenuOpen(isOpen !== undefined ? isOpen : !menuOpen);
    setNotificationOpen(false);
  };

  const handleMenuClick = url => {
    toggleMenu(false);
    setNotificationOpen(false);
    if (url === routes.logout.path) {
      logout();
    } else {
      pushUrl(url);
    }
  };

  const toggleNotification = () => {
    setMenuOpen(false);
    setNotificationOpen(!notificationOpen);
    notificationMenuToggle();
    if (menuMobileOpen) menuToggle();
    if (navigationMobile) navigationMobileToogle();
  };

  const handleOnClickSeeAll = () => {
    toggleNotification();
    pushUrl(routes.notifications.path);
  };

  const handleOnClickFavourites = () => {
    setNotificationOpen(false);
    pushUrl(routes.favourites.path);
  };

  const handleOnClickFaq = () => {
    setNotificationOpen(false);
    pushUrl(routes.faq.path);
  };

  const toggleMenuNotification = () => {
    setNotificationMenuOpen(!notificationMenuOpen);
  };

  const handleOnClickNotifcation = ({
    id, node_id, type, status, user_role: role, tutee_id, deadline,
  }) => {
    toggleNotification();
    if (status === 'not_read') readNotification(id);
    switch (type) {
      case 'selection': {
        pushUrl(`${routes.mandatorytraining.path}/${node_id}`);
        break;
      }
      case 'collection': {
        pushUrl(`${routes.optionalcourses.path}/${node_id}`);
        break;
      }
      case 'virtual_room': {
        pushUrl(`${routes.remoteclassroom.path}/${node_id}`);
        break;
      }
      case 'toj': {
        pushUrl(`${routes.toj.path}/${node_id}/${parseQueryParams({ role, tutee_id, deadline })}`);
        break;
      }
      default:
        break;
    }
  };

  const handleReadAll = () => {
    toggleNotification();
    readNotification('all');
  };

  const noNotification = notifications?.notifications.every(item => item.status === 'read');

  const handleNavigationMobileToggle = () => {
    if (notificationMenuMobileOpen) notificationMenuToggle();
    navigationMobileToogle();
  };

  const handleOnChangeLanguage = (newValue) => {
    setSelectedCountry(newValue);
    // changeLanguage(newValue.value);
    storage.write('default_language', newValue.value);
    window.location.reload();
  };

  return (
    <NavBarContainer arabic={isArabic()}>
      {mediaIsPhone || mediaIsTablet ? (
        <>
          <ContentWrapper>
            <FirstNav>
              <LogoWrapper>
                <Logo
                  size={Logo.SIZE.SMALL}
                  onClick={() => {
                    changeLocation(routes.home.path);
                    setNotificationOpen(false);
                  }}
                  src={LogoBase}
                />
              </LogoWrapper>
              <MobileIconContainer>
                <Menu
                  callback={handleMenuClick}
                  menuList={menuElements}
                  customClass={`menu-mobile ${menuMobileOpen ? 'open' : ''}`}
                />
                <NameContainer>
                  <CircleName>
                    <span className="initials">
                      <Arial>{getInitials(`${userName.first_name} ${userName.last_name}`)}</Arial>
                    </span>
                  </CircleName>
                  {/* <FullName>
                    <Arial type="newsTitle">{userName.first_name}</Arial>
                  </FullName> */}
                </NameContainer>
                <IconWrapper
                  onClick={handleOnClickFaq}
                >
                  <IconContainer className="bell-notification">
                    <IconSpan className={location === '/faq' ? 'icon icon-assistance-hr active' : 'icon icon-assistance-hr'} />
                  </IconContainer>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    toggleNotification();
                  }}
                >
                  <div className="bell-notification">
                    {noNotification === false ? (
                      <span className="notification-badge" />
                    ) : null}
                    <span className={notificationOpen ? 'icon icon-notifiche active' : 'icon icon-notifiche'} />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={handleOnClickFavourites}
                >
                  <IconContainer className="bell-notification">
                    <IconSpan className={location === '/favourites' ? 'icon icon-heart-empty active' : 'icon icon-heart-empty'} />
                  </IconContainer>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    logout();
                  }}
                >
                  <span className="icon icon-logout" />
                </IconWrapper>
                <LanguageContainer>
                  <Filter>
                    <CustomSelect
                      options={optionCountry}
                      selected={selectedCountry}
                      onChange={newValue => handleOnChangeLanguage(newValue || [])}
                    />
                  </Filter>
                </LanguageContainer>
                {notificationMenuMobileOpen && (
                  <Notifications
                    className={`notification-menu-mobile ${
                      notificationMenuMobileOpen ? 'open' : ''
                    }`}
                  >
                    <NotificationHeader>
                      <Arial type="sectionTitle" vocabulary="notificationsTitle">{getValueVocabulary(vocabulary.notificationsTitle, 'notificationsTitle')}</Arial>
                      <IconMenu onClick={toggleMenuNotification}>
                        <span className="icon-menu" />
                      </IconMenu>
                      {notificationMenuOpen && (
                        <NotificationMenu onClick={() => handleReadAll()}>
                          <Icon className="icon-check" />
                          <Arial type="button">Segna tutte come già lette</Arial>
                        </NotificationMenu>
                      )}
                    </NotificationHeader>
                    {notifications?.notifications.length > 0 ? (
                      <NotificationContent>
                        {(notifications?.notifications
                          ? [...notifications?.notifications]
                          : []
                        )
                          ?.splice(0, 5)
                          .map((itemNotification, index) => (
                            <NotificationDescription
                              key={`navbar-notification-${index}`}
                              onClick={() => handleOnClickNotifcation(itemNotification)}
                            >
                              {itemNotification.status === 'not_read' && <Status />}
                              <InfoDescription
                                notRead={itemNotification.status === 'not_read'}
                              >
                                <NotificationLabel>
                                  <Arial type="newsDescription">
                                    {itemNotification?.title}
                                  </Arial>
                                  <Arial type="newsDescription">
                                    {itemNotification?.message}
                                  </Arial>
                                </NotificationLabel>
                                <NotificationDate>
                                  <Arial type="newsDescriptionDate">
                                    {moment(
                                      new Date(itemNotification?.date * 1000)
                                    ).format('DD/MM/YYYY h:mm A')}
                                  </Arial>
                                </NotificationDate>
                              </InfoDescription>
                            </NotificationDescription>
                          ))}
                        <SeeAll onClick={() => handleOnClickSeeAll()}>
                          <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                          <span className="icon-arrow-right" />
                        </SeeAll>
                      </NotificationContent>
                    ) : (<NoResult text="No notification found" />)}
                  </Notifications>
                )}
              </MobileIconContainer>
            </FirstNav>
            <SecondNav>
              <SearchContainer>
                <SearchBar
                  placeholder={getValueVocabulary(vocabulary.searchBarPlaceholder, 'searchBarPlaceholder')}
                  mobile
                  value={search}
                  onChange={handleOnChangeSearch}
                  onSubmit={handleOnSubmitSearch}
                  onClick={() => setOpenMobileSearch(!openMobileSearch)}
                />
              </SearchContainer>
              <MenuButton handleOnClick={handleNavigationMobileToggle} />
            </SecondNav>
          </ContentWrapper>
        </>
      ) : (
        <ContentWrapper>
          <>
            <LogoWrapper>
              <Logo
                size={Logo.SIZE.SMALL}
                onClick={() => changeLocation(routes.home.path)}
                src={LogoBase}
              />
            </LogoWrapper>
            <ActionWrapper>
              <SearchWrapper>
                <SearchContainer style={{ left: mediaIsLaptop ? '11.5rem' : '' }}>
                  <SearchBar
                    placeholder={getValueVocabulary(vocabulary.searchBarPlaceholder, 'searchBarPlaceholder')}
                    mobile
                    value={search}
                    onChange={handleOnChangeSearch}
                    onSubmit={handleOnSubmitSearch}
                    onClick={() => setOpenMobileSearch(!openMobileSearch)}
                  />
                </SearchContainer>
              </SearchWrapper>
              <LoginWrapper>
                {notificationOpen && (
                  <Notifications
                    className={`notification-menu-mobile ${
                      notificationMenuMobileOpen ? 'open' : ''
                    }`}
                  >
                    <NotificationHeader>
                      <Arial type="sectionTitle" vocabulary="notificationsTitle">{getValueVocabulary(vocabulary.notificationsTitle, 'notificationsTitle')}</Arial>
                      <IconMenu onClick={toggleMenuNotification}>
                        <span className="icon-menu" />
                      </IconMenu>
                      {notificationMenuOpen && (
                        <NotificationMenu onClick={() => handleReadAll()}>
                          <Icon className="icon-check" />
                          <Arial type="button">Segna tutte come già lette</Arial>
                        </NotificationMenu>
                      )}
                    </NotificationHeader>
                    {notifications?.notifications.length > 0 ? (
                      <NotificationContent>
                        {(notifications?.notifications
                          ? [...notifications?.notifications]
                          : []
                      )
                          ?.splice(0, 5)
                          .map((itemNotification, index) => (
                            <NotificationDescription
                              key={`notification--${index}`}
                              onClick={() => handleOnClickNotifcation(itemNotification)
                              }
                            >
                              {itemNotification.status === 'not_read' && <Status />}
                              <InfoDescription
                                notRead={itemNotification.status === 'not_read'}
                              >
                                <NotificationLabel>
                                  <Arial type="newsDescription">
                                    {itemNotification?.title}
                                  </Arial>
                                  <Arial type="newsDescription">
                                    {itemNotification?.message}
                                  </Arial>
                                </NotificationLabel>
                                <NotificationDate>
                                  <Arial type="newsDescription">
                                    {moment(
                                      new Date(itemNotification?.date * 1000)
                                    ).format('DD/MM/YYYY h:mm A')}
                                  </Arial>
                                </NotificationDate>
                              </InfoDescription>
                            </NotificationDescription>
                          ))}
                      </NotificationContent>
                    ) : (
                      <NoResultContainer>
                        <NoResult text={`${getValueVocabulary(vocabulary.noNotificationsFound, 'noNotificationsFound')}`} />
                      </NoResultContainer>
                    )}
                    <SeeAll onClick={() => handleOnClickSeeAll()}>
                      <Arial type="newsAttachment" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial>
                      <span className="icon-arrow-right" />
                    </SeeAll>
                  </Notifications>
                )}
                <NameContainer>
                  <CircleName>
                    <span className="initials">
                      <Arial>{getInitials(`${userName.first_name} ${userName.last_name}`)}</Arial>
                    </span>
                  </CircleName>
                  {/* <FullName>
                        <Arial type="newsTitle">{userName.first_name}</Arial>
                      </FullName> */}
                </NameContainer>
                <FaqContainer>
                  <IconWrapper
                    onClick={handleOnClickFaq}
                  >
                    <IconContainer className="bell-notification">
                      <IconSpan className={location === '/faq' ? 'icon icon-assistance-hr active' : 'icon icon-assistance-hr'} />
                    </IconContainer>
                    <Arial type="titlePeople" vocabulary="faqTitle" configuration={{ color: 'white' }}>{getValueVocabulary(vocabulary.faqTitle, 'faqTitle')}</Arial>
                  </IconWrapper>
                </FaqContainer>
                <IconsContainer>
                  <IconWrapper
                    onClick={toggleNotification}
                    notificationOpen={notificationOpen}
                  >
                    <IconContainer className="bell-notification">
                      {noNotification === false ? (
                        <NotificationBadge className="notification-badge" />
                      ) : null}
                      <IconSpan className={notificationOpen ? 'icon icon-notifiche active' : 'icon icon-notifiche'} />
                    </IconContainer>
                    <Arial type="titlePeople" vocabulary="notificationsTitle" configuration={{ color: 'white' }}>{getValueVocabulary(vocabulary.notificationsTitle, 'notificationsTitle')}</Arial>
                  </IconWrapper>
                  <IconWrapper
                    onClick={handleOnClickFavourites}
                  >
                    <IconContainer className="bell-notification">
                      <IconSpan className={location === '/favourites' ? 'icon icon-heart-empty active' : 'icon icon-heart-empty'} />
                    </IconContainer>
                    <Arial type="titlePeople" vocabulary="favouritesTitle" configuration={{ color: 'white' }}>{getValueVocabulary(vocabulary.favouritesTitle, 'favouritesTitle')}</Arial>
                  </IconWrapper>
                  <IconWrapper
                    onClick={() => logout()}
                  >
                    <IconSpan className="icon icon-logout" />
                    <Arial type="titlePeople" vocabulary="notificationsTitle" configuration={{ color: 'white' }}>{getValueVocabulary(vocabulary.logoutLabel, 'logoutLabel')}</Arial>
                  </IconWrapper>
                </IconsContainer>
                <LanguageContainer>
                  <Filter>
                    <CustomSelect
                      options={optionCountry}
                      selected={selectedCountry}
                      onChange={newValue => handleOnChangeLanguage(newValue || [])}
                    />
                  </Filter>
                </LanguageContainer>
              </LoginWrapper>
            </ActionWrapper>
          </>
        </ContentWrapper>
      )}
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,

  // HOC (connect, state)
  menuMobileOpen: PropTypes.bool.isRequired,
  notificationMenuMobileOpen: PropTypes.bool.isRequired,
  notifications: PropTypes.object,
  vocabulary: PropTypes.object,
  userName: PropTypes.object,

  // HOC (connect, dispatch)
  setSearchWord: PropTypes.func,
  startLogout: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  menuToggle: PropTypes.func.isRequired,
  notificationMenuToggle: PropTypes.func.isRequired,
  navigationMobileToogle: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
};

const composed = compose(
  connect(
    state => ({
      menuMobileOpen: state.app.menuMobileOpen,
      notificationMenuMobileOpen: state.app.notificationMenuMobileOpen,
      notifications: state.notifications.data,
      vocabulary: state.app.vocabulary,
      navigationMobile: state.app.navigationMobile,
      userName: state.user.data,
      user_type: state.user.data.user_type,
      // user_lang: state.user.data.lang,
      isAuthSSO: state.app.authState.sso,
      location: state.router.location.pathname,
    }),
    dispatch => ({
      setSearchWord: payload => dispatch({ type: SEARCH_WORD_SET, response: payload }),
      startLogout: () => dispatch({ type: AUTH_LOGOUT._REQUEST }),
      pushUrl: url => dispatch(push(url)),
      menuToggle: () => dispatch({ type: APP_MENU_TOGGLE }),
      notificationMenuToggle: () => dispatch({ type: APP_MENU_NOTIFICATION_TOGGLE }),
      navigationMobileToogle: () => dispatch({ type: APP_NAVIGATION_TOGGLE }),
      getNotification: () => dispatch({ type: NOTIFICATION_GET._REQUEST }),
      readNotification: id => dispatch({ type: NOTIFICATION_READ._REQUEST, id }),
      getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST }),
      // changeLanguage: (langcode) => dispatch({ type: CHANGE_LANGUAGE._REQUEST, langcode })
    })
  ),
  withMediaQueries
)(NavBar);
export default composed;
