import styled from 'styled-components';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: auto;
  left: 0;

  &:checked ~ span {
    background-color: ${props => props.theme.colors.lightBlue};
    opacity: 0.9;
    border: 1px solid ${props => props.theme.colors.primaryBlue};
    border-radius: 4px;
  }

  &:checked ~ div {
    z-index: 1;
    color: ${props => props.theme.colors.white};
  }
`;

const CustomRadio = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 34px;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  width: fit-content;
  cursor: pointer;

  @media ${props => props.theme.device.mobileL} {
    margin-top: 10px;
    padding: 8px 0px;
  }

  & > div {
    width: auto;
    min-height: 34px;
    display: flex;
    align-items: center;
    margin: 0px 10px;
    z-index: 1;
    color: #acacac;
  }
`;

export { Label, Input, CustomRadio };
