import styled from 'styled-components';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 34px;
  width: auto;
  left: 0;

  &:checked ~ span {
    background-color: ${props => props.theme.colors.lightBlue};
    opacity: 0.9;
    border: 1px solid ${props => props.theme.colors.primaryBlue};
    z-index: 0;
  }

  &:checked ~ div {
    z-index: 1;
    color: ${props => props.theme.colors.white};
  }
`;

const CustomCheckbox = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  //margin: 0;
  //padding: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  min-height: 34px;
  width: fit-content;
  padding: 10px 0px;
  margin-bottom: 0px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  & > div {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    z-index: 1;
    color: #acacac;
    opacity: ${props => (props.disabled ? 0.2 : null)};
  }
`;

export { Label, Input, CustomCheckbox };
