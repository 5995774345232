import produce from 'immer';
import {
  SEARCH_MANDATORY_TRAINING_LIST_GET, SEARCH_OPTIONAL_COURSES_LIST_GET, SEARCH_WORD_SET
} from '../actions/search';

const initialState = {
  search_word: '',
  mandatory_training: [],
  optional_courses: []
};

const searchReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case SEARCH_WORD_SET:
      draft.search_word = action.response;
      break;
    case SEARCH_MANDATORY_TRAINING_LIST_GET._SUCCESS: {
      const { data } = action;
      draft.mandatory_training = data.courses;
      break;
    }
    case SEARCH_OPTIONAL_COURSES_LIST_GET._SUCCESS: {
      const { data } = action;
      draft.optional_courses = data.courses;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default searchReducer;
