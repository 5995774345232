import styled, { css } from 'styled-components';
import { FooterHeight } from '../../../const';

const FootContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 20px 0;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Icon = styled.span`
  margin-left: 15px;
`;

const FootSection = styled.div`
  min-width: 30vw;
  max-width: 40vw;
`;

const LinkSection = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 90px;
  margin: 10px 0;
  color: #575a64;
  align-items: flex-start;
`;

const ContactSection = styled.div`
  color: ${props => props.theme.colors.gray};
  margin-top: 40px;
  
  div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const Link = styled.a`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #575a64;
  
  div {
    transition: ${props => props.theme.constants.transitionBase};
    &:hover {
      color: ${props => props.theme.mixin.rgba(props.theme.colors.gray, 0.6)};
    }
  }
`;

const IntesaLogo = styled.div`
  font-size: 14px;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 150px;
    left: 0;
    top: -15px;
    background-color: #575a64;
  }
`;

const FootContainer = styled.footer`
  display: block;
  background-color: #1f2025;
  z-index: ${props => props.theme.zIndex.foot};
  padding: 40px 0;
  
  height: ${FooterHeight}px;
  width: 100%;

  & span {
    margin-left: 0;
  }

  @media ${props => props.theme.device.mobileL} {
    padding: 20px 35px;
  }

  ${({ arabic }) => arabic && css`

    ${IntesaLogo} {
      text-align: right;

      @media ${props => props.theme.device.tabletL} {
        padding-right: 30px;
      }

      @media ${props => props.theme.device.mobileL} {
        padding-right: 0;
      }

      &:after {
        left: auto;
        right: 0;

        @media ${props => props.theme.device.tabletL} {
          right: 20px;
        }

        @media ${props => props.theme.device.mobileL} {
          right: 0;
          top: -8px;
        }
      }
    }

    ${FootContent} {
      flex-direction: row-reverse;
      @media ${props => props.theme.device.tabletL} {
        padding-right: 20px;
      }
      
      @media ${props => props.theme.device.mobileL} {
        padding-right: 0;
        display: flex;
        justify-content: space-between;
      }
    }
    
    ${FootSection} {
      text-align: right;

      @media ${props => props.theme.device.mobileL} {
        max-width: 30vw;
      }
    }

    ${LinkSection} {
      flex-direction: row-reverse;
    }
  `}
`;

export {
  FootContainer, Icon, FootContent, FootSection, Link, LinkSection, ContactSection,
  IntesaLogo
};
