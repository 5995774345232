import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import queryString from 'query-string';

import { isMobile } from 'react-device-detect';
import {
  REMOTE_CLASSROOM_CONTENT_GET, REMOTE_CLASSROOM_LIKE_DISLIKE,
} from '../../redux/actions/remoteclassroom';

import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  Background,
  Title,
  OverviewContainer,
  Text,
  RightContainer,
  RightContainerItems,
  SectionContainer,
  IconCompleted,
  SlotTitle,
  SessionDuration,
  Banner,
  BannerContainer,
  BannerInfo,
  Wrapper,
  IconWrapper,
} from './style';

import Arial from '../../../ui/typography/arial';
import { BackButton, BoxFinalTest, Tabs } from '../../../ui/atoms';
import { GuideboookCard, TeacherBox } from '../../../ui/components';
import {
  GuidebookItemColumn,
  GuidebookListColumn,
  RowGuidebook,
} from '../Guidebook/style';
import AgendaTimeline from '../../../ui/components/AgendaTimeline';
import durationFormatHour from '../../../utils/duration-format-hour';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import {
  CourseNotAvailable,
  InfoContainer, InfoDescription, InfoTitle, TabsContainer,
} from '../Toj/style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import LikeDislikeBox from '../../../ui/components/LikeDislikeBox/index';
import { isArabic } from '../../../utils/locale';
import { dateFormatCalendar } from '../../../utils/common';

const RemoteClassroom = ({
  heroImage, mediaIsLaptop, mediaIsTablet, getRemoteClassroomContent,
  id, selected_classroom: {
    title, descriptionAgenda, materials, agenda, conferenceLink, cover, goal = {}, sections = [], teachers = [], duration, slots, completed, tutee = [], feedback,
  }, contentNotAvailable, user_type, location, vocabulary, setRemoteClassroomFeedback,
}) => {
  const { tutee_id } = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user_type === 'tutor') {
      getRemoteClassroomContent({ id, tutee_id });
    } else {
      getRemoteClassroomContent({ id });
    }
  }, [getRemoteClassroomContent, id, tutee_id, user_type]);

  const defaultTabs = [
    { label: getValueVocabulary(vocabulary.overviewLabel, 'overviewLabel'), value: 'overview' },
    { label: getValueVocabulary(vocabulary.materialsLabel, 'materialsLabel'), value: 'materials' },
  ];

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const onChangeTab = tab => {
    setActiveTab(tab);
  };

  const getSlotTitle = (slot) => (
    <SlotTitle>
      <Arial vocabulary="slotTitleSchedule">
        <Arial vocabulary="slotDate" type="slotDate" configuration={{ color: '#fff' }}>{dateFormatCalendar(slot.startDateTimestamp)}</Arial>
        <Arial type="scheduledTime">{`${moment(new Date(slot.startDateTimestamp)).format('h:mm A')} - ${moment(new Date(slot.endDateTimestamp)).format('h:mm A')}`}</Arial>
      </Arial>
    </SlotTitle>
  );

  const handleOnchangeLikeDislike = (value) => {
    setRemoteClassroomFeedback({ feedback_value: value, id, tutee_id });
  };

  const getSessionsDuration = (slot) => {
    const startDate = new Date(slot.startDateTimestamp);
    const endDate = new Date(slot.endDateTimestamp);
    return (`${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`);
  };

  return (
    <Wrapper arabic={isArabic()}>
      {
        !contentNotAvailable ? (
          <React.Fragment>
            <Banner>
              <Background img={heroImage || cover} />
              <BannerContainer hasSlot={!!(slots || []).length}>
                <BackButton />
                {(slots || []).length && (
                  <SessionDuration>
                    {
                      (slots || []).map((slot, index) => <Arial key={`session-duration-${index}`} type="tojSessionDuration">{getSessionsDuration(slot)}</Arial>)
                    }
                  </SessionDuration>
                )}
                <Arial
                  vocabulary="newsDetailTitle"
                  type="newsDetailTitle"
                  configuration={{ size: isMobile ? 35 : 50 }}
                >
                  {title}{' '}
                  {completed ? (
                    <IconWrapper>
                      <IconCompleted
                        style={{ fontSize: 30 }}
                        className="icon-completato"
                      />
                    </IconWrapper>
                  ) : null}
                </Arial>
                <BannerInfo>
                  <Arial type="tojName" vocabulary="remoteClassroomName">
                    {getValueVocabulary(
                      vocabulary.remoteClassroomName,
                      'remoteClassroomName'
                    )}{' '}
                    | <span>{durationFormatHour(duration)}</span>
                  </Arial>
                </BannerInfo>
              </BannerContainer>
            </Banner>
            <Container>
              <TabsContainer>
                <Tabs
                  tabs={[...defaultTabs]}
                  onChange={onChangeTab}
                  selected={activeTab}
                  tabType={Tabs.TYPE.PRIMARY}
                  classList="remoteClassroom"
                />
              </TabsContainer>
              {activeTab.value === 'overview' && (
                <OverviewContainer>
                  <Row className={isArabic() && 'd-flex flex-row-reverse'}>
                    <Col lg={mediaIsLaptop ? 12 : 8} md={12} sm={12}>
                      <Text>
                        <Title>
                          <Arial type="tojTitle" vocabulary="tojTitle">{getValueVocabulary(vocabulary.tojTitle, 'tojTitle')}</Arial>
                        </Title>
                        <Arial type="agendaTimelineDescription">
                          {' '}
                          <span
                            dangerouslySetInnerHTML={{ __html: goal?.description }}
                          />
                        </Arial>
                      </Text>
                      <AgendaTimeline
                        // timeline={agenda}
                        descriptionAgenda={descriptionAgenda}
                        vocabularyKey="agendaListRemoteClassroom"
                        classList="remoteClassroom"
                      />
                      {sections?.length > 0 && (
                        <SectionContainer>
                          {sections?.map((section, index) => (
                            <Text key={`section--${index}`}>
                              <Title>
                                <Arial type="tojTitle">{section?.title}</Arial>
                              </Title>
                              <Arial type="agendaTimelineDescription">
                                {' '}
                                <span
                                  dangerouslySetInnerHTML={{ __html: section?.description }}
                                />
                              </Arial>
                            </Text>
                          ))}
                        </SectionContainer>
                      )}
                      {(slots || []).map((slot, index) => (
                        <BoxFinalTest
                          key={`slot--${index}`}
                          isClassroom
                          title={getSlotTitle(slot, index + 1)}
                          canPartecipate={slot?.canPartecipateConference}
                          link={conferenceLink}
                        />
                      ))}
                    </Col>
                    <Col lg={mediaIsLaptop ? 12 : 4} md={12} sm={12}>
                      {mediaIsTablet || mediaIsLaptop ? (
                        <RightContainer>
                          <RightContainerItems>
                            <InfoContainer>
                              <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                              <InfoDescription>
                                <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>{getValueVocabulary(vocabulary.guidelinesBody1, 'guidelinesBody1')}{getValueVocabulary(vocabulary.guidelinesBody2, 'guidelinesBody2')}</Arial>
                              </InfoDescription>
                            </InfoContainer>
                          </RightContainerItems>
                          <RightContainerItems>
                            <TeacherBox
                              title="Teachers"
                              tutor={teachers.length ? teachers : tutee}
                            />
                            {user_type === 'student'
                              && completed
                              // && feedback && Object.keys(feedback).length > 0
                              && (
                                <LikeDislikeBox
                                  countDislike={feedback?.negative}
                                  countLike={feedback?.positive}
                                  disabled={user_type !== 'student'}
                                  title="RemoteClassroomLikeDislikeBoxTitle"
                                  isLiked={feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1}
                                  onChange={handleOnchangeLikeDislike}
                                />
                              )}
                          </RightContainerItems>


                        </RightContainer>
                      ) : (
                        <>
                          <TeacherBox
                            title={getValueVocabulary(vocabulary.teachersLabel, 'teachersLabel')}
                            tutor={teachers.length ? teachers : tutee}
                          />
                          <InfoContainer>
                            <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                            <InfoDescription>
                              <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>{getValueVocabulary(vocabulary.guidelinesBody1, 'guidelinesBody1')}{getValueVocabulary(vocabulary.guidelinesBody2, 'guidelinesBody2')}</Arial>
                            </InfoDescription>
                          </InfoContainer>
                          {user_type === 'student'
                            && completed
                            // && feedback && Object.keys(feedback).length > 0
                            && (
                              <LikeDislikeBox
                                countDislike={feedback?.negative}
                                countLike={feedback?.positive}
                                disabled={user_type !== 'student'}
                                title="RemoteClassroomLikeDislikeBoxTitle"
                                isLiked={feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1}
                                onChange={handleOnchangeLikeDislike}
                              />
                            )}
                        </>
                      ) }
                    </Col>
                  </Row>
                </OverviewContainer>
              )}
              {activeTab.value === 'materials' && (
                <>
                  <GuidebookListColumn md={9} sm={12} lg={12}>
                    <RowGuidebook>
                      {(materials || []).map((singleMaterials, i) => (
                        <GuidebookItemColumn key={i}>
                          <GuideboookCard guidebook={singleMaterials} />
                        </GuidebookItemColumn>
                      ))}
                    </RowGuidebook>
                  </GuidebookListColumn>
                </>
              )}
            </Container>
          </React.Fragment>
        ) : (
          <CourseNotAvailable>
            <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="contentNotAvailableMessage">
              {getValueVocabulary(vocabulary.contentNotAvailableMessage, 'contentNotAvailableMessage')}
            </Arial>
          </CourseNotAvailable>
        )}

    </Wrapper>
  );
};

RemoteClassroom.propTypes = {
  heroImage: PropTypes.string,
  mediaIsLaptop: PropTypes.bool,
  mediaIsTablet: PropTypes.bool,
  id: PropTypes.string.isRequired,
  // HOC (connect, dispatch)
  getRemoteClassroomContent: PropTypes.func,
  setRemoteClassroomFeedback: PropTypes.func,
  // HOC (connect, state)
  selected_classroom: PropTypes.object,
  contentNotAvailable: PropTypes.bool,
  location: PropTypes.object,
  user_type: PropTypes.string,
  vocabulary: PropTypes.object,
};

export default connect(
  state => {
    const { selected_classroom, contentNotAvailable } = state.remoteclassroom;
    const {
      data: { user_type },
    } = state.user;
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      selected_classroom,
      contentNotAvailable,
      user_type,
      vocabulary,
      location,
    };
  },
  dispatch => ({
    getRemoteClassroomContent: ({ id }) => dispatch({ type: REMOTE_CLASSROOM_CONTENT_GET._REQUEST, id }),
    setRemoteClassroomFeedback: (payload) => dispatch({ type: REMOTE_CLASSROOM_LIKE_DISLIKE._REQUEST, payload }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(RemoteClassroom));
