import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  LikeDislikeBoxContainer, Text, Title, Icon,
} from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';

const LikeDislikeBox = ({
  vocabulary, title, isLiked, countLike = 0, countDislike = 0, disabled, onChange,
}) => {
  const [like, setLike] = useState(isLiked);
  const [numLike, setNumLike] = useState(countLike);
  const [numDislike, setNumDislike] = useState(countDislike);

  const handleOnClick = (value) => {
    if (onChange) onChange(value ? 1 : -1);
    /*START This block of code is use to calculate right away the number of likes and dislikes*/
    setLike(value);
    if (value) {
      setNumLike(numLike + 1);
      setNumDislike(numDislike > 0 && typeof like === 'boolean' ? numDislike - 1 : typeof like === 'boolean' ? 0 : numDislike);
    } else {
      setNumLike(numLike > 0 && typeof like === 'boolean' ? numLike - 1 : typeof like === 'boolean' ? 0 : numLike);
      setNumDislike(numDislike + 1);
    }
    /*END This block of code is use to calculate right away the number of likes and dislikes*/
  };

  return (
    <LikeDislikeBoxContainer arabic={isArabic()}>
      <Title><Arial type="author" configuration={{ textTransform: 'none' }} vocabulary={title}>{getValueVocabulary(vocabulary[title], title)}</Arial></Title>
      <Text>
        <Icon
          className="icon-like"
          onClick={!disabled && !like ? () => handleOnClick(true) : null}
          isActive={like}
          disabled={disabled}
        >
          <Arial configuration={{ size: 28 }}>{numLike}</Arial>
        </Icon>
        <Icon
          className="icon-nonutile"
          onClick={!disabled && (like || typeof like !== 'boolean') ? () => handleOnClick(false) : null}
          isActive={typeof like === 'boolean' && !like}
          disabled={disabled}
        >
          <Arial configuration={{ size: 28 }}>{numDislike}</Arial>
        </Icon>
      </Text>
    </LikeDislikeBoxContainer>
  );
};

LikeDislikeBox.propTypes = {
  countDislike: PropTypes.number,
  countLike: PropTypes.number,
  isLiked: PropTypes.bool,
  title: PropTypes.string,
  vocabulary: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  // HOC (connect, dispatch)
};
export default connect(
  state => {
    const { vocabulary } = state.app;
    const { data: { user_type } } = state.user;
    return {
      vocabulary,
      user_type,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
  })
)(LikeDislikeBox);
