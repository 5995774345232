import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  UserContainer, Name, ImageContainer, User
} from './style';

import { CircleImage } from '../../atoms';
import { USER_INFO_GET } from '../../../app/redux/actions';
import { UserDataInterface } from '../../../app/redux/reducers/user';
import Arial from '../../typography/arial';

const UserInfo = ({
  user: {
    name, surname, picture
  },
  getUserInfo
}) => {
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContainer>
      <User>
        <ImageContainer>
          <CircleImage src={picture} name={`${name} ${surname}`} />
        </ImageContainer>
        <div>
          <Name><Arial type="infoTitle" configuration={{ size: 16 }}>{name} {surname}</Arial></Name>
        </div>
      </User>
    </UserContainer>
  );
};

UserInfo.propTypes = {

  // HOC (connect, state)
  user: UserDataInterface,

  // HOC (connect, dispatch)
  getUserInfo: PropTypes.func.isRequired
};

export default connect(
  state => ({
    user: state.user.data
  }),
  dispatch => ({
    getUserInfo: () => dispatch({ type: USER_INFO_GET._REQUEST })
  })
)(UserInfo);
