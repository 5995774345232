export const BaseSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  lazyLoad: true,
  centerPadding: 12,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        goToSlide: 2
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 50,
        goToSlide: 2
      }
    },
    {
      breakpoint: 995,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        goToSlide: 2
      }
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        goToSlide: 2
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        goToSlide: 2
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 180,
        goToSlide: 1
      }
    },
    {
      breakpoint: 645,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 160,
        goToSlide: 1
      }
    },
    {
      breakpoint: 605,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 130,
        goToSlide: 1
      }
    },
    {
      breakpoint: 545,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 20,
        goToSlide: 1
      }
    }
  ]
};
