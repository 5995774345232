import styled, { css } from 'styled-components';

export const Top = styled.div`
  z-index: 1;
`;
export const Bottom = styled.div`
  z-index: 1;
`;

const getStyleLabel = ({ theme, type }) => {
  let style;
  switch (type) {
    case 'selection':
      style = css`
        background-color: ${theme.colors.lima}
      `;
      break;
    case 'collection':
    default:
      style = css`
        background-color: ${theme.colors.buttercup}
      `;
      break;
  }
  return style;
};

export const Label = styled.div`
  width: fit-content;
  max-width: 160px;
  padding: 0 10px;
  height: auto;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  ${props => getStyleLabel(props)};
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 20px;
`;

const getStyleBanner = ({ theme, data: { type, status } }) => {
  let style;
  let themeBanner = type;
  if (status === 'expiring' || status === 'expired') themeBanner = status;
  switch (themeBanner) {
    case 'expiring':
      style = css`
        color: ${theme.colors.black};
        &:before {
          background-color: ${theme.colors.white}
        }
        &:after {
          background-color: ${theme.colors.white}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.white}
          }
        }
      `;
      break;
    case 'expired':
      style = css`
        color: ${theme.colors.tamarillo};
        &:before {
          background-color: ${theme.colors.white}
        }
        &:after {
          background-color: ${theme.colors.white}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.white}
          }
        }
      `;
      break;
    case 'selection':
      style = css`
        &:before {
          background-color: ${theme.colors.lima}
        }
        &:after {
          background-color: ${theme.colors.lima}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.lima}
          }
        }
      `;
      break;
    case 'collection':
    default:
      style = css`
        &:before {
          background-color: ${theme.colors.buttercup}
        }
        &:after {
          background-color: ${theme.colors.buttercup}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.buttercup}
          }
        }
      `;
      break;
  }
  return style;
};

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 178px;
  color: ${props => props.theme.colors.white};
  z-index: 0;
  position: absolute;
  top: 31px;
  right: -42px;
  transform: rotate(45deg);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;

  ${({ alt }) => alt && css`
  height: 42px;
  right: -46px;
  top: 23px;
`}
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    transform: rotate(45deg);
  }
  
  & > div {
    z-index: 1;
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 8px;
    
    ${({ alt }) => alt && css`
      padding: 8px 31px;
    `}

    &:after {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(17.8% 0%,82% 0%,100% 100%,0% 100%);
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: -1;

      ${({ alt }) => alt && css`
        clip-path: polygon(23% 0%,76% 0%,100% 100%,0% 100%);
      `}
    }
  }
  ${props => getStyleBanner(props)};
`;

export const Favourite = styled.div`
  color: ${props => props.theme.colors.white};
  position: absolute;
  right: 4px;
  top: 12px;
  z-index: 2;
`;

export const Icon = styled.span`
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
  color: ${props => props.theme.colors.white};
  margin-right: 5px;
`;

export const CardDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  > div:first-child {
    width: fit-content;
  }
`;

export const Detail = styled.div`
  display: flex;
`;

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: 275px;
  height: 270px;
  border-radius: 4px;
  padding: 15px 20px;  
  transition: ${props => props.theme.constants.transitionBase};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  @media ${props => props.theme.device.mobileL} {
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8));
    transition: ${props => props.theme.constants.transitionBase};
  }
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    &:before {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
    }
  }

  ${({ arabic }) => arabic && css`
  
    ${Top} {
      margin-left: auto;
    }
    ${Favourite} {
      right: auto;
      left: 10px;
    }
    ${Banner} {
      right: auto;
      left: -42px;
      transform: rotate(315deg);
    }
    ${CardDetails} {
      flex-direction: row-reverse;
    }
    ${Detail} {
      flex-direction: row-reverse;
    }
    ${Label} {
      margin-left: auto;
    }
    ${Icon} {
      margin-right: 0;
      margin-left: 5px;
    }
  `}
`;
