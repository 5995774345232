import styled from 'styled-components';


export const Title = styled.div`
  color: ${props => props.theme.colors.white};
  text-align: center;
  padding-bottom: 20px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    background-color: ${props => props.theme.colors.black07};
    left: -26px;
    width: calc(100% + 52px);
    bottom: 0;
  }
`;

export const IconClose = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
`;

export const IconError = styled.span`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 60px;
  color: ${props => props.theme.colors.red};
`;

export const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & > div {
    color: ${props => props.theme.colors.white};
    text-align: center;
  }
`;

export const ErrorModalContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 300px;
  max-height: 300px;
`;
