import React, { } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  TeacherBoxContainer, TeacherHeader, TeacherDetails, TeacherPhoto, Text, TeacherName,
  CircleNameContainer, CircleName, Icon,
} from './style';
import { withMediaQueries } from '../../../app/hoc/withMediaQueries';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';


const getInitials = (name) => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
    }
  }
  return initials;
};

const TeacherBox = ({
  mediaIsPhone, tutor, role, title, vocabulary,
}) => (
  <TeacherBoxContainer arabic={isArabic()} style={{ width: mediaIsPhone ? 'auto' : '' }}>
    <TeacherHeader>
      <Arial type="author">{title || (role === 'tutee' ? getValueVocabulary(vocabulary.teacherBoxLabelTutor, 'teacherBoxLabelTutor') : getValueVocabulary(vocabulary.teacherBoxLabelTutee, 'teacherBoxLabelTutee'))}</Arial>
    </TeacherHeader>
    {(tutor || []).map((teacher, index) => (
      <TeacherDetails key={`teacher-${index}`}>
        <div>
          {
            teacher.picture ? <TeacherPhoto img={teacher.picture} /> : (
              <CircleNameContainer>
                <CircleName className="initials">
                  <Arial type="teacher">{getInitials(`${teacher.name || teacher.firstName} ${teacher.surname || teacher.lastName}`)}</Arial>
                </CircleName>
              </CircleNameContainer>
            )}
          <Text>
            <TeacherName>
              <Arial className="name" type="teacher">{teacher.name || teacher.firstName} {teacher.surname || teacher.lastName}</Arial>

            </TeacherName>
            <Arial type="teacher" configuration={{ color: 'gray' }}>{teacher.job || teacher.role}</Arial>
          </Text>
        </div>
        {teacher?.mail && (
          <Icon className="icon-icon-mail" href={`mailto:${teacher?.mail}`} />
        )}
      </TeacherDetails>
    ))
    }


  </TeacherBoxContainer>
);

TeacherBox.propTypes = {
  title: PropTypes.string,
  role: PropTypes.string,
  vocabulary: PropTypes.object,
  mediaIsPhone: PropTypes.bool.isRequired,
};


export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  }
)(withMediaQueries(TeacherBox));
