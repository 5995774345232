import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { isTablet } from 'react-device-detect';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer,
  Background,
  HeaderWrapper,
  Question,
  Title,
  QuestionContainer,
} from './style';
import Arial from '../../../ui/typography/arial';
import {
  BackButton, Button, CheckboxSurvey, RadioButtonSurvey, SurveyLikeDislike,
} from '../../../ui/atoms';
import SurveyComments from '../../../ui/components/SurveyComments';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import routes from '../../../routes';
import { newForm, newFormField } from '../../../utils/form';
import Stars from '../../../ui/components/Stars';
import { TOJ_SET_INSERT_SURVEY, TOJ_SURVEY_CONTENT_GET } from '../../redux/actions/tojsurvey';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import ModalSurveyConfirmation from '../../../ui/components/Modals/ModalSurveyConfirmation';
import { parseQueryParams } from '../../../utils/queryParams';
import { ButtonContainer } from '../../../ui/components/QuestionBox/style';
import { isArabic } from '../../../utils/locale';

const TojSurvey = ({
  heroImage,
  selected_survey,
  getSurveyContent,
  location,
  pushUrl,
  tojId,
  setSurveyInsert,
  vocabulary,
}) => {
  const { idSurvey, selectedDate } = queryString.parse(location.search);
  const [form, setForm] = useState({});
  const [survey, setSurvey] = useState({});
  const [formReduce, setFormReduce] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSurvey(selected_survey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_survey]);

  const { questions = [] } = survey;

  const initialForm = newForm(
    questions.map((question, index) => newFormField({
      field: `survey-${index}-${question?.idQuestion}`,
      required: false,
      survey: question,
    }))
  );

  useEffect(() => {
    setForm(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  useEffect(() => {
    if (!idSurvey && tojId) {
      pushUrl(`${routes.toj.path}/${tojId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, tojId, idSurvey, pushUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idSurvey) {
      getSurveyContent({ id: idSurvey, toj_id: tojId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSurvey, getSurveyContent]);

  const handleOnChange = (field = '', newValue, type, index) => {
    const { required, value = [] } = form[field];
    switch (type) {
      case 'checkbox': {
        const checkboxValue = value && [...value].indexOf(newValue) >= 0
          ? [...value].filter(val => val !== newValue)
          : value
            ? [...value, newValue]
            : [newValue];
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: checkboxValue.length ? checkboxValue : null,
            valid: required ? !!checkboxValue.length : true,
          },
        });
        break;
      }
      default:
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: newValue,
            valid: required ? !!newValue : true,
          },
        });
        break;
    }

    if (index === 0 && (newValue.toLowerCase() === 'si' || newValue.toLowerCase() === 'sì')) {
      setFormReduce(true);
    } else if (index === 0 && (newValue.toLowerCase() !== 'si' || newValue.toLowerCase() !== 'sì')) {
      setFormReduce(true);
    }
  };

  const getQuestionType = (key, questionItem, index) => {
    const {
      idQuestion,
      questionType,
      minLabel,
      maxLabel,
      maxValue,
      answers,
    } = questionItem;

    switch (questionType) {
      case 'question_evaluation': {
        return (
          <Stars onClick={(value) => handleOnChange(key, value, '', index)} stars={maxValue} minLabel={minLabel} maxLabel={maxLabel} />
        );
      }

      case 'question_single': {
        return answers.map(answer => (
          <RadioButtonSurvey
            key={`single--${index}`}
            label={answer.answer}
            name={`radio-${idQuestion}`}
            value={answer.answer}
            onChange={value => handleOnChange(key, value, '', index)}
          />
        ));
      }
      case 'question_multiple': {
        return answers.map(answer => (
          <QuestionContainer key={`multi--${index}`}>
            <CheckboxSurvey
              label={answer.answer}
              name={`checkbox-${idQuestion}`}
              value={answer.answer}
              onChange={value => handleOnChange(key, value, 'checkbox', index)}
            />
          </QuestionContainer>
        ));
      }
      case 'question_open': {
        return (
          <SurveyComments onChange={value => handleOnChange(key, value, '', index)} />
        );
      }
      case 'question_like_dislike': {
        return (<SurveyLikeDislike onChange={value => handleOnChange(key, value, '', index)} />);
      }
      default: {
        return 'no field';
      }
    }
  };

  const handleOnSubmit = () => {
    const finalForm = !formReduce ? Object.keys(form).splice(0, 1).reduce((acc, key) => ({
      ...acc,
      [key]: { ...form[key] },
    }), {}) : form;
    setSurveyInsert({
      tojId,
      answers: finalForm,
      id: survey.idSurvey,
      description: survey?.title,
    });
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage} />
        <Container>
          <Row className={isArabic() && 'flex-row-reverse'}>
            <HeaderWrapper>
              <BackButton url={`${routes.toj.path}/${tojId}`} />
              <Arial type="newsDetailTitle" vocabulary="surveyTitle">{getValueVocabulary(vocabulary.surveyTitle, 'surveyTitle')}</Arial>
            </HeaderWrapper>
          </Row>
        </Container>
      </HeaderContainer>
      <Container>
        <Row>
          <Col lg={isTablet ? 6 : 12} md={12} sm={12}>
            {Object.keys(form).length
              && Object.keys(form).splice(0, !formReduce ? 1 : Object.keys(form).length).map((key, index) => (
                <Row key={`form-key--${index}`}>
                  <Question>
                    <Title>
                      <Arial type="newsTitle">
                        {form[key].survey.question}
                      </Arial>
                    </Title>
                    {getQuestionType(key, form[key].survey, index)}
                  </Question>
                </Row>
              ))}
            <ButtonContainer>
              <Button
                type={Button.TYPE.PRIMARY}
                btnText="Confirm"
                height={40}
                size={180}
                onClick={handleOnSubmit}
              />
            </ButtonContainer>
          </Col>
          {/*<Col lg={isTablet ? 6 : 4} md={12} sm={12}>
            <QuestionContainer>
              <QuestionBox
                question={Object.keys(form).splice(0, !formReduce ? 1 : Object.keys(form).length)}
                form={form}
                handleOnSubmit={handleOnSubmit}
                showOtherQuestions={formReduce}
              />
            </QuestionContainer>
              </Col>*/}
        </Row>
      </Container>
      <ModalSurveyConfirmation messageKey="tojSurveyModalMessage" btnKey="tojSurveyModalBtn" redirectLink={`${routes.toj.path}/${tojId}${parseQueryParams({ selectedDate })}`} />
    </>
  );
};

TojSurvey.propTypes = {
  tojId: PropTypes.number.isRequired,
  // HOC (connect, dispatch)
  getSurveyContent: PropTypes.func.isRequired,
  setSurveyInsert: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  // HOC (connect, state)
  location: PropTypes.object,
  selected_survey: PropTypes.object,
};

export default connect(
  state => {
    const { selected_survey } = state.tojsurvey;
    const { location } = state.router;
    const { vocabulary } = state.app;
    return {
      location,
      selected_survey,
      vocabulary,
    };
  },
  dispatch => ({
    getSurveyContent: (filters) => dispatch({ type: TOJ_SURVEY_CONTENT_GET._REQUEST, filters }),
    setSurveyInsert: ({
      tojId, answers, id, description,
    }) => dispatch({
      type: TOJ_SET_INSERT_SURVEY._REQUEST,
      tojId,
      answers,
      id,
      description,
    }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(TojSurvey));
