import produce from 'immer';
import { LOCATION_CHANGE } from 'redux-first-history';

import {
  LOADER_OPEN,
  LOADER_CLOSE,
  APP_REQUEST_URL_RESET,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  APP_MENU_TOGGLE,
  APP_CONFIGURATION_GET,
  APP_REQUEST_URL_SET,
  AUTH_RESET_SET,
  APP_STORE_RELOAD,
  FAQ_GET,
  VOCABULARY_GET,
  APP_MENU_NOTIFICATION_TOGGLE,
  APP_NAVIGATION_TOGGLE,
  AUTH_RESTORE_PASSWORD,
} from '../actions';
import routes from '../../../routes';

const initialState = {
  loader: {
    instances: 1,
    isSpinning: true
  },
  authState: {
    isLogged: !!localStorage.getItem('X-CSRF-Token'),
    error: null,
    polling: false
  },
  requestedURL: null,
  menuMobileOpen: false,
  notificationMenuMobileOpen: false,
  navigationMobile: false,
  appConfig: {
    homeHeroImage: null,
    // faqUrl: null
  },
  currentPath: undefined,
  backPath: undefined,
  faq: {
    categories: [],
    items: []
  },
  vocabulary: {}
};

const appReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      draft.menuMobileOpen = initialState.menuMobileOpen;
      draft.notificationMenuMobileOpen = initialState.notificationMenuMobileOpen;

      if (
        action.payload
          && action.payload.action === 'POP'
          && action.payload.pathname !== routes.auth.path
      ) {
        draft.requestedURL = action.payload;
      }
      const { payload } = action;
      if (
        payload
        && payload.action === 'PUSH'
        && payload.pathname !== routes.auth.path
        && state.backPath
        && payload.pathname.indexOf(state.backPath.pathname) < 0
      ) {
        if (
          payload.pathname !== routes.remoteclassroom.path
          && payload.pathname.indexOf(routes.remoteclassroom.path) >= 0
        ) draft.backPath = state.backPath;
        else if (
          payload.pathname !== routes.toj.path
          && payload.pathname.indexOf(routes.toj.path) >= 0
        ) draft.backPath = state.backPath;
        else draft.backPath = payload;
      } else if (
        state.backPath
        && payload.pathname.indexOf(routes.learningevents.path) >= 0
      ) {
        draft.backPath.pathname = routes.learningevents.path;
        draft.backPath.search = (state.backPath.search && payload.search)
        || (!state.backPath.search && payload.search)
        || state.backPath.pathname === payload.pathname
          ? payload.search
          : state.backPath.search;
      } else draft.backPath = payload;


      if (
        payload
          && payload.action === 'PUSH'
          && payload.pathname !== routes.auth.path
          && state.backPath
          && payload.pathname.indexOf(state.backPath.pathname) < 0
      ) {
        if (
          payload.pathname !== routes.remoteclassroom.path
            && payload.pathname.indexOf(routes.remoteclassroom.path) >= 0
        ) draft.backPath = state.backPath;
        else if (
          payload.pathname !== routes.toj.path
            && payload.pathname.indexOf(routes.toj.path) >= 0
        ) draft.backPath = state.backPath;
        else draft.backPath = payload;
      } else if (
        state.backPath
          && payload.pathname.indexOf(routes.learningevents.path) >= 0
      ) {
        draft.backPath.pathname = routes.learningevents.path;
        draft.backPath.search = (state.backPath.search && payload.search)
            || (!state.backPath.search && payload.search)
            || state.backPath.pathname === payload.pathname
          ? payload.search
          : state.backPath.search;
      } else draft.backPath = payload;

      draft.navigationMobile = false;
      draft.currentPath = action.payload.pathname;

      break;
    }

    case APP_STORE_RELOAD: {
      draft.authState.isLogged = !!localStorage.getItem('X-CSRF-Token');
      draft.authState.sso = !!JSON.parse(localStorage.getItem('SSO'));
      break;
    }

    case APP_REQUEST_URL_SET:
      draft.requestedURL = action.requestedURL || action.payload;
      break;

    case APP_MENU_TOGGLE:
      draft.menuMobileOpen = !state.menuMobileOpen;
      break;

    case APP_MENU_NOTIFICATION_TOGGLE:
      draft.notificationMenuMobileOpen = !state.notificationMenuMobileOpen;
      break;

    case APP_NAVIGATION_TOGGLE:
      draft.menuMobileOpen = initialState.menuMobileOpen;
      draft.navigationMobile = !state.navigationMobile;
      break;

    case APP_REQUEST_URL_RESET:
      draft.requestedURL = initialState.requestedURL;
      break;

    case LOADER_OPEN: {
      const i = state.loader.instances > 0 ? state.loader.instances + 1 : 1;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;

      break;
    }
    case LOADER_CLOSE: {
      const i = state.loader.instances >= 1 ? state.loader.instances - 1 : 0;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;

      break;
    }

    case AUTH_LOGIN._SUCCESS: {
      const { token } = action;
      localStorage.setItem('X-CSRF-Token', token);
      draft.authState.isLogged = true;
      draft.authState.error = initialState.authState.error;
      break;
    }

    case AUTH_RESET_SET: {
      const { params, token } = action;
      draft.authState = {
        ...state,
        ...params
      };
      sessionStorage.setItem('TMP-X-CSRF-Token', token);

      break;
    }

    case AUTH_LOGOUT._REQUEST:
      sessionStorage.setItem('isLogout', true);
      break;

    case AUTH_LOGOUT._SUCCESS:
      draft.authState.isLogged = false;
      draft.authState.error = initialState.authState.error;
      localStorage.removeItem('X-CSRF-Token');
      localStorage.removeItem('SSO');
      break;

    case AUTH_RESTORE_PASSWORD._REQUEST:
      draft.authState.polling = true;
      break;

    case AUTH_RESTORE_PASSWORD._SUCCESS:
      draft.authState.polling = false;
      draft.authState.error = initialState.authState.error;
      break;

    case AUTH_RESTORE_PASSWORD._ERROR: {
      const { data = {} } = action.response;

      if (data.message) {
        draft.authState.error = {
          ...state.authState.error,
          message: data.message
        };
      } else {
        draft.authState.error = {
          ...state.authState.error,
          message: 'Unexpected error'
        };
      }

      draft.authState.polling = false;
      break;
    }

    case AUTH_LOGIN._ERROR: {
      const { data = {} } = action.response;

      if (data.message) {
        draft.authState.error = {
          ...state.authState.error,
          message: data.message
        };
      } else {
        draft.authState.error = {
          ...state.authState.error,
          message: 'Unexpected error'
        };
      }

      draft.authState.isLogged = false;
      localStorage.removeItem('X-CSRF-Token');
      localStorage.removeItem('SSO');
      break;
    }

    case APP_CONFIGURATION_GET._SUCCESS: {
      const { data: { homepage = {}, 
        // site = {}
      } = {} } = action.response;

      draft.appConfig = {
        ...state.appConfig,
        homeHeroImage: homepage['hero-image'],
        // faqUrl: site.faq
      };

      break;
    }

    case FAQ_GET._SUCCESS: {
      const { categories, faqs } = action;
      draft.faq = { categories, faqs };

      break;
    }

    case VOCABULARY_GET._SUCCESS: {
      const { vocabulary } = action;
      draft.vocabulary = vocabulary;
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default appReducer;
