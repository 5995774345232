import styled from 'styled-components';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 17px;
  width: 17px;
  z-index:9;
  left: 0;


  &:checked ~ span {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }

  &:checked ~ span:after {
    display: block;
  }
`;

const CustomCheckbox = styled.span`
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 17px;
  height: 17px;
  background-color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: 2px;
  border-radius: 0.9px;
  
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    display: none;
    width: 6px;
    height: 13px;
    border: solid ${props => props.theme.colors.black};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #acacac;
  margin: 0;
  padding-left: 1.3rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-left: 30px;

  @media ${props => props.theme.device.mobileL} {
    margin-top: 10px;
  }
`;

export { Label, Input, CustomCheckbox };
