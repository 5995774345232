import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getLoginMock, getLogoutMock, restorePasswordMock } from './mock';

export default {
  login: (params) => {
    if (shouldUseMock()) {
      return getLoginMock();
    }
    return apiCall(`${baseUrl}${extendUrl}login`, 'POST', params);
  },
  logout: () => {
    if (shouldUseMock()) {
      return getLogoutMock();
    }
    return apiCall(`${baseUrl}${extendUrl}logout`, 'GET');
  },
  reset: (params) => apiCall(`${baseUrl}${extendUrl}user/first_login_password_reset`, 'POST', params),
  restorePassword: (params) => {
    if (shouldUseMock()) {
      return restorePasswordMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/password_restore`, 'POST', params);
  },
  resetWithToken: (params) => apiCall(`${baseUrl}${extendUrl}user/password_reset`, 'POST', params)
};
