import styled from 'styled-components';

const BodyContainer = styled.div`
  margin: 50px 0;
  
  ul {
    list-style: initial;
  }
`;

const BodyItem = styled.div`
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  padding-right: ${props => (props.type === 'first_part' ? '60px' : '0')};
  display: inline-flex;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    margin-bottom: ${props => (props.type === 'first_part' ? '40px' : '0')};
    padding: 0px 10px;
  }
`;

export { BodyContainer, BodyItem };
