import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, IconSearch, InputSearch } from './style';
import { isArabic } from '../../../utils/locale';

const SearchBar = ({
  placeholder, value, onChange,
  onSubmit, mobile, onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
    onClick();
  };

  return (
    <Container arabic={isArabic()} mobile={mobile} isOpen={isOpen}>
      <form onSubmit={onSubmit}>
        <InputSearch
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        <IconSearch
          className="search icon-search"
          onClick={handleOnClick}
        />
      </form>
    </Container>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  mobile: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SearchBar;
