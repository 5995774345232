import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AUTH_LOGIN, MODAL_OPEN, VOCABULARY_GET } from '../../redux/actions';
import {
  AuthContainer, Background, FormContainer,
  ColoredLinesBar, InputWrapper, Subtitle,
  Title, LogoContainer, ColoredLinesContainer,
  ResetPassword, ResetPasswordWrapper, LanguageContainer, Filter, LoginWrapper, WelcomeWrapper,
} from './styles';
import {
  Button, CustomInput, CustomSelect, Logo,
} from '../../../ui/atoms';
import heroImage from '../../../ui/assets/img/logo2.png';
import {
  getValues,
  isValidForm,
  newForm,
  newFormField,
} from '../../../utils/form';
import { RegexpType, validator } from '../../../utils/validator';
import Arial from '../../../ui/typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import LogoAuth from '../../../ui/assets/img/logo-testo-bianco.png';
import ModalResetPassword from '../../../ui/components/Modals/ModalResetPassword';
import ModalConfirmResetPassword from '../../../ui/components/Modals/ModalConfirmResetPassword';
import storage from '../../../utils/storage';
import { optionCountry, isArabic } from '../../../utils/locale';


const initialAuthForm = newForm([
  newFormField({
    field: 'username',
    required: true,
    type: RegexpType.STRING,
    valid: true,
  }),
  newFormField({
    field: 'password',
    required: true,
    type: RegexpType.PASSWORD,
    valid: true,
  }),
]);

const Auth = ({
  startLogin, vocabulary, resetPassword, getVocabulary,
}) => {
  sessionStorage.setItem('SSO', false);
  sessionStorage.removeItem('isLogout');
  const [authForm, setAuthForm] = useState(initialAuthForm);
  const [selectedCountry, setSelectedCountry] = useState([]);

  // console.log('lang', storage.read('default_language').value);
  const handleOnChange = (field = '', newValue) => {
    const { type, required } = authForm[field];
    setAuthForm({
      ...authForm,
      [field]: {
        ...authForm[field],
        value: newValue,
        valid:
          typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true,
      },
    });
  };

  useEffect(() => {
    if (storage.read('default_language')?.value) {
      const countrySel = optionCountry?.find(country => country.value.toLowerCase() === storage.read('default_language')?.value.toLowerCase());
      setSelectedCountry(countrySel);
    } else {
      const browser_default_language = window.navigator.language.indexOf('zh') < 0 ? window.navigator.language.split('-')[0] : window.navigator.language;
      const countrySel = optionCountry?.find(country => country.value.toLowerCase() === browser_default_language.toLowerCase());
      setSelectedCountry(countrySel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vocabulary]);

  const handleOnChangeLanguage = (newValue) => {
    setSelectedCountry(newValue);
    storage.write('default_language', newValue.value);
    getVocabulary();
  };

  return (
    <AuthContainer arabic={isArabic()}>
      <LanguageContainer>
        <Filter>
          <CustomSelect
            options={optionCountry}
            selected={selectedCountry}
            onChange={newValue => handleOnChangeLanguage(newValue || [])}
          />
        </Filter>
      </LanguageContainer>
      <WelcomeWrapper>
        <Background img={heroImage} />
        <Arial>
          <Title>
            <Arial type="titleHome" vocabulary="authTitle">
              {getValueVocabulary(vocabulary.authTitle, 'authTitle')}
            </Arial>
          </Title>
          <Subtitle>
            <Arial type="descriptionHome" vocabulary="authDescription">
              {getValueVocabulary(
                vocabulary.authDescription,
                'authDescription'
              )}
            </Arial>
          </Subtitle>
        </Arial>
      </WelcomeWrapper>
      <LoginWrapper>
        <ColoredLinesContainer>
          <ColoredLinesBar />
          <ColoredLinesBar />
          <ColoredLinesBar />
        </ColoredLinesContainer>
        <FormContainer>
          <LogoContainer>
            <Logo size={Logo.SIZE.XL} src={LogoAuth} />
          </LogoContainer>
          <InputWrapper>
            <CustomInput
              placeholder={getValueVocabulary(vocabulary.emailPlaceholder, 'emailPlaceholder')}
              onBlur={newValue => handleOnChange('username', newValue)}
              value={authForm?.username?.value}
              valid={authForm?.username?.valid}
            />
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              placeholder={getValueVocabulary(vocabulary.passwordPlaceholder, 'passwordPlaceholder')}
              onChange={newValue => handleOnChange('password', newValue)}
              type="password"
              value={authForm?.password?.value}
              valid={authForm?.password?.valid}
            />
          </InputWrapper>
          <ResetPasswordWrapper>
            <ResetPassword onClick={resetPassword}><Arial type="forgotPassword" vocabulary="forgotPassword">{getValueVocabulary(vocabulary.forgotPassword, 'forgotPassword')}</Arial></ResetPassword>
          </ResetPasswordWrapper>
          <InputWrapper>
            <Button
              size={Button.SIZE.FULL}
              btnText={getValueVocabulary(vocabulary.btnLogin, 'btnLogin')}
              disabled={!isValidForm(authForm)}
              onClick={() => startLogin(getValues(authForm))}
              height={40}
            />
          </InputWrapper>
        </FormContainer>
      </LoginWrapper>
      <ModalResetPassword />
      <ModalConfirmResetPassword />
    </AuthContainer>
  );
};

Auth.propTypes = {
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  startLogin: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getVocabulary: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  },
  dispatch => ({
    startLogin: params => dispatch({ type: AUTH_LOGIN._REQUEST, params }),
    resetPassword: () => dispatch({ type: MODAL_OPEN, id: ModalResetPassword.id }),
    getVocabulary: () => dispatch({ type: VOCABULARY_GET._REQUEST }),
  })
)(Auth);
