import styled, { css } from 'styled-components';
import { Container } from '../../../ui/gridSystem';

export const PlannedListRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);

  @media ${props => props.theme.device.laptop} {
    display: flex;
    justify-content: center;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 100%;
  }

  @media ${props => props.theme.device.mobileL} {
    > div:first-child {
      display: flex;
      justify-content: center;
    }
  }
`;

export const PlannedListCol = styled.div`
  width: 580px;
  margin-right: 20px;
  @media ${props => props.theme.device.mobileL} {
    margin: 0 auto 20px auto;
    width: 100%
  }
`;

export const CalendarContainer = styled.div`
  padding-top: 50px;

  .fc .fc-toolbar {
    @media ${props => props.theme.device.tabletL} {
      .fc-toolbar-chunk:first-child {
        width: 50%;
      }

      .fc-toolbar-chunk:last-child {
        width: 50%;
        text-align: right;
      }
    }
  }

  .fc .fc-scroller-liquid-absolute {
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #989898; 
    }
  }
  .fc {
    font-family: Arial;
    a {
      color: white;
    }
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 15px;
  }

  .fc-h-event {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid ${props => props.theme.colors.gray};
  }

  .fc .fc-toolbar-title {
    font-size: 30px;
    text-transform: capitalize;
    min-width: 300px;
  }
  .fc .fc-today-button {
    display: none;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0px;
    
    @media ${props => props.theme.device.tabletL} {
      margin-right: 0;
    }
  }
  .fc .fc-button-primary {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
    background-color: transparent;
  }
  .fc-direction-ltr .fc-button-group > .fc-button {
    background-color: transparent;
  }
  .fc .fc-view-harness {
    background-color: #1f2025;
  }

  .fc .fc-scrollgrid {
    border: none;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }

  .fc .fc-daygrid-day-frame {
    border: 1px solid ${props => props.theme.colors.doveGray};
    border-left: none;
    border-bottom: none;
    cursor: pointer;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 15px;
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 2px;
    .fc-daygrid-event-dot {
      display: none;
    }

    &.toj, &.remote_classroom {
      color: ${props => props.theme.colors.primaryThemeBlue};
      border-color: ${props => props.theme.colors.primaryThemeBlue};
      background-color: rgba(0, 178, 243, 0.3);
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;

    .fc-daygrid-day-number {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 16px;
      text-align: center;
      padding: 0px;
      margin-top: 4px;
      background-color: ${props => props.theme.colors.white};
      color: #1f2025;
      margin-bottom: 6px;
    }

    &.active-tile {
      background: rgb(94 94 94 / 50%);
    }
  }
  .fc-direction-ltr .fc-daygrid-event {
    .fc-event-time {
      display: none;
    }
    .fc-event-title {
      font-size: 10px;
      font-weight: normal;
      text-overflow: ellipsis;
    }
  }

  .fc .fc-daygrid-day-number {
    margin-right: 5px;
  }

  .fc-daygrid-day.fc-day-sat, .fc-daygrid-day.fc-day-sun {
    background-color: rgba(19, 21, 30, 0.4);
  }

  .fc-daygrid-more-link {
    font-size: 10px;
  }

  .fc-daygrid-day.active-tile {
    background: rgba(255, 255, 255, 0.5);
  }

  .fc-theme-standard .fc-popover {
    background: ${props => props.theme.colors.black};
  }

  @media ${props => props.theme.device.tablet} {
    .react-calendar {
      background: ${props => props.theme.colors.black};
      border: none;
      border-radius: 4px;

      .react-calendar__month-view__days__day--neighboringMonth {
        color: ${props => props.theme.colors.gray}!important;
      }

      button:not(:disabled),
      [type='button']:not(:disabled),
      [type='reset']:not(:disabled),
      [type='submit']:not(:disabled) {
        color: white;
      }
      .react-calendar__navigation {
        background: ${props => props.theme.colors.doveGray};
        /* padding-bottom: 1em; */
        margin-bottom: 0px;

        .react-calendar__navigation__label {
          font-size: 26px;
          font-family: Arial;
          text-transform: capitalize;
        }
      }

      .react-calendar__navigation .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation .react-calendar__navigation__next2-button {
        display: none;
      }

      abbr[title],
      abbr[data-original-title] {
        text-decoration: none;
      }

      .react-calendar__month-view__weekdays {
        border-bottom: 1px solid ${props => props.theme.colors.gray};
      }

      .react-calendar__tile--now {
        background: white;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: ${props => props.theme.colors.darkGrassGreen}!important;
      }

      .react-calendar__tile--now .react-calendar__tile--now:enabled:hover,
      .react-calendar__tile--now:enabled:focus {
        background: white !important;
      }

      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        background-color: transparent;
      }
      .react-calendar__month-view__weekdays__weekday {
        padding: 15px 12px 10px 12px;
      }

      .react-calendar__tile--active {
        background: rgba(255, 255, 255, 0.5) !important;
        border-radius: 50%;
      }

      .react-calendar__tile {
        text-align: center;
        height: 32px;
        margin-bottom: 10px;
        max-width: 32px !important;
        margin-left: 14px;
        padding: 0;
        position: relative;

        &.active {
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.brownGrey};
          }
        }
      }

      .react-calendar__tile--active {
        background: transparent;
      }

      .react-calendar__tile--active:enabled:hover,
      .react-calendar__tile--active:enabled:focus {
        background: transparent;
      }

      .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background-color: transparent;
      }

      .react-calendar__tile.active::after {
        left: 45%;
      }
    }
  }
`;

export const BoxContainer = styled.div`
  width: 580px;
  min-height: 410px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(50, 50, 50, 0.1);
  background-color: #1f2025;
  margin-top: 20px;
  margin-bottom: 40px;

  @media ${props => props.theme.device.mobileL} {
    box-shadow: none;
    background-color: transparent;
    width: auto;
  }

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.doveGray};
  margin: 15px 0 14px 0;
  padding: 15px 20px 15px 20px;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 0px;
  }
`;

export const Body = styled.div`
  padding: 20px;

  @media ${props => props.theme.device.mobileL} {
    padding: 20px 25px;
  }
  
  .slick-slider { 
    .slick-list {
      padding-top: 5px;
      padding-bottom: 0;
      margin-right: -25px;
    }
    .slick-next {
      right: -15px;
    }
  }
`;

export const SelectedDate = styled.div`
  & > div {
    display: inline;
    font-size: 16px;
    text-transform: capitalize;
  }
  
  div:first-child {
    font-weight: bold;
  }  
`;

export const NumEvents = styled.div`
  & > div {
    display: inline;
    font-size: 16px;
  }
  
  div:first-child {
    font-weight: bold;
  }
`;

export const TitleAgenda = styled.div`
  font-family: Arial;
  font-size: 30px;
  line-height: 0.8;
  color: #ffffff;
  text-align: center;
`;

export const NoResultContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleBox = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 55px; 
  
  @media ${props => props.theme.device.tabletL} {
    text-align: center;
  }
`;

export const PlannableListContainer = styled(Container).attrs(() => ({
  className: 'p-0'
}))`
  div[data-vocabulary="eventToScheduleLabel"] {
    margin-bottom: 25px;

    @media ${props => props.theme.device.tabletL} {
      text-align: center;
    }
  }
`;

export const PlannableListRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 25px);

  @media ${props => props.theme.device.tabletL} {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
`;

export const PlannableListCol = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;

  @media ${props => props.theme.device.mobileL} {
    margin: 0 auto 20px auto;
  }
`;

export const ViewMore = styled.div`
  margin: 25px auto;
  text-align: center;
  width: 100%;
  
  div[data-vocabulary="viewMore"] {
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }
`;

export const PlannedListContainer = styled(Container).attrs(() => ({
  className: 'pr-0 pl-0'
}))`
  padding: 40px 0;

  ${({ arabic }) => arabic && css`
    ${PlannedListRow} {
      flex-direction: row-reverse;
    }

    ${Body} {
    
    .fc .fc-toolbar {
      flex-direction: row-reverse !important;
    }
    ${CalendarContainer} {
      flex-direction: row-reverse;

      .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
        position: absolute;
        right: -40px;
        transform: rotate( 180deg);
      }

      .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
        left: -10px;
        transform: rotate(180deg);
      }
      
      .fc .fc-toolbar.fc-header-toolbar {
        flex-direction: row-reverse !important;
        margin-bottom: 15px;
      }

      .fc-header-toolbar .fc-toolbar-title {
        text-align: right;
      }
      .fc-toolbar .fc-toolbar-chunk:last-child {
        @media ${props => props.theme.device.tabletL} {
          text-align: left;
        } 
      }
    }
  `}
`;

export const BlackContainer = styled.div`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.midGrayDark};

  ${({ arabic }) => arabic && css`
    ${PlannableListRow} {
      flex-direction: row-reverse;
    }
  `}
`;
