import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getCompulsaryTrainingCommentsMock, getRemoteClassroomMock, setTojSessionModeMock } from './mock';

export default {
  getRemoteClassroomContent: ({ id }) => {
    if (shouldUseMock()) {
      return getRemoteClassroomMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/remote_classroom/${id}/details`, 'GET');
  },
  getRemoteClassroomComments: ({ id }) => {
    if (shouldUseMock()) {
      return getCompulsaryTrainingCommentsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}comments/${id}`, 'GET');
  },
  setLikeDislikeFeedback: ({ id, feedback_value }) => {
    if (shouldUseMock()) {
      return setTojSessionModeMock();
    }
    return apiCall(`${baseUrl}${extendUrl}integration/feedback/${id}/insert`, 'POST', { feedback_value });
  }
};
