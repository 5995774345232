import React from 'react';
import PropTypes from 'prop-types';

import {
  Container, LineBar, PercentageLine, Percentage,
} from './style';
import { isArabic } from '../../../utils/locale';

const ProgressBar = ({ percentage, hasPercentage, state }) => (
  <Container arabic={isArabic()}>
    <LineBar>
      <PercentageLine
        percentage={percentage}
        state={state}
      />
    </LineBar>
    {hasPercentage && <Percentage>{percentage || 0}%</Percentage>}
  </Container>
);

ProgressBar.STATE = {
  MANDATORY_TRAINING: 'mandatory_training',
  OPTIONAL_COURSES: 'optional_courses',
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  hasPercentage: PropTypes.bool,
  state: PropTypes.string,
};

ProgressBar.defaultProps = {
  percentage: 0,
  hasPercentage: false,
};

export default ProgressBar;
