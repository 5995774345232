import React from 'react';
import PropTypes from 'prop-types';

import Arial from '../../typography/arial';

import {
  CommonCardContainer,
  Header,
  Content,
  Title,
  Description,
  RightContent,
  SpinnerContainer
} from './style';

import { Spinner } from '../index';
import { NoResult } from '../../atoms';

const CommonCard = ({
  title, description, rightContent, children, isLoading, type, noPadding
}) => (
  <CommonCardContainer>
    {isLoading ? (
      <SpinnerContainer>
        <Spinner size={35} />
      </SpinnerContainer>
    ) : (
      <>
        <Header className={type}>
          <Title><Arial type="title">{title}</Arial></Title>
          {description && <Description><Arial type="description">{description}</Arial></Description>}
          {rightContent && <RightContent>{rightContent}</RightContent>}
        </Header>
        <Content noPadding={noPadding}>{children || <NoResult />}</Content>
      </>
    )}
  </CommonCardContainer>
);

CommonCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  rightContent: PropTypes.node,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  type: PropTypes.string
};

CommonCard.defaultProps = {
  title: '',
  description: '',
  type: ''
};

export default CommonCard;
