import produce from 'immer';
import { LEARNING_EVENTS_PLANNED_LIST_GET, LEARNING_EVENTS_PLANNABLE_LIST_GET } from '../actions/learningevents';

const initialState = {
  plannedList: [],
  plannableList: [],
  pager: {}
};

const learningEventsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LEARNING_EVENTS_PLANNED_LIST_GET._SUCCESS: {
      const { response = [] } = action;
      draft.plannedList = response;
      break;
    }
    case LEARNING_EVENTS_PLANNABLE_LIST_GET._SUCCESS: {
      const { response = [], pager, subset } = action;
      const list = subset ? [...state.plannableList, ...response] : response;
      const orderedPlannableList = list.sort((a, b) => new Date(a.values.deadline) - new Date(b.values.deadline));
      draft.plannableList = orderedPlannableList;
      draft.pager = pager;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default learningEventsReducer;
