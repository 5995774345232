import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  text-align: center;
  margin: 4px auto 0 auto;
  color: ${props => props.theme.colors.white};
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: calc(100% + 52px);
    bottom: 0;
    left: 0;
    height: 1px;
    margin: 0 -26px;
    margin-top: 25px;
    background: ${props => props.theme.colors.midGray};
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin: 55px auto 0 auto;
  
  a {
    font-weight: bold;
    color: #0081dd;
    &:hover, &:active, &:visited {
      color: #0081dd;
    }
  }
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 400px;
`;

export const CloseModal = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 99;
  font-size: 20px;
  top: 20px;
  right: 20px;
  color: ${props => props.theme.colors.white};
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
  width: 380px;
  margin-right: auto;
  margin-left: auto;

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  margin: 10px auto 4px auto;
  width: 160px;
  @media ${props => props.theme.device.mobileL} {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    
    > button {
      margin-bottom: 0;
      border-radius: 0;
    }
  }
`;

