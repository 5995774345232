import styled, { css } from 'styled-components';
import { Container } from '../../../ui/gridSystem';


export const HomeBanner = styled.div`
  width: 100vw;
  height: 250px;
  position: relative;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  z-index: -1;
  `;

export const HomeBannerContainer = styled(Container)`
  padding: 50px 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  height: 100%;
  
  @media ${props => props.theme.device.tabletL}{
    width: 100%;
    padding-left: 20px !important;
  }
`;

export const Greetings = styled.div``;

export const GreetingsMessage = styled.div`
  margin-top: 20px;
  width: 530px;
  
  @media ${props => props.theme.device.mobileL}{
    width: 100%;
  }
  `;

export const HomeContentContainer = styled(Container)`
  padding-top: ${({ paddingTop }) => (paddingTop || 60)}px;
  padding-right: 0 !important;
  padding-left: 0 !important;
`;

export const CarouselContainer = styled.div`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}` : 100)}px;
  @media ${props => props.theme.device.tablet}{
    margin: auto;
    margin-bottom: 100px;
    width: 90%;
  }
  
  .slick-slider { 
    .slick-list {
      padding-top: 5px;
      padding-bottom: 0;
      margin-right: -25px;
    }
    .slick-next {
      right: -25px;
    }
  }
`;

export const CarouselHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CarouselTitle = styled.div``;

export const ViewAll = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  [data-vocabulary="showAll"] {
    margin-right: 5px;
  }
`;

export const Icon = styled.div`
  font-weight: bold !important;
`;

export const BlackContainer = styled.div`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.midGrayDark};
`;

export const HomeContainer = styled.div`
  ${({ arabic }) => arabic && css`
    ${GreetingsMessage} {
      margin-left: auto;
    }

    ${CarouselHeader} {
      flex-direction: row-reverse;
    }

    ${ViewAll} {
      flex-direction: row-reverse;
      margin-right: 0;

      > div:last-child {
        transform: rotate(180deg);
        margin-right: 5px;
      }
    }
  `}
`;
