export function createAsyncActionType(section = '', type = '') {
  const action = {
    _REQUEST: `@@${section.toLowerCase()}/${type.toUpperCase()}_REQUEST`,
    _SUCCESS: `@@${section.toLowerCase()}/${type.toUpperCase()}_SUCCESS`,
    _ERROR: `@@${section.toLowerCase()}/${type.toUpperCase()}_ERROR`
  };

  return action;
}

// app
export const LOADER_OPEN = '@@global/LOADER_OPEN';
export const LOADER_CLOSE = '@@global/LOADER_CLOSE';
export const APP_MENU_TOGGLE = '@@global/APP_MENU_TOGGLE';
export const APP_MENU_NOTIFICATION_TOGGLE = '@@global/APP_MENU_NOTIFICATION_TOGGLE';
export const APP_NAVIGATION_TOGGLE = '@@global/APP_NAVIGATION_TOGGLE';
export const APP_REQUEST_URL_SET = '@@global/APP_REQUEST_URL_SET';
export const APP_REQUEST_URL_RESET = '@@global/APP_REQUEST_URL_RESET';
export const APP_CONFIGURATION_GET = createAsyncActionType(
  'global',
  'APP_CONFIGURATION_GET'
);
export const VOCABULARY_GET = createAsyncActionType('global', 'VOCABULARY_GET');
export const APP_STORE_RELOAD = '@@global/APP_STORE_RELOAD';

export const FAQ_GET = createAsyncActionType('global', 'FAQ_GET');

// auth
export const AUTH_LOGIN = createAsyncActionType('auth', 'AUTH_LOGIN');
export const AUTH_LOGOUT = createAsyncActionType('auth', 'AUTH_LOGOUT');
export const AUTH_RESET_SET = '@@auth/AUTH_RESET_SET';
export const AUTH_RESET = createAsyncActionType('auth', 'AUTH_RESET');
export const AUTH_RESET_WITH_TOKEN = createAsyncActionType('auth', 'AUTH_RESET_WITH_TOKEN');
export const AUTH_RESTORE_PASSWORD = createAsyncActionType('auth', 'AUTH_RESTORE_PASSWORD');

// user
export const USER_INFO_GET = createAsyncActionType('user', 'USER_INFO_GET');

// export const CHANGE_LANGUAGE = createAsyncActionType('user', 'CHANGE_LANGUAGE');

// modal
export const MODAL_OPEN = '@@global/MODAL_OPEN';
export const MODAL_CLOSE = '@@global/MODAL_CLOSE';
