import styled, { css } from 'styled-components';
import { NavBarHeight, NavBarSecondRowMobile } from '../../../const';

export const FirstNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 2px;
`;
export const SecondNav = styled.div`
  height: 59px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  width: 10%;  

  @media ${props => props.theme.device.mobileL} {
    width: 20%;

    > div {
      width: fit-content;
    }
  }
`;
export const SearchWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
export const ActionWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .logout {
    margin-left: 10px;
    cursor: pointer;
  }

  @media ${props => props.theme.device.laptop} {
    &.logout {
      margin-left: 9px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  transition: ${props => props.theme.constants.transitionBase};
  min-width: 44px;
  z-index: 100;
  height: 60px;
  align-items: center;
  padding: 10px;

    span:first-child, span.icon-notifiche, span.icon-logout{
      font-size: 20px;
      color: ${props => props.theme.colors.primaryYellow}
    }
    span:first-child, span.icon-notifiche, span.icon-logout{
      font-size: 20px;
      color: ${props => props.theme.colors.primaryYellow}
    }

    span.active {
      color: ${props => props.theme.colors.primaryThemeBlue}
    }

    @media ${props => props.theme.device.tablet} {
      height: 40px; 
      justify-content: center;

      &:hover {
        background: transparent;
      }

      span:first-child {
        margin-bottom: 0px;
        font-size: 20px;
      }

      span.icon-bell-empty {
        font-size: 20px;
      }
      .notification-badge {
        right: 13px;

        @media ${props => props.theme.device.mobileL}{
          right: 20px;
        }
      }
    }

    @media ${props => props.theme.device.mobileL} {
    min-width: 35px;
    span:first-child {
        margin-bottom: 0px;
        font-size: 20px;
      }

      span.icon-notifiche {
        font-size: 20px;
      }
      .notification-badge {
        right: 20px;
        }
      }

    &.icon-title {
      color: #fff;
    }

  /* &:hover {
    /* color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)}; */
    color: ${props => props.theme.colors.primaryYellow};
    align-items: center;
    z-index: 90;
    height: 100%;
/*     background: #2a7210;
 */
    span:first-child {
      scale: 1.1;
    };
    /* opacity: 0.4; */
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green));
  }
  
  ${props => props.notificationOpen
    && css`
      color: props.theme.colors.primaryYellow;
      align-items: center;
      z-index: 90;
      height: 100%;
      /*       background: #2a7210;
 */
      /*  span:first-child {
        scale: 1.1;
      };
      background: opacity: 0.4;
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green)); */
    `};

  ${props => props.menuOpen
    && css`
  color:white;
  align-items: center;
  z-index: 90;
  height: 100%;
/*   background: #2a7210;
 */

   span:first-child {
    scale: 1.1;
  }; 
  background: opacity: 0.4;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, var(--dark-grass-green), var(--dark-grass-green));
  
  `}

  :first-child {
    margin-right: 0px;
  }

  .bell-notification {
    position: relative;
  }

  .notification-badge {
    background: red;
    position: absolute;
    top: 4px;
    right: 13px;
    padding: 5px 10x;
    border-radius: 50%;
    width: 7px;
    height: 7px;

    @media ${props => props.theme.device.mobileL}{
      right: 12px;
    }
  }

  span:first-child {
    margin-bottom: 5px;
  }

  .label {
    font-size: 11px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1180px;
  height: 60px;
  padding: 0;
  position: relative;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    height: calc(${NavBarHeight}px + ${NavBarSecondRowMobile}px)
  }
`;

export const SearchContainer = styled.div.attrs(() => ({
  className: 'search-container',
}))`
  width: 375px;
  display: flex;

  > div > form {
    position: relative;
  }

  @media ${props => props.theme.device.laptop} {
    width: 300px;
  }

  @media ${props => props.theme.device.tablet} {
    position: relative;
    width: 80%;
    left: 0;
    margin-right: 19px;
    top: unset;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const MenuElement = styled.span`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.6)};
  }

  span {
    margin-right: 10px;
  }
`;

export const SidebarMenu = styled.div`
  background-color: ${props => props.theme.colors.primaryColor};
  top: calc(100% + 10px);
  right: 281px;
  position: absolute;
  padding: 10px;
  width: 150px;
  display: none;
  transition: ${props => props.theme.constants.transitionBase};
  ${props => props.isOpen
    && css`
      right: 281px;
      display: block;
    `};

  ${MenuElement} {
    width: 100%;
    &:not(:last-child) {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const Notifications = styled.div`
  position: absolute;
  max-width: 380px;
  min-width: 380px;
  max-height: 405px;
  top: calc(100% + 10px);
  right: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  transition: ${props => props.theme.constants.transitionBase};
  overflow: scroll;

  @media ${props => props.theme.device.tablet} {
    &.notification-menu-mobile {
      background-color: ${props => props.theme.colors.black};
      max-height: 100%;

      &.open {
        position: fixed;
        display: initial;
        top: 60px;
        left: 0;
        border: none;
        margin-bottom: 0;
        height: calc(100vh - 60px);
        min-height: 100%;
        min-width: 100%;
        //overflow-y: auto;
      }
    }
  }

  @media ${props => props.theme.device.tablet} {
    &.notification-menu-mobile {
      &.open {
        top: calc(${NavBarHeight}px + ${NavBarSecondRowMobile}px);
      }
    }
  }
`;

export const NotificationMenu = styled.div`
  position: absolute;
  min-width: 380px;
  height: 30px;
  top: 60px;
  right: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.gray};
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }

  @media ${props => props.theme.device.tablet} {
    min-width: 375px;
    right: 5px;
  }
`;

export const Icon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

export const IconMenu = styled.div`
  cursor: pointer;
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.white};

 /*  @media ${props => props.theme.device.tablet} {
    margin-right: 10px;
  } */
`;

export const NotificationHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  background-color: ${props => props.theme.colors.black};
  border-radius: 4px 4px 0px 0px;
  color: ${props => props.theme.colors.white};
  padding: 16px 20px;

  @media ${props => props.theme.device.tablet} {
    height: 50px;
    border-radius: 0;
    padding: 25px !important;
  }
`;

export const NotificationDescription = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }

  @media ${props => props.theme.device.tablet} {
    min-height: 60px;
    padding: 5px !important;
  }
`;

export const NotificationContent = styled.div`
  border-top: 1px solid ${props => props.theme.colors.gray};
  width: 100%;
  height: 100%;
  max-height: 760px;
  overflow-y: scroll;

  & > ${NotificationDescription}:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.gray};
  }

  @media ${props => props.theme.device.tablet} {
    padding: 10px !important;
  }
`;

export const NotificationLabel = styled.div`
  width: 100%;
  margin-left: 10px;
  min-height: 16px;
  margin-bottom: 5px;
  max-height: 65px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  div:first-child {
    font-weight: bold;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.primaryThemeBlue};
  }

  div:last-child {
    color: ${props => props.theme.colors.white};
  }
`;

export const NotificationDate = styled.div`
  margin-left: 10px;
  min-height: 16px;
  color: ${props => props.theme.colors.brownGrey};
`;

export const InfoDescription = styled.div`
  width: calc(100% - 10px);
  ${({ notRead }) => !notRead
    && css`
      margin-left: 10px;
    `}

  ${props => props.theme.device.tablet} {
    ${({ notRead }) => !notRead
      && css`
        margin-left: 20px;
      `}
  }
`;

export const SeeAll = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  white-space: nowrap;
  padding: 15px 40px;
  transition: ${props => props.theme.constants.transitionBase};
  text-align: end;
  border-top: 1px solid ${props => props.theme.colors.gray};
  width: 100%;

  span {
    margin-left: 10px;
    transition: ${props => props.theme.constants.transitionBase};
  }

  :active {
    background: ${props => props.theme.colors.primaryThemeBlue};
  }

  &:hover {
    color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.8)};
    background: #268900 span {
      right: 15px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    padding-top: 10px !important;
    padding-right: 25px !important;
  }
`;

export const IconWrapperPeople = styled.div`
  background-color: ${props => props.theme.colors.black};
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.doveGray};
  }

  @media ${props => props.theme.device.tablet} {
    height: 45px;
    margin-right: 5px;
  }
`;

export const NameContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ::after{
    content: "";
    min-height: 48px;
    display: inline-block;
    width: 2px;
    background-color: ${props => props.theme.colors.doveGray};
    margin-left: 10px;
  }

  @media ${props => props.theme.device.tablet} {
    ::after {
      display: none;
    }
  }
  
  @media ${props => props.theme.device.mobileL} {
  }
`;

export const CircleName = styled.div`
  background-color: #0081dd;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media ${props => props.theme.device.tablet} {
    height: 36px;
    width: 36px;

    margin-right: 0;
  }

  .initials {
    line-height: 1;
    color: #FFFFFF;

    > div {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;
export const FullName = styled.div``;

export const MobileIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NoResultContainer = styled.div`
  margin-bottom: 20px;
`;

export const FaqContainer = styled.div`
  margin-right: 10px;
  margin-left: 20px;
  padding: 0 13px;
  background-color: #1a1b22;
`;
export const IconsContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const IconContainer = styled.div``;
export const NotificationBadge = styled.span``;
export const IconSpan = styled.span``;

export const Filter = styled.div`
  min-width: 58px;

  div[class*="singleValue"] {
    color: ${props => props.theme.colors.white};
    font-weight: normal;
    top: 20px;
    /* left: 3px; */
  }
  
  div[class*="control"] {
    border: 0;
    background-color: ${props => props.theme.colors.sharkLight};

    > div:first-child {
      overflow: auto;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }

  div[class*="MenuList"] {
     /* width */
     ::-webkit-scrollbar {
       width: 3px;
     }
     
     /* Track */
     ::-webkit-scrollbar-track {
       box-shadow: inset 0 0 5px grey; 
       border-radius: 10px;
     }
      
     /* Handle */
     ::-webkit-scrollbar-thumb {
       background: #989898;
       border-radius: 10px;
     }
   }

  div[class*="singleValue"] {
    color: #ffffff;
    font-weight: normal;
    top: 22px;
    /* left: 5px; */
  }

  div[class*="indicatorContainer"] {
    color: ${props => props.theme.colors.primaryYellow};
    padding: 4px;
    height: 100%;
    top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    &:after {
      display: none;
    }
  }

  div[class*="MenuList"] {
    /* width */
    ::-webkit-scrollbar {
      width: 3px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 10px;
    }
  }

  div[class*="menu"] {
    border-color: ${props => props.theme.colors.doveGray};
    > div {
      ::-webkit-scrollbar {
        width: 3px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #989898;
        border-radius: 10px;
      }
    }
  }
  
  div[class*="option"] {
    border-color: ${props => props.theme.colors.doveGray};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const LanguageContainer = styled.div`
  display: flex; 
`;

export const NavBarContainer = styled.header.attrs(() => ({
  className: 'header header-wrapper',
}))`
  position: fixed;
  width: 100%;
  height: ${NavBarHeight}px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.white};
  padding: 0 2rem;
  z-index: ${props => props.theme.zIndex.navBar};
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: #1f2025;

  &.header-wrapper {
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.theme.zIndex.headerWrapper};
    margin-left: auto;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 0 30px;
    height: 120px;
    div {
      div {
        div {
          .close-modal {
            outline: none;
            animation: fadein 1s;
            background-color: white;
            border: none;
            display: block;
            padding: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            z-index: ${props => props.theme.zIndex.closeModal};

            &::after {
              content: '';
              position: absolute;
              transform: rotate(-45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }

            &::before {
              content: ' ';
              position: absolute;
              transform: rotate(45deg);
              background-color: ${props => props.theme.colors.white};
              height: 20px;
              width: 1px;
              top: 10px;
              z-index: ${props => props.theme.zIndex.closeModal};
            }
          }
        }
      }
    }
  }

  @media ${props => props.theme.device.mobileL} {
    min-height: 110px;
    padding: 0 20px;
    div {
      padding: 0;
      div {
        div {
          //margin-right: 0px;
          //margin-left: 0px;
        }
      }
    }
  }

  ${({ arabic }) => arabic && css`
    ${ContentWrapper},
    ${ActionWrapper},
    ${LoginWrapper} {
      flex-direction: row-reverse;
    }

    ${IconsContainer} {
      flex-direction: row-reverse;
    }

    ${IconWrapper} {
      flex-direction: row-reverse;

      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
      transition: ${props => props.theme.constants.transitionBase};
      min-width: 44px;
      z-index: 100;
      height: 60px;
      align-items: center;
      padding: 10px;

      .bell-notification {
        transform: scale(-1, 1);
      }

      span:first-child, span.icon-notifiche, span.icon-logout {
        font-size: 20px;
        color: ${props => props.theme.colors.primaryYellow}
        transform: rotate(180deg);
      }

      @media ${props => props.theme.device.tablet} {
        justify-content: center;
      }
    }

    div[class*="-option"] {
      justify-content: center !important;
    }

    ${SearchWrapper} {
      margin: 0;
      margin-left: 20px;
      direction: rtl;
    }

    ${Notifications} {
      right: auto;
      left: 20px;
    }

    ${NotificationHeader},
    ${NotificationContent} {
      text-align: right !important;
    }

    ${NotificationDescription} {
      flex-direction: row-reverse;
    }

    ${NotificationLabel} {
      margin-left: 0;
      margin-right: 10px;
    }

    ${Status} {
      margin-left: 10px;
    }

    @media ${props => props.theme.device.tablet} {
      ${ContentWrapper} {
        flex-direction: column;
      }

      ${FirstNav},
      ${SecondNav},
      ${MobileIconContainer} {
        flex-direction: row-reverse;
      }     
    }
  `}
`;
