import moment from 'moment';
import { isArabic, toArabicNumbers, SHOULD_ANGLIFY } from './locale';
import { getValueVocabulary } from './getValueVocabulary';
import storage from './storage';

export function getPaths(location) {
  const loc = location || window.location;
  const pathname = loc ? loc.pathname || '' : '';
  const paths = pathname.split('/');
  const params = {};
  loc.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = decodeURIComponent(decodeURIComponent(value.toString()));
  });

  return {
    pathname,
    paths,
    params,
  };
}

export function shouldUseMock() {
  return process.env.REACT_APP_DEPLOY_ENV === 'LOCAL' && Number(process.env.REACT_APP_ENABLE_MOCK) === 1;
}

export function shouldUseMaintenance() {
  return Number(process.env.REACT_APP_ENABLE_MAINTENANCE) === 1;
}

export function defaultTabsValue(vocabulary, mandatoryTraining, optionalCourses) {
  const allLabel = isArabic() ? `(${mandatoryTraining + optionalCourses}) ${getValueVocabulary(vocabulary.searchTabAllResult)}` : `${getValueVocabulary(vocabulary.searchTabAllResult)} (${mandatoryTraining + optionalCourses})`;

  const mandatoryTrainingLabel = isArabic() ? `(${mandatoryTraining}) ${getValueVocabulary(vocabulary.mandatoryTraining)}` : `${getValueVocabulary(vocabulary.mandatoryTraining)} (${mandatoryTraining})`;

  const optionalCoursesLabel = isArabic() ? `(${optionalCourses}) ${getValueVocabulary(vocabulary.optionalCourses)}` : `${getValueVocabulary(vocabulary.optionalCourses)} (${optionalCourses})`;
  return [
    {
      label: allLabel,
      value: 'all',
    },
    {
      label: mandatoryTrainingLabel,
      value: 'mandatoryTraining',
    },
    {
      label: optionalCoursesLabel,
      value: 'optionalCourses',
    },
  ];
}

export function defaultTabsCourses(vocabulary, headQuarter, regional) {
  const allLabel = isArabic() ? `(${headQuarter + regional}) ${getValueVocabulary(vocabulary.searchTabAllResult)}` : `${getValueVocabulary(vocabulary.searchTabAllResult)} (${headQuarter + regional})`;
  const headQuarterLabel = isArabic() ? `(${headQuarter}) ${getValueVocabulary(vocabulary.tabHeadQuarter)}` : `${getValueVocabulary(vocabulary.tabHeadQuarter)} (${headQuarter})`;
  const regionalLabel = isArabic() ? `(${regional}) ${getValueVocabulary(vocabulary.tabRegional)}` : `${getValueVocabulary(vocabulary.tabRegional)} (${regional})`;
  return [
    {
      label: allLabel,
      value: 'all',
    },
    {
      label: headQuarterLabel,
      value: 'head_quarter',
    },
    {
      label: regionalLabel,
      value: 'regional',
    },
  ];
}

export function durationConvert(time) {
  return ((Number(time) / 60).toFixed(0));
}

export function convertDuration(time, format = 'm') {
  if (time < 60 && format === 'm') {
    return ((Number(time) / 60).toFixed(2));
  }

  if (time > 3600) {
    return SHOULD_ANGLIFY.includes(moment.locale().toUpperCase())
    ? moment.utc(time * 1000).locale('EN').format('h:mm:ss')
    : moment.utc(time * 1000).format('h:mm:ss');
  }

  return SHOULD_ANGLIFY.includes(moment.locale().toUpperCase())
    ? moment.utc(time * 1000).locale('EN').format(format)
    : moment.utc(time * 1000).format(format);
}

export function get(time) {
  if (time > 60) {
    return SHOULD_ANGLIFY.includes(moment.locale().toUpperCase())
      ? moment.utc(time * 1000).locale('EN').format('m')
      : moment.utc(time * 1000).format('m');
  }
  return ((Number(time) / 60).toFixed(2));
}

export const defaultMandatoryStatusList = [
  { value: 'empty', label: 'statusEmpty' },
  { value: 'started', label: 'statusStarted' },
  { value: 'completed', label: 'statusCompleted' },
  { value: 1, label: 'statusExpiring' },
];

export const defaultOptionalStatusList = [
  { value: 'empty', label: 'statusEmpty' },
  { value: 'started', label: 'statusStarted' },
  { value: 'completed', label: 'statusCompleted' },
];

export const dateFormat = (date = moment()) => (moment(date).format('DD/MM/YYYY') !== 'Invalid date'
  ? moment(date).format('DD/MM/YYYY')
  : date);

export const getExactLabel = (vocabulary, num, singular, plural) => (Number(num) > 1 ? getValueVocabulary(vocabulary[plural], plural) : getValueVocabulary(vocabulary[singular], singular));

export const dateFormatCalendar = (date) => {
  const language = storage.read('default_language')?.value;
  if (language?.toLowerCase() === 'it') return (moment(new Date(date)).format('Do MMMM').replace('º', ''));
  return (moment(new Date(date)).format('Do MMMM'));
};

export function truncateWithDots(str, end) {
  if (typeof str !== 'string' || str.length <= end) return str;
  else return str.slice(0, end - 3) + '...';
}