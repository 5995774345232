import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { Col, Row } from '../../../ui/gridSystem';
import {
  Background, OverviewContainer, Text, InfoContainer,
  InfoTitle, InfoDescription, Link, SlotContainer,
  IconWrapper, Title, ButtonContainer, SlotTitle,
  ParticipateBtnMessage, StudentSurvey, Icon, MaterialWrapper,
  RightContainer, RightContainerItems, TabsContainer, IconCompleted,
  TojBanner, TojBannerContainer, TojBannerInfo, SessionsStatus,
  ButtonScheduleContainer, SessionDuration, HeaderWrap, Body,
  SlotSingle, InfoSession, QuestionJoin, InfoParticipationContainer,
  InfoParticipation, SelectParticipateWrapper, SlotSingleActionButtons,
  TojContentContainer, TojWrapper, CourseNotAvailable,
} from './style';
import Arial from '../../../ui/typography/arial';
import {
  BackButton, Tabs, Button, RadioButton,
} from '../../../ui/atoms';
import { GuideboookCard, TeacherBox } from '../../../ui/components';
import { GuidebookItemColumn, GuidebookListColumn, RowGuidebook } from '../Guidebook/style';
import AgendaTimeline from '../../../ui/components/AgendaTimeline';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { TOJ_CONTENT_GET, TOJ_LIKE_DISLIKE, TOJ_SESSION_MODE } from '../../redux/actions/toj';
import routes from '../../../routes';
import { parseQueryParams } from '../../../utils/queryParams';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';

import durationFormatHour from '../../../utils/duration-format-hour';
import ModalPartecipateConference from '../../../ui/components/Modals/ModalPartecipateConference';
import { MODAL_OPEN } from '../../redux/actions';
import { SectionContainer } from '../RemoteClassroom/style';
import LikeDislikeBox from '../../../ui/components/LikeDislikeBox';
import HtmlRaw from '../../../ui/atoms/HtmlRaw';
import ModalSchedule from '../../../ui/components/Modals/ModalSchedule';
import { isArabic } from '../../../utils/locale';
import { dateFormatCalendar } from '../../../utils/common';

const Toj = ({
  heroImage, mediaIsTablet, mediaIsLaptop, getTojContent,
  selected_toj: {
    title, descriptionAgenda, materials, agenda,
    tutors = [], duration, slotSize, slots = [], completed,
    cover, tutee = [], sections = [], feedback, goal = {},
    deadline: dataDeadline,
  },
  contentNotAvailable, user_type, vocabulary, location, pushUrl,
  tojId, setTojSessionMode, modalPartecipateConference, setTojFeedback, modalSchedule,
}) => {
  const { tutee_id, selectedDate, role: queryRole = '', deadline: queryDeadline } = queryString.parse(location.search);
  const [deadline, setDeadline] = useState();
  const [role, setRole] = useState();

  useState(() => {
    setRole(queryRole.toLowerCase());
  }, [dataDeadline, queryDeadline]);

  useState(() => {
    setDeadline(dataDeadline || queryDeadline);
  }, [queryRole]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getTojContent({ id: tojId, role, tutee_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTojContent, tojId, tutee_id, role]);

  const defaultTabs = [
    { label: getValueVocabulary(vocabulary.overviewLabel, 'overviewLabel'), value: 'overview' },
    { label: getValueVocabulary(vocabulary.materialsLabel, 'materialsLabel'), value: 'materials' },
  ];

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);
  const [valueSelectAccordion, setValueSelectAccordion] = useState([]);
  const [totalAvailableSlotTime, setTotalAvailableSlotTime] = useState(0);

  useEffect(() => {
    if (slots.length > 0) {
      setValueSelectAccordion([...slots].map(slot => slot.partecipateMode));
      const availableSlotTime = slots.reduce((acc, val) => {
        const startTime = moment(val.startDateTimestamp);
        const endTime = moment(val.endDateTimestamp);
        const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
        return acc - durationInMinutes;
      }, duration);
      setTotalAvailableSlotTime(availableSlotTime);
    } else {
      setTotalAvailableSlotTime(duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots, vocabulary]);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const convertUrlParticipate = link => (link.indexOf('http') < 0 ? `https:\\\\${link}` : link);

  const getSlotTitle = (slot, id, role_type) => (
    <SlotTitle arabic={isArabic()}>
      <Arial vocabulary="slotTitle" type="slotTitle">
        {isArabic() ? (
          `${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')} °${id}`
        ) : (
          `${id}° ${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')}`
        )}
      </Arial>
      {slot && (
        <Arial vocabulary="slotTitleSchedule">
          <Arial vocabulary="slotDate" type="slotDate">{dateFormatCalendar(slot?.startDateTimestamp)}</Arial>
          <Arial vocabulary="scheduledTime" type="scheduledTime">{`${moment(new Date(slot?.startDateTimestamp)).format('h:mm A')} - ${moment(new Date(slot?.endDateTimestamp)).format('h:mm A')}`}</Arial>
        </Arial>
      )}
      {
        role_type === 'tutor' && !slot
        && (
          <Arial vocabulary="waitingScheduleLabel" type="waitingScheduleLabel">{getValueVocabulary(vocabulary.waitingScheduleLabel, 'waitingScheduleLabel')}</Arial>
        )
      }
    </SlotTitle>
  );

  const selectAccordionOptions = [
    {
      value: 'remote',
      label: 'Remotely',
    },
    {
      value: 'presence',
      label: 'In Attendance',
    },
  ];

  const btnParticipateMessageDisabled = (slot) => {
    if (!slot) return false;
    const { startDateTimestamp, endDateTimestamp } = slot;
    const start = moment(new Date(startDateTimestamp)).subtract(2, 'hours');
    const end = moment(new Date(endDateTimestamp)).add(2, 'hours');
    return moment(new Date()).isBetween(start, end);
  };

  const btnShareResultDisabled = (slot) => {
    const { endDateTimestamp } = slot;
    return !moment(new Date()).isAfter(moment(new Date(endDateTimestamp)));
  };

  const onChangeRadioPaticipationMode = (value, idSlot, index) => {
    const newValueSelectAccordion = [...valueSelectAccordion];
    newValueSelectAccordion[index] = value;
    const handleOnSubmit = () => {
      setTojSessionMode({
        idSlot, mode: value, tojId,
      });
      setValueSelectAccordion(newValueSelectAccordion);
    };
    const modePartecipateConference = { remote: 'Remotely', presence: 'In Attendance', reschedule: 'Reschedule' };
    modalPartecipateConference({ handleOnSubmit, mode: modePartecipateConference[value] });
  };

  const handleOnchangeLikeDislike = (value) => {
    setTojFeedback({ feedback_value: value, id: tojId, tutee_id });
  };

  const handleOnSchedule = (slot, index, isLastSession) => {
    modalSchedule({
      tojId, slots, slotSize, slot, index, totalAvailableSlotTime, isLastSession, deadline,
    });
  };

  const getTojSessionsDuration = (slot) => {
    const startDate = new Date(slot.startDateTimestamp);
    const endDate = new Date(slot.endDateTimestamp);
    const isSameDay = moment(startDate).isSame(endDate, 'day');
    if (isSameDay) {
      if (isArabic()) {
        return (`${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')} | ${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))}`);
      }
      return (`${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} | ${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`);
    }
    if (isArabic()) {
      return `${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')} | ${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} - ${moment(endDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))}`;
    }
    return `${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} - ${moment(endDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} | ${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`;
  };

  return (
    <TojWrapper arabic={isArabic()}>
      { !contentNotAvailable ?
        <React.Fragment>
          <TojBanner>
            <Background img={heroImage || cover} />
            <TojBannerContainer hasSlot={!!slots.length}>
              <BackButton url={`${routes.learningevents.path}${parseQueryParams({ selectedDate })}`} />
              {!!slots.length && (
                <SessionDuration>
                  {
                    slots.map((slot, index) => <Arial key={`session-duration-${index}`} type="tojSessionDuration">{getTojSessionsDuration(slot)}</Arial>)
                  }
                </SessionDuration>
              )}
              <Arial vocabulary="tojDetailsTile" type="tojDetailsTile" configuration={{ size: isMobile ? 35 : 50 }}>{title} {completed ? <IconWrapper><IconCompleted style={{ fontSize: 30 }} className="icon-completato" /></IconWrapper> : null}</Arial>
              <TojBannerInfo>
                <Arial type="tojName" vocabulary="tojName">
                  {isArabic() ? (
                    <span>
                      {slotSize > 1 ? `${slotSize} ${getValueVocabulary(vocabulary.sessionsLabel, 'sessionsLabel')}` : `${slotSize} ${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')}`} | {durationFormatHour(duration)} | {getValueVocabulary(vocabulary.tojName, 'tojName')}
                    </span>
                  ) : (
                    <span>
                      {getValueVocabulary(vocabulary.tojName, 'tojName')} | {durationFormatHour(duration)} | {slotSize > 1 ? `${slotSize} ${getValueVocabulary(vocabulary.sessionsLabel, 'sessionsLabel')}` : `${slotSize} ${getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')}`}
                    </span>
                  )}
                  {/* {getValueVocabulary(vocabulary.tojName, 'tojName')} | <span>{durationFormatHour(duration)} | </span> {slots?.length !== 1 ? <span>{slots?.length} {getValueVocabulary(vocabulary.sessionsLabel, 'sessionsLabel')} </span> : <span>{slots?.length} {getValueVocabulary(vocabulary.sessionLabel, 'sessionLabel')}</span> } */}
                </Arial>
                {!completed &&
                <>
                  {!!slots.length && Number(slotSize) !== slots.length && (
                    <SessionsStatus>
                      {`${getValueVocabulary(vocabulary.firstSessionScheduled, 'firstSessionScheduled')}`}
                    </SessionsStatus>
                  )}
                  {Number(slotSize) === slots.length && (
                    <SessionsStatus>
                      {`${getValueVocabulary(vocabulary.scheduled, 'scheduled')}`}
                    </SessionsStatus>
                  )}
                </>
                }
              </TojBannerInfo>
            </TojBannerContainer>
          </TojBanner>
          <TojContentContainer>
            <TabsContainer>
              <Tabs tabs={[...defaultTabs]} onChange={onChangeTab} selected={activeTab} tabType={Tabs.TYPE.PRIMARY} classList="toj" />
            </TabsContainer>
            {activeTab.value === 'overview' && (
              <OverviewContainer>
                <Row className={isArabic() ? 'd-flex flex-row-reverse' : ''}>
                  <Col lg={mediaIsLaptop ? 12 : 8} md={12} sm={12}>
                    <Text>
                      <Arial type="tojTitle" vocabulary="tojTitle">{getValueVocabulary(vocabulary.tojTitle, 'tojTitle')}</Arial>
                      <Arial type="tab"><HtmlRaw html={goal?.description} /></Arial>
                    </Text>
                    <AgendaTimeline
                      // timeline={agenda}
                      descriptionAgenda={descriptionAgenda}
                      vocabularyKey="agendaListToj"
                      classList="toj"
                    />
                    {sections?.length > 0 && (
                      <SectionContainer>
                        {sections?.map((section, index) => (
                          <Text key={`section-text-${index}`}>
                            <Title>
                              <Arial type="tojTitle">{section?.title}</Arial>
                            </Title>
                            <Arial type="agendaTimelineDescription">
                              {' '}
                              <span
                                dangerouslySetInnerHTML={{ __html: section?.description }}
                              />
                            </Arial>
                          </Text>
                        ))}
                      </SectionContainer>
                    )}
                    {role === 'tutee' && (
                      <SlotContainer>
                        {
                        // eslint-disable-next-line prefer-spread
                          Array.apply(null, { length: slotSize }).map((e, i, array) => {
                            const slot = slots[i] || null;
                            const isPlannable = i === 0 || !!slots[i - 1];
                            return (
                              <React.Fragment key={`slot-${i}`}>
                                <SlotSingle isScheduled={!!slot}>
                                  <HeaderWrap style={isArabic() ? { flexDirection: 'row-reverse' } : {}}>
                                    <InfoSession>
                                      {getSlotTitle(slot, i + 1, role)}
                                    </InfoSession>
                                    {!slot?.completed && (
                                      <ButtonScheduleContainer>
                                        <Button
                                          btnText={slot
                                            ? `${getValueVocabulary(vocabulary.buttonReschedule, 'buttonReschedule')}`
                                            : `${getValueVocabulary(vocabulary.buttonSchedule, 'buttonSchedule')}`}
                                          onClick={() => handleOnSchedule(slot, i, i === array.length - 1)}
                                          disabled={!isPlannable || slot?.showSurvey}
                                          type="eventsCourses"
                                        />
                                      </ButtonScheduleContainer>
                                    )}
                                    {slot?.completed && slot?.partecipateMode && (
                                      <InfoParticipationContainer>
                                        <InfoParticipation isLabel>
                                          <Arial vocabulary="infoParticipationLabel" type="infoParticipationLabel">
                                            {getValueVocabulary(vocabulary.participationSelectedLabel, 'participationSelectedLabel')}
                                          </Arial>
                                        </InfoParticipation>
                                        <InfoParticipation>
                                          <Arial vocabulary="infoParticipationValue" type="infoParticipationValue">
                                            {getValueVocabulary(vocabulary[`${slot?.partecipateMode}ParticipationLabel`], `${slot?.partecipateMode}ParticipationLabel`)}
                                          </Arial>
                                        </InfoParticipation>
                                        {slot?.completed && (
                                          <Icon className="icon-check" />
                                        )}
                                      </InfoParticipationContainer>
                                    )}
                                  </HeaderWrap>
                                  {!slot?.completed && (
                                    <Body>
                                      <QuestionJoin>
                                        {getValueVocabulary(vocabulary.joinToj, 'joinToj')}
                                      </QuestionJoin>
                                      <SelectParticipateWrapper>
                                        {selectAccordionOptions.map((option, index) => (
                                          <RadioButton
                                            key={`slot-${i}-radio-${index}`}
                                            name={`radio-participation-${i}`}
                                            label={option.label}
                                            value={option.value}
                                            checked={option.value === valueSelectAccordion[i]}
                                            disabled={!slot?.canModifyPartecipateMode}
                                            onChange={slot?.canModifyPartecipateMode ? () => onChangeRadioPaticipationMode(option.value, slot?.idSlot, i) : null}
                                            isToj
                                          />
                                        ))}
                                      </SelectParticipateWrapper>
                                    </Body>
                                  )}
                                </SlotSingle>
                                {slot && (
                                  <SlotSingleActionButtons>
                                    {(valueSelectAccordion[i] === 'remote') && (
                                      <ButtonContainer>
                                        <Link target="_blank" href={slot?.conferenceLink ? convertUrlParticipate(slot?.conferenceLink) : ''} disabled={!slot?.canPartecipateConference}>
                                          {getValueVocabulary(vocabulary.joinButtonLabel, 'joinButtonLabel')}
                                        </Link>
                                        {!slot.completed &&
                                        <ParticipateBtnMessage>
                                          {slot?.canPartecipateConference && <Arial type="agendaTimelineDescription" vocabulary="TOJSessionParticipateActiveSlot">{getValueVocabulary(vocabulary.TOJSessionParticipateBtnMsgSlot, 'TOJSessionParticipateBtnMsgSlot')}</Arial>}
                                          {!slot?.canPartecipateConference && !btnParticipateMessageDisabled(slot) && <Arial type="agendaTimelineDescription" vocabulary="TOJSessionParticipateDisabledSlot">{getValueVocabulary(vocabulary.TOJSessionParticipateDisabledSlot, 'TOJSessionParticipateDisabledSlot')}</Arial>}
                                        </ParticipateBtnMessage>
                                        }
                                      </ButtonContainer>
                                    )}
                                    <StudentSurvey>
                                      <Button
                                        type={!slot?.showSurvey || btnShareResultDisabled(slot) ? 'eventsCourses' : Button.TYPE.PRIMARY}
                                        btnText={getValueVocabulary(vocabulary.surveySessionTitle, 'surveySessionTitle')}
                                        onClick={() => {
                                          pushUrl(`${routes.toj.path}/${tojId}/surveySession${parseQueryParams({ idSurvey: slot?.idSurvey, idSlot: slot?.idSlot, tutee_id, selectedDate, role, deadline })}`);
                                        }}
                                        height={40}
                                        disabled={!slot?.showSurvey || btnShareResultDisabled(slot)}
                                      />
                                    </StudentSurvey>
                                  </SlotSingleActionButtons>
                                )}
                              </React.Fragment>
                            );
                          })
                        }
                      </SlotContainer>
                    )}
                    {role === 'tutor' && (
                      <SlotContainer>
                        {
                        // eslint-disable-next-line prefer-spread
                          Array.apply(null, { length: slotSize }).map((e, i) => {
                            const slot = slots[i] || null;
                            return (
                              <React.Fragment key={`slot-${i}`}>
                                <SlotSingle isScheduled>
                                  <HeaderWrap style={isArabic() ? { flexDirection: 'row-reverse' } : {}}>
                                    <InfoSession>
                                      {getSlotTitle(slot, i + 1, role)}
                                    </InfoSession>
                                    {slot?.partecipateMode && (
                                      <InfoParticipationContainer>
                                        <InfoParticipation isLabel>
                                          <Arial vocabulary="infoParticipationLabel" type="infoParticipationLabel">
                                            {getValueVocabulary(vocabulary.participationSelectedLabel, 'participationSelectedLabel')}
                                          </Arial>
                                        </InfoParticipation>
                                        <InfoParticipation isTutor isCompleted={slot?.completed}>
                                          <Arial vocabulary="infoParticipationValue" type="infoParticipationValue">
                                            {getValueVocabulary(vocabulary[`${slot?.partecipateMode}ParticipationLabel`], `${slot?.partecipateMode}ParticipationLabel`)}
                                          </Arial>
                                        </InfoParticipation>
                                        {slot?.completed && (
                                          <Icon className="icon-check" />
                                        )}
                                      </InfoParticipationContainer>
                                    )}
                                  </HeaderWrap>
                                </SlotSingle>
                                {slot && (
                                  <SlotSingleActionButtons>
                                    <ButtonContainer>
                                      <Link target="_blank" href={slot?.conferenceLink ? convertUrlParticipate(slot?.conferenceLink) : ''} disabled={!slot?.canPartecipateConference}>
                                        {getValueVocabulary(vocabulary.joinButtonLabel, 'joinButtonLabel')}
                                      </Link>
                                      <ParticipateBtnMessage>
                                        {slot?.canPartecipateConference && <Arial type="agendaTimelineDescription" vocabulary="TOJSessionParticipateActiveSlot">{getValueVocabulary(vocabulary.TOJSessionParticipateBtnMsgSlot, 'TOJSessionParticipateBtnMsgSlot')}</Arial>}
                                        {!slot?.canPartecipateConference && !btnParticipateMessageDisabled(slot) && <Arial type="agendaTimelineDescription" vocabulary="TOJSessionParticipateDisabledSlot">{getValueVocabulary(vocabulary.TOJSessionParticipateDisabledSlot, 'TOJSessionParticipateDisabledSlot')}</Arial>}
                                      </ParticipateBtnMessage>
                                    </ButtonContainer>
                                  </SlotSingleActionButtons>
                                )}
                              </React.Fragment>
                            );
                          })
                        }
                      </SlotContainer>
                    )}
                  </Col>
                  <Col lg={mediaIsLaptop ? 12 : 4} md={12} sm={12}>
                    {mediaIsTablet || mediaIsLaptop ? (
                      <RightContainer>
                        <RightContainerItems>
                          <InfoContainer>
                            <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                            <InfoDescription><Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>In agreement with your tutor, you can select the mode of the session. If you choose the 'remote' mode, you can agree on the tool to be used . If you prefer Teams, simply connect via the link you find by clicking on the "Join" button. We activate this button 2 hours before the session.</Arial></InfoDescription>
                          </InfoContainer>
                        </RightContainerItems>
                        <RightContainerItems>
                          <TeacherBox className="teacher-box" style={{ width: '300px' }} tutor={tutors.length ? tutors : tutee} />
                          {user_type === 'student'
                            && completed
                            // && feedback && Object.keys(feedback).length > 0
                            && (
                              <LikeDislikeBox
                                countDislike={feedback?.negative}
                                countLike={feedback?.positive}
                                disabled={role !== 'tutee'}
                                isLiked={feedback ? feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1 : null}
                                title={role === 'tutee' ? 'TojLikeDislikeBoxTutee' : 'TojLikeDislikeBoxTutor'}
                                onChange={handleOnchangeLikeDislike}
                              />
                            )}
                        </RightContainerItems>
                      </RightContainer>
                    ) : (
                      <>
                        {((role === 'tutor' && !!tutee.length) || (role === 'tutee' && !!tutors.length)) && <TeacherBox tutor={role === 'tutor' ? tutee : tutors} role={role} />}
                        <InfoContainer>
                          <InfoTitle>
                            <Arial type="author" configuration={{ textTransform: 'none' }} vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial>
                          </InfoTitle>
                          <InfoDescription>
                            <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>In agreement with your tutor, you can select the mode of the session. If you choose the 'remote' mode, you can agree on the tool to be used . If you prefer Teams, simply connect via the link you find by clicking on the "Join" button. We activate this button 2 hours before the session.
                            </Arial>
                          </InfoDescription>
                        </InfoContainer>
                        {user_type === 'student'
                          && completed
                          // && feedback && Object.keys(feedback).length > 0
                          && (
                            <LikeDislikeBox
                              countDislike={feedback?.negative}
                              countLike={feedback?.positive}
                              disabled={role !== 'tutee'}
                              isLiked={feedback ? feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1 : null} // not sure how the numbers map to undefined, true, false
                              title={role === 'tutee' ? 'TojLikeDislikeBoxTutee' : 'TojLikeDislikeBoxTutor'}
                              onChange={handleOnchangeLikeDislike}
                            />
                          )}
                      </>
                    )}
                  </Col>
                </Row>
              </OverviewContainer>
            )}
            {activeTab.value === 'materials' && (
              <MaterialWrapper>
                <GuidebookListColumn md={12} sm={12} lg={12}>
                  <RowGuidebook>
                    {(materials || []).map((singleMaterials, i) => (
                      <GuidebookItemColumn key={`guidebook-${i}`}>
                        <GuideboookCard guidebook={singleMaterials} />
                      </GuidebookItemColumn>
                    ))}
                  </RowGuidebook>
                </GuidebookListColumn>
              </MaterialWrapper>
            )}
          </TojContentContainer>
          <ModalPartecipateConference />
          <ModalSchedule />
        </React.Fragment> :
        <CourseNotAvailable>
          <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="contentNotAvailableMessage">
            {getValueVocabulary(vocabulary.contentNotAvailableMessage, 'contentNotAvailableMessage')}
          </Arial>
        </CourseNotAvailable>
      }
    </TojWrapper>
  );
};


Toj.propTypes = {
  heroImage: PropTypes.string,
  tojId: PropTypes.string.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,
  // HOC (connect, dispatch)
  getTojContent: PropTypes.func.isRequired,
  setTojSessionMode: PropTypes.func.isRequired,
  setTojFeedback: PropTypes.func.isRequired,
  modalPartecipateConference: PropTypes.func.isRequired,
  modalSchedule: PropTypes.func.isRequired,
  pushUrl: PropTypes.func,
  // HOC (connect, state)
  selected_toj: PropTypes.object,
  contentNotAvailable: PropTypes.bool,
  user_type: PropTypes.string,
  location: PropTypes.object,
  vocabulary: PropTypes.object,
};

export default connect(
  state => {
    const { selected_toj, contentNotAvailable } = state.toj;
    const { vocabulary } = state.app;
    const { location } = state.router;
    const {
      data: { user_type },
    } = state.user;
    return {
      selected_toj,
      contentNotAvailable,
      user_type,
      vocabulary,
      location,
    };
  },
  dispatch => ({
    getTojContent: (filters) => dispatch({ type: TOJ_CONTENT_GET._REQUEST, filters }),
    setTojSessionMode: ({ idSlot, mode, tojId }) => dispatch({
      type: TOJ_SESSION_MODE._REQUEST, idSlot, mode, tojId,
    }),
    setTojFeedback: (payload) => dispatch({ type: TOJ_LIKE_DISLIKE._REQUEST, payload }),
    pushUrl: url => dispatch(push(url)),
    modalPartecipateConference: (data) => dispatch({ type: MODAL_OPEN, id: ModalPartecipateConference.id, data }),
    modalSchedule: (data) => dispatch({ type: MODAL_OPEN, id: ModalSchedule.id, data }),
  })
)(withMediaQueries(Toj));
