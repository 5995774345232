import styled from 'styled-components';

const UserContainer = styled.div`
  width: 100%;
  min-height: 73px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 15px 20px 5px 20px;

  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: calc(33.3% - 5px);

  @media ${props => props.theme.device.tablet} {
    padding-right: 20px;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 5px 15px;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  padding-left: 20px;

  @media ${props => props.theme.device.mobileL} {
    width: 50%;
    padding-left: 0px;
    padding-bottom: 0px;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 10px;

  @media ${props => props.theme.device.tablet} {
    width: 220px;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

const Name = styled.div`
  color: ${props => props.theme.colors.white};
`;

const ImageContainer = styled.div`
  margin-right: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.white};
  padding-bottom: 5px;
`;

export {
  UserContainer,
  Name,
  ImageContainer,
  Title,
  ContentInfo,
  User
};
