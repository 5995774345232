import {
  takeLatest, put, call, select
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_RESET, AUTH_RESET_SET, AUTH_RESET_WITH_TOKEN,
  LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN, AUTH_RESTORE_PASSWORD
} from '../actions';
import auth from '../../api/auth';
import routes from '../../../routes';
import ErrorModal from '../../../ui/components/Modals/ErrorModal';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import ModalConfirmResetPassword from '../../../ui/components/Modals/ModalConfirmResetPassword';

function* authLogin({ params, sso = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { username, password } = params;
    const body = { username, password };
    yield call(auth.logout); // monkey-patch to remove drupal's session cookie
    const { data: { data: { csrf_token: token, force_password_reset: toReset } = {} } = {} } = yield call(auth.login, body);
    if (toReset) {
      yield put({ type: AUTH_RESET_SET, params, token });
      yield put(push(routes.resetAuth.path));
    } else {
      yield put({ type: AUTH_LOGIN._SUCCESS, token, sso });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authLogin', err);
    const { vocabulary } = yield select(state => state.app);
    yield put({ type: MODAL_OPEN, id: ErrorModal.id, data: { title: getValueVocabulary(vocabulary.authErrorTitle, 'authErrorMessage'), message: getValueVocabulary(vocabulary.authErrorMessage, 'authErrorMessage') } });
    yield put({ type: AUTH_LOGIN._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authLoginWatch() {
  yield takeLatest(AUTH_LOGIN._REQUEST, authLogin);
}

function* authReset({ params: { old_password, new_password } = {} }) {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.reset, { old_password, new_password });
    yield put({ type: AUTH_LOGOUT._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authReset', err);
    yield put({ type: AUTH_RESET._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authResetWatch() {
  yield takeLatest(AUTH_RESET._REQUEST, authReset);
}

function* authRestorePassword({ params: { email } = {} }) {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.restorePassword, { email });
    yield put({ type: AUTH_RESTORE_PASSWORD._SUCCESS });
    yield put({ type: MODAL_OPEN, id: ModalConfirmResetPassword.id });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authRestorePassword', err);
    yield put({ type: AUTH_RESTORE_PASSWORD._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authRestorePasswordWatch() {
  yield takeLatest(AUTH_RESTORE_PASSWORD._REQUEST, authRestorePassword);
}

function* authResetWithToken({ params: { token, new_password } = {} }) {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.resetWithToken, { token, password: new_password });
    yield put({ type: AUTH_LOGOUT._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authResetWithToken', err);
    yield put({ type: AUTH_RESET_WITH_TOKEN._ERROR, response: err });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authResetWithTokenWatch() {
  yield takeLatest(AUTH_RESET_WITH_TOKEN._REQUEST, authResetWithToken);
}

function* authLogout() {
  try {
    yield put({ type: LOADER_OPEN });
    yield call(auth.logout);
    yield put({ type: AUTH_LOGOUT._SUCCESS });
    yield put(push(routes.auth.path));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > auth > authLogout', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authLogoutWatch() {
  yield takeLatest(AUTH_LOGOUT._REQUEST, authLogout);
}

export default [
  authLoginWatch(),
  authLogoutWatch(),
  authResetWatch(),
  authResetWithTokenWatch(),
  authRestorePasswordWatch()
];
