import styled, { css } from 'styled-components';
import Tabs from './index';

export const TabItem = styled.div``;

const funzioneCalcoloStile = ({ theme, tabType, color }) => {
  let style;
  switch (tabType) {
    case Tabs.TYPE.PRIMARY:
      style = css`
        ${TabItem} {
          position: relative;
          height: 40px;
          width: 230px;
          padding: 12px;
          display: inline-block;
          border: solid 1px #ffffff;
          background-color: rgba(0, 0, 0, 0.3);
          color: ${theme.colors.white};
          cursor: pointer;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }

          &.active {
            border: 1px solid ${theme.colors.white};
            background-color: rgba(255, 255, 255, 0.2);
            color: ${theme.colors.white};
          }

          &.remoteClassroom {
            &.active {
              border: 1px solid ${theme.colors.primaryThemeBlue};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryThemeBlue};
            }
          }

          &.toj {
            &.active {
              border: 1px solid ${theme.colors.primaryThemeBlue};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryThemeBlue};
            }
          }
        }
      `;
      break;
    case Tabs.TYPE.SECONDARY:
    default:
      style = css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        overflow: auto;
        padding-bottom: 7px;
        flex-wrap: wrap;
        overflow: hidden;

        @media ${props => props.theme.device.mobileL} {
          flex-wrap: nowrap;
          overflow: auto;
        }

        ${TabItem} {
          position: relative;
          display: inline-block;
          cursor: pointer;
          width: fit-content;
          margin-top: 10px;
          padding: 10px 0;
          padding-right: 70px;
          white-space: nowrap;
          z-index: 1;

          &:before {
            content: '';
            display: block;
            width: 100vw;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -1px;
            background-color: ${theme.colors.gray};
            z-index: 0;

            @media ${props => props.theme.device.mobileL} {
              width: 100%;
            }
          }

          &:after {
            content: '';
            display: block;
            width: calc(100% - 70px);
            height: 5px;
            position: absolute;
            left: 0;
            bottom: -3px;
            background-color: ${theme.colors.transparent};
            transition: ${theme.constants.transitionBase};
          }

          &.active {
            &:after {
              background-color: ${color || theme.colors.white};
            }
          }
        }
      `;
      break;
    case Tabs.TYPE.MANDATORY:
      style = css`
        ${TabItem} {
          position: relative;
          height: 40px;
          width: 230px;
          padding: 12px;
          display: inline-block;
          border: solid 1px #ffffff;
          background-color: rgba(0, 0, 0, 0.3);
          color: ${theme.colors.white};
          cursor: pointer;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }

          &.active {
            border: 1px solid ${theme.colors.chartreuse};
            background-color: rgba(255, 255, 255, 0.2);
            color: ${theme.colors.chartreuse};
          }

          &.remoteClassroom {
            &.active {
              border: 1px solid ${theme.colors.primaryThemeBlue};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryThemeBlue};
            }
          }

          &.toj {
            &.active {
              border: 1px solid ${theme.colors.primaryThemeBlue};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryThemeBlue};
            }
          }
        }
      `;
      break;
    case Tabs.TYPE.OPTIONALCOURSES:
      style = css`
        ${TabItem} {
          position: relative;
          height: 40px;
          width: 230px;
          padding: 12px;
          display: inline-block;
          border: solid 1px #ffffff;
          background-color: rgba(0, 0, 0, 0.3);
          color: ${theme.colors.white};
          cursor: pointer;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }

          &.active {
            border: 1px solid ${theme.colors.yellowOrange};
            background-color: rgba(255, 255, 255, 0.2);
            color: ${theme.colors.yellowOrange};
          }

          &.remoteClassroom {
            &.active {
              border: 1px solid ${theme.colors.primaryYellow};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryYellow};
            }
          }

          &.toj {
            &.active {
              border: 1px solid ${theme.colors.primaryThemeBlue};
              background-color: rgba(255, 255, 255, 0.15);
              color: ${theme.colors.primaryThemeBlue};
            }
          }
        }
      `;
      break;
  }

  return style;
};

export const TabsContainer = styled.div`
  ${props => funzioneCalcoloStile(props)};
`;
