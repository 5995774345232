import styled, { css } from 'styled-components';

export const LineBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #575a64;
`;

export const PercentageLine = styled.span`
  position: absolute;
  top: -1px;
  left: 0;
  width: ${props => (props.percentage > 0 ? `${props.percentage}%` : 0)};
  height: 6px;
  border-radius: 3px;
  background-color: ${props => (props.state === 'mandatory_training' ? props.theme.colors.chartreuse : props.theme.colors.yellowOrange)};
`;

export const Percentage = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: ${props => props.theme.colors.greyLighter};
  padding-left: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ arabic }) => arabic && css`
    ${PercentageLine} {
      left: auto;
      right: 0;
      transform: rotate(180deg);
    }
  `}
`;
