import axios from 'axios';
import { store } from '../redux/store';
import { AUTH_LOGOUT } from '../redux/actions';

const isLocalHost = window.location.hostname === 'localhost';

const ENV_BASE_URL = {
  DEV: 'https://devapp.lea.isplearning.com',
  SIT: null,
  PRE_PROD: '',
  PROD: 'https://be-lea.isplearning.com',

  DEFAULT: `${window.location.origin}`
};

export const apiHost = ENV_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_URL.DEFAULT;

export const baseUrl = '/api/';
export const extendUrl = 'rest_api/';

const checkForLocalhostRules = (url = '') => {
  if (isLocalHost) {
    const baseurl = `http://${window.location.hostname}`;
    const port = `:${url.indexOf('/sso/auth') !== -1 ? '3000' : '2222'}`;
    return `${baseurl}${port}${url}`;
  }
  return `${apiHost}${url}`;
};

const axiosApi = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosApi.interceptors.request.use(req => {
  const CSRF_Token = sessionStorage.getItem('TMP-X-CSRF-Token') || localStorage.getItem('X-CSRF-Token');
  if (CSRF_Token) req.headers['X-CSRF-Token'] = CSRF_Token;
  if (sessionStorage.getItem('TMP-X-CSRF-Token')) sessionStorage.removeItem('TMP-X-CSRF-Token');
  return req;
});

axiosApi.interceptors.response.use(
  res => res,
  error => {
    if (error.response && Number(error.response.status) === 403) {
      store.dispatch({ type: AUTH_LOGOUT._REQUEST });
    } else if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export function apiCall(url, method, body = null, options = null) {
  return axiosApi[method.toLowerCase()](checkForLocalhostRules(url), body, options);
}
