import produce from 'immer';
import { REMOTE_CLASSROOM_CONTENT_GET, REMOTE_CLASSROOM_COMMENTS_GET } from '../actions/remoteclassroom';

const initialState = {
  selected_classroom: {},
  comments: [],
  contentNotAvailable: false
};

const remoteClassroomReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case REMOTE_CLASSROOM_CONTENT_GET._SUCCESS: {
      const { response } = action;
      draft.selected_classroom = response;
      break;
    }
    case REMOTE_CLASSROOM_CONTENT_GET._REQUEST: {
      draft.contentNotAvailable = false;
      break;
    }
    case REMOTE_CLASSROOM_CONTENT_GET._ERROR: {
      draft.contentNotAvailable = false;
      break;
    }
    case REMOTE_CLASSROOM_COMMENTS_GET._SUCCESS: {
      const { comments } = action;
      draft.comments = comments;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default remoteClassroomReducer;
