import React from 'react';
import PropTypes from 'prop-types';

import {
  GuidebookCardContainer, Guidebook, Date, Title,
  IconAttachment, GuidebookCardContent,
} from './style';
import Arial from '../../typography/arial';
import dateFormat from '../../../utils/date-format';
import { isArabic } from '../../../utils/locale';

const GuidebookCard = ({
  guidebook,
}) => {
  const { url } = guidebook?.data?.file_download ? guidebook?.data?.file_download[0] : guidebook?.data?.file ? guidebook?.data?.file[0] : {};

  return (
    <GuidebookCardContainer arabic={isArabic()}>
      <Guidebook href={url || guidebook?.url} target="_blank" download />
      <IconAttachment className="document">
        <span className="icon-right" />
      </IconAttachment>
      <GuidebookCardContent>
        {guidebook?.data?.date && <Date><Arial type="guidebookCardDate">{dateFormat(guidebook?.data?.date, 'news')}</Arial></Date>}
        {(guidebook?.data?.title || guidebook?.title) && <Title><Arial type="guidebookCardAttachment">{guidebook?.data?.title || guidebook?.title}</Arial></Title>}
        {/* {(guidebook?.data?.description || guidebook?.description) && <Description><Arial type="guidebookCardText">{guidebook?.data?.description || guidebook?.description}</Arial></Description>} */}
      </GuidebookCardContent>
      <IconAttachment arabic={isArabic()} className="right" position="right" icon="download">
        <span className="icon-download" />
      </IconAttachment>
    </GuidebookCardContainer>
  );
};

GuidebookCard.propTypes = {
  guidebook: PropTypes.object,
};

export default GuidebookCard;
