import styled, { css } from 'styled-components';

const Guidebook = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Date = styled.div`
  color: ${props => props.theme.colors.brownGrey};
`;

const Title = styled.div`
  margin: 5px 0;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Description = styled.div`
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconAttachment = styled.div`
  margin: 0 20px;
  font-size: 28px;

  &.document {
    color: #575a64;
    display: flex;
  }

  margin-left: ${props => (props.position === 'right' ? 'auto' : '')};
  span {
    font-size: ${props => (props.icon === 'download' ? '14px' : '28px')};
  }

  ${({ arabic }) => arabic && css`
    margin-left: 0;
    padding: 0 20px;
    margin-right: ${props => (props.position === 'right' ? 'auto' : '')};
  `}
`;

const GuidebookCardContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 60px;
`;

const GuidebookCardContainer = styled.div`
  position: relative;
  height: 81px;
  padding: 10px 0 15px;
  background-color: #1f2025;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  transition: ${props => props.theme.constants.transitionBase};

  &:hover {
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.6)};
  }

  ${({ arabic }) => arabic && css`
    flex-direction: row-reverse;
  `}
`;

export {
  GuidebookCardContainer, Guidebook, Date, Title, Description, IconAttachment, GuidebookCardContent
};
