import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { isTablet } from 'react-device-detect';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer,
  Background,
  HeaderWrapper,
  Question,
  Title,
  QuestionContainer,
  ButtonContainer,
} from './style';
import Arial from '../../../ui/typography/arial';
import {
  BackButton, Button, SurveyLikeDislike,
} from '../../../ui/atoms';
import RadioButtonSurvey from '../../../ui/atoms/RadioButtonSurvey';
import SurveyComments from '../../../ui/components/SurveyComments';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import routes from '../../../routes';
import { isValidForm, newForm, newFormField } from '../../../utils/form';
import Stars from '../../../ui/components/Stars';
import { TOJ_SET_INSERT_SURVEY_SESSION, TOJ_SURVEY_SESSION_CONTENT_GET } from '../../redux/actions/tojsurvey';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import ModalSurveyConfirmation from '../../../ui/components/Modals/ModalSurveyConfirmation';
import { parseQueryParams } from '../../../utils/queryParams';
// import { ButtonContainer } from '../../../ui/components/QuestionBox/style';
import CheckboxSurvey from '../../../ui/atoms/CheckboxSurvey';
import HtmlRaw from '../../../ui/atoms/HtmlRaw';
import { isArabic } from '../../../utils/locale';

const TojSurveySession = ({
  heroImage,
  selected_survey_session,
  getTojSurveySessionContent,
  location,
  pushUrl,
  tojId,
  setSurveyInsertSession,
  vocabulary,
  selected_toj,
}) => {
  const {
    idSurvey, selectedDate, idSlot, tutee_id, role, deadline,
  } = queryString.parse(location.search);
  const [form, setForm] = useState({});
  const [survey, setSurvey] = useState({});
  const [formReduce, setFormReduce] = useState([2, 3]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSurvey(selected_survey_session);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_survey_session]);

  const { questions = [] } = survey;

  const initialForm = newForm(
    questions.map((question, index) => newFormField({
      field: `survey-${index}-${question?.idQuestion}`,
      required: true,
      survey: question,
    }))
  );

  useEffect(() => {
    setForm(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  useEffect(() => {
    if (!idSurvey && tojId) {
      pushUrl(`${routes.toj.path}/${tojId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, tojId, idSurvey, pushUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idSurvey) {
      getTojSurveySessionContent({ id: idSurvey, toj_id: tojId, idSlot });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSurvey, getTojSurveySessionContent]);

  const handleOnChange = (field = '', newValue, type, index, indexRadio) => {
    const { required, value = [] } = form[field];
    switch (type) {
      case 'checkbox': {
        const checkboxValue = value && [...value].indexOf(newValue) >= 0
          ? [...value].filter(val => val !== newValue)
          : value
            ? [...value, newValue]
            : [newValue];

        if (index === 1) {
          const thirdQuestionKey = Object.keys(form)[2];
          const thirdQuestionAnswers = form[thirdQuestionKey].value || [];
          const thirdQuestionValue = thirdQuestionAnswers.filter(answer => checkboxValue.includes(answer));
          setForm({
            ...form,
            [field]: {
              ...form[field],
              value: checkboxValue.length ? checkboxValue : null,
              valid: required ? !!checkboxValue.length : true,
            },
            [thirdQuestionKey]: {
              ...form[thirdQuestionKey],
              value: thirdQuestionValue.length ? thirdQuestionValue : null,
              valid: required ? !!checkboxValue.length : true,
            },
          });
        } else {
          setForm({
            ...form,
            [field]: {
              ...form[field],
              value: checkboxValue.length ? checkboxValue : null,
              valid: required ? !!checkboxValue.length : true,
            },
          });
        }

        break;
      }
      default:
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: newValue,
            valid: required ? !!newValue : true,
          },
        });
        break;
    }

    if (index === 1 && indexRadio !== 1) {
      setFormReduce([2]);
    } else if (index === 1 && indexRadio === 1) {
      setFormReduce([]);
    }
  };

  const getQuestionType = (key, questionItem, index) => {
    const {
      idQuestion,
      questionType,
      minLabel,
      maxLabel,
      maxValue,
      answers,
    } = questionItem;

    const checkboxDisabled = (val) => {
      if (index === 2) {
        const firstQuestionKey = Object.keys(form)[0];
        const firstQuestionAnswers = form[firstQuestionKey].value || [];
        return !firstQuestionAnswers.includes(val);
      }
      return false;
    };

    switch (questionType) {
      case 'question_evaluation': {
        return (
          <Stars onClick={(value) => handleOnChange(key, value, '', index)} stars={maxValue} minLabel={minLabel} maxLabel={maxLabel} />
        );
      }

      case 'question_single': {
        return answers.map((answer, indexRadio) => (
          <RadioButtonSurvey
            key={`answer--radio--${indexRadio}`}
            label={answer.answer}
            name={`radio-${idQuestion}`}
            value={answer.answer}
            onChange={value => handleOnChange(key, value, '', index, indexRadio)}
          />
        ));
      }
      case 'question_multiple': {
        return answers.map((answer, indexAnswer) => (
          <QuestionContainer key={`answer--${indexAnswer}`}>
            <CheckboxSurvey
              label={answer.answer}
              name={`checkbox-${idQuestion}`}
              value={answer.answer}
              onChange={value => handleOnChange(key, value, 'checkbox', index)}
              checked={form[key]?.value?.includes(answer.answer)}
              disabled={checkboxDisabled(answer.answer)}
            />
          </QuestionContainer>

        ));
      }
      case 'question_open': {
        return (
          <SurveyComments onChange={value => handleOnChange(key, value, '', index)} value={form[key].value} />
        );
      }
      case 'question_like_dislike': {
        return (<SurveyLikeDislike onChange={value => handleOnChange(key, value, '', index)} />);
      }
      default: {
        return 'no field';
      }
    }
  };

  const handleOnSubmit = () => {
    const finalForm = Object.keys(form).filter((key, index) => !formReduce.includes(index)).reduce((acc, key) => ({
      ...acc,
      [key]: { ...form[key] },
    }), {});

    setSurveyInsertSession({
      answers: finalForm,
      idSlot,
    });
  };

  const checkConfirmDisabled = () => {
    const finalForm = Object.keys(form).filter((key, index) => !formReduce.includes(index)).reduce((acc, key) => ({
      ...acc,
      [key]: { ...form[key] },
    }), {});

    return !isValidForm(finalForm);
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage} />
        <Container>
          <Row className={isArabic() && 'flex-row-reverse'}>
            <HeaderWrapper>
              <BackButton url={`${routes.toj.path}/${tojId}${parseQueryParams({ tutee_id, selectedDate, role, deadline })}`} />
              <Arial type="surveySessionTitle"><HtmlRaw html={selected_toj?.title || selected_survey_session.title} /></Arial>
              <Arial type="surveySessionTitleShareResults" vocabulary="surveySessionTitle">{getValueVocabulary(vocabulary.surveySessionTitle, 'surveySessionTitle')}</Arial>
            </HeaderWrapper>
          </Row>
        </Container>
      </HeaderContainer>
      <Container>
        <Row className={isArabic() && 'flex-row-reverse'}>
          <Col lg={isTablet ? 6 : 12} md={12} sm={12}>
            {Object.keys(form).length
              && Object.keys(form).filter((key, index) => !formReduce.includes(index)).map((key, index) => (
                <Row key={`key--${index}`} className={isArabic() && 'flex-row-reverse'}>
                  <Question arabic={isArabic()}>
                    <Title>
                      <Arial type="newsTitle">
                        {form[key].survey.question}
                      </Arial>
                    </Title>
                    {getQuestionType(key, form[key].survey, index)}
                  </Question>
                </Row>
              ))}
            <ButtonContainer arabic={isArabic()}>
              <Button
                type={Button.TYPE.PRIMARY}
                btnText="Confirm"
                height={40}
                size={180}
                onClick={handleOnSubmit}
                disabled={checkConfirmDisabled()}
              />
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
      <ModalSurveyConfirmation messageKey="tojSurveySessionModalMessage" btnKey="tojSurveySessionModalBtn" redirectLink={`${routes.toj.path}/${tojId}${parseQueryParams({ selectedDate, tutee_id, role })}`} />
    </>
  );
};

TojSurveySession.propTypes = {
  tojId: PropTypes.number.isRequired,
  // HOC (connect, dispatch)
  getTojSurveySessionContent: PropTypes.func.isRequired,
  setSurveyInsertSession: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  // HOC (connect, state)
  location: PropTypes.object,
  selected_survey_session: PropTypes.object,
};

export default connect(
  state => {
    const { selected_survey_session } = state.tojsurvey;
    const { location } = state.router;
    const { vocabulary } = state.app;
    const { selected_toj } = state.toj;
    return {
      location,
      selected_survey_session,
      selected_toj,
      vocabulary,
    };
  },
  dispatch => ({
    getTojSurveySessionContent: (filters) => dispatch({
      type: TOJ_SURVEY_SESSION_CONTENT_GET._REQUEST,
      filters,
    }),
    setSurveyInsertSession: ({
      answers, idSlot,
    }) => dispatch({
      type: TOJ_SET_INSERT_SURVEY_SESSION._REQUEST,
      answers,
      idSlot,
    }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(TojSurveySession));
