import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer
} from '../Modal.style';
import { CloseModal, ModalSurveyConfirmationContainer } from './style';
import MenuButton from '../../../atoms/MenuButton';
import { MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import { Button } from '../../../atoms';

const modalId = 'ModalSurveyConfirmation';

const ModalSurveyConfirmation = ({
  onClose, closeIcon, vocabulary, messageKey, redirectLink, pushUrl, btnKey
}) => {
  const handleOnConfirm = () => {
    pushUrl(redirectLink);
    onClose();
  };

  return (
    <Modal id={modalId} onClose={() => console.log('')}>
      <Container>
        {closeIcon && (
          <CloseModal>
            <MenuButton isOpen onClick={() => onClose()} />
          </CloseModal>
        )}
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          widthUnit="%"
          width="50"
          heightUnit="%"
          height="90"
        >
          <ModalSurveyConfirmationContainer>
            <Arial configuration={{ textTransform: 'none', size: 20 }} vocabulary={messageKey}>{getValueVocabulary(vocabulary[messageKey], messageKey)}</Arial>
            <Button btnText={getValueVocabulary(vocabulary[btnKey], btnKey)} onClick={handleOnConfirm} height={40} />
          </ModalSurveyConfirmationContainer>
        </ModalGlobalContainer>
      </Container>
    </Modal>
  );
};

ModalSurveyConfirmation.propTypes = {
  redirectLink: PropTypes.string,
  closeIcon: PropTypes.bool,
  btnKey: PropTypes.string,
  messageKey: PropTypes.string,
  // HOC (connect, state)
  vocabulary: PropTypes.object,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

ModalSurveyConfirmation.id = modalId;

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ModalSurveyConfirmation);
