import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CommentContainer, CommentsContainer, CommentsWrapper,
  CommentWrapper, TextArea, Timestamp, Text, CommentBody,
  CommentInfo, CommentsHeader, ViewMore,
} from './style';
import Arial from '../../typography/arial';
import formatTimestamp from '../../../utils/formatTimestamp';
import { Button, CircleImage } from '../../atoms';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';

const Comments = ({
  list = [], onSubmit, vocabulary, type = '',
}) => {
  const [newComment, setNewComment] = useState('');
  const [viewMore, setViewMore] = useState(false);

  const handleOnchangeNewComment = ({ target: { value } }) => {
    setNewComment(value);
  };

  const handleOnSubmit = ({ key } = {}) => {
    const submit = !key || key === 'Enter';
    if (submit && onSubmit) {
      onSubmit(newComment);
      setNewComment('');
    }
  };

  return (
    <CommentsContainer arabic={isArabic()} viewMore={viewMore || list.length <= 2}>
      <CommentsHeader>
        <Arial configuration={{ size: 10, textTransform: 'uppercase' }}>
          { isArabic() ? (
            `${list?.length > 1 ? getValueVocabulary(vocabulary.comments, 'comments') : getValueVocabulary(vocabulary.comment, 'comment')} ${list?.length}`
          ) : (
            `${list?.length} ${list?.length > 1 ? getValueVocabulary(vocabulary.comments, 'comments') : getValueVocabulary(vocabulary.comment, 'comment')}`
          )}
        </Arial>
        <CommentsWrapper>
          <TextArea onKeyPress={handleOnSubmit} onChange={handleOnchangeNewComment} value={newComment.replace(/\r?\n|\r/, '')} placeholder={getValueVocabulary(vocabulary.commentPlaceholder, 'commentPlaceholder')} />
          {newComment
            && <Button type={type} height={30} btnText={getValueVocabulary(vocabulary.btnSend, 'btnSend')} onClick={handleOnSubmit} />
          }
        </CommentsWrapper>
      </CommentsHeader>
      <CommentContainer viewMore={viewMore}>
        {list.map((item = {}, index) => (
          <CommentWrapper key={`comments${index}`}>
            <CircleImage src={item?.user_picture} name={`${item?.first_name} ${item?.last_name}`} size={40} />
            <CommentBody>
              <CommentInfo>
                <Arial type="newsTitle"><span dangerouslySetInnerHTML={{ __html: `${item?.first_name} ${item?.last_name}` }} /></Arial>
                <Timestamp>
                  <Arial type="newsDate">{formatTimestamp(item?.created * 1000)}</Arial>
                </Timestamp>
              </CommentInfo>
              <Text>
                <Arial type="guidebookCategory"><span dangerouslySetInnerHTML={{ __html: item?.comment_body }} /></Arial>
              </Text>
            </CommentBody>
          </CommentWrapper>
        ))}
      </CommentContainer>
      {list.length > 2 && (
        <ViewMore onClick={() => setViewMore(!viewMore)}>
          {viewMore ? (
            <Arial type="viewMore">{getValueVocabulary(vocabulary.viewLess, 'viewLess')}</Arial>
          ) : (
            <Arial type="viewMore">{getValueVocabulary(vocabulary.viewMore, 'viewMore')}</Arial>
          )}
        </ViewMore>
      )}
    </CommentsContainer>
  );
};

Comments.propTypes = {
  type: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  }
)(Comments);
