import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import Container from './style';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ percentage }) => (
  <Container>
    <CircularProgressbar value={percentage} text={`${percentage}%`} />
  </Container>
);

CircularProgress.propTypes = {
  percentage: PropTypes.number
};

CircularProgress.defaultProps = {
  percentage: 0
};

export default CircularProgress;
