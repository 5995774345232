import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal, { stopEvent } from '../Modal';
import {
  ModalGlobalContainer
} from '../Modal.style';
import {
  ModalBodyContainer, CloseModal, Title,
  Subtitle, InputWrapper, ButtonContainer
} from './style';
import { AUTH_RESTORE_PASSWORD, MODAL_CLOSE } from '../../../../app/redux/actions';
import { Container } from '../../../gridSystem';
import Arial from '../../../typography/arial';
import { getValueVocabulary } from '../../../../utils/getValueVocabulary';
import { getValues, isValidForm, newForm, newFormField } from '../../../../utils/form';
import { RegexpType, validator } from '../../../../utils/validator';
import { Button, CustomInput } from '../../../atoms';

const modalId = 'ModalResetPassword';

const initialForm = newForm([
  newFormField({
    field: 'email',
    required: true,
    type: RegexpType.EMAIL,
    valid: true
  })
]);

const ModalResetPassword = ({ onClose, vocabulary, restorePassword, authError, isPolling }) => {
  const [resetForm, setResetForm] = useState(initialForm);

  useEffect(() => {
    if (isPolling) return
    else if (authError && authError.message === 'Wrong email address') {
      setResetForm({
        ...resetForm,
        email: {
          ...resetForm['email'],
          valid: false,
        }
      });
    } else {
      setResetForm(initialForm);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling])

  const onClickOnClose = () => {
    setResetForm(initialForm);
    onClose();
  };

  const handleOnChange = (field = '', newValue) => {
    const { type, required } = resetForm[field];
    setResetForm({
      ...resetForm,
      [field]: {
        ...resetForm[field],
        value: newValue,
        valid:
          typeof newValue !== 'object'
            ? validator(type, newValue, required)
            : true
      }
    });
  };

  const handleOnSubmit = () => {
    restorePassword(getValues(resetForm));
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          width="680"
          height="334"
        >
          <CloseModal onClick={onClickOnClose} className="icon-close" />
          <ModalBodyContainer>
            <Title><Arial type="forgotPasswordTitle" vocabulary="forgotPasswordTitle">{getValueVocabulary(vocabulary.forgotPasswordTitle, 'forgotPasswordTitle')}</Arial></Title>
            <Subtitle><Arial type="forgotPasswordSubTitle" vocabulary="forgotPasswordSubTitle">{getValueVocabulary(vocabulary.forgotPasswordSubTitle, 'forgotPasswordSubTitle')}</Arial></Subtitle>
            <InputWrapper>
              <CustomInput
                placeholder={getValueVocabulary(vocabulary.emailPlaceholder, 'emailPlaceholder')}
                onChange={newValue => handleOnChange('email', newValue)}
                value={resetForm?.email?.value}
                valid={resetForm?.email?.valid}
              />
            </InputWrapper>
            <ButtonContainer>
              <Button
                height={40}
                size={Button.SIZE.FULL}
                btnText={getValueVocabulary(vocabulary.btnSend, 'btnSend')}
                disabled={!isValidForm(resetForm)}
                onClick={handleOnSubmit}
              />
            </ButtonContainer>
          </ModalBodyContainer>
        </ModalGlobalContainer>
      </Container>
    </Modal>
  );
}

ModalResetPassword.propTypes = {
  // HOC (connect, state)
  authError: PropTypes.string,
  isPolling: PropTypes.bool,
  vocabulary: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  restorePassword: PropTypes.func.isRequired
};

ModalResetPassword.id = modalId;

export default connect(
  state => {
    const { authState: { error: authError, polling: isPolling } = {}, vocabulary } = state.app;
    return {
      authError,
      isPolling,
      vocabulary
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    restorePassword: params => dispatch({ type: AUTH_RESTORE_PASSWORD._REQUEST, params })
  })
)(ModalResetPassword);
