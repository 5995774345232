import React, { } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Arial from '../../typography/arial';
import {
  AgendaTimelineContainer, TimeLineContainer, TimelineItemContainer, TimelineItemContent, Description, EventTitle,
} from './style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';


const AgendaTimeline = ({
  timeline, vocabulary, vocabularyKey,
  descriptionAgenda, classList = '', language,
}) => (
  <AgendaTimelineContainer arabic={isArabic()}>
    <Arial vocabulary={vocabularyKey} type="collectionCardTitle">{getValueVocabulary(vocabulary[vocabularyKey], vocabularyKey)}</Arial>
    {descriptionAgenda && (<Arial type="agendaTimelineDescription">{descriptionAgenda}</Arial>)}
    {/* {timeline?.length > 0 && (
      <TimeLineContainer className={classList}>
        {timeline?.map((item, index) => (
          <TimelineItemContainer key={`agenda-timeline-item-${index}`}>
            <TimelineItemContent>
              <EventTitle className={classList}>
                <Arial type="agendaTimelineTitle"><span dangerouslySetInnerHTML={{ __html: item?.data?.title.replace(/\n/g, '<br>') || item?.title.replace(/\n/g, '<br>') }} /></Arial>

              </EventTitle>
              {(item?.data?.body || item?.description) && (
                <Description><Arial type="agendaTimelineDescription"><span dangerouslySetInnerHTML={{ __html: item?.data?.body.replace(/\n/g, '<br>') || item?.description.replace(/\n/g, '<br>') }} /></Arial></Description>
              )}
            </TimelineItemContent>
          </TimelineItemContainer>
        ))}
      </TimeLineContainer>
    )} */}
  </AgendaTimelineContainer>
);

AgendaTimeline.propTypes = {
  timeline: PropTypes.array,
  // HOC (connect, state)

  // HOC (connect, dispatch)
};


export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  }

)(AgendaTimeline);
