const MainTitle = 'Banca 5';

const ENV_BASE_PATH = {
  DEV: null,
  SIT: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};

const basePath = ENV_BASE_PATH[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const routes = {
  auth: {
    path: `${basePath}auth`,
    title: `Auth - ${MainTitle}`
  },
  resetAuth: {
    path: `${basePath}reset-auth`,
    title: `Reset Auth - ${MainTitle}`
  },
  home: {
    path: `${basePath}`,
    title: `Home - ${MainTitle}`
  },
  maintenance: {
    path: `${basePath}maintenance`,
    title: `${MainTitle}`
  },
  notifications: {
    path: `${basePath}notifications`,
    title: `Notifications - ${MainTitle}`
  },
  favourites: {
    path: `${basePath}favourites`,
    title: `Favorites - ${MainTitle}`
  },
  guidebook: {
    path: `${basePath}guidebook`,
    title: `Manuali - ${MainTitle}`
  },
  learningevents: {
    path: `${basePath}learning-events`,
    title: `Learning Events  - ${MainTitle}`
  },
  remoteclassroom: {
    path: `${basePath}remote-classroom`,
    title: `Aula remota  - ${MainTitle}`
  },
  remoteclassroomsurvey: {
    path: `${basePath}survey`,
    title: `Survey   - ${MainTitle}`
  },
  toj: {
    path: `${basePath}toj`,
    title: `Toj  - ${MainTitle}`
  },
  survey: {
    path: `${basePath}survey`,
    title: `Survey  - ${MainTitle}`
  },
  surveydetails: {
    path: `${basePath}survey/details`,
    title: `Survey - ${MainTitle}`
  },
  faq: {
    path: `${basePath}faq`,
    title: `FAQ - ${MainTitle}`
  },
  classrooms: {
    path: `${basePath}classrooms`,
    title: `Classrooms - ${MainTitle}`
  },
  mandatorytraining: {
    path: `${basePath}mandatory-training`,
    title: `Mandatory Training - ${MainTitle}`
  },
  optionalcourses: {
    path: `${basePath}optional-courses`,
    title: `Optional Courses - ${MainTitle}`
  },
  notFound: {
    path: `${basePath}404`,
    title: 'Not Found'
  },
  logout: {
    path: `${basePath}logout`,
    title: 'Logout'
  },
  search: {
    path: `${basePath}search`,
    title: `Search - ${MainTitle}`
  },
};

export default routes;
