import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { isTablet } from 'react-device-detect';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  HeaderContainer,
  Background,
  HeaderWrapper,
  Question,
  Title,
  QuestionContainer
} from './style';
import Arial from '../../../ui/typography/arial';
import { BackButton, Button, CheckboxSurvey, RadioButtonSurvey, SurveyLikeDislike } from '../../../ui/atoms';
import SurveyComments from '../../../ui/components/SurveyComments';
import {
  REMOTE_CLASSROOM_SET_INSERT_SURVEY,
  REMOTE_CLASSROOM_SURVEY_CONTENT_GET
} from '../../redux/actions/remoteclassroomsurvey';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import routes from '../../../routes';
import { newForm, newFormField } from '../../../utils/form';
import Stars from '../../../ui/components/Stars';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import ModalSurveyConfirmation from '../../../ui/components/Modals/ModalSurveyConfirmation';
import { ButtonContainer } from '../../../ui/components/QuestionBox/style';

const RemoteClassroomSurvey = ({
  heroImage,
  selected_survey,
  getSurveyContent,
  location,
  pushUrl,
  remoteClassroomId,
  setSurveyInsert,
  vocabulary
}) => {
  const { idSurvey } = queryString.parse(location.search);
  const [form, setForm] = useState({});
  const [survey, setSurvey] = useState({});

  useEffect(() => {
    setSurvey(selected_survey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_survey]);

  useEffect(() => {
    const { questions = [] } = survey;

    const initialForm = newForm(
      questions.map((question, index) =>
        newFormField({
          field: `survey-${index}-${question?.idQuestion}`,
          required: true,
          survey: question,
          value:
            index === 0 &&
            form[`survey-${index}-${question?.idQuestion}`]?.value
              ? form[`survey-${index}-${question?.idQuestion}`].value
              : null,
          valid:
            index === 0 &&
            form[`survey-${index}-${question?.idQuestion}`]?.value
              ? true
              : false
        })
      )
    );

    setForm(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  useEffect(() => {
    if (!idSurvey && remoteClassroomId) {
      pushUrl(`${routes.remoteclassroom.path}/${remoteClassroomId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, remoteClassroomId, idSurvey, pushUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idSurvey) {
      getSurveyContent(idSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSurvey, getSurveyContent]);

  const handleOnChange = (field = '', newValue, type) => {
    const { required, value = [] } = form[field];
    switch (type) {
      case 'checkbox': {
        const checkboxValue =
          value && [...value].indexOf(newValue) >= 0
            ? [...value].filter(val => val !== newValue)
            : value
            ? [...value, newValue]
            : [newValue];
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: checkboxValue.length ? checkboxValue : null,
            valid: required ? !!checkboxValue.length : true
          }
        });
        break;
      }
      default:
        setForm({
          ...form,
          [field]: {
            ...form[field],
            value: newValue,
            valid: required ? !!newValue : true
          }
        });
        break;
    }
  };

  const questionType = (key, questionItem) => {
    const {
      idQuestion,
      questionType,
      minLabel,
      maxLabel,
      maxValue,
      answers
    } = questionItem;

    switch (questionType) {
      case 'question_evaluation': {
        return  (
          <Stars onClick={(value) => handleOnChange(key, value)} stars={maxValue} minLabel={minLabel} maxLabel={maxLabel} />
        );
      }

      case 'question_single': {
        return answers.map((answer) => (
          <RadioButtonSurvey
            label={answer.answer}
            name={`radio-${idQuestion}`}
            value={answer.answer}
            onChange={value => handleOnChange(key, value, '')}
          />
        ));
      }
      case 'question_multiple': {
        return answers.map(answer => (
          <QuestionContainer>
            <CheckboxSurvey
              label={answer.answer}
              name={`checkbox-${idQuestion}`}
              value={answer.answer}
              onChange={value => handleOnChange(key, value, 'checkbox')}
            />
          </QuestionContainer>
          
        ));
      }
      case 'question_open': {
        return (
          <SurveyComments onChange={value => handleOnChange(key, value)} />
        );
      }
      case 'question_like_dislike': {
        return (<SurveyLikeDislike onChange={value => handleOnChange(key, value)} />)
      }
      default: {
        return 'no field';
      }
    }
  };

  const handleOnSubmit = () => {
    setSurveyInsert({
      remoteClassroomId,
      answers: form,
      id: survey.idSurvey,
      description: survey?.title
    });
  };

  return (
    <>
      <HeaderContainer>
        <Background img={heroImage} />
        <Container>
          <HeaderWrapper>
            <Container>
              <BackButton url={`${routes.remoteclassroom.path}/${remoteClassroomId}`} />
              <Arial type="newsDetailTitle" vocabulary="surveyTitle">{getValueVocabulary(vocabulary.surveyTitle, 'surveyTitle')}</Arial>
            </Container>
          </HeaderWrapper>
        </Container>
      </HeaderContainer>
      <Container>
        <Row>
          <Col lg={isTablet ? 6 : 12} md={12} sm={12}>
            {Object.keys(form).length &&
              Object.keys(form).map(key => (
                <Row>
                  <Question>
                    <Title>
                      <Arial type="newsTitle">
                        {form[key].survey.question}
                      </Arial>
                    </Title>
                    {questionType(key, form[key].survey)}
                  </Question>
                </Row>
              ))}
              <ButtonContainer>
                <Button
                  type={Button.TYPE.PRIMARY}
                  btnText="Confirm"
                  height={40}
                  size={180}
                  onClick={handleOnSubmit}
                />
            </ButtonContainer>
          </Col>
          {/*<Col lg={isTablet ? 6 : 4} md={12} sm={12}>
            <QuestionContainer>
              <QuestionBox
                question={Object.keys(form).splice(0, Object.keys(form).length)}
                form={form}
                handleOnSubmit={handleOnSubmit}
              />
            </QuestionContainer>
              </Col>*/}
        </Row>
      </Container>
      <ModalSurveyConfirmation messageKey="remoteClassroomSurveyModalMessage" btnKey="remoteClassroomSurveyModalBtn" redirectLink={`${routes.remoteclassroom.path}/${remoteClassroomId}`} />
    </>
  );
};

RemoteClassroomSurvey.propTypes = {
  remoteClassroomId: PropTypes.number.isRequired,
  // HOC (connect, dispatch)
  getSurveyContent: PropTypes.func.isRequired,
  setSurveyInsert: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  // HOC (connect, state)
  location: PropTypes.object,
  selected_survey: PropTypes.object
};

export default connect(
  state => {
    const { selected_survey } = state.remoteclassroomSurvey;
    const { location } = state.router;
    const { vocabulary } = state.app;

    return {
      location,
      selected_survey,
      vocabulary
    };
  },
  dispatch => ({
    getSurveyContent: id =>
      dispatch({
        type: REMOTE_CLASSROOM_SURVEY_CONTENT_GET._REQUEST,
        id
      }),
    setSurveyInsert: ({ remoteClassroomId, answers, id, description }) =>
      dispatch({
        type: REMOTE_CLASSROOM_SET_INSERT_SURVEY._REQUEST,
        remoteClassroomId,
        answers,
        id,
        description
      }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(RemoteClassroomSurvey));
