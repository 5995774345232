import styled, { css } from 'styled-components';

export const AccordionContainer = styled.div`
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 200px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #5c5c5c;
  margin: 0 -20px 0px -20px;

  ${props => props.isOpen
    && css`
      border: none;
      background-color: transparent;
    `}
`;

export const AccordionContent = styled.div`
  cursor: pointer;
  padding: 0px 20px;
`;

export const Icon = styled.span`
  font-size: 20px;
  ${props => props.visibleBody
    && css`
      transform: rotate(-180deg);
    `}
`;

export const Body = styled.div`
  width: 100%;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in, opacity 0.2s ease-in;


  ${props => props.isOpen
    && css`
      min-height: auto;
      height: 100%;
      opacity: 1;
      padding: 20px 20px 0px;
      margin-top: 20px;
      overflow: visible;
    `}
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.mobileL} {
    align-items: flex-start;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.brownGrey};
  font-size: 14px;

  @media ${props => props.theme.device.mobileL} {
    padding-right: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Label = styled.div`
  margin-left: 18px;
  font-size: 14px;
  color: #fff;

  @media ${props => props.theme.device.mobileL} {
    margin-left: 0;
  }
`;
