import produce from 'immer';
import {
  OPTIONAL_COURSES_CATEGORIES_GET,
  OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS,
  OPTIONAL_COURSES_DETAILS_GET,
  OPTIONAL_COURSES_LIST_GET, OPTIONAL_COURSES_LO_SET_COMMENT,
  OPTIONAL_COURSES_LO_SET_LIKEDISLIKE, OPTIONAL_COURSES_LO_SET_VIEWS,
  OPTIONAL_COURSES_LO_UNSET,
} from '../actions/optionalcourses';

const initialState = {
  data: [],
  pager: {},
  categories: [],
  selectedOptionalCourses: {},
  contentNotAvailable: false
};

const mandatoryTrainingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case OPTIONAL_COURSES_LIST_GET._SUCCESS: {
      const { data, pager, subset } = action;
      const { courses } = data;
      draft.data = subset ? [...state.data, ...courses] : courses;
      draft.pager = pager;
      break;
    }

    case OPTIONAL_COURSES_CATEGORIES_GET._SUCCESS: {
      const { categories } = action;
      draft.categories = categories;
      break;
    }

    case OPTIONAL_COURSES_DETAILS_GET._SUCCESS: {
      const { data: selectedOptionalCourses } = action;
      const { lo_list = [] } = selectedOptionalCourses;
      draft.selectedOptionalCourses = {
        ...selectedOptionalCourses,
        lo_list: [...lo_list].reduce((acc, val, index, array) => [...acc, { ...val, index, last_item: index === array.length - 1 }], [])
      };
      break;
    }
    case OPTIONAL_COURSES_DETAILS_GET._REQUEST: {
      draft.contentNotAvailable = false;
      break;
    }
    case OPTIONAL_COURSES_DETAILS_GET._ERROR: {
      draft.contentNotAvailable = true;
      break;
    }
    case OPTIONAL_COURSES_LO_SET_LIKEDISLIKE._SUCCESS: {
      const { nid, action_type } = action;
      const { selectedOptionalCourses } = state;
      const { lo_list } = selectedOptionalCourses;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(nid)) {
          const { like, dislike, user_actions: { like: isLike, dislike: isDislike } } = lo_item;
          return [
            ...acc,
            {
              ...lo_item,
              like: action_type === 'like' ? like + 1 : (like !== 0 && (isLike || isDislike)) ? like - 1 : 0,
              dislike: action_type === 'dislike' ? dislike + 1 : (dislike !== 0 && (isLike || isDislike)) ? dislike - 1 : 0,
              user_actions: {
                like: action_type === 'like',
                dislike: action_type === 'dislike'
              }
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedOptionalCourses = {
        ...selectedOptionalCourses,
        lo_list: remappedLOs
      };
      break;
    }
    case OPTIONAL_COURSES_COURSE_DETAILS_SET_VIEWS._SUCCESS: {
      draft.selectedOptionalCourses = {
        ...state.selectedOptionalCourses,
        user_actions: {
          views: true
        }
      };
      break;
    }
    case OPTIONAL_COURSES_LO_SET_VIEWS._SUCCESS: {
      const { lo_id } = action;
      const { selectedOptionalCourses } = state;
      const { lo_list } = selectedOptionalCourses;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(lo_id)) {
          return [
            ...acc,
            {
              ...lo_item,
              user_actions: {
                ...lo_item.user_actions,
                views: true
              }
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedOptionalCourses = {
        ...selectedOptionalCourses,
        lo_list: remappedLOs
      };
      break;
    }
    case OPTIONAL_COURSES_LO_SET_COMMENT._SUCCESS: {
      const { payload, lo_id } = action;
      const { selectedOptionalCourses } = state;
      const { lo_list } = selectedOptionalCourses;
      const remappedLOs = [...lo_list].reduce((acc, lo_item) => {
        if (Number(lo_item.id) === Number(lo_id)) {
          const { comments } = lo_item;
          return [
            ...acc,
            {
              ...lo_item,
              comments: [payload, ...comments]
            }
          ];
        }
        return [...acc, lo_item];
      }, []);
      draft.selectedOptionalCourses = {
        ...selectedOptionalCourses,
        lo_list: remappedLOs
      };
      break;
    }
    case OPTIONAL_COURSES_LO_UNSET._SUCCESS: {
      draft.selectedOptionalCourses = initialState.selectedOptionalCourses;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default mandatoryTrainingReducer;
