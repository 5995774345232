import styled, { css } from 'styled-components';
import {
  NavBarHeight,
  NavBarHeightMobile,
  SubNavBarHeight,
  SubNavBarHeightMobile,
  FooterHeight,
  FooterHeightMobile,
  NavBarSecondRowMobile
} from '../../../const';

export const PageContainer = styled.div`
  min-height: calc(
    100vh - ${NavBarHeight}px - ${SubNavBarHeight}px - ${FooterHeight}px
  );
  margin-top: calc(${NavBarHeight}px + ${SubNavBarHeight}px);
  width: 100%;

  ${props => props.arabic && css`
    text-align: right !important;
  `};

  @media ${props => props.theme.device.tablet} {
    min-height: calc(
      100vh - ${NavBarHeightMobile}px - ${SubNavBarHeightMobile}px -
        ${FooterHeightMobile}px - ${NavBarSecondRowMobile}px
    );
    margin-top: calc(
      ${NavBarHeightMobile}px + ${SubNavBarHeightMobile}px);
  }
`;

export const ColoredLinesContainer = styled.div`
  position: fixed;
  top: ${NavBarHeight}px;
  right: 0;
  height: 2px;
  width: 100vw;
  display: flex;
  z-index: 21;

  @media ${props => props.theme.device.tablet} {
    top: calc(${NavBarHeight}px + ${SubNavBarHeightMobile}px);
  }
`;

export const ColoredLinesBar = styled.div`
  height: 2px;
  width: 33.3333%;
  display: inline-block;
  &:nth-child(1) {
    background-image: ${props => props.theme.colors.gradientBlue};
  }
  &:nth-child(2) {
    background-image: ${props => props.theme.colors.gradientGreen};
  }
  &:nth-child(3) {
    background-image: ${props => props.theme.colors.grandientYellow};
  }
`;
