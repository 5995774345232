import styled from 'styled-components';

const GuidebookContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  padding: 0px 0px 96px;

  @media ${props => props.theme.device.tablet} {
    padding-top: 20px;
    margin-top: 0;
  }
`;

const Text = styled.div`
  text-align: center;
  padding: 50px;
  font-size: smaller;

  @media ${props => props.theme.device.mobileL} {
    padding: 15px;
  }
`;

const GuidebookListColumn = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media ${props => props.theme.device.tablet} {
    justify-content: center;
  }

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
`;

const GuidebookItemColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  width: 100%;

  > div:first-child {
    width: 100%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: auto;
    margin: 10px 0px;
    max-width: 100%;
  }
`;

const RowGuidebook = styled.div`
  display: flex;
  min-width: 100%;
  flex-wrap: wrap;
  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
  }
`;

export {
  GuidebookContainer,
  GuidebookListColumn,
  GuidebookItemColumn,
  RowGuidebook,
  Text
};
