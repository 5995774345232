import React from 'react';
import PropTypes from 'prop-types';
import Typeface, { TypefaceInterface } from './index';
import { FontBase } from '../../const';
import theme from '../theme';

const baseConfig = {
  color: 'unset',
  fontFamily: 'arial',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  size: FontBase
};

const types = {
  1: {
    ...baseConfig
  },
  title: {
    ...baseConfig,
    fontWeight: 'bold',
    lineHeight: 1.25
  },
  description: {
    ...baseConfig,
    size: 12,
    fontStyle: 'italic',
    lineHeight: 1.33,
    fontWeight: 'normal'
  },
  description14: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  titleHome: {
    ...baseConfig,
    fontWeight: 'bold',
    size: 36
  },
  descriptionHome: {
    ...baseConfig,
    size: 15,
    lineHeight: 1.47
  },
  newsDate: {
    ...baseConfig,
    size: 10,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  backButton: {
    ...baseConfig,
    size: 12,
    fontWeight: 500,
    lineHeight: 1.33
  },
  newsTitle: {
    ...baseConfig,
    fontWeight: 'bold',
    size: 14
  },
  newsSubtitle: {
    ...baseConfig,
    fontWeight: 'normal',
    lineHeight: 1.11,
    size: 18
  },
  newsDescription: {
    ...baseConfig,
    fontWeight: 'normal',
    size: 12
  },
  newsDescriptionDate: {
    ...baseConfig,
    fontWeight: 'normal',
    size: 10
  },
  newsDetailTitle: {
    ...baseConfig,
    fontWeight: 'normal',
    lineHeight: 1.4,
    size: 50
  },
  tojDetailsTile: {
    ...baseConfig,
    lineHeight: 1.2,
    size: 50
  },
  newsDetailDescription: {
    ...baseConfig,
    fontWeight: 'normal',
    size: 18,
    lineHeight: 1.1
  },
  newsDateItalic: {
    ...baseConfig,
    size: 12,
    lineHeight: '16px',
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  newsAttachment: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.33,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  sectionTitle: {
    ...baseConfig,
    size: 18,
    lineHeight: '20px',
    fontWeight: 'bold'
  },
  sectionNotificationTitle: {
    ...baseConfig,
    size: 30,
    lineHeight: 0.67
  },
  tojTitle: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.11
  },
  guidebookCardDate: {
    ...baseConfig,
    size: 10,
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 'normal'
  },
  guidebookCardAttachment: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'bold'
  },
  guidebookCardText: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.17,
    fontWeight: 'normal'
  },
  guidebookCategory: {
    ...baseConfig,
    size: 14,
    lineHeight: '16px',
    fontWeight: 'normal'
  },
  editorialTitle: {
    ...baseConfig,
    size: 32,
    lineHeight: 1.38,
    fontWeight: 'bold'
  },
  editorialSubtitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5,
    fontWeight: 'normal'
  },
  productCardTitle: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.29,
    fontWeight: 'bold'
  },
  productCardDetail: {
    ...baseConfig,
    size: 10,
    lineHeight: 1,
    fontWeight: 'normal'
  },
  collectionCardTitle: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.11
  },
  collectionCardFooter: {
    ...baseConfig,
    size: 10,
    fontWeight: 'normal',
    lineHeight: 1
  },
  like: {
    ...baseConfig,
    size: 16,
    lineHeight: 0.63,
    fontWeight: 'normal'
  },
  videoTitle: {
    ...baseConfig,
    size: 26,
    lineHeight: 1.15,
    fontWeight: 'bold'
  },
  videoSubTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.25,
    fontWeight: 'normal'
  },
  editorialImageTitle: {
    ...baseConfig,
    size: 20,
    lineHeight: 1.3,
    fontWeight: 'bold'
  },
  agendaTimelineTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.13,
    fontWeight: 'bold',
    fontStretch: 'normal'
  },
  editorialImageText: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5,
    fontWeight: 'normal'
  },
  tab: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.25,
    fontWeight: 'normal'
  },
  activeTab: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.25,
    fontWeight: 'bold'
  },
  SubMenuElement: {
    ...baseConfig,
    size: 14,
    fontWeight: 'bold'
  },
  scrollUp: {
    ...baseConfig,
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.33
  },
  collectionHeaderTitle: {
    ...baseConfig,
    size: 20,
    fontWeight: 'bold',
    lineHeight: 'normal'
  },
  categoryPill: {
    size: 10,
    fontWeight: 'bold'
  },
  progressInfo: {
    size: 12,
    lineHeight: 1.17,
    letterspacing: '-0.1px',
    textTransform: 'uppercase'
  },
  coursesHeaderLength: {
    size: 12,
    lineHeight: 1.17,
    letterSpacing: '-0.1px'
  },
  coursesHeaderLengthBold: {
    size: 14,
    fontWeight: 700,
    lineHeight: 1.29,
    letterSpacing: '-0.1px'
  },
  tagTitle: {
    size: 10,
    opacity: 0.6
  },
  tagText: {
    size: 14,
    lineHeight: 1.07
  },
  newsBannerTitle: {
    size: 50,
    lineHeight: 1.4,
    fontWeight: 'bold'
  },
  welcomeModalTitle: {
    size: 18,
    lineHeight: 1.22,
    fontWeight: 'bold'
  },
  welcomeModalSubtitle: {
    size: 14,
    lineHeight: 1.14
  },
  scheduleModalSubtitle: {
    size: 16,
    lineHeight: 1.5
  },
  resetModalTitle: {
    size: 24,
    lineHeight: 1.22,
    fontWeight: 'bold'
  },
  resetModalSubtitle: {
    size: 20,
    lineHeight: 1.14
  },
  agendaTimelineDescription: {
    size: 14,
    lineHeight: 1.29
  },
  EditorialAboutTitle: {
    size: 40,
    fontWeight: 'normal'
  },
  EditorialAboutText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.38
  },
  EditorialGridTitle: {
    size: 20,
    fontWeight: 'bold',
    lineHeight: 1.3
  },
  EditorialGridText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.5
  },
  editorialCardTitle: {
    size: 40,
    fontWeight: 'normal'
  },
  editorialCardText: {
    size: 16,
    fontWeight: 'normal',
    lineHeight: 1.5
  },
  filterLabel: {
    size: 14,
    fontWeight: 'bold',
    lineHeight: 1.29,
    marginBottom: 8
  },
  quote: {
    size: 30,
    fontWeight: 'normal',
    lineHeight: 1.33,
    fontStyle: 'italic'
  },
  author: {
    size: 16,
    fontWeight: 'bold',
    lineHeight: 1.25,
    textTransform: 'capitalize'
  },
  slotTitleAR: {
    size: 16,
    fontWeight: 'bold',
    lineHeight: 1.25
  },
  quoteDetail: {
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.5,
    textTransform: 'uppercase'
  },
  generalCardTag: {
    size: 10,
    fontWeight: 'bold',
    letterSpacing: 'normal'
  },
  addInfoGeneralCard: {
    size: 10,
    letterSpacing: 'normal'
  },
  bannerGeneralCard: {
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  button: {
    size: 12,
    fontWeight: 'bold'
  },
  comingSoonTitle: {
    size: 60,
    fontWeight: 'bold',
    lineHeight: 1.17
  },
  maintenanceDescription: {
    size: 26,
    fontWeight: 'normal',
    lineHeight: 1
  },
  teacher: {
    size: 16,
    lineHeight: 1.5,
    fontWeight: 'bold',
    fontStretch: 'normal',
    textTransform: 'capitalize'
  },
  coaching: {
    size: 14,
    lineHeight: 0.71,
    fontWeight: 'bold',
    fontStretch: 'normal'
  },
  tojDetailInfo: {
    size: 10
  },
  coachingSpan: {
    size: 14,
    lineHeight: 0.71,
    fontWeight: 'normal',
    fontStretch: 'normal'
  },
  contentForYou: {
    size: 14,
    lineHeight: 1.29,
    fontWeight: 'bold',
    fontStretch: 'normal'
  },
  titlePeople: {
    size: 11,
    lineHeight: 1.09,
    textAlign: 'center'
  },
  slot: {
    ...baseConfig,
    size: 10,
    lineHeight: 1,
    color: theme().colors.brownGrey
  },
  slotTitle: {
    ...baseConfig,
    size: 14,
    lineHeight: 0.7,
    textTransform: 'uppercase'
  },
  slotWaiting: {
    size: 18
  },
  slotDate: {
    ...baseConfig,
    size: 18,
    lineHeight: 1.11,
    fontWeight: 'bold'
  },
  slotTime: {
    ...baseConfig,
    size: 10,
    lineHeight: 1,
    color: theme().colors.white
  },
  scheduledTime: {
    ...baseConfig,
    size: 15
  },
  learningEvents: {
    ...baseConfig,
    size: 50,
    lineHeight: 0.8
  },
  agendaCardTag: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.25
  },
  surveySessionTitle: {
    ...baseConfig,
    size: 21,
    lineHeight: 1
  },
  surveySessionTitleShareResults: {
    ...baseConfig,
    fontWeight: 'normal',
    lineHeight: 1.4,
    size: 50,
    textTransform: 'uppercase'
  },
  forgotPassword: {
    ...baseConfig,
    size: 12,
    lineHeight: 1.33
  },
  resetPassword: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.22
  },
  forgotPasswordTitle: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.22
  },
  forgotPasswordSubTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5
  },
  confirmationForgotPasswordTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.56,
    fontWeight: 'bold'
  },
  confirmationForgotPasswordSubTitle: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.56
  },
  bannerCardLearningEvents: {
    ...baseConfig,
    size: 14,
    fontWeight: 'bold'
  },
  greetings: {
    ...baseConfig,
    size: 40,
    fontWeight: 'bold'
  },
  greetingsMessage: {
    ...baseConfig,
    size: 20,
    lineHeight: 1.5
  },
  viewMore: {
    ...baseConfig,
    size: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  carouselTitle: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.11
  },
  carouselShowAll: {
    ...baseConfig,
    size: 12,
    fontWeight: 'bold',
    lineHeight: 1.33
  },
  expDateCourse: {
    ...baseConfig,
    size: 10
  },
  courseExpireDate: {
    ...baseConfig,
    size: 10,
    color: theme().colors.brownGrey
  },
  contactHRLabel: {
    ...baseConfig,
    size: 14
  },
  eventToScheduleLabel: {
    ...baseConfig,
    size: 18,
    fontWeight: 'bold',
    lineHeight: 1.11
  },
  tojName: {
    ...baseConfig,
    size: 10,
    lineHeight: 1
  },
  tojSessionDuration: {
    ...baseConfig,
    size: 10,
    lineHeight: 1
  },
  infoParticipationLabel: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.29,
    fontWeight: 'bold'
  },
  infoParticipationValue: {
    ...baseConfig,
    size: 14,
    lineHeight: 1.29
  },
  waitingScheduleLabel: {
    ...baseConfig,
    size: 18,
    lineHeight: 1.11
  },
  modalScheduleTitle: {
    ...baseConfig,
    size: 18,
    lineHeight: 1.22,
    fontWeight: 'bold'
  },
  modalScheduleLabels: {
    ...baseConfig,
    size: 16,
    lineHeight: 1.5
  },
  slotError: {
    size: 12,
    fontWeight: 'bold'
  }
};

const Arial = ({
  type, configuration, children, style, vocabulary, onClick
}) => (
  <Typeface
    style={style}
    configuration={{ ...types[type], ...configuration }}
    vocabulary={vocabulary}
    onClick={onClick}
  >
    {children}
  </Typeface>
);

Arial.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface,
  vocabulary: PropTypes.string
};

Arial.defaultProps = {
  type: '1'
};

export default Arial;
