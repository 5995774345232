import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import {
  // getChangeLanguageMock,
  getConfigMock, getFaqMock, getVocabularyMock
} from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getConfig: () => {
    if (shouldUseMock()) {
      return getConfigMock();
    }
    return apiCall(`${baseUrl}${extendUrl}platform_configuration`, 'GET');
  },
  getFaq: (filters) => {
    if (shouldUseMock()) {
      return getFaqMock();
    }
    return apiCall(`${baseUrl}${extendUrl}faq${parseQueryParams(filters)}`, 'GET');
  },
  getVocabulary: (filters) => {
    if (shouldUseMock()) {
      return getVocabularyMock();
    }
    return apiCall(`${baseUrl}${extendUrl}vocabulary${parseQueryParams(filters)}`, 'GET');
  },
  // changeLanguage: (params) => {
  //   if (shouldUseMock()) {
  //     return getChangeLanguageMock();
  //   }
  //   return apiCall(`${baseUrl}${extendUrl}user/change_language`, 'POST', params);
  // }
};
