import React from 'react';
import PropTypes from 'prop-types';

import {
  ContentCardContainer, Title, Subtitle, BackgroundGradient
} from './style';
import Arial from '../../typography/arial';

const ContentCard = ({
  title, subtitle, background, onClick
}) => (
  <ContentCardContainer background={background} onClick={onClick}>
    <Title><Arial configuration={{ size: 10 }}>{title}</Arial></Title>
    <Subtitle><Arial configuration={{ size: 24, fontWeight: 'bold', lineHeight: 1.25 }}>{subtitle}</Arial></Subtitle>
    <BackgroundGradient />
  </ContentCardContainer>
);

ContentCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func
};

ContentCard.defaultProps = {
  title: 'CHI SIAMO'
};

export default ContentCard;
