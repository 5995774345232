import styled, { css } from 'styled-components';
import Logo from './index';

export const LogoContainer = styled.div`
  display: inline-block;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  ${props => {
    switch (props.size) {
      case Logo.SIZE.SMALL:
        return css`
          height: auto;
          width: 75px;
        `;

      case Logo.SIZE.MEDIUM:
      default:
        return css`
          height: auto;
          width: 130px;
        `;

      case Logo.SIZE.LARGE:
        return css`
          height: auto;
          width: 120px;
        `;
      case Logo.SIZE.XL:
        return css`
          height: auto;
          width: 130px;
        `;
    }
  }};
`;

export const LogoImg = styled.img`
  height: 100%;
  width: 100%;
`;
