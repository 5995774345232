import {
  takeLatest, put, call
} from 'redux-saga/effects';

import {
  LOADER_CLOSE,
  LOADER_OPEN,
  USER_INFO_GET, VOCABULARY_GET
} from '../actions';

import user from '../../api/user';
import storage from '../../../utils/storage';

export function isArabic() {
  const language = storage.read('default_language')?.value;
  return language?.toLowerCase() === 'ar';
}

function* userInfoGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data: response } = {} } = yield call(user.getInfo);
    yield put({ type: USER_INFO_GET._SUCCESS, response });
    yield put({ type: VOCABULARY_GET._REQUEST });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > user > userInfoGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* userInfoGetWatch() {
  yield takeLatest(USER_INFO_GET._REQUEST, userInfoGet);
}

export default [
  userInfoGetWatch()
];
