import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Label, Input, CustomCheckbox } from './style';

const Checkbox = ({
  label, name, onChange, checked, value: eValue
}) => {
  const [value, setValue] = useState(eValue);
  const handleOnChange = ({ currentTarget: { value: newValue } = {} }) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  return (
    <Label>
      {label || checked}
      <Input checked={checked} type="checkbox" name={`checkbox-${name}`} value={value} onChange={handleOnChange} />
      <CustomCheckbox />
    </Label>
  );
};


Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
