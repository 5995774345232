import styled from 'styled-components';

const Container = styled.div`
    width: 65px;
    height: 65px;
    
    .CircularProgressbar {
    
        .CircularProgressbar-trail {
            stroke:  ${props => props.theme.colors.greyLight};
        }
        
        .CircularProgressbar-path {
            stroke: ${props => props.theme.colors.greenLight};
        }
        
        .CircularProgressbar-text {
            font-family: Roboto;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.49;
            letter-spacing: normal;
            text-align: center;
            fill: ${props => props.theme.colors.primaryBlack};
            color: ${props => props.theme.colors.primaryBlack};
        }
    }
`;

export default Container;
