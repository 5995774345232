import React from 'react';
import PropTypes from 'prop-types';

import { FilterPillContainer, Label, Remove } from './style';

const FilterPill = ({ label, value, onRemove }) => {
  const handleOnRemove = () => {
    onRemove(value);
  };

  return (
    <FilterPillContainer>
      <Label>{label}</Label>
      {onRemove && <Remove onClick={handleOnRemove} className="icon-close" />}
    </FilterPillContainer>
  );
};

FilterPill.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func
};

export default FilterPill;
