import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TabsContainer, TabItem } from './style';
import Arial from '../../typography/arial';

const Tabs = ({
  tabs, onChange, tabType, selected, color, classList = ''
}) => {
  const [selectedTab, setSelectedTab] = useState(selected);

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  const handleOnclickTab = (tab) => {
    setSelectedTab(tab);
    if (onChange) onChange(tab);
  };

  return (
    <TabsContainer tabType={tabType} color={color}>
      {tabs.map((tab) => (
        <TabItem onClick={() => handleOnclickTab(tab)} key={tab.value} className={tab.label === selectedTab?.label ? `${classList} active` : `${classList}`} color={color}>
          <Arial type="activeTab">{tab.label}</Arial>
        </TabItem>
      ))}
    </TabsContainer>
  );
};

Tabs.TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MANDATORY: 'mandatory',
  OPTIONALCOURSES: 'optionalcourses'
};

Tabs.defaultProps = {
  tabType: Tabs.TYPE.SECONDARY
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabType: PropTypes.string,
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string
};

export default Tabs;
