import produce from 'immer';
import { TOJ_SURVEY_CONTENT_GET, TOJ_SURVEY_SESSION_CONTENT_GET } from '../actions/tojsurvey';

const initialState = {
  selected_survey: {},
  selected_survey_session: {}
};

const surveyReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case TOJ_SURVEY_CONTENT_GET._SUCCESS: {
      const { response } = action;
      // eslint-disable-next-line prefer-destructuring
      draft.selected_survey = {
        ...state.selected_survey,
        ...response
      };
      break;
    }
    case TOJ_SURVEY_SESSION_CONTENT_GET._SUCCESS: {
      const { response } = action;
      // eslint-disable-next-line prefer-destructuring
      draft.selected_survey_session = {
        ...state.selected_survey_session,
        ...response
      };
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default surveyReducer;
