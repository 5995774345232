import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';

import moment from 'moment';
import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
import {
  MANDATORY_TRAINING_CATEGORIES_GET,
  MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS,
  MANDATORY_TRAINING_DETAILS_GET,
  MANDATORY_TRAINING_LIST_GET,
  MANDATORY_TRAINING_LO_SET_COMMENT,
  MANDATORY_TRAINING_LO_SET_LIKEDISLIKE,
  MANDATORY_TRAINING_LO_SET_VIEWS,
  MANDATORY_TRAINING_SET_FAVOURITE,
} from '../actions/mandatorytraining';
import mandatorytraining from '../../api/mandatorytraining';
import { calcPagination } from '../../../utils/pagination';
import { getDefaultLanguage } from '../../../utils/locale';
import { truncateWithDots } from '../../../utils/common';
import { DRUPAL_MAX_TITLE_LENGTH } from '../../../const';

function* mandatoryTraningGet({
  loader = true, subset = false, area, progress,
}) {
  try {
    if (loader) yield put({ type: LOADER_OPEN });
    const { pager: { current_page, total_pages } = {} } = yield select(state => state.mandatorytraining);
    const { next_page, isLastPage } = calcPagination(subset ? { current_page, total_pages } : {});
    if (!isLastPage) {
      const { defaultLanguage } = getDefaultLanguage();
      const completeFilters = {
        items_per_page: 24, page: next_page, area, progress, lang: defaultLanguage, type: 'selection',
      };
      const { data: { data, pager } = {} } = yield call(mandatorytraining.getMandatoryTrainingList, completeFilters);
      yield put({
        type: MANDATORY_TRAINING_LIST_GET._SUCCESS, data, pager, subset,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > mandatorytraining > mandatoryTraningGet', err);
  } finally {
    if (loader) yield put({ type: LOADER_CLOSE });
  }
}

function* mandatoryTraningGetWatch() {
  yield takeLatest(MANDATORY_TRAINING_LIST_GET._REQUEST, mandatoryTraningGet);
}

function* mandatoryTrainingDetailsGet({ id }) {
  if (id) {
    try {
      yield put({ type: LOADER_OPEN });
      const { defaultLanguage } = getDefaultLanguage();
      const { data: { data = {} } = {} } = yield call(mandatorytraining.getMandatoryTrainingDetails, { id, lang: defaultLanguage });
      yield put({ type: MANDATORY_TRAINING_DETAILS_GET._SUCCESS, data });
    } catch (err) {
      yield put({ type: MANDATORY_TRAINING_DETAILS_GET._ERROR });
      // eslint-disable-next-line no-console
      console.log('sagas > mandatorytraining > mandatoryTrainingDetailsGet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* mandatoryTrainingDetailsGetWatch() {
  yield takeLatest(MANDATORY_TRAINING_DETAILS_GET._REQUEST, mandatoryTrainingDetailsGet);
}

function* favouriteSet({ nid }) {
  if (nid) {
    try {
      const payload = { nid, node_type: 'selection', action_type: 'favourite' };
      yield call(mandatorytraining.setNodeAction, payload);
      yield put({ type: MANDATORY_TRAINING_SET_FAVOURITE._SUCCESS });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > mandatorytraining > favouriteSet', err);
    }
  }
}

function* favouriteSetWatch() {
  yield takeLatest(MANDATORY_TRAINING_SET_FAVOURITE._REQUEST, favouriteSet);
}

function* loSetLikeDislike({ nid, action_type }) {
  if (nid && action_type) {
    try {
      const payload = { nid, action_type, node_type: 'learning_object' };
      yield call(mandatorytraining.setNodeAction, payload);
      yield put({ type: MANDATORY_TRAINING_LO_SET_LIKEDISLIKE._SUCCESS, nid, action_type });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > mandatorytraining > loSetLikeDislike', err);
    }
  }
}

function* loSetLikeDislikeWatch() {
  yield takeLatest(MANDATORY_TRAINING_LO_SET_LIKEDISLIKE._REQUEST, loSetLikeDislike);
}

function* mantdatoryTraniningDetailsSetViews({ nid }) {
  if (nid) {
    try {
      const payload = { nid, action_type: 'views', node_type: 'selection' };
      yield call(mandatorytraining.setNodeAction, payload);
      yield put({ type: MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS._SUCCESS, nid });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > mandatorytraining > mantdatoryTraniningDetailsSetViews', err);
    }
  }
}

function* mantdatoryTraniningDetailsSetViewsWatch() {
  yield takeLatest(MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS._REQUEST, mantdatoryTraniningDetailsSetViews);
}

function* loSetViews({ nid }) {
  if (nid) {
    try {
      const payload = { nid, action_type: 'views', node_type: 'learning_object' };
      yield call(mandatorytraining.setNodeAction, payload);
      yield put({ type: MANDATORY_TRAINING_LO_SET_VIEWS._SUCCESS, lo_id: nid });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > mandatorytraining > loSetViews', err);
    }
  }
}

function* loSetViewsWatch() {
  yield takeLatest(MANDATORY_TRAINING_LO_SET_VIEWS._REQUEST, loSetViews);
}

function* loSetComment({ body }) {
  try {
    const payload = {
      entity_id: [{ target_id: body.id }],
      entity_type: [{ value: 'node' }],
      comment_type: [{ target_id: 'comment' }],
      field_name: [{ value: 'comment' }],
      subject: [{ value: truncateWithDots(body.subject, DRUPAL_MAX_TITLE_LENGTH) }],
      comment_body: [{ value: body.comment_body }],
    };
    yield call(mandatorytraining.setComment, payload);
    const { data: { first_name, last_name } = {} } = yield select(state => state.user);
    yield put({
      type: MANDATORY_TRAINING_LO_SET_COMMENT._SUCCESS,
      lo_id: body.id,
      payload: {
        first_name, last_name, comment_body: body.comment_body, created: moment().unix(), changed: moment().unix(),
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > mandatorytraining > loSetLikeDislike', err);
  }
}

function* loSetCommentWatch() {
  yield takeLatest(MANDATORY_TRAINING_LO_SET_COMMENT._REQUEST, loSetComment);
}

function* categoriesGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { defaultLanguage } = getDefaultLanguage();
    const { data: { data: categories = {} } = {} } = yield call(mandatorytraining.getCategories, { lang: defaultLanguage, name: 'selection' });

    yield put({ type: MANDATORY_TRAINING_CATEGORIES_GET._SUCCESS, categories });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > mandatorytraining > categoriesGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
    yield put({ type: LOADER_CLOSE });
  }
}

function* categoriesGetWatch() {
  yield takeLatest(MANDATORY_TRAINING_CATEGORIES_GET._REQUEST, categoriesGet);
}

export default [
  mandatoryTraningGetWatch(),
  mandatoryTrainingDetailsGetWatch(),
  favouriteSetWatch(),
  loSetLikeDislikeWatch(),
  mantdatoryTraniningDetailsSetViewsWatch(),
  loSetViewsWatch(),
  loSetCommentWatch(),
  categoriesGetWatch(),
];
