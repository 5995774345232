import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 14px;
  height: 55px;

  &:last-child {
    border-top: solid 1px ${props => props.theme.colors.hazeGray};
    padding-top: 16px;
  }
`;

const Icon = styled.div.attrs(props => ({
  className: `icon ${props.icon}`
}))`
  font-size: 24px;
  color: ${props => props.theme.colors.greyLighter};
  margin-right: 24px;
`;

const Span = styled.span`
  margin-right: 4px;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.white};
`;

const DetailsContainer = styled.div``;

const Percentage = styled.div`
  display: flex;
  color: ${props => props.theme.colors.white};
`;

export {
  Container, Icon, Span, Description, DetailsContainer, Percentage
};
