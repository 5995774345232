import produce from 'immer';
import { NOTIFICATION_GET } from '../actions/notifications';

const initialState = [];

const notificationReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case NOTIFICATION_GET._SUCCESS: {
      draft = action.response;
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default notificationReducer;
