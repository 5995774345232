import app from './app';
import home from './home';
import user from './user';
import search from './search';
import modal from './modal';
import notifications from './notification';
import favourite from './favourite';
import toj from './toj';
import tojsurvey from './tojsurvey';
import remoteclassroom from './remoteclassroom';
import remoteclassroomSurvey from './remoteclassroomsurvey';
import mandatorytraining from './mandatorytraining';
import optionalcourses from './optionalcourses';
import learningevents from './learningevents';

export default {
  app,
  home,
  mandatorytraining,
  optionalcourses,
  learningevents,
  user,
  search,
  modal,
  notifications,
  favourite,
  toj,
  tojsurvey,
  remoteclassroom,
  remoteclassroomSurvey
};
