import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getNotificationMock, readNotificationMock, shareNotificationMock } from './mock';
import { parseQueryParams } from '../../utils/queryParams';

export default {
  getNotification: (filters) => {
    if (shouldUseMock()) {
      return getNotificationMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/retrieve_notification${parseQueryParams(filters)}`, 'GET');
  },
  notificationRead: ({ id }) => {
    if (shouldUseMock()) {
      return readNotificationMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/read/${id}/notification`, 'POST');
  },
  notificationShare: (body) => {
    if (shouldUseMock()) {
      return shareNotificationMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/share`, 'POST', body);
  }
};
