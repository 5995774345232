const zIndex = {
  body: 4000,
  headerFooter: 5000,
  modal: 7000,
  loader: 9000,
  absoluteUp: 9999,
  headerWrapper: 501,
  navBar: 20,
  closeModal: 5,
  foot: 2,
  box: 1
};

export default zIndex;
