import styled, { css } from 'styled-components';

export const LanguageContainer = styled.div`
  position: absolute;
  right: 10%;
  bottom: 5%;
  z-index: 2;

  @media ${props => props.theme.device.mobileL} {
    right: 22px;
  }
`;

export const Filter = styled.div`
  min-width: 68px;

  div[class*="singleValue"] {
    color: ${props => props.theme.colors.white};
    font-weight: normal;
    top: 20px;
    /* left: 3px; */
  }
  
  div[class*="control"] {
    border: 0;
    background-color: ${props => props.theme.colors.sharkLight};

    > div:first-child {
      overflow: auto;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }

  div[class*="MenuList"] {
     /* width */
     ::-webkit-scrollbar {
       width: 3px;
     }
     
     /* Track */
     ::-webkit-scrollbar-track {
       box-shadow: inset 0 0 5px grey; 
       border-radius: 10px;
     }
      
     /* Handle */
     ::-webkit-scrollbar-thumb {
       background: #989898;
       border-radius: 10px;
     }
   }

  div[class*="singleValue"] {
    color: #ffffff;
    font-weight: normal;
    top: 22px;
    /* left: 5px; */
  }

  div[class*="indicatorContainer"] {
    color: ${props => props.theme.colors.primaryYellow};
    padding: 4px;
    height: 100%;
    top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    &:after {
      display: none;
    }
  }

  div[class*="MenuList"] {
    /* width */
    ::-webkit-scrollbar {
      width: 3px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #989898;
      border-radius: 10px;
    }
  }

  div[class*="menu"] {
    border-color: ${props => props.theme.colors.doveGray};
    top: auto;
    bottom: 100%;

    > div {
      ::-webkit-scrollbar {
        width: 3px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #989898;
        border-radius: 10px;
      }
    }
  }
  
  div[class*="option"] {
    border-color: ${props => props.theme.colors.doveGray};
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const ColoredLinesContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 5px;
  width: 55vw;
  display: flex;

  @media ${props => props.theme.device.mobileL} {
    width: 100vw;
  }
`;

export const ColoredLinesBar = styled.div`
  height: 5px;
  width: 33.3333%;
  display: inline-block;
  &:nth-child(1) {
    background-image: ${props => props.theme.colors.gradientBlue};
  }
  &:nth-child(2) {
    background-image: ${props => props.theme.colors.gradientGreen};
  }
  &:nth-child(3) {
    background-image: ${props => props.theme.colors.grandientYellow};
  }
`;

export const Background = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.img});

  @media ${props => props.theme.device.mobileL} {
    height: 20vh;
    width: 100%;
    margin-bottom: 30px;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.colors.doveGray};
    opacity: 0.3;
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      ${props => props.theme.colors.transparent},
      ${props => props.theme.colors.doveGray}
    );
  }
`;

export const FormContainer = styled.div`
  max-width: 35vw;

  @media ${props => props.theme.device.tabletL} {
    max-width: unset;
    width: 60%;
  }

  @media ${props => props.theme.device.mobileL} {
    max-width: unset;
    width: 80%;
  }
`;

export const Image = styled.img`
  margin-bottom: 60px;
`;

export const InputWrapper = styled.div`
  margin-top: 20px;
  width: 380px;
  margin-right: auto;
  margin-left: auto;

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  
  @media ${props => props.theme.device.mobileL} {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: bold;

  position: absolute;
  top: 30%;
  max-width: 30vw;
  left: 10%;

  @media ${props => props.theme.device.tablet} {
    left: 5%;
  }

  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
    position: inherit;
    padding: 0 20px;
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.div`
  position: absolute;
  top: calc(30% + 48px);
  max-width: 24vw;
  line-height: 1.4;
  left: 10%;

  @media ${props => props.theme.device.tablet} {
    left: 5%;
  }

  @media ${props => props.theme.device.mobileL} {
    max-width: 100%;
    position: inherit;
    margin-bottom: 20px;
    padding: 0 20px;
  }
`;

export const ResetPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  cursor: pointer;
`;

export const ResetPasswordWrapper = styled.div`
  margin-top: 10px;
  width: 380px;
  margin-right: auto;
  margin-left: auto;

  @media ${props => props.theme.device.tablet} {
    width: auto;
  }
`;

export const LoginWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const WelcomeWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 45%;

  @media ${props => props.theme.device.mobileL} {
    height: 30%;
    width: 100%;;
  }
`;

export const AuthContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.primaryColor};
  color: ${props => props.theme.colors.white};
  height: 100vh;
  width: 100vw;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
  }

  ${({ arabic }) => arabic && css`
    flex-direction: row-reverse;

    ${ColoredLinesContainer} {
      right: auto;
      left: 0;
    }
    ${Title} {
      left: auto;
      right: 10%;
    }
    ${Subtitle} {
      left: auto;
      right: 10%;
    }
    ${LanguageContainer} {
      right: auto;
      left: 5%;

      div[class*="-option"] {
        justify-content: center !important;
      }
    }
  `}
`;
