import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Icon,
  Span,
  Description,
  DetailsContainer,
  Percentage
} from './style';

import Arial from '../../typography/arial';

const StatisticsItem = ({
  icon, percentage, score, total, description
}) => (
  <Container>
    <Icon icon={icon} />
    <DetailsContainer>
      <Percentage>
        <Span className="bold"><Arial type="description" configuration={{ fontWeight: 'bold' }}>{`${score}/${total}`}</Arial></Span>
        <Span><Arial type="description">-</Arial></Span>
        <Span><Arial type="description">{percentage}%</Arial></Span>
      </Percentage>
      <Description><Arial type="infoDescription">{description}</Arial></Description>
    </DetailsContainer>
  </Container>
);

StatisticsItem.propTypes = {
  icon: PropTypes.string,
  percentage: PropTypes.number,
  score: PropTypes.number,
  total: PropTypes.number,
  description: PropTypes.string
};

StatisticsItem.defaultProps = {
  icon: '',
  percentage: 0,
  score: 0,
  total: 0,
  description: ''
};

export default StatisticsItem;
