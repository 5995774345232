import React from 'react';
import storage from './storage';
import {
  Albania,
  BosniaAndHerzegovina,
  China,
  Croatia,
  Egypt,
  Hungary,
  Italy,
  Romania,
  Serbia,
  Slovakia,
  Slovenia,
  Ukraine,
  UnitedKingdom,
} from '../ui/assets/img/flags';

export const SHOULD_ANGLIFY = []; //'AR'];

export function isArabic() {
  const { defaultLanguage } = getDefaultLanguage();
  return defaultLanguage === 'ar';
}

export const toArabicNumbers = (num) => {
  const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
  return num.toString().replace(/[0123456789]/g, (d) => arabicNumbers[d]);
};

export function getDefaultLanguage() {
  // const { data: { lang: user_default_language = '' } } = yield select(state => state.user);
  // if (user_default_language) storage.write('default_language', user_default_language);
  const localStorage_default_language = storage.read('default_language')?.value.toLowerCase();
  const browser_default_language = window.navigator.language.indexOf('zh') < 0 ? window.navigator.language.split('-')[0] : window.navigator.language;
  return { defaultLanguage: localStorage_default_language || browser_default_language?.toLowerCase(), isInStorage: !!localStorage_default_language };
}

export function filterLanguages(languages) {
  const { defaultLanguage: lang = {} } = getDefaultLanguage();
  if (!lang || typeof (lang) !== 'string') return false;
  let res = false;
  for (const l of languages) {
    if (l.toUpperCase() === lang.toUpperCase()) {
      res = true;
      break;
    }
  }
  return res;
}

const flags = [
  { label: 'Italy', image: Italy },
  { label: 'UnitedKingdom', image: UnitedKingdom },
  { label: 'BosniaAndHerzegovina', image: BosniaAndHerzegovina },
  { label: 'China', image: China },
  { label: 'Croatia', image: Croatia },
  { label: 'Egypt', image: Egypt },
  { label: 'Hungary', image: Hungary },
  { label: 'Romania', image: Romania },
  { label: 'Serbia', image: Serbia },
  { label: 'Slovakia', image: Slovakia },
  { label: 'Slovenia', image: Slovenia },
  { label: 'Ukraine', image: Ukraine },
  { label: 'Albania', image: Albania },
];

const renderFlag = (language) => {
  const flag = flags.find((item => item.label === language));
  return (
    <div style={{ width: '20px', height: '20px' }}>
      <img style={{ width: '100%', height: 'auto' }} src={flag.image} alt={flag.label} />
    </div>
  );
};

export const optionCountry = [
  {
    label: renderFlag('UnitedKingdom'),
    value: 'EN',
  },
  {
    label: renderFlag('Albania'),
    value: 'SQ',
  },
  {
    label: renderFlag('Romania'),
    value: 'RO',
  },
  {
    label: renderFlag('Egypt'),
    value: 'AR',
  },
  {
    label: renderFlag('Hungary'),
    value: 'HU',
  },
  {
    label: renderFlag('Serbia'),
    value: 'SR',
  },
  {
    label: renderFlag('Croatia'),
    value: 'HR',
  },
  {
    label: renderFlag('Slovenia'),
    value: 'SL',
  },
  {
    label: renderFlag('China'),
    value: 'ZH-HANS',
  },
  {
    label: renderFlag('BosniaAndHerzegovina'),
    value: 'BS',
  },
  {
    label: renderFlag('Slovakia'),
    value: 'SK',
  },
  {
    label: renderFlag('Ukraine'),
    value: 'UK',
  },
  {
    label: renderFlag('Italy'),
    value: 'IT',
  },
];

export const getCountry = () => optionCountry?.find(country => country.value.toLowerCase() === storage.read('default_language')?.value?.toLowerCase())
    ?? optionCountry?.find(country => country.value.toLowerCase() === navigator.language.toLowerCase())
    ?? optionCountry?.find(country => country.value.toLowerCase() === 'EN'.toLowerCase());
