import styled, { css } from 'styled-components';
import GeneralCard from './index';

export const GeneralCardContainer = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.mineShaft2, 0.3)};
  background-image: url(${props => props.cover});
  background-size: cover;
  width: ${props => (props.isAgenda ? '260px' : '280px')};
  height: 276px;
  border-radius: 4px;
  margin: auto;
  padding: 20px;  
  transition: ${props => props.theme.constants.transitionBase};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${props => props.theme.device.tablet} {
    margin: 0;
  }

  @media ${props => props.theme.device.mobileL} {
    width: ${props => (props.isAgenda ? '290px' : '280px')};
    margin-bottom: ${props => (props.isAgenda ? '15px' : '0px')};
  }
  
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.3)};
    transition: ${props => props.theme.constants.transitionBase};
  }
  
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    &:before {
      background-color: ${props => props.theme.mixin.rgba(props.theme.colors.doveGray, 0.6)};
    }
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 6px;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.white, 0.2)};
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    width: ${({ percentage }) => `${percentage}%`};
    background-color: ${props => props.theme.colors.white};
  }
`;

const getStyleTag = ({ theme, tagType, color }) => {
  let style;
  switch (tagType) {
    case GeneralCard.TYPE.SMARTWORKING:
      style = css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};
      `;
      break;
    default:
      style = css`
        background-color: ${color || theme.colors.white};
        color: ${color ? theme.colors.white : theme.colors.black};
      `;
      break;
  }
  return style;
};



export const AdditionalInfoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const Tag = styled.div`
  border-radius: 12px;
  padding: 3px 10px;
  width: fit-content;
  overflow: hidden;
  max-width: 165px;
  margin-bottom: 10px;
  height: auto;
  ${props => getStyleTag(props)};
`;

export const AdditionalInfo = styled.div`
  color: ${props => props.theme.colors.white};
`;


export const Icons = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Favorite = styled.div`
  color: ${props => props.theme.colors.white};
`;

export const Completed = styled.div`

`;

export const Icon = styled.span`
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
  color: ${props => props.theme.colors.white};
  margin-right: 5px;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 0;
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.white};
  min-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
 

  & > div {
  white-space: normal;
  max-width: 240px; 
  }
`;

export const CardDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
  
  &:nth-child(3) {
    justify-content: flex-end;
     & > div {
      width: fit-content;
    }
  }
`;

const getStyleBanner = ({ theme, status }) => {
  let style;
  switch (status) {
    case 'in scadenza':
    case 'scaduto':
      style = css`
        &:before {
          background-color: ${theme.colors.darkRed}
        }
        &:after {
          background-color: ${theme.colors.darkRed}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.redError}
          }
        }
      `;
      break;
    case 'completato':
      style = css`
        &:before {
          background-color: ${theme.colors.salem}
        }
        &:after {
          background-color: ${theme.colors.salem}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.greenLight}
          }
        }
      `;
      break;
    case 'in corso':
      style = css`
        &:before {
          background-color: ${theme.colors.azureDark}
        }
        &:after {
          background-color: ${theme.colors.azureDark}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.azureLight}
          }
        }
      `;
      break;
    case 'da iniziare':
    default:
      style = css`
        &:before {
          background-color: ${theme.colors.orangeDarker}
        }
        &:after {
          background-color: ${theme.colors.orangeDarker}
        }
        & > div {
          &:after {
            background-color: ${theme.colors.orangeDark}
          }
        }
      `;
      break;
  }
  return style;
};

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 170px;
  color: ${props => props.theme.colors.white};
  z-index: 0;
  position: absolute;
  top: 35px;
  right: -37px;
  transform: rotate(45deg);
  text-transform: uppercase;
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    transform: rotate(-45deg);
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 4px;
    width: 10px;
    transform: rotate(45deg);
  }
  
  & > div {
    z-index: 1;
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 5px;
    
    &:after {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: -1;
    }
  }
  
  ${props => getStyleBanner(props)};
`;

export const IconCompleted = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.yellowOrange};
  background: white;
  overflow:hidden;
  border-radius:50%;
  background-color: ${props => props.theme.colors.white};
  border: 0.5px solid ${props => props.theme.colors.white};
`;
