import { apiCall, baseUrl, extendUrl } from './config';
import { shouldUseMock } from '../../utils/common';
import { getUserInfoMock } from './mock';

export default {
  getInfo: () => {
    if (shouldUseMock()) {
      return getUserInfoMock();
    }
    return apiCall(`${baseUrl}${extendUrl}user/info`, 'GET');
  }
};
