import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import moment from 'moment';
import backgroundImage from '../../../ui/assets/img/top-bg.png';

import {
  HOME_FAVOURITE_LIST_GET, HOME_LEARNING_EVENTS_LIST_GET,
  HOME_MANDATORY_TRAINING_LIST_GET,
  HOME_OPTIONAL_COURSES_LIST_GET, HOME_SET_FAVOURITE, HOME_TOJ_PLANNED_LIST_GET,
} from '../../redux/actions/home';
import {
  HomeBanner, HomeContainer, BackgroundImage,
  Greetings, GreetingsMessage, HomeBannerContainer,
  HomeContentContainer, CarouselHeader, CarouselContainer,
  CarouselTitle, ViewAll, Icon, BlackContainer,
} from './style';
import Arial from '../../../ui/typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { Settings } from './settings';
import { CustomCarousel } from '../../../ui/components';
import { CardEventsCourses, CardLearningEvents } from '../../../ui/atoms';
import CardCourses from '../../../ui/atoms/CardCourses';
import routes from '../../../routes';
import { parseQueryParams } from '../../../utils/queryParams';
import { isArabic } from '../../../utils/locale';

const Home = ({
  getLearningEventsList, getMandatoryTrainingList, getOptionalCoursesList, getFavouriteList,
  getTOJPlannedList, setFavourite, pushUrl, mandatory_training, optional_courses, favourites,
  learning_events, userName, vocabulary, plannedTOJ,
}) => {
  useEffect(() => {
    getLearningEventsList();
    const start_date_timestamp = moment(new Date(`${new Date().getMonth() + 1}/1/${new Date().getFullYear()}`)).unix() * 1000;
    const end_date_timestamp = moment(`${moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('MM/DD/YYYY')} 23:59:59`, 'MM/DD/YYYY HH:mm:ss').unix() * 1000;
    getTOJPlannedList({ start_date_timestamp, end_date_timestamp });
    getMandatoryTrainingList();
    getOptionalCoursesList();
    getFavouriteList();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectMandatoryTrainingDetails = (id) => {
    pushUrl(`${routes.mandatorytraining.path}/${id}`);
  };

  const redirectOptionalTrainingDetails = (id) => {
    pushUrl(`${routes.optionalcourses.path}/${id}`);
  };

  const redirectLearningEventsDetails = (item) => {
    const {
      id, tutee_id, role, deadline,
    } = item;
    pushUrl(`${routes.toj.path}/${id}${parseQueryParams({ tutee_id, role, deadline })}`);
  };

  const onClickFavouriteCard = ({ id, type }) => {
    if (type === 'selection') redirectMandatoryTrainingDetails(id);
    if (type === 'collection') redirectOptionalTrainingDetails(id);
  };

  const onClickSetFavourite = ({ id, type }) => {
    if (type === 'selection') setFavourite({ nid: id, node_type: type });
    if (type === 'collection') setFavourite({ nid: id, node_type: type });
  };

  return (
    <HomeContainer arabic={isArabic()}>
      <HomeBanner>
        <BackgroundImage src={backgroundImage} />
        <HomeBannerContainer>
          <Greetings>
            <Arial type="greetings" vocabulary="greetings">
              { isArabic() ? (
                `,${userName} ${getValueVocabulary(vocabulary.greetings, 'greetings')}`
              ) : (
                `${getValueVocabulary(vocabulary.greetings, 'greetings')} ${userName},`
              )
              }
            </Arial>
          </Greetings>
          <GreetingsMessage><Arial type="greetingsMessage" vocabulary="greetingsMessage">{getValueVocabulary(vocabulary.greetingsMessage, 'greetingsMessage')}</Arial></GreetingsMessage>
        </HomeBannerContainer>
      </HomeBanner>
      <HomeContentContainer>
        {/* LEARNING EVENTS */}
        {[...learning_events, ...plannedTOJ].splice(0, 10).length > 0
          && (
            <CarouselContainer>
              <CarouselHeader>
                <CarouselTitle><Arial type="carouselTitle" vocabulary="learningEvents">{getValueVocabulary(vocabulary.learningEvents, 'learningEvents')}</Arial></CarouselTitle>
                <ViewAll onClick={() => pushUrl(routes.learningevents.path)}><Arial type="carouselShowAll" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial><Icon className="icon-arrow-right" /></ViewAll>
              </CarouselHeader>
              <CustomCarousel config={Settings}>
                {
                  [...learning_events, ...plannedTOJ].splice(0, 10).map((item, index) => (item.agenda_type === 'toj' || item.agenda_type === 'remote_classroom'
                    ? <CardEventsCourses height={270} width={275} key={`learning-events-${index}`} item={item} isCarousel />
                    : <CardLearningEvents key={`learning-events-${index}`} onClickCard={() => redirectLearningEventsDetails(item.values)} item={item} />))
                }
              </CustomCarousel>
            </CarouselContainer>
          )
        }
        {/* MANDATORY TRAINING */}
        {mandatory_training.length > 0
        && (
          <CarouselContainer>
            <CarouselHeader>
              <CarouselTitle><Arial type="carouselTitle" vocabulary="mandatoryTraining">{getValueVocabulary(vocabulary.mandatoryTraining, 'mandatoryTraining')}</Arial></CarouselTitle>
              <ViewAll onClick={() => pushUrl(routes.mandatorytraining.path)}><Arial type="carouselShowAll" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial><Icon className="icon-arrow-right" /></ViewAll>
            </CarouselHeader>
            <CustomCarousel config={Settings}>
              {
                mandatory_training.map((item, index) => <CardCourses key={`mandartory-traning-${index}`} item={item} onClickCard={() => redirectMandatoryTrainingDetails(item.id)} onClickFavourite={() => onClickSetFavourite(item)} />)
              }
            </CustomCarousel>
          </CarouselContainer>
        )
        }
        {/* OPTIONAL COURSES */}
        {optional_courses.length > 0
        && (
          <CarouselContainer>
            <CarouselHeader>
              <CarouselTitle><Arial type="carouselTitle" vocabulary="optionalCourses">{getValueVocabulary(vocabulary.optionalCourses, 'optionalCourses')}</Arial></CarouselTitle>
              <ViewAll onClick={() => pushUrl(routes.optionalcourses.path)}><Arial type="carouselShowAll" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial><Icon className="icon-arrow-right" /></ViewAll>
            </CarouselHeader>
            <CustomCarousel config={Settings}>
              {
                optional_courses.map((item, index) => <CardCourses key={`optional-courses-${index}`} item={item} onClickCard={() => redirectOptionalTrainingDetails(item.id)} onClickFavourite={() => onClickSetFavourite(item)} />)
              }
            </CustomCarousel>
          </CarouselContainer>
        )
        }
      </HomeContentContainer>
      {/* FAVOURITES */}
      {favourites.length > 0
      && (
        <BlackContainer>
          <HomeContentContainer paddingTop="0">
            <CarouselContainer marginBottom="0">
              <CarouselHeader>
                <CarouselTitle><Arial type="carouselTitle" vocabulary="favouritesTitle">{getValueVocabulary(vocabulary.favouritesTitle, 'favouritesTitle')}</Arial></CarouselTitle>
                <ViewAll onClick={() => pushUrl(routes.favourites.path)}><Arial type="carouselShowAll" vocabulary="showAll">{getValueVocabulary(vocabulary.showAll, 'showAll')}</Arial><Icon className="icon-arrow-right" /></ViewAll>
              </CarouselHeader>
              <CustomCarousel config={Settings}>
                {
                  favourites.map((item, index) => <CardCourses key={`favourties-${index}`} item={item} onClickCard={() => onClickFavouriteCard(item)} onClickFavourite={() => onClickSetFavourite(item)} />)
                }
              </CustomCarousel>
            </CarouselContainer>
          </HomeContentContainer>
        </BlackContainer>
      )
      }
    </HomeContainer>
  );
};

Home.propTypes = {
  // HOC (connect, state)
  mandatory_training: PropTypes.array.isRequired,
  optional_courses: PropTypes.array.isRequired,
  favourites: PropTypes.array.isRequired,
  learning_events: PropTypes.array.isRequired,
  userName: PropTypes.string,
  vocabulary: PropTypes.object.isRequired,
  plannedTOJ: PropTypes.array.isRequired,
  //HOC (connect, dispatch)
  getLearningEventsList: PropTypes.func.isRequired,
  getTOJPlannedList: PropTypes.func.isRequired,
  getMandatoryTrainingList: PropTypes.func.isRequired,
  getOptionalCoursesList: PropTypes.func.isRequired,
  getFavouriteList: PropTypes.func.isRequired,
  setFavourite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const {
      mandatory_training, optional_courses, favourites, learning_events, plannedTOJ,
    } = state.home;
    const { first_name: userName } = state.user.data;
    const { vocabulary } = state.app;
    return {
      mandatory_training,
      optional_courses,
      favourites,
      learning_events,
      userName,
      vocabulary,
      plannedTOJ,
    };
  },
  dispatch => ({
    getLearningEventsList: () => dispatch({ type: HOME_LEARNING_EVENTS_LIST_GET._REQUEST }),
    getTOJPlannedList: (filters) => dispatch({ type: HOME_TOJ_PLANNED_LIST_GET._REQUEST, filters }),
    getMandatoryTrainingList: () => dispatch({ type: HOME_MANDATORY_TRAINING_LIST_GET._REQUEST }),
    getOptionalCoursesList: () => dispatch({ type: HOME_OPTIONAL_COURSES_LIST_GET._REQUEST }),
    getFavouriteList: () => dispatch({ type: HOME_FAVOURITE_LIST_GET._REQUEST }),
    setFavourite: ({ nid, node_type }) => dispatch({ type: HOME_SET_FAVOURITE._REQUEST, nid, node_type }),
    pushUrl: url => dispatch(push(url)),
  })
)(Home);
