import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CustomCarouselContainer } from './style';
import { isArabic } from '../../../utils/locale';

const CustomCarousel = ({
  children, config, onSlideChange, isAgenda,
}) => {
  const [arabic, setArabic] = useState(false);
  const slider = useRef();
  useEffect(() => {
    setArabic(isArabic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    if (arabic && slider.current) {
      const currentBreakpoint = config.responsive.filter((item) => item.breakpoint === slider.current.state.breakpoint);
      if (currentBreakpoint.length) slider.current.slickGoTo(children.length - currentBreakpoint[0].settings.goToSlide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arabic]);

  return (
    <CustomCarouselContainer isAgenda={isAgenda} arabic={arabic}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading*/}
      <Slider ref={slider} {...config} rtl={arabic} dir={arabic ? 'rtl' : 'ltr'} afterChange={onSlideChange}>{children}</Slider>
    </CustomCarouselContainer>
  );
};

CustomCarousel.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({ responsive: PropTypes.array }),
  responsive: PropTypes.array,
  onSlideChange: PropTypes.func,
};

export default CustomCarousel;
