import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  Category, ProductCoursesContainer, Description, ProductCoursesListContainer,
  HeaderContainer, HeaderInfoContainer, Iframe, MainSection, ProgressContainer,
  ProgressWrapper, ProductCourseHeader, ProductCourseClock,
  ProductCourseHeaderLength, ProductCourseHeaderDuration, ProductCourseList,
  VideoContainer, VideoInfo, VideoInfoWrapper,
  VideoSocialWrapper, Like, Social, ProductCoursesListCount,
  Info, ExpireDate, Frame, SocialTitle, ButtonContainer, CourseNotAvailable,
} from './style';
import { BackButton, ProgressBar } from '../../../ui/atoms';
import routes from '../../../routes';
import { Comments, LearningObjectCard } from '../../../ui/components';
import Arial from '../../../ui/typography/arial';
import Button from '../../../ui/atoms/Button';
import { convertDuration, durationConvert, getExactLabel } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import HtmlRaw from '../../../ui/atoms/HtmlRaw';
import dateFormat from '../../../utils/date-format';
import {
  MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS,
  MANDATORY_TRAINING_DETAILS_GET,
  MANDATORY_TRAINING_LO_SET_COMMENT,
  MANDATORY_TRAINING_LO_SET_LIKEDISLIKE,
  MANDATORY_TRAINING_LO_SET_VIEWS,
  MANDATORY_TRAINING_LO_UNSET,
} from '../../redux/actions/mandatorytraining';
import { isArabic } from '../../../utils/locale';

const MandatoryTrainingDetails = ({
  course_id, lo_id, course_details: data, vocabulary,
  getMandatoryTrainingDetails, setLikeDislike, setMandatoryDetailsCourseViews, resetMandatoryTrainingDetails,
  setLOViews, setComment, pushUrl, contentNotAvailable, location
}) => {
  const [selectedLO, setSelectedLO] = useState();
  const [scormFullscreen, setScormFullscreen] = useState(false);
  const [scormFrameIsLoaded, setScormFrameIsLoaded] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [showCourse, setShowCourse] = useState(false);

  useEffect(() => {
    setIsSelection(location?.includes("mandatory-training"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location]);

  useEffect(() => {
    setShowCourse(data.type === 'selection' && isSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelection, data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMandatoryTrainingDetails(course_id);
    return () => resetMandatoryTrainingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_id, setScormFullscreen]);

  useEffect(() => {
    const { lo_list = [] } = data;
    if (Object.keys(data).length > 0 && !data?.user_actions?.views) setMandatoryDetailsCourseViews(course_id);
    if (lo_list.length > 0) {
      const isCourseCompleted = [...lo_list].filter(item => (item.status !== 'completed' && item.status !== 'passed')).length <= 0;
      const selectedDefaultLO = [...lo_list].find((item) => item.id === Number(lo_id));

      if (isCourseCompleted && !lo_id) {
        //If all LOs are completed and there's no selected LO the first LO will be selected
        setSelectedLO(lo_list[0]);
        pushUrl(`${routes.mandatorytraining.path}/${course_id}/${lo_list[0]?.id}`);
      } else if (isCourseCompleted && selectedDefaultLO) {
        //If all LOs are completed and there's a selected LO
        setSelectedLO(selectedDefaultLO);
      } else if (!isCourseCompleted && selectedDefaultLO && !selectedDefaultLO.blocked) {
        // If not all LOs are completed and there's selected LO that is also not blocked
        setSelectedLO(selectedDefaultLO);
      } else {
        // Select the first LO available if the conditions above are not meet
        const firstAvailableLO = lo_list.find(lo => lo.status !== 'completed' && lo.status !== 'passed') ?? lo_list[0]?.id;
        setSelectedLO(firstAvailableLO);
        pushUrl(`${routes.mandatorytraining.path}/${course_id}/${firstAvailableLO?.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedLO && !selectedLO?.user_actions?.views) setLOViews(selectedLO.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLO]);

  const onClickLOItem = (item) => {
    if (!item.blocked) {
      setSelectedLO(item);
      pushUrl(`${routes.mandatorytraining.path}/${course_id}/${item.id}`);
      getMandatoryTrainingDetails(course_id);
    }
  };

  const onClickNext = () => {
    const { lo_list } = data;
    if ((selectedLO.status === 'completed' || selectedLO.status === 'passed') && !selectedLO?.last_item) {
      pushUrl(`${routes.mandatorytraining.path}/${course_id}/${lo_list[selectedLO.index + 1].id}`);
    } else {
      pushUrl(`${routes.mandatorytraining.path}/${course_id}`);
    }
    getMandatoryTrainingDetails(course_id);
  };

  /*This block of code is use to open the iframe in fullscreen mode*/
  // const windowOpen = (uri) => {
  //   setScormFullscreen(true);
  //   const scormWindow = window.open(uri, '', 'width="+screen.availWidth+",height="+screen.availHeight');
  //   setInterval(() => {
  //     if (scormWindow.closed) {
  //       pushUrl(`${routes.mandatorytraining.path}/${course_id}`);
  //       window.location.reload();
  //       clearInterval();
  //     }
  //   }, 500);
  // };

  const calcPercentage = (items = []) => {
    const { status = '', selection_type } = data;
    const completed = items.reduce((acc, item) => acc += (item.status === 'completed' || item.status === 'passed') ? 1 : 0, 0);
    const percentage = completed * 100 / items.length;
    if (status !== 'completed' && selection_type === 'open' && percentage === 100) return percentage - 10;
    return percentage;
  };

  const handleOnclickLikeDislike = (action_type) => {
    setLikeDislike({ nid: selectedLO.id, action_type });
  };

  const handleOnSubmitComment = (value) => {
    setComment({
      id: selectedLO?.id,
      subject: selectedLO?.title,
      comment_body: value,
    });
  };

  return (
    <ProductCoursesContainer arabic={isArabic()}>
      <Container className="p-0">
        <BackButton url={routes.mandatorytraining.path} />
        {(!contentNotAvailable && showCourse) ? (
          <>
            <HeaderContainer>
              <HeaderInfoContainer>
                <Info>
                  {data?.category && (
                    <Category>
                      <Arial type="categoryPill">{data.category.name}</Arial>
                    </Category>
                  )}
                  {data?.expire_date && data?.expire_date ? (
                    <ExpireDate>
                      <Arial type="courseExpireDate">
                        { isArabic() ? (
                          `${dateFormat(data.expire_date, '', getValueVocabulary(vocabulary.dateFormat, 'dateFormat'))} :${getValueVocabulary(vocabulary.expireDate, 'expireDate')}`
                        ) : (
                          `${getValueVocabulary(vocabulary.expireDate, 'expireDate')}: ${dateFormat(data.expire_date, '', getValueVocabulary(vocabulary.dateFormat, 'dateFormat'))}`
                        )}
                      </Arial>
                    </ExpireDate>
                  ) : null}
                </Info>
                <SocialTitle>
                  <Arial type="collectionHeaderTitle">{data?.title}</Arial>
                </SocialTitle>
              </HeaderInfoContainer>
              <ProgressContainer>
                <ProgressWrapper>
                  <Arial type="progressInfo">{getValueVocabulary(vocabulary.completion, 'completion')}</Arial>
                  <Arial type="progressInfo">{calcPercentage(data?.lo_list).toFixed(0)}%</Arial>
                </ProgressWrapper>
                <ProgressBar state="mandatory_training" percentage={calcPercentage(data.lo_list)} />
              </ProgressContainer>
            </HeaderContainer>
            <MainSection>
              <Row className={isArabic() && 'd-flex flex-row-reverse'}>
                <Col sm={12} md={data?.lo_list?.length > 1 ? 8 : 12} lg={data?.lo_list?.length > 1 ? 8 : 12}>
                  {selectedLO ? (
                    <>
                      <VideoContainer>
                        {scormFullscreen
                          ? (<Frame><Arial type="editorialCardText" vocabulary="fullScreenMode">{getValueVocabulary(vocabulary.fullScreenMode, 'fullScreenMode')}</Arial></Frame>)
                          : (
                            <Iframe
                              title={selectedLO?.title}
                              src={selectedLO?.content}
                              id="scorm_frame"
                              onLoad={() => setScormFrameIsLoaded(true)}
                            />
                          )
                        }
                      </VideoContainer>
                      <VideoInfo>
                        <ButtonContainer>
                          {/*{selectedLO?.content && <Button btnText={getValueVocabulary(vocabulary.btnFullscreen, 'btnFullscreen')} onClick={() => windowOpen(selectedLO?.content)} height={20} />}*/}
                          {data?.lo_list?.length > 1
                            && <Button type="mandatorytraining" btnText={getValueVocabulary(vocabulary.btnNext, 'btnNext')} onClick={onClickNext} height={20} />
                          }
                        </ButtonContainer>
                        <VideoInfoWrapper>
                          <Arial type="sectionTitle">{selectedLO?.title}</Arial>
                          <Social socialTop>
                            <span className="icon-visualizzazioni" />
                            <span style={{ fontSize: 12, color: 'white', marginTop: '2px' }}>
                              {selectedLO?.views}
                            </span>
                          </Social>
                        </VideoInfoWrapper>
                        <Description title={selectedLO?.description?.replace(/<[^>]*>/g, '')}>
                          <Arial type="description14"><HtmlRaw html={selectedLO?.description || ''} /></Arial>
                        </Description>
                        <VideoSocialWrapper>
                          <Like>
                            <Social isActive={selectedLO?.user_actions?.like} onClick={() => (!selectedLO?.user_actions?.like ? handleOnclickLikeDislike('like') : '')}>
                              <span className="icon-like" />
                              <Arial type="like">
                                {selectedLO?.like}
                              </Arial>
                            </Social>
                            <Social isActive={selectedLO?.user_actions?.dislike} onClick={() => (!selectedLO?.user_actions?.dislike ? handleOnclickLikeDislike('dislike') : '')}>
                              <span className="icon-nonutile" />
                              <Arial type="like">
                                {selectedLO?.dislike}
                              </Arial>
                            </Social>
                          </Like>
                        </VideoSocialWrapper>
                      </VideoInfo>
                      <Comments type="mandatorytraining" list={selectedLO?.comments} onSubmit={handleOnSubmitComment} />
                    </>
                  ) : null}
                </Col>
                {data?.lo_list?.length > 1 && (
                  <Col sm={12} md={4} lg={4}>
                    <ProductCoursesListContainer>
                      <ProductCourseHeader>
                        <ProductCourseHeaderLength>
                          <ProductCoursesListCount>
                            <Arial type="coursesHeaderLengthBold">{data?.lo_list?.length}</Arial>
                            <Arial type="coursesHeaderLength" vocabulary="totalLearningObject">{getExactLabel(vocabulary, data?.lo_list?.length, 'totalLearningObject', 'totalLearningObjects')}</Arial>
                          </ProductCoursesListCount>
                          <ProductCourseHeaderDuration>
                            <ProductCourseClock className="icon-clock" />
                            <Arial configuration={{ size: 10 }}>
                              { isArabic() ? (
                                `${getValueVocabulary(vocabulary.courseDetailDuration, 'courseDetailDuration')} ${durationConvert(data.duration)}`
                              ) : (
                                `${durationConvert(data.duration)} ${getValueVocabulary(vocabulary.courseDetailDuration, 'courseDetailDuration')}`
                              )
                              }
                            </Arial>
                          </ProductCourseHeaderDuration>
                        </ProductCourseHeaderLength>
                      </ProductCourseHeader>
                      <ProductCourseList>
                        {scormFrameIsLoaded && data.lo_list.map(item => (/* should wait for iframe to call for ../gate.html? */
                          <LearningObjectCard
                            key={item.code}
                            item={item}
                            label={getValueVocabulary(vocabulary.videoDurationMin, 'videoDurationMin')}
                            isActive={item?.id?.toString() === selectedLO?.id?.toString()}
                            onClick={() => onClickLOItem(item)}
                            arabic={isArabic()}
                          />
                        ))
                        }
                      </ProductCourseList>
                    </ProductCoursesListContainer>
                  </Col>
                )}
              </Row>
            </MainSection>
          </>
        ) : (
          <CourseNotAvailable>
            <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="contentNotAvailableMessage">
              {getValueVocabulary(vocabulary.contentNotAvailableMessage, 'contentNotAvailableMessage')}
            </Arial>
          </CourseNotAvailable>
        )}
      </Container>
    </ProductCoursesContainer>
  );
};

MandatoryTrainingDetails.propTypes = {
  course_id: PropTypes.string.isRequired,
  lo_id: PropTypes.string,

  // HOC (connect, state)
  contentNotAvailable: PropTypes.bool,
  course_details: PropTypes.object.isRequired,
  vocabulary: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getMandatoryTrainingDetails: PropTypes.func.isRequired,
  resetMandatoryTrainingDetails: PropTypes.func.isRequired,
  setLikeDislike: PropTypes.func.isRequired,
  setMandatoryDetailsCourseViews: PropTypes.func.isRequired,
  setLOViews: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { selectedMandatoryTraining: course_details, contentNotAvailable } = state.mandatorytraining;
    const { vocabulary } = state.app;
    const { pathname: location } = state.router.location;
    return {
      course_details,
      vocabulary,
      contentNotAvailable,
      location
    };
  },
  dispatch => ({
    getMandatoryTrainingDetails: id => dispatch({ type: MANDATORY_TRAINING_DETAILS_GET._REQUEST, id }),
    resetMandatoryTrainingDetails: () => dispatch({ type: MANDATORY_TRAINING_LO_UNSET._SUCCESS }),
    setLikeDislike: ({ nid, action_type }) => dispatch({ type: MANDATORY_TRAINING_LO_SET_LIKEDISLIKE._REQUEST, nid, action_type }),
    setMandatoryDetailsCourseViews: (nid) => dispatch({ type: MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS._REQUEST, nid }),
    setLOViews: (nid) => dispatch({ type: MANDATORY_TRAINING_LO_SET_VIEWS._REQUEST, nid }),
    setComment: (body) => dispatch({ type: MANDATORY_TRAINING_LO_SET_COMMENT._REQUEST, body }),
    pushUrl: url => dispatch(push(url)),
  })
)(MandatoryTrainingDetails);
