import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  background-color: ${props => props.theme.colors.sharkLight};
  color: ${props => props.theme.colors.white};
  padding: 20px 53px 25px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 4px;
  @media ${props => props.theme.device.mobileL} {
    flex-direction: column;
    padding: 20px;
  }
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 75%;
  align-items: self-start;

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > div {
    width: fit-content;
  }
`;

export const ExpireDate = styled.div`
  > div {
    color: #a3a6b2;
  }
`;

export const ProgressContainer = styled.div`
  width: 25%;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }

  @media ${props => props.theme.device.mobileL} {
    width: 100%;
  }
`;

export const ProgressWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  color: #a3a6b2;

  div:last-child {
    text-align: right;
  }
`;
export const Category = styled.div`
  color: white;
  padding: 3px 10px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.buttercup};
  text-transform: capitalize;
  width: fit-content;
  display: table;
  margin-right: 20px;
`;

export const MainSection = styled.div`
  margin-top: 20px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.mixin.rgba(props.theme.colors.black, 0.4)};
  min-height: 550px;
  width: 100%;
  margin-bottom: 20px;
`;

export const VideoInfo = styled.div`
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid #575a64;
`;

export const VideoInfoWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const VideoSocialWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  div:first-child {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:first-child {
      margin-right: 10px;
    }
  }
`;

export const TagContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding: 20px 0;

  > div:first-child {
    margin-bottom: 10px;
  }
`;

export const ProductCoursesListContainer = styled.div``;

export const ProductCourseHeader = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;

  font-size: 12px;

  span {
    span {
      margin-right: 6px;
    }
  }
`;

export const ProductCourseClock = styled.span`
  font-size: 16px;
  margin-right: 5px;
  margin-left: auto;
`;

export const ProductCourseHeaderLength = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ProductCourseHeaderDuration = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  color: #a3a6b2;

  > div {
    width: fit-content;
  }
`;


export const ProductCourseList = styled.div`
  height: 100%;
  max-height: 480px;
  width: 100%;
  -ms-overflow-style: scrollbar; /* IE and Edge */
  scrollbar-width: scrollbar; /* Firefox */
  scrollbar-base-color: #5c5c5c;
  scrollbar-face-color: #5c5c5c;
  scrollbar-3dlight-color: #5c5c5c;
  scrollbar-highlight-color: #5c5c5c;
  scrollbar-track-color: #323232;
  scrollbar-arrow-color: #323232;
  scrollbar-shadow-color: #5c5c5c;
  scrollbar-dark-shadow-color: #5c5c5c;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    width: 5px;
    background: #1f2025;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a3a6b2;
    border-radius: 3px;

    &:hover {
    background: #82848e;
    }
  }
`;

export const ProductCourseElementCounter = styled.div`
  margin-right: 5px;
  font-size: 12px;
  width: 14px;
  text-align: center;
  color: ${props => props.theme.colors.brownGrey};
`;

export const ProductCoursesListCount = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: fit-content;
    width: auto;
  }

  > div:first-child {
    margin-right: 10px;
  }
`;

export const Iframe = styled.iframe`
  min-height: 550px;
  height: auto;
  height: calc(${props => props.frameHeight} + 30px);
  overflow: hidden;
  width: 100%;
`;

export const Social = styled.div`
  color: ${props => (props.isActive
    ? props.theme.colors.azureDark
    : props.theme.colors.brownGrey)};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${props => (props.socialTop ? '50px' : 'unset')};

  @media ${props => props.theme.device.mobileL} {
    margin-left: ${props => (props.socialTop ? '0' : 'unset')};
    align-self: ${props => (props.socialTop ? 'baseline' : 'unset')};
  }

  > span {
    font-size: ${props => (props.socialTop ? '25px' : '20px')};
    margin-right: 9px;
  }
  div,
  span {
    cursor: ${props => (props.socialTop ? 'default' : 'pointer')};
    transition: ${props => props.theme.constants.transitionBase};
  }
  &:hover {
    div,
    span {
      opacity: ${props => (props.socialTop ? '1' : '0.6')};
    }
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.brownGrey};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 18px;
`;

export const Like = styled.div`
  > div {
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
`;

export const Frame = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.black40};
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SocialTitle = styled.div`
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobileL} {
    flex-direction: row;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > button {
    width: 100px;
    margin: 0 0 20px 10px;
  }

  & > button:nth-child(2n) {
     min-width: 180px;
     width: auto;
     white-space: nowrap;
  }
  
  @media ${props => props.theme.device.mobileL} {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0;
    
    & > button {
      margin: 0;
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
`;

export const CourseNotAvailable = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;

  > div {
    line-height: 1.17;

    @media ${props => props.theme.device.mobileL} {
      font-size: 22px;
    }
  }
`;

export const ProductCoursesContainer = styled.div`
  color: ${props => props.theme.colors.white};

  ${({ arabic }) => arabic && css`
    ${HeaderContainer} {
      flex-direction: row-reverse;
      @media ${props => props.theme.device.mobileL} {
        flex-direction: column;
        padding: 20px;
      }
    }
    ${Info} {
      flex-direction: row-reverse;
    }
    ${ProgressWrapper} {
      flex-direction: row-reverse;

      div:last-child {
        text-align: left;
      }
    }
    ${HeaderInfoContainer} {
      align-items: flex-end;
    }
    ${Category} {
      margin-right: 0;
      margin-left: 20px;
    }

    // List Video Section
    ${ProductCourseHeaderLength} {
      flex-direction: row-reverse;
    }
    ${ProductCourseClock} {
      margin-left: 5px;
    }
    ${ProductCourseHeaderDuration} {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    ${ProductCoursesListCount} {
      flex-direction: row-reverse;
      > div:first-child {
        margin-right: 0;
        margin-left: 10px;
      }
    }
    ${SocialTitle} {
      direction: rtl;
    }

    // Video Info
    ${ButtonContainer} {
      justify-content: flex-start;
    }
    ${VideoInfoWrapper} {
      > div:first-child {
        direction: rtl;
      }
    }
    ${VideoSocialWrapper} {
      flex-direction: row-reverse;
      div:first-child {
        align-items: center;
        display: inline-flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;

        div:first-child {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
    ${Like} {
      flex-direction: row-reverse !important;
    }
    ${Social} {
      flex-direction: row-reverse;
      > span.icon-like,
      > span.icon-nonutile {
        margin-right: 0;
        margin-left: 9px;
        transform: scale(-1, 1);
      }
    }
    
    ${Description} {
      > div {
        direction: rtl;
      }
    `}
`;
