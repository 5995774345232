import styled from 'styled-components';

const BackButtonContainer = styled.div.attrs(() => ({
  className: 'back-button'
}))`
  padding: 20px 0;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    padding: 20px;
  }
`;

const Span = styled.span.attrs(() => ({
  className: 'back'
}))`
  color: ${props => props.theme.colors.white};
  cursor: pointer;
`;

export { BackButtonContainer, Span };
