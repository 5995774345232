import React from 'react';
import PropTypes from 'prop-types';

import { Label, Input, CustomRadio } from './style';

const RadioButton = ({
  label,
  name,
  value,
  onChange,
  checked,
  isToj
}) => {
  const handleOnChange = (e) => {
    const { currentTarget } = e;
    if (onChange) onChange(currentTarget.value);
  };
  return (
    <Label isToj={isToj}>
      {label}
      <Input type="radio" name={name} value={value} onChange={handleOnChange} checked={checked} />
      <CustomRadio />
    </Label>
  );
};


RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  checked: PropTypes.bool,
  isToj: PropTypes.bool
};

export default RadioButton;
