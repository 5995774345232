import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';

import {
  SearchContainer, ResultContainer, SectionTitle, Icon, SearchWord, TabsContainer,
  TotalCount, TotalCountLabel, TotalCountNumber, ItemsContainer, CardContainer,
  InfoContainer, HeaderContent, Title,
} from './style';
import { Col, Container } from '../../../ui/gridSystem';
import routes from '../../../routes';
import { Tabs, NoResult, CardCourses } from '../../../ui/atoms';
import Arial from '../../../ui/typography/arial';
import { defaultTabsValue } from '../../../utils/common';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import {
  SEARCH_MANDATORY_TRAINING_LIST_GET,
  SEARCH_OPTIONAL_COURSES_LIST_GET,
  SEARCH_SET_FAVOURITE,
} from '../../redux/actions/search';
import { isArabic } from '../../../utils/locale';

const Search = ({
  getMandatoryTrainingList, getOptionalCoursesList, setFavourite,
  pushUrl, mandatory_training, optional_courses, search_word, vocabulary,
}) => {
  useEffect(() => {
    if (search_word.length <= 0) {
      pushUrl(routes.home.path);
    } else {
      window.scrollTo(0, 0);
      getMandatoryTrainingList(search_word);
      getOptionalCoursesList(search_word);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_word]);


  const defaultTabs = defaultTabsValue(vocabulary, mandatory_training.length, optional_courses.length);

  const [selectedTab, setSelectedTab] = useState(defaultTabs[0]);

  const handleOnchangeTab = (value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    setSelectedTab(defaultTabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatory_training, optional_courses]);

  const redirectMandatoryTrainingDetails = (id) => {
    pushUrl(`${routes.mandatorytraining.path}/${id}`);
  };

  const redirectOptionalTrainingDetails = (id) => {
    pushUrl(`${routes.optionalcourses.path}/${id}`);
  };

  const onClickSetFavourite = ({ id, type }) => {
    if (type === 'selection') setFavourite({ nid: id, node_type: type });
    if (type === 'collection') setFavourite({ nid: id, node_type: type });
  };

  return (
    <SearchContainer arabic={isArabic()}>
      <Container className="p-0">
        <SectionTitle>
          <Icon className="icon-search" />{mandatory_training.length + optional_courses.length} {getValueVocabulary(vocabulary.searchTotalResult, 'searchTotalResult')} <SearchWord>{search_word}</SearchWord>
        </SectionTitle>
        <TabsContainer>
          <Tabs tabType={Tabs.TYPE.PRIMARY} tabs={[...defaultTabs]} onChange={handleOnchangeTab} selected={selectedTab} />
        </TabsContainer>

        {/* ALL - MANDATORY TRAINING */}
        {(selectedTab.value === 'all' || selectedTab.value === 'mandatoryTraining') && (
          <>
            {mandatory_training.length > 0 && (
              <ResultContainer>
                <Col lg={12} sm={12}>
                  <InfoContainer>
                    <HeaderContent>
                      <Title><Arial type="sectionTitle" vocabulary="mandatoryTraining">{getValueVocabulary(vocabulary.mandatoryTraining, 'mandatoryTraining')}</Arial></Title>
                    </HeaderContent>
                  </InfoContainer>
                  <TotalCount>
                    {isArabic() ? (
                      `${mandatory_training.length} :${getValueVocabulary(vocabulary.searchResultsLabel, 'searchResultsLabel')}`
                    ) : (
                      `${getValueVocabulary(vocabulary.searchResultsLabel, 'searchResultsLabel')}: ${mandatory_training.length}`
                    )}
                  </TotalCount>
                </Col>
                <Col lg={12} sm={12}>
                  <ItemsContainer>
                    {(mandatory_training || []).map(item => (
                      <CardContainer sm={12} md={6} lg={3} key={`obbligatory-${item.id}`}>
                        <CardCourses item={item} onClickCard={() => redirectMandatoryTrainingDetails(item.id)} onClickFavourite={() => onClickSetFavourite(item)} />
                      </CardContainer>
                    ))}
                  </ItemsContainer>
                </Col>
              </ResultContainer>
            )}
          </>
        )}
        {((selectedTab.value === 'mandatoryTraining' && mandatory_training?.length === 0) && <NoResult />)}

        {/* ALL - OPTIONAL COURSES */}
        {(selectedTab.value === 'all' || selectedTab.value === 'optionalCourses') && (
          <>
            {optional_courses.length > 0 && (
              <ResultContainer>
                <Col lg={12} sm={12}>
                  <InfoContainer>
                    <HeaderContent>
                      <Title><Arial type="sectionTitle" vocabulary="optionalCourses">{getValueVocabulary(vocabulary.optionalCourses, 'optionalCourses')}</Arial></Title>
                    </HeaderContent>
                  </InfoContainer>
                  <TotalCount>
                    <TotalCountLabel>{getValueVocabulary(vocabulary.searchResultsLabel, 'searchResultsLabel')} </TotalCountLabel>
                    <TotalCountNumber>{optional_courses.length}</TotalCountNumber>
                  </TotalCount>
                </Col>
                <Col lg={12} sm={12}>
                  <ItemsContainer>
                    {(optional_courses || []).map(item => (
                      <CardContainer sm={12} md={6} lg={3} key={`obbligatory-${item.id}`}>
                        <CardCourses item={item} onClickCard={() => redirectOptionalTrainingDetails(item.id)} onClickFavourite={() => onClickSetFavourite(item)} />
                      </CardContainer>
                    ))}
                  </ItemsContainer>
                </Col>
              </ResultContainer>
            )}
          </>
        )}
        {((selectedTab.value === 'optionalCourses' && optional_courses?.length === 0) && <NoResult />)}
        {(selectedTab.value === 'all' && mandatory_training?.length === 0 && optional_courses?.length === 0) && <NoResult />}
      </Container>
    </SearchContainer>
  );
};

Search.propTypes = {
  // HOC (connect, state)
  search_word: PropTypes.string,
  mandatory_training: PropTypes.array.isRequired,
  optional_courses: PropTypes.array.isRequired,
  vocabulary: PropTypes.object.isRequired,
  //HOC (connect, dispatch)
  getMandatoryTrainingList: PropTypes.func.isRequired,
  getOptionalCoursesList: PropTypes.func.isRequired,
  setFavourite: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { search_word, mandatory_training, optional_courses } = state.search;
    const { vocabulary } = state.app;
    return {
      search_word, mandatory_training, optional_courses, vocabulary,
    };
  },
  dispatch => ({
    getMandatoryTrainingList: (title) => dispatch({ type: SEARCH_MANDATORY_TRAINING_LIST_GET._REQUEST, title }),
    getOptionalCoursesList: (title) => dispatch({ type: SEARCH_OPTIONAL_COURSES_LIST_GET._REQUEST, title }),
    setFavourite: ({ nid, node_type }) => dispatch({ type: SEARCH_SET_FAVOURITE._REQUEST, nid, node_type }),
    pushUrl: url => dispatch(push(url)),
  })
)(Search);
