import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { connect } from 'react-redux';
import { SelectContainer } from './style';
import theme from '../../theme';
import Checkbox from '../Checkbox';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';

const CustomSelect = ({
  options = [], onChange, onBlur, selected, disabled,
  placeholder = 'Select', styles = {}, readonly = false,
  formatOptionLabel, className, isSearchType = false, isClearable = false,
  isMulti = false, vocabulary, isSearchable = false,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      color: state.isSelected ? theme().colors.black
        : theme().colors.brownGrey,
      backgroundColor: state.isSelected ? theme().colors.brownGrey
        : theme().colors.black,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      lineHeight: '20px',
      minHeight: 40,
      height: 'auto',
      cursor: 'pointer',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${state.isSelected ? theme().colors.black : theme().colors.brownGrey}`,
      },
      '&:hover': {
        color: theme().colors.black,
        backgroundColor: theme().colors.brownGrey,
      },
      ...styles?.option,
    }),
    control: (provided, state, arabic = isArabic()) => ({
      ...provided,
      width: '100%',
      height: 40,
      borderRadius: 6,
      boxShadow: 'none',
      backgroundColor: !state.isDisabled ? theme().colors.black : theme().colors.black20,
      cursor: !state.isDisabled ? 'pointer' : 'not-allowed',
      border: `solid 1px ${theme().colors.brownGrey}`,
      overflow: 'hidden',
      flexDirection: arabic ? 'row-reverse' : 'row',
      '&:hover': {
        border: `solid 1px ${theme().colors.brownGrey}`,
        boxShadow: 'none',
      },
      ...styles?.controls,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme().colors.black,
      borderRadius: 6,
      border: `solid 1px ${theme().colors.brownGrey}`,
      boxShadow: 'none',
      marginTop: 10,
      overflow: 'hidden',
      height: 'auto',
      ...styles?.menu,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      ...styles?.menuList,
    }),
    dropdownIndicator: (provided, state, arabic = isArabic()) => ({
      ...provided,
      transform: state.isFocused ? 'rotate(180deg)' : null,
      ...styles?.dropdownIndicator,
      '&:after': !arabic ? {
        left: !state.isFocused ? 0 : 'unset',
        right: !state.isFocused ? 'unset' : 0,
      } : {
        left: !state.isFocused ? 'unset' : 0,
        right: !state.isFocused ? 0 : 'unset',
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: theme().colors.black,
      '&:hover': {
        color: theme().colors.black,
      },
      ...styles?.indicatorsContainer,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
      ...styles?.indicatorSeparator,
    }),
  };

  const [selectedOption, setSelectedOption] = useState(selected);

  const onchangeSelected = (value) => {
    setSelectedOption(value);
    if (onChange) onChange(value);
  };

  const handleOnBlur = (e) => {
    e.preventDefault();
    if (onBlur) onBlur(selectedOption);
  };

  useEffect(() => {
    setSelectedOption(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const [searchInput, setSearchInput] = useState('');
  let customProps = {};
  if (isSearchType) {
    customProps = {
      menuIsOpen: searchInput.length >= 3,
      isClearable: true,
    };
  }

  let selectComponents = {};
  const animatedComponents = makeAnimated();
  const Option = (props) => (
    <components.Option {...props}>
      <Checkbox checked={props.isSelected} />
      {props.label}
    </components.Option>
  );

  if (isMulti) {
    selectComponents = {
      animatedComponents,
      Option,
    };
  }

  return (
    <SelectContainer arabic={isArabic()} readonly={readonly} isSearchType={isSearchType}>
      <Select
        className={className}
        styles={customStyles}
        value={selectedOption}
        onChange={onchangeSelected}
        onBlur={handleOnBlur}
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        onInputChange={(value) => setSearchInput(value)}
        noOptionsMessage={() => <>{getValueVocabulary(vocabulary.noResults, 'noResults')}</>}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        hideSelectedOptions={false}
        components={{ ...selectComponents }}
        controlShouldRenderValue={!isMulti}
        {...customProps}
      />
    </SelectContainer>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  styles: PropTypes.object,
  customProps: PropTypes.object,
};

export default connect(
  (state) => {
    const { vocabulary } = state.app;
    return { vocabulary };
  }
)(CustomSelect);
