import { createAsyncActionType } from './index';

export const MANDATORY_TRAINING_LIST_GET = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_LIST_GET');
export const MANDATORY_TRAINING_DETAILS_GET = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_DETAILS_GET');
export const MANDATORY_TRAINING_SET_FAVOURITE = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_SET_FAVOURITE');
export const MANDATORY_TRAINING_LO_SET_LIKEDISLIKE = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_LO_SET_LIKEDISLIKE');
export const MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_COURSE_DETAILS_SET_VIEWS');
export const MANDATORY_TRAINING_LO_SET_VIEWS = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_LO_SET_VIEWS');
export const MANDATORY_TRAINING_LO_SET_COMMENT = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_LO_SET_COMMENT');
export const MANDATORY_TRAINING_CATEGORIES_GET = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_CATEGORIES_GET');
export const MANDATORY_TRAINING_LO_UNSET = createAsyncActionType('mandatorytraining', 'MANDATORY_TRAINING_LO_UNSET');
