const baseSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  lazyLoad: true,
  arrows: true,
  centerPadding: 12
};

export const Settings = {
  ...baseSettings,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        ...baseSettings,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 50,
        goToSlide: 4
      }
    },
    {
      breakpoint: 1024,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: 10,
        goToSlide: 3
      }
    },
    {
      breakpoint: 995,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        goToSlide: 3
      }
    },
    {
      breakpoint: 950,
      settings: {
        ...baseSettings,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        goToSlide: 3
      }
    },
    {
      breakpoint: 770,
      settings: {
        ...baseSettings,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        centerPadding: 20,
        goToSlide: 2
      }
    },
    {
      breakpoint: 660,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 180,
        goToSlide: 1
      }
    },
    {
      breakpoint: 645,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 160,
        goToSlide: 1
      }
    },
    {
      breakpoint: 605,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 130,
        goToSlide: 1
      }
    },
    {
      breakpoint: 545,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 50,
        goToSlide: 1
      }
    },
    {
      breakpoint: 420,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 40,
        goToSlide: 1
      }
    },
    {
      breakpoint: 380,
      settings: {
        ...baseSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 20,
        goToSlide: 1
      }
    }
  ]
};
