import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';
import { push } from 'redux-first-history';
import queryString from 'query-string';
import {
  Banner, Bottom, ButtonContainer,
  Container, SessionDate, Hour, Info,
  Title, Top, SessionDuration,
} from './style';
import Button from '../Button';
import ExampleImageCard from '../../assets/img/img-card.jpg';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import durationFormatHour from '../../../utils/duration-format-hour';
import routes from '../../../routes';
import { parseQueryParams } from '../../../utils/queryParams';
import { isArabic } from '../../../utils/locale';
import { getExactLabel } from '../../../utils/common';

const CardEventsCourses = ({
  item, btnText, height, width, isCarousel = false, pushUrl, vocabulary, location,
}) => {
  const { selectedDate } = queryString.parse(location.search);
  const [data, setData] = useState(item);

  useEffect(() => {
    setData(item);
  }, [item]);

  const agendaType = (type) => {
    switch (type) {
      default:
      case 'toj':
        return getValueVocabulary(vocabulary.tojName, 'tojName');
      case 'remote_classroom':
        return getValueVocabulary(vocabulary.remoteClassroomName, 'remoteClassroomName');
    }
  };

  const handleOnClickViewDetails = () => {
    const { tutee_id, role, deadline } = data;
    switch (data.agenda_type) {
      default:
      case 'toj': {
        pushUrl(`${routes.toj.path}/${data.id}${parseQueryParams({
          selectedDate, tutee_id, role, deadline,
        })}`);
        break;
      }
      case 'remote_classroom':
        pushUrl(`${routes.remoteclassroom.path}/${data.id}${parseQueryParams({ tutee_id })}`);
        break;
    }
  };

  const getTojSessionsDuration = (slot) => {
    const startDate = new Date(slot.startDateTimestamp);
    const endDate = new Date(slot.endDateTimestamp);
    const isSameDay = moment(startDate).isSame(endDate, 'day');
    if (isSameDay) {
      if (isArabic()) {
        return (`${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')} | ${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))}`);
      }
      return (`${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} | ${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`);
    }
    if (isArabic()) {
      return `${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')} | ${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} - ${moment(endDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))}`;
    }
    return `${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} - ${moment(endDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} | ${moment(startDate).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`;
  };

  return (
    <Container arabic={isArabic()} cover={ExampleImageCard} height={height} width={width}>
      {data.completed && (
        <Banner alt={getValueVocabulary(vocabulary.statusCompleted, 'statusCompleted').length >= 13 ? true : undefined}><Arial type="bannerCardLearningEvents" vocabulary="statusCompleted">{getValueVocabulary(vocabulary.statusCompleted, 'statusCompleted')}</Arial></Banner>
      )}
      {
        data.agenda_type === 'toj' && !data.completed && data.slotSize !== data.slots.length
        && (<Banner alt={getValueVocabulary(vocabulary.statusHalfScheduled, 'Half-scheduled').length >= 13 ? true : undefined}><Arial type="bannerCardLearningEvents" vocabulary="statusHalfScheduled">{getValueVocabulary(vocabulary.statusHalfScheduled, 'Half-scheduled')}</Arial></Banner>)
      }
      <Top>
        {
          isCarousel
            ? data.slots.map((slot, idx) => (
              <SessionDuration key={`${slot.startDateTimestamp}-${slot.endDateTimestamp}--${idx}`}>{getTojSessionsDuration(slot)}</SessionDuration>
            ))
            : (
              <>
                <SessionDate>{`${moment(data.start).format(getValueVocabulary(vocabulary.dateFormat, 'dateFormat'))} - ${moment(data.end).format(getValueVocabulary(vocabulary.dateFormat, 'dateFormat'))}`}</SessionDate>
                <Hour>{`${moment(data.start).format('h:mm A')} - ${moment(data.end).format('h:mm A')}`}</Hour>
              </>
            )
        }
      </Top>
      <Bottom>
        <Title>{data?.title}</Title>
        <Info>
          {isArabic() ? (
            <span>
              {
                `${getExactLabel(vocabulary, data.slots.length, 'sessionLabel', 'sessionsLabel')} ${data.slots.length} | ${durationFormatHour(data.duration)} | ${agendaType(data.agenda_type)}`
              }
            </span>
          ) : (
            <span>
              {
                `${agendaType(data.agenda_type)} | ${durationFormatHour(data.duration)} | ${data.slots.length} ${getExactLabel(vocabulary, data.slots.length, 'sessionLabel', 'sessionsLabel')}`
              }
            </span>
          )}
        </Info>
        <ButtonContainer>
          <Button btnText={btnText} onClick={handleOnClickViewDetails} type="eventsCourses" />
        </ButtonContainer>
      </Bottom>
    </Container>
  );
};

CardEventsCourses.propTypes = {
  item: PropTypes.object.isRequired,
  btnText: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  isCarousel: PropTypes.bool,
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
};

CardEventsCourses.defaultProps = {
  btnText: 'View Detail',
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      vocabulary,
      location,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
  })
)(CardEventsCourses);
