import styled, { css } from 'styled-components';

export const VideoContainer = styled.div`
  width: 100%;
  video {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  z-index: 99;
  right: 20px;
`;

export const ButtonsContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

export const ModalPartecipateConferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  
  > div:not(:last-child) {
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }

  ${({ arabic }) => arabic && css`
    ${ButtonsContainer} {
      flex-direction: row-reverse;
    }
  `}
`;
