import { createAsyncActionType } from './index';

export const TOJ_SURVEY_CONTENT_GET = createAsyncActionType(
  'survey',
  'TOJ_SURVEY_CONTENT_GET'
);

export const TOJ_SURVEY_SESSION_CONTENT_GET = createAsyncActionType(
  'survey',
  'TOJ_SURVEY_SESSION_CONTENT_GET'
);

export const TOJ_SET_INSERT_SURVEY = createAsyncActionType(
  'survey',
  'TOJ_SET_INSERT_SURVEY'
);

export const TOJ_SET_INSERT_SURVEY_SESSION = createAsyncActionType(
  'survey',
  'TOJ_SET_INSERT_SURVEY_SESSION'
);
