import styled from 'styled-components';

export const VideoContainer = styled.div`
  width: 100%;
  video {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  z-index: 99;
  right: 20px;
`;
