import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  VideoWithModalContainer, Image, Overlay, IconVideo
} from './style';
import { MODAL_OPEN } from '../../../app/redux/actions';
import { VideoModal } from '../../components/Modals';

const VideoWithModal = ({
  image, video, openVideoModal
}) => {
  const openVideo = () => {
    openVideoModal();
  };
  return (
    <>
      <VideoWithModalContainer>
        <Image src={image}>
          {video && (
          <Overlay onClick={() => openVideo()}>
            <IconVideo>
              <span className="icon-video"><span className="path1" /><span className="path2" /></span>
            </IconVideo>
          </Overlay>
          )}
        </Image>
      </VideoWithModalContainer>
      {video && <VideoModal videoUrl={video} />}
    </>
  );
};

VideoWithModal.propTypes = {
  image: PropTypes.string.isRequired,
  video: PropTypes.string,
  openVideoModal: PropTypes.func
};

export default connect(
  () => ({}),
  dispatch => ({
    openVideoModal: () => dispatch({ type: MODAL_OPEN, id: VideoModal.id })
  })
)(VideoWithModal);
