import React from 'react';
import Arial from '../../../ui/typography/arial';
import {
  MaintenanceContainer,
  TextContainer,
  IconContainer,
  Title
} from './style';
import IconMaintenance from '../../../ui/assets/img/maintenance.svg';

const Maintenance = () => (
  <MaintenanceContainer>
    <TextContainer>
      <Title>
        <Arial type="comingSoonTitle">
          Stiamo lavorando per tornare con tante novità!
        </Arial>
      </Title>
    </TextContainer>
    <IconContainer>
      <img src={IconMaintenance} alt="maintenance" />
    </IconContainer>
  </MaintenanceContainer>
);

export default Maintenance;
